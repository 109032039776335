import React, { useState, useCallback, useEffect } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AuthLayout from '../components/AuthLayout';
import { FormInputAutocompleteMultiSelectControl, FormInputDateControl } from '../components/FormControls';
import { useJobs } from '../hooks/useJobs';
import { useTenants } from '../hooks/useTenants';
import { toDurationHHMMSS, toMoney } from '../utilities/formatter';
import { useAuth } from '../hooks/useAuth';
import TippyToolTip from '../components/TippyToolTip';
import { ExportSimpleTableCustomHeaderToCSV } from '../components/TableFeatures';
import Loading from '../components/Loading';

interface FormInput {
    users: string[];
    dateFrom?: string;
    dateTo?: string;
}

const schema = yup
    .object({
        dateFrom: yup.string().required('Date From is required.'),
        dateTo: yup
            .string()
            .required('Date To is required.')
            .test('date-end-min', 'Date end must be after Date from', (value, context) => {
                return (context.parent.dateFrom || '') <= (value || '');
            }),
    })
    .required();

const ProgressBar = ({ percent, color }: { percent: number; color: string }) => {
    return (
        <div className="flex flex-nowrap">
            <div className="text-right pr-1" style={{ width: '70px' }}>
                {`${percent} %`}
            </div>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 h-4">
                <div
                    className="text-xs font-medium text-center p-1 leading-none rounded-full text-white h-4"
                    style={{
                        width: (percent > 100 ? 100 : percent) + '%',
                        backgroundColor: color,
                    }}
                >
                    {''}
                </div>
            </div>
        </div>
    );
};

const EmployeePerformance = () => {
    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        setValue,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const { tenant } = useAuth();

    const { useGetTenantSettings, useGetUserPerformance } = useTenants();
    const { data: dataGetTenant, isSuccess: isSuccessGetTenant, isFetching: isFetchingGetTenant } = useGetTenantSettings(true);

    const { useGetCreateJob } = useJobs();
    const { data: dataUsers, isFetching: isFetchingUsers } = useGetCreateJob(true);

    const [filter, setFilter] = useState<FormInput>();

    const [initialValuesUsers, setInitialValuesUsers] = useState<{ value: string; text: string }[]>([]);
    const [dateStart, setDateStart] = useState<string>('');
    const [dateEnd, setDateEnd] = useState<string>('');
    const [initialDateStart, setInitialDateStart] = useState<string>();
    const [initialDateEnd, setInitialDateEnd] = useState<string>();
    const [mileagePricetext, setMileagePricetext] = useState<string>('');
    const [showInactiveEmployees, setShowInactiveEmployees] = useState(false);
    const [employees, setEmployees] = useState<{ value: string; text: string; active: boolean }[] | undefined>([]);

    const { data, isSuccess, refetch, isFetching } = useGetUserPerformance({
        users: filter?.users || [],
        startDate: filter?.dateFrom || '',
        endDate: filter?.dateTo || '',
    });

    const getHeadersToExportCSV = () => {
        let headers = [
            { key: 'Employee_Name', label: 'Employee Name' },
            { key: 'Clock_Time', label: 'Clock Time' },
        ];

        if (tenant.hasJobsFeature) {
            headers.push(
                ...[
                    { key: 'Idle_Time', label: 'Idle Time' },
                    { key: 'Idle_Pct', label: 'Idle Pct' },
                    { key: 'Idle_Estimated_Pay', label: 'Idle Estimated Pay' },
                    { key: 'Job_Time', label: 'Job Time' },
                    { key: 'Job_Pct', label: 'Job Pct' },
                    { key: 'Job_Estimated_Pay', label: 'Job Estimated Pay' },
                ]
            );
        }

        headers.push(
            ...[
                { key: 'Hourly_Rate', label: 'Hourly Rate' },
                { key: 'Estimated_Payment', label: 'Estimated Payment' },
                { key: 'Mileage', label: 'Mileage' },
                { key: 'Mileage_Price', label: 'Mileage Price' },
                { key: 'Mileage_Cost', label: 'Mileage Cost' },
            ]
        );

        return headers;
    };

    const getDataToExportCSV = () => {
        return (
            data?.employeesData.map((emp) => ({
                Employee_Name: emp.employeeName,
                Clock_Time: toDurationHHMMSS(emp.weekClocksTotalDuration),
                Idle_Time: toDurationHHMMSS(emp.weekIdleTotalDuration),
                Idle_Pct: `${emp.idlePercent} %`,
                Idle_Estimated_Pay: emp.estimatedIdlePay ? toMoney(emp.estimatedIdlePay) : notAssignedFmt,
                Job_Time: toDurationHHMMSS(emp.weekJobsTotalDuration),
                Job_Pct: `${emp.workPercent} %`,
                Job_Estimated_Pay: emp.estimatedPayJob ? toMoney(emp.estimatedPayJob) : notAssignedFmt,
                Hourly_Rate: emp.hourlyRate ? toMoney(emp.hourlyRate) : 'Not assigned',
                Estimated_Payment: emp.totalEstimated ? toMoney(emp.totalEstimated) : notAssignedFmt,
                Mileage: emp.mileage ? `${emp.mileage.toFixed(2)}` : '0',
                Mileage_Price: `${mileagePricetext}`,
                Mileage_Cost:
                    emp.totalMileageCost || emp.totalMileageCost === 0 ? toMoney(emp.totalMileageCost) : notAssignedFmt,
            })) || []
        );
    };

    const onEmployeeSelected = (users: { value: string; text: string }[]) => {
        setValue(
            'users',
            users.map((obj) => obj.value)
        );
    };

    const onDateStartChange = (data: any) => {
        setValue('dateFrom', data);
        setDateStart(data);

        if (dateEnd !== '') {
            if (new Date(data) > new Date(dateEnd)) {
                setValue('dateTo', '');
            }
        }
    };

    const onDateEndChange = (data: any) => {
        setValue('dateTo', data);
        setDateEnd(data);
    };

    const onSubmit = (data: FormInput) => {
        setFilter({
            users: data.users,
            dateFrom: data.dateFrom !== '' ? data.dateFrom : undefined,
            dateTo: data.dateTo !== '' ? data.dateTo : undefined,
        });
    };

    const onResetForm = useCallback(() => {
        setValue('dateFrom', '');
        setValue('dateTo', '');
        setValue('users', []);
        setInitialValuesUsers([]); //clear selected elements
        setInitialDateStart(undefined);
        setInitialDateEnd(undefined);

        resetClient();
        setFilter(undefined);
    }, [resetClient, setValue]);

    useEffect(() => {
        if (!!filter?.dateFrom && !!filter.dateTo) {
            refetch();
        }
    }, [filter, refetch]);

    useEffect(() => {
        setInitialDateStart(dateStart);
        setInitialDateEnd(dateEnd);
    }, [dateStart, dateEnd]);

    useEffect(() => {
        if (isSuccessGetTenant) {
            const mileagePrice = dataGetTenant?.mileagePrice;
            const milPricetext = mileagePrice ? toMoney(mileagePrice) : 'Not assigned';
            setMileagePricetext(milPricetext || '');
        }
    }, [dataGetTenant, isSuccessGetTenant, setMileagePricetext]);

    const notAssignedFmt = '-.--';

    useEffect(() => {
        if (showInactiveEmployees) {
            setEmployees(dataUsers?.employees.filter((employee) => !employee.active));
        }
        else {
            setEmployees(dataUsers?.employees.filter((employee) => employee.active));
        }
    }, [showInactiveEmployees, dataUsers]);

    return (
        <>
            <AuthLayout menuOption="Employee Performance">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Employee Performance</h1>
                    </div>
                </section>
                <div className="card p-5 mb-5">
                    <Loading isLoading={isFetchingUsers || isFetchingGetTenant} boxStyle />
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-5">
                        <div className="mb-5 col-span-3">
                            <FormInputAutocompleteMultiSelectControl
                                label="Employees"
                                register={register('users')}
                                options={employees || []}
                                onChange={onEmployeeSelected}
                                placementSelectedAtBottom={true}
                                clientErrors={clientErrors?.users as FieldError}
                                initialValues={initialValuesUsers}
                                tippyChild={<TippyToolTip tooltipText="Empty to select all employees" />}
                            />
                        </div>
                        <div className="mb-5 col-span-1">
                            <FormInputDateControl
                                label="Date From"
                                clientErrors={clientErrors?.dateFrom}
                                onChange={onDateStartChange}
                                includeDeselect={true}
                                initialValue={initialDateStart}
                            />
                        </div>
                        <div className="mb-5 col-span-1">
                            <FormInputDateControl
                                label="Date To"
                                clientErrors={clientErrors?.dateTo}
                                minValue={dateStart}
                                onChange={onDateEndChange}
                                includeDeselect={true}
                                initialValue={initialDateEnd}
                            />
                        </div>
                    </div>
                    <section className="breadcrumb lg:flex items-start">
                        <div className="flex flex-wrap w-full self-center items-center lg:mt-0">
                            <div className="flex flex-wrap items-center ml-0">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        onChange={() => setShowInactiveEmployees(!showInactiveEmployees)}
                                    />
                                    <span></span>
                                    <span>Show inactive employees</span>
                                </label>
                            </div>
                            <div className="flex flex-wrap gap-2 items-center ml-0 sm:ml-auto">
                                <div className="flex gap-2">
                                    <button type="submit" className="btn btn_secondary uppercase" onClick={onResetForm}>
                                        Clear
                                    </button>
                                    <button type="submit" className="btn btn_primary uppercase" onClick={handleSubmit(onSubmit)}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="card p-5">
                        <div className="overflow-x-auto">
                            <Loading isLoading={isFetching} boxStyle />
                            <table className="table w-full table_striped">
                                <thead>
                                    <tr>
                                        <th className="text-left uppercase">Employee</th>
                                        <th className="text-right uppercase">Clock Time</th>
                                        {tenant.hasJobsFeature && (
                                            <>
                                                <th className="text-right uppercase">Idle Time</th>
                                                <th className="text-center uppercase"> Idle Time % </th>
                                                <th className="text-right uppercase">Job Time</th>
                                                <th className="text-center uppercase">Job Time %</th>
                                            </>
                                        )}
                                        <th className="text-right uppercase">
                                            Current <br /> Hourly Rate
                                        </th>
                                        <th className="text-right uppercase">
                                            Estimated <br /> Payment
                                        </th>
                                        <th className="text-right uppercase">Mileage</th>
                                        <th className="text-right uppercase">
                                            Mileage <br /> Cost{' '}
                                            <TippyToolTip tooltipText={`Mile Price: ${mileagePricetext}`} />
                                        </th>
                                        <th className="text-right" style={{ width: '40px' }}>
                                            <ExportSimpleTableCustomHeaderToCSV
                                                title={'EmployeePerformance'}
                                                headers={getHeadersToExportCSV()}
                                                data={getDataToExportCSV()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isSuccess &&
                                        data?.employeesData.map((employee, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="align-top">
                                                    <td className="text-left">
                                                        <strong>{employee.employeeName}</strong>
                                                    </td>
                                                    <td className="text-right uppercase">
                                                        <i className="las la-stopwatch"></i>
                                                        <span>{toDurationHHMMSS(employee.weekClocksTotalDuration)}</span>
                                                    </td>
                                                    {tenant.hasJobsFeature && (
                                                        <>
                                                            <td className="text-right uppercase">
                                                                <i className="las la-pause-circle mr-1"></i>
                                                                <span>
                                                                    {toDurationHHMMSS(employee.weekIdleTotalDuration)}
                                                                </span>
                                                                <br />
                                                                <strong>
                                                                    {employee.estimatedIdlePay ||
                                                                        employee.estimatedIdlePay === 0
                                                                        ? toMoney(employee.estimatedIdlePay)
                                                                        : notAssignedFmt}
                                                                </strong>
                                                            </td>
                                                            <td className="text-left uppercase">
                                                                <ProgressBar
                                                                    percent={employee.idlePercent}
                                                                    color="orange"
                                                                />
                                                            </td>
                                                            <td className="text-right uppercase">
                                                                <i className="las la-business-time mr-1"></i>
                                                                <span>
                                                                    {toDurationHHMMSS(employee.weekJobsTotalDuration)}
                                                                </span>
                                                                <br />
                                                                <strong>
                                                                    {employee.estimatedPayJob ||
                                                                        employee.estimatedPayJob === 0
                                                                        ? toMoney(employee.estimatedPayJob)
                                                                        : notAssignedFmt}
                                                                </strong>
                                                            </td>
                                                            <td className="text-left uppercase">
                                                                <ProgressBar percent={employee.workPercent} color="green" />
                                                            </td>
                                                        </>
                                                    )}
                                                    <td className="text-right">
                                                        <span>
                                                            {employee.hourlyRate
                                                                ? toMoney(employee.hourlyRate)
                                                                : 'Not assigned'}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {employee.totalEstimated
                                                                ? toMoney(employee.totalEstimated)
                                                                : notAssignedFmt}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        {employee.mileage ? `${employee.mileage.toFixed(2)}` : '0.00'}
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {employee.totalMileageCost || employee.totalMileageCost === 0
                                                                ? toMoney(employee.totalMileageCost)
                                                                : notAssignedFmt}
                                                        </strong>
                                                    </td>
                                                    <td className="whitespace-nowrap"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default EmployeePerformance;
