import classNames from 'classnames';
import React from 'react';

interface ModalBaseProps {
    toggler: () => void;
    children: React.ReactNode;
    closeButtonDisabled?: boolean;
}

interface ModalProps extends ModalBaseProps {
    isOpen: boolean;
    size?: string;
    isStatic?: boolean;
    isCentered?: boolean;
    isFullHeight?: boolean;
    isDisabled?: boolean;
}

export const ModalHeader = (props: ModalBaseProps) => {
    const { children, toggler, closeButtonDisabled } = props;

    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">{children}</h2>
                <button
                    type="button"
                    className="close la la-times"
                    onClick={toggler}
                    disabled={closeButtonDisabled}
                ></button>
            </div>
        </>
    );
};

export const ModalBody = (props: { children: React.ReactNode; bgColor?: string }) => {
    const { children, bgColor } = props;

    return (
        <>
            <div className={`modal-body h-full ${bgColor}`}>{children}</div>
        </>
    );
};

export const ModalFooter = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="modal-footer">{children}</div>
        </>
    );
};

export const Modal = (props: ModalProps) => {
    const { isOpen, size, isStatic, isCentered, isFullHeight, isDisabled, toggler, children } = props;

    if (!isOpen) {
        return null;
    }

    const modalSize =
        size === 'sm'
            ? 'max-w-sm'
            : size === 'md'
            ? 'max-w-2xl'
            : size === 'lg'
            ? 'max-w-5xl'
            : size === 'full'
            ? 'max-w-full'
            : 'max-w-2xl';

    const handleOnClick = () => {
        if (isStatic) {
            return;
        }

        toggler();
    };

    const modalClasses = {
        'modal-dialog': true,
        'modal-dialog_scrollable': true,
        'modal-dialog_centered': isCentered,
        'h-screen': isFullHeight,
    };

    return (
        <>
            <div
                className="modal active text-left text-secondary"
                onClick={handleOnClick}
                style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            >
                <div className={classNames(modalClasses, modalSize)} onClick={(e) => e.stopPropagation()}>
                    <div className="flex modal-content w-full">{children}</div>
                </div>
            </div>
            <div
                className={`${
                    isOpen ? 'opacity-25' : 'opacity-0'
                } fixed inset-0 z-40 bg-black transition-all duration-500`}
            />
        </>
    );
};
