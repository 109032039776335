import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import BasicLayout from '../components/BasicLayout';

const Login = () => {
    const location = useLocation();
    const state = location.state as { from: { pathname: string } };
    const from = state?.from?.pathname || '/';

    const { isLoading, isAuthenticated, login } = useAuth();

    useEffect(() => {
        if (isLoading || isAuthenticated) {
            return;
        }

        (async () => {
            await login({
                appState: { targetUrl: from },
            });
        })();
    }, [isLoading, isAuthenticated, login, from]);

    if (isAuthenticated) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <>
            <BasicLayout>
                <div className="container flex items-center justify-center py-10">
                    <div className="w-full md:w-1/2 xl:w-1/3">
                        <div className="card p-5 md:p-10">
                            <div className="mx-5 md:mx-10">
                                <h2 className="text-center uppercase">Welcome to Work Alert!</h2>
                            </div>
                            <div className="mt-5 mx-5 md:mx-10 text-center">
                                <button
                                    type="submit"
                                    className="btn btn_primary uppercase"
                                    disabled={isLoading}
                                    onClick={login}
                                >
                                    Click to login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        </>
    );
};

export default Login;
