import { useQuery, useMutation, useQueryClient } from 'react-query';
import apiClient, { ErrorResponse } from '../utilities/apiClient';
import {
    formatFullName,
    getDateWithoutTime,
    getHoursDuration,
    getMinutesDuration,
    toDuration,
    toFriendlyDate,
    toTimeZoneTime,
    toTimeZoneDate,
    sortByDateDesc,
} from '../utilities/formatter';
import { Address, ItemInvoice } from '../utilities/interfaces';
import { jobActionEnum, jobAssignActionEnum, recurringOptionEnum } from '../utilities/staticData';
import { useAuth } from './useAuth';

interface CreateJobResponse {
    customers: CreateJobCustomer[];
    employees: CreateJobEmployee[];
}

interface GetJobResponse {
    record: GetJob;
}

interface CreateJobCustomer {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    mobile: string;
    addresses: Address[];
}

interface CreateJobEmployee {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    mobile: string;
    profilePicture?: string;
}

interface PostJob {
    description?: string;
    customerId?: string;
    employees?: string[];
    jobDate?: Date;
    multipleJobDates?: Date[];
    addressId?: string;
    windowOfArrivalStart?: Date;
    windowOfArrivalEnd?: Date;
    duration: string;
    isFloating: boolean;
    recurrenceOption?: number;
    jobDateRange?: {
        startDate: Date;
        endDate: Date;
    };
    jobDates?: Date[];
    files?: string[];
}

interface PostInvoice {
    jobId: string;
    daysUntilDue: number;
    items: ItemInvoice[];
}

interface SendInvoice {
    stripeInvoiceId?: string;
}

interface GetInvoicesLinkResponse {
    invoices?: {
        id?: string;
        url?: string;
        status?: string;
        created?: Date;
        dueDate?: Date;
        total?: number;
    }[];
}

interface AssignJobs {
    jobs: string[];
    employees?: string[];
    assignAction?: number;
}

interface GetJob extends PostJob {
    id: string;
    address: Address;
    title: string;
    status: string;
    isOverTime: boolean;
    runTime: string;
    timeIn: Date;
    timeOut: Date;
    customerName: string;
    employeesTimeCard: {
        jobTimekeepingEntryId: string;
        employeeId: string;
        firstName: string;
        lastName: string;
        entryType?: string;
        autocreated: boolean;
        timeStamp?: Date;
    }[];
    notes: {
        employeeId: string;
        note?: string;
        files?: string[];
    }[];
    allowedJobActions: number[];
    jobLink?: string;
    stripeInvoiceId?: string;
}

interface UpdateJob extends PostJob {
    jobId?: string;
    address?: Address;
}

interface DeleteJob {
    jobId: string;
}

const useGetCreateJob = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['createJob'],
        async () => {
            const response = await apiClient.get<CreateJobResponse>(`job/create`);

            const employees = response.data?.employees
                .sort((a, b) =>
                    formatFullName(a.firstName, a.lastName).localeCompare(formatFullName(b.firstName, b.lastName))
                )
                .map((employee) => ({
                    id: employee.id,
                    value: employee.id,
                    text: formatFullName(employee.firstName, employee.lastName),
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    mobile: employee.mobile,
                    profilePicture: employee.profilePicture,
                    active: employee.active,
                }));

            const customers = response.data?.customers
                .sort((a, b) =>
                    formatFullName(a.firstName, a.lastName).localeCompare(formatFullName(b.firstName, b.lastName))
                )
                .map((customer) => ({
                    id: customer.id,
                    value: customer.id,
                    text: formatFullName(customer.firstName, customer.lastName),
                    addresses: customer.addresses,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    mobile: customer.mobile,
                    profilePicture: undefined,
                    active: customer.active,
                }));

            return {
                customers: customers,
                employees: employees,
            };
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetJob = (jobId: string, shouldGet: boolean, action: jobActionEnum) => {
    const { tenant } = useAuth();

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['job', jobId],
        async () => {
            const response = await apiClient.get<GetJobResponse>(`jobs/${jobId}`);

            let employees: string[] = [];
            let jobDate: string = '';
            let recurringOptionsEnabled: boolean = false;
            let recurrenceOption: string = '';
            let jobDateRangeStartDate: string = '';
            let jobDateRangeEndDate: string = '';
            let jobDates: string[] = [];
            let files: string[] = [];
            let duration: string = '';
            let windowOfArrivalStart: string = '';
            let windowOfArrivalEnd: string = '';
            let isOverTime: boolean = false;
            let runTime: string = '';
            let timeIn: string = '';
            let timeOut: string = '';

            if (action === jobActionEnum.Edit || action === jobActionEnum.Info) {
                files = response.data.record.files?.sort((a, b) => a.localeCompare(b)) || [];
                employees = response.data.record.employees || [];

                if (response.data?.record.recurrenceOption === null) {
                    var localDate = new Date(toTimeZoneDate(response.data?.record.jobDate!, tenant.timeZoneId));
                    if (action === jobActionEnum.Edit)
                        jobDate = getDateWithoutTime(localDate) || '';
                    else
                        jobDate = toFriendlyDate(localDate.toString()) || '';
                } else {
                    recurringOptionsEnabled = true;
                    recurrenceOption = response.data?.record.recurrenceOption?.toString() || '';
                    switch (response.data?.record.recurrenceOption) {
                        case recurringOptionEnum.Daily:
                        case recurringOptionEnum.Biweekly:
                        case recurringOptionEnum.Monthly:
                            if (action === jobActionEnum.Edit) {
                                jobDateRangeStartDate =
                                    response.data?.record.jobDateRange?.startDate!.toString()!.split('T')[0] || '';
                                jobDateRangeEndDate =
                                    response.data?.record.jobDateRange?.endDate!.toString()!.split('T')[0] || '';
                            } else {
                                jobDateRangeStartDate =
                                    toFriendlyDate(response.data?.record.jobDateRange?.startDate!.toString()) || '';
                                jobDateRangeEndDate =
                                    toFriendlyDate(response.data?.record.jobDateRange?.endDate!.toString()) || '';
                            }
                            break;
                        case recurringOptionEnum['Multiple Days']:
                            if (action === jobActionEnum.Edit)
                                jobDates = response.data?.record.jobDates!.map((date) => date.toString().split('T')[0]);
                            else
                                jobDates = response.data?.record.jobDates!.map(
                                    (date) => toFriendlyDate(date.toString()) || ''
                                );
                            break;
                    }
                }
            }

            if (action === jobActionEnum.Info) {
                duration = toDuration(response.data.record.duration);
                windowOfArrivalStart = toTimeZoneTime(
                    response.data.record.windowOfArrivalStart!,
                    tenant.timeZoneId,
                    true
                );
                windowOfArrivalEnd = toTimeZoneTime(response.data.record.windowOfArrivalEnd!, tenant.timeZoneId, true);
                isOverTime = response.data.record.isOverTime;
                runTime = toDuration(response.data.record.runTime);
                timeIn = !!response.data.record.timeIn
                    ? toTimeZoneTime(response.data.record.timeIn, tenant.timeZoneId, true)
                    : '';
                timeOut = !!response.data.record.timeOut
                    ? toTimeZoneTime(response.data.record.timeOut, tenant.timeZoneId, true)
                    : '';
            }

            if (action === jobActionEnum.Clone || action === jobActionEnum.Edit) {
                windowOfArrivalStart = toTimeZoneTime(response.data.record.windowOfArrivalStart!, tenant.timeZoneId);
                windowOfArrivalEnd = toTimeZoneTime(response.data.record.windowOfArrivalEnd!, tenant.timeZoneId);
            }

            return {
                id: jobId,
                title: response.data.record.title,
                description: response.data.record.description || '',
                customerId: response.data.record.customerId || '',
                employees: employees || [],
                jobDate: jobDate || '',
                addressId: response.data.record.address.id || '',
                windowOfArrivalStart: windowOfArrivalStart || '',
                windowOfArrivalEnd: windowOfArrivalEnd || '',
                durationHours: getHoursDuration(response.data.record.duration) || 0,
                durationMinutes: getMinutesDuration(response.data.record.duration) || 0,
                status: response.data.record.status,
                isFloating: response.data.record.isFloating || false,
                recurringOptionsEnabled: recurringOptionsEnabled,
                recurrenceOption: recurrenceOption || '',
                jobDateRangeStartDate: jobDateRangeStartDate || '',
                jobDateRangeEndDate: jobDateRangeEndDate || '',
                jobDates: jobDates || [],
                files: files || [],
                notes: response.data.record.notes || [],
                duration,
                isOverTime,
                runTime,
                timeIn,
                timeOut,
                address: response.data.record.address,
                customerName: response.data.record.customerName,
                employeesTimeCard: response.data.record.employeesTimeCard,
                isEdit: action === jobActionEnum.Edit ? true : undefined,
                allowedJobActions: response.data.record.allowedJobActions,
                jobLink: response.data.record.jobLink,
                stripeInvoiceId: response.data.record.stripeInvoiceId,
            };
        },
        {
            enabled: !!jobId && shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const usePostJob = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, PostJob>(
        async (job: PostJob) => {
            return await apiClient.post<PostJob>('jobs', job);
        }
    );

    const create = (job: PostJob) => {
        return mutate(job, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['jobsdates']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const useUpdateJob = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        UpdateJob
    >(async (job: UpdateJob) => {
        return await apiClient.put<UpdateJob>('jobs', job);
    });

    const update = (job: UpdateJob) => {
        return mutate(job, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useAssignJobs = (action: jobAssignActionEnum) => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        AssignJobs
    >(async (assignJobs: AssignJobs) => {
        assignJobs.assignAction = action;
        return await apiClient.put<AssignJobs>('jobs/assign', assignJobs);
    });

    const assign = (assignJobs: AssignJobs) => {
        return mutate(assignJobs, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        assign,
        reset,
    };
};

const useDeleteJob = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        DeleteJob
    >(async (job: DeleteJob) => {
        return await apiClient.delete<DeleteJob>(`jobs/${job.jobId}`);
    });

    const deleteJob = (job: DeleteJob) => {
        return mutate(job, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        deleteJob: deleteJob,
        reset,
    };
};

const usePostInvoiceJob = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, PostInvoice>(
        async (invoice: PostInvoice) => {
            return await apiClient.post<PostInvoice>('jobs/payments/invoices', invoice);
        }
    );

    const createInvoice = (invoice: PostInvoice) => {
        return mutate(invoice, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
                queryClient.invalidateQueries(['invoices']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        createInvoice,
        reset,
    };
};

const useGetInvoicesLink = (jobId: string) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['invoices'],
        async () => {
            const response = await apiClient.get<GetInvoicesLinkResponse>(`jobs/payments/invoices/${jobId}`);
            return response.data.invoices?.sort((a, b) => sortByDateDesc(a.created, b.created));
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error as ErrorResponse,
        refetch,
        isFetching,
    };
};

const usePostSendInvoice = () => {
    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, SendInvoice>(
        async (invoice: SendInvoice) => {
            return await apiClient.post<SendInvoice>(`jobs/payments/invoices/send/${invoice.stripeInvoiceId}`);
        }
    );

    const sendInvoice = (invoice: SendInvoice) => {
        return mutate(invoice);
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        sendInvoice,
        reset,
    };
};

const useCancelInvoice = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        DeleteJob
    >(async (job: DeleteJob) => {
        return await apiClient.delete<DeleteJob>(`jobs/payments/invoices/${job.jobId}`);
    });

    const cancelInvoice = (job: DeleteJob) => {
        return mutate(job, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
                queryClient.invalidateQueries(['invoices']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        cancelInvoice,
        reset,
    };
};

export const useJobs = () => {
    return {
        useGetCreateJob,
        usePostJob,
        useGetJob,
        useUpdateJob,
        useAssignJobs,
        useDeleteJob,
        usePostInvoiceJob,
        useGetInvoicesLink,
        usePostSendInvoice,
        useCancelInvoice,
    };
};
