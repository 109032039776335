import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiClient, { ErrorResponse } from '../utilities/apiClient';
import { sortByDate } from '../utilities/formatter';

type HistoryDirection = 'All' | 'Inbound' | 'Outbound';

interface GetSmsResponse {
    records: Sms[];
}

interface GetAvailablePhoneNumbersResponse {
    records: AvailablePhoneNumber[];
}

interface Sms {
    direction: string;
    message: string;
    date: Date;
}

interface SendSms {
    to: string;
    mobile: string;
    message: string;
}

interface SendBulkSms {
    to: any[];
    message: string;
}

interface AvailablePhoneNumber {
    phoneNumber: string;
    name: string;
}

interface SmsMarkReadRequest {
    userId: string;
}

interface UsersLastUnreadSmsResponse {
    records: UserLastUnreadSms[];
}

interface UserLastUnreadSms {
    userId?: string;
    dateSent: Date;
}

const useGetSms = (props: {
    direction: HistoryDirection;
    phoneNumber?: string;
    dateFrom?: Date;
    dateTo?: Date;
    enabled?: boolean;
}) => {
    const defaults = { ...{ enabled: true }, ...props };
    const { direction, phoneNumber, dateFrom, dateTo, enabled } = defaults;

    const { isLoading, isSuccess, isFetching, data, isError, error, refetch } = useQuery(
        ['sms', direction, phoneNumber],
        async () => {
            const response = await apiClient.get<GetSmsResponse>(`tenants/sms`, {
                params: {
                    direction,
                    phoneNumber,
                    dateFrom,
                    dateTo,
                },
            });

            return response.data?.records?.sort((a, b) =>
                // TODO: look at https://medium.com/@dorontohar/a-simple-type-safe-http-client-wrapper-edb7df9317db
                // for a better solution to manage dates from APIs
                sortByDate(a.date, b.date, true)
            );
        },
        {
            enabled: (direction !== 'All') !== !!phoneNumber && enabled,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useSendSms = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, SendSms>(
        async (sms: SendSms) => {
            return await apiClient.post<SendSms>('tenants/sms', sms);
        }
    );

    const create = (sms: SendSms) => {
        return new Promise<void>((resolve, reject) => {
            mutate(sms, {
                onSuccess: async () => {
                    resolve();
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    queryClient.invalidateQueries(['sms', 'All', sms.mobile]);
                },
                onError: (error) => {
                    reject(error?.errors);
                },
            });
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const useSendBulkSms = (bgPhoneNumber?: string) => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        SendBulkSms
    >(async (sms: SendBulkSms) => {
        return await apiClient.post<SendBulkSms>('tenants/bulksms', sms);
    });

    const create = (sms: SendBulkSms) => {
        const updateBgChat = async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            queryClient.invalidateQueries(['sms', 'All', bgPhoneNumber]);
        };

        return new Promise<void>((resolve, reject) => {
            mutate(sms, {
                onSuccess: () => {
                    resolve();
                    if (sms.to.some(user => user.userMobile === bgPhoneNumber)) {
                        updateBgChat();
                    }
                },
                onError: (error) => {
                    reject(error?.errors);
                    if (bgPhoneNumber && !error?.errors?.GeneralErrors?.some((e: string) => e.includes(bgPhoneNumber))) {
                        updateBgChat();
                    }
                },
            });
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const useGetAvailablePhoneNumbers = (props: { search?: string; enabled: boolean }) => {
    const defaults = { ...{ enabled: true }, ...props };

    const { search, enabled } = defaults;

    const { isLoading, isSuccess, isFetching, data, isError, error, refetch } = useQuery(
        ['available-phone-numbers'],
        async () => {
            const response = await apiClient.get<GetAvailablePhoneNumbersResponse>(`tenants/availablephonenumbers`, {
                params: {
                    search,
                },
            });

            return response.data?.records;
        },
        {
            enabled: enabled,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useMarkSmsRead = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        SmsMarkReadRequest
    >(async (input: SmsMarkReadRequest) => {
        return await apiClient.put<SmsMarkReadRequest>('tenants/smsmarkread', input);
    });

    const update = (inputRequest: SmsMarkReadRequest) => {
        return mutate(inputRequest, {
            onSuccess: () => {
                queryClient.invalidateQueries(['sms', 'All']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useGetUsersLastUnreadSms = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['users-lastunreadsms'],
        async () => {
            const response = await apiClient.get<UsersLastUnreadSmsResponse>(`/tenant/users/lastunreadsms`);

            return response.data?.records;
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

export const useNotifications = () => {
    return {
        useGetSms,
        useSendSms,
        useSendBulkSms,
        useGetAvailablePhoneNumbers,
        useMarkSmsRead,
        useGetUsersLastUnreadSms,
    };
};
