import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { formatFullName, formatPhoneNumber, toChatDate, toTimeZoneDateTime } from '../utilities/formatter';
import AuthLayout from '../components/AuthLayout';
import Sidebar from '../components/Sidebar';
import Loading from '../components/Loading';
import useInput from '../hooks/useInput';
import { useJobs } from '../hooks/useJobs';
import { useNotifications } from '../hooks/useNotifications';
import { useSmsNotifications } from '../hooks/useSmsNotifications';
import Avatar from '../components/Avatar';
import { useAuth } from '../hooks/useAuth';
import MessageModal from '../components/MessageModal';

interface User {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    mobile: string;
    profilePicture?: string;
}

interface UserCardProps {
    user: User;
    onClick: (id: string) => void;
}

interface SmsCardProps {
    data: {
        direction: string;
        message: string;
        date: Date;
    };
}

interface MessagesSideBarProps {
    onSelectedUser: (user?: User) => void;
}

const UserCard = (props: UserCardProps) => {
    const { user, onClick } = props;

    const { unreadSms } = useSmsNotifications();

    const fullName = formatFullName(user.firstName, user.lastName);

    const hasUnreadSms = !!unreadSms?.find((x) => x.userId === user.id);

    const handleOnClick = () => {
        if (user.mobile) {
            onClick(user.id);
        }
    };

    const cardClasses = {
        'flex items-center mb-6': true,
        'hover:cursor-pointer': !!user.mobile,
        'hover:cursor-not-allowed': !user.mobile,
    };

    return (
        <>
            {user?.active && (
                <div className={classNames(cardClasses)} onClick={handleOnClick}>
                    <Avatar fullName={fullName} imageURL={user.profilePicture} showDangerStatus={hasUnreadSms} />
                    <div className="ml-2">
                        <h5>{fullName}</h5>
                        <p>{formatPhoneNumber(user.mobile) || '[no phone number]'}</p>
                    </div>
                </div>
            )}
        </>
    );
};

const SmsCard = (props: SmsCardProps) => {
    const {
        data: { direction, message, date },
    } = props;

    const { tenant } = useAuth();

    const cardClasses = {
        'card flex items-start w-11/12 p-5': true,
        'ml-auto bg-primary text-component': direction === 'Outbound',
    };

    const messageClasses = {
        'whitespace-nowrap ml-auto text-right pl-5': true,
        'text-muted': direction !== 'Outbound',
    };

    return (
        <>
            <div className={classNames(cardClasses)}>
                <span>{message}</span>
                <span className={classNames(messageClasses)}>
                    {toChatDate(toTimeZoneDateTime(date, tenant.timeZoneId))}
                </span>
            </div>
        </>
    );
};

const MessageIndicatorInTab = ({ users }: { users?: any[] }) => {
    const { unreadSms, hasUnreadSms } = useSmsNotifications();

    const hasAnyUnreadSmsByTab = () => {
        var unreadMessagesByUser = users?.filter((sel) => unreadSms?.some((item) => item.userId === sel.id)).length;
        return unreadMessagesByUser === undefined ? false : unreadMessagesByUser > 0 ? true : false;
    };

    return (
        <>
            {hasUnreadSms && hasAnyUnreadSmsByTab() && (
                <span className="absolute top-0 right-0 rounded-full badge_danger mt-1 -mr-3 h-2.5 w-2.5">
                    {'\u00a0'}
                </span>
            )}
        </>
    );
};

const MessagesSideBar = (props: MessagesSideBarProps) => {
    const { onSelectedUser } = props;

    const [tabSelected, setTabSelected] = useState(0);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();
    const searchInput = useInput('');

    const { useGetCreateJob } = useJobs();
    const { data, isSuccess, isLoading, isFetching } = useGetCreateJob(true);

    const { removeUnread } = useSmsNotifications();

    const employeesTabClasses = {
        'nav-link h5 uppercase': true,
        active: tabSelected === 0,
    };

    const customersTabClasses = {
        'nav-link h5 uppercase': true,
        active: tabSelected === 1,
    };

    const getSelectedUsers = () => {
        const searchValue = searchInput.value.toLowerCase();

        const selectedUsers = tabSelected === 0 ? data?.employees : data?.customers;

        // Filter on first name, last name and mobile value
        return selectedUsers?.filter(
            (user) => `${user.firstName}||${user.lastName}||${user.mobile}`.toLowerCase().search(searchValue) > -1
        );
    };

    const handleUserCardOnClick = (id: string) => {
        const selectedUsers = tabSelected === 0 ? data?.employees : data?.customers;

        const selectedUser = selectedUsers?.find((x) => x.id === id);

        removeUnread(selectedUser?.id || '');

        onSelectedUser(selectedUser);
        setSelectedPhoneNumber(selectedUser?.mobile);
    };

    return (
        <>
            <Sidebar>
                <div className="tabs p-5 overflow-x-hidden overflow-y-auto">
                    <nav className="tab-nav mt-5">
                        <button className={classNames(employeesTabClasses)} onClick={() => setTabSelected(0)}>
                            Employees
                            <MessageIndicatorInTab users={data?.employees} />
                        </button>
                        <button className={classNames(customersTabClasses)} onClick={() => setTabSelected(1)}>
                            Customers
                            <MessageIndicatorInTab users={data?.customers} />
                        </button>
                    </nav>
                    <div className="tab-content mt-5">
                        <div className="open">
                            <div className="flex gap-2 items-center mb-5">
                                <MessageModal tabSelected={tabSelected} bgPhoneNumber={selectedPhoneNumber} />
                                <form action="#">
                                    <label className="form-control-addon-within rounded-full">
                                        <input
                                            type="text"
                                            className="form-control border-none"
                                            placeholder="Search"
                                            {...searchInput.bind}
                                            disabled={isLoading}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-link text-gray-300 dark:text-gray-700 dark:hover:text-primary text-xl leading-none la la-search mr-4"
                                            disabled={isLoading}
                                        ></button>
                                    </label>
                                </form>
                            </div>

                            <Loading isLoading={isFetching} boxStyle />
                            {isSuccess &&
                                getSelectedUsers()?.map((user) => (
                                    <UserCard key={user.id} user={user} onClick={handleUserCardOnClick} />
                                ))}
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
};

const Messages = () => {
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const ref = useRef<null | HTMLInputElement>(null);

    const [selectedUser, setSelectedUser] = useState<User>();
    const smsTextInput = useInput('');

    const { unreadSms } = useSmsNotifications();

    const { useGetSms, useSendSms } = useNotifications();

    const { isSuccess, isLoading, data, refetch } = useGetSms({
        direction: 'All',
        phoneNumber: selectedUser?.mobile,
    });

    const { create: sendSms, isLoading: sendingSms } = useSendSms();

    const handleSendSms = () => {
        sendSms({
            to: selectedUser?.id!,
            mobile: selectedUser?.mobile!,
            message: smsTextInput.value,
        })
            .then(() => {
                smsTextInput.reset();
            })
            .catch((errors) => {
                const message = errors?.GeneralErrors?.length
                    ? 'An error occured: ' + errors?.GeneralErrors[0]
                    : 'An unknown error occurred if the issue persists contact your system administrator';

                toast.error(message, { autoClose: 4000 });
            });
    };

    const fullName = formatFullName(selectedUser?.firstName, selectedUser?.lastName);

    const canTypeSms = !!selectedUser && isSuccess && !sendingSms;
    const canSendSms = canTypeSms && !!smsTextInput.value && selectedUser?.active;

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const focusInput = () => {
        ref.current?.focus();
    };

    const onKeyUp = (event: any) => {
        if (event.key === 'Enter' && canSendSms) {
            handleSendSms();
        }
    };

    useEffect(() => {
        if (data?.length) {
            scrollToBottom();
        }
    }, [data]);

    useEffect(() => {
        if (selectedUser && unreadSms?.length) {
            refetch();
        }
    }, [unreadSms, selectedUser, refetch]);

    useEffect(() => {
        if (selectedUser && canTypeSms) {
            focusInput();
        }
    }, [selectedUser, canTypeSms]);

    return (
        <>
            <AuthLayout
                className="pb-28"
                menuOption="Messages"
                hideFooter={true}
                sidebar={<MessagesSideBar onSelectedUser={setSelectedUser} />}
            >
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Messages</h1>
                    </div>
                    <div className="md:ml-auto">
                        {selectedUser && (
                            <div className="flex items-center">
                                <Avatar fullName={fullName} imageURL={selectedUser.profilePicture} />
                                <div className="ml-2">
                                    <h5>{fullName}</h5>
                                    <p>{formatPhoneNumber(selectedUser?.mobile) || '[no phone number]'}</p>
                                    {!selectedUser?.active && (
                                        <span className="text-xs text-[color:darkred] text-center"> Inactive </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </section>
                <div className="relative flex-grow">
                    <div className="absolute flex flex-col gap-5 w-full top-0 bottom-0 overflow-y-scroll">
                        <Loading isLoading={!isSuccess && isLoading} />
                        {isSuccess && !data?.length && !!selectedUser && (
                            <div className="flex items-center justify-center py-10">
                                <div className="w-full md:w-1/2 xl:w-1/3">
                                    <div className="text-center">
                                        <h2 className="uppercase">No SMS found.</h2>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isSuccess && (
                            <>
                                {data?.map((sms, index) => (
                                    <SmsCard key={index} data={sms} />
                                ))}
                                <div ref={messagesEndRef} />
                            </>
                        )}
                    </div>
                </div>
                <div className="footer-bar absolute">
                    <input
                        ref={ref}
                        type="text"
                        className="flex flex-auto mr-5 bg-transparent outline-none placeholder:text-placeholder"
                        placeholder={canTypeSms ? 'Enter message...' : 'Select a conversation'}
                        disabled={!canTypeSms || !selectedUser?.active}
                        onKeyUp={onKeyUp}
                        {...smsTextInput.bind}
                    />
                    <div className="flex sm:flex-wrap gap-5 ml-auto">
                        <button className="btn btn_primary uppercase" disabled={!canSendSms} onClick={handleSendSms}>
                            {sendingSms && (
                                <span className="la la-circle-notch text-xl leading-none mr-2 animate-spin"></span>
                            )}
                            {!sendingSms && <span className="la la-paper-plane text-xl leading-none mr-2"></span>}
                            <span>Send</span>
                        </button>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Messages;
