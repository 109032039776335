import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import parseJSON from 'date-fns/parseJSON';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import { Address } from './interfaces';

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat();

export const toMoney = (amount?: number) => {
    if (!amount && amount !== 0) {
        return null;
    }

    return moneyFormatter.format(amount).replace('$', '$ ');
};

export const toCommaSeparated = (value?: number) => {
    if (!value) {
        return null;
    }

    return numberFormatter.format(value);
};

export const jsonToDate = (date?: Date) => {
    if (!date) {
        return null;
    }

    return parseJSON(date);
};

export const toShortDate = (date?: Date) => {
    if (!date) {
        return null;
    }

    const aDate = isDate(date) ? date : jsonToDate(date);

    return format(aDate!, 'MM/dd/yyyy');
};

export const toFriendlyDate = (date?: string) => {
    if (!date) {
        return null;
    }

    const aDate = new Date(date);

    if (!isValid(aDate)) {
        return null;
    }
    return format(aDate, 'cccc LLLL dd, yyyy');
};

export const toFullDateTime = (date?: Date) => {
    if (!date) {
        return null;
    }

    const aDate = isDate(date) ? date : jsonToDate(date);

    return format(aDate!, 'MM/dd/yyyy HH:mm:ss');
};

export const toDateTime = (date?: string) => {
    if (!date) {
        return null;
    }

    const aDate = new Date(date);

    if (!isValid(aDate)) {
        return null;
    }

    return format(aDate!, "yyyy-MM-dd'T'HH:mm:ssxxxxx");
};

export const toDurationToDate = (date?: Date) => {
    if (!date) {
        return {};
    }

    const aDate = isDate(date) ? date : jsonToDate(date);

    const interval = intervalToDuration({
        start: aDate!,
        end: new Date(),
    })!;

    return { ...interval };
};

export const toChatDate = (date?: string) => {
    if (!date) {
        return null;
    }

    const aDate = new Date(date);

    if (!isValid(aDate)) {
        return null;
    }

    if (isToday(aDate)) {
        return `Today at ${format(aDate, 'h:mm aa')}`;
    }

    if (isYesterday(aDate)) {
        return `Yesterday at ${format(aDate, 'h:mm aa')}`;
    }

    return `${format(aDate, 'LLLL dd, yyyy, h:mm aa')}`;
};

export const formatDateTime = (date?: string, time: string = '00:00') => {
    return new Date(`${date}T${time}`);
};

export const toLocaleTime = (date?: Date, hour12: boolean = false) => {
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    if (hour12) options.hour12 = true;
    else options.hourCycle = 'h23';

    return new Date(`${date}`).toLocaleTimeString([], options);
};

export const toLocaleDateTime = (date?: Date, hour12: boolean = false) => {
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    if (hour12) options.hour12 = true;
    else options.hourCycle = 'h23';

    return new Date(`${date}`).toLocaleString([], options);
};

export const toTimeZoneTime = (date: Date, timeZoneId: string, hour12: boolean = false) => {
    if (!date) {
        return '';
    }

    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timeZoneId,
    };

    if (hour12) options.hour12 = true;
    else options.hourCycle = 'h23';

    return new Date(`${date}`).toLocaleString([], options);
};

export const toTimeZoneDateTime = (date: Date, timeZoneId: string, hour12: boolean = false) => {
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timeZoneId,
    };

    if (hour12) options.hour12 = true;
    else options.hourCycle = 'h23';

    return new Date(`${date}`).toLocaleString([], options);
};

export const toTimeZoneDate = (date: Date, timeZoneId: string) => {
    if (date !== null && date !== undefined) {
        return new Date(`${date}`).toLocaleString([], {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            timeZone: timeZoneId,
        });
    }
    return ' ';
};

export const sortByDate = (a?: Date | string, b?: Date | string, sortNullAsLast: boolean = false) => {
    return (
        (new Date(`${a}`).getTime() || (sortNullAsLast ? Number.MAX_SAFE_INTEGER : 0)) -
        (new Date(`${b}`).getTime() || (sortNullAsLast ? Number.MAX_SAFE_INTEGER : 0))
    );
};

export const sortByDateDesc = (a?: Date | string, b?: Date | string, sortNullAsLast: boolean = false) => {
    return (
        (new Date(`${b}`).getTime() || (sortNullAsLast ? Number.MAX_SAFE_INTEGER : 0)) -
        (new Date(`${a}`).getTime() || (sortNullAsLast ? Number.MAX_SAFE_INTEGER : 0))
    );
};

export const formatFullName = (firstName?: string, lastName?: string) => {
    return `${firstName} ${lastName}`;
};

export const formatAddress = (
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zipCode?: string
) => {
    let addressComplete: string = '';

    addressComplete += addressLine1 ? `${addressLine1} ` : '';
    addressComplete += addressLine2 ? `${addressLine2} ` : '';
    addressComplete += city ? `${city} ` : '';
    addressComplete += state ? `${state} ` : '';
    addressComplete += zipCode ? `${zipCode}` : '';

    return addressComplete;
};

export const formatAddressFromObject = (address: Address) => {
    return formatAddress(address.addressLine1, address.addressLine2, address.city, address.state, address.zipCode);
};

export const formatAddressLine = (streetNumber?: string, streetName?: string) => {
    return `${streetNumber} ${streetName}`;
};

export const formatTimeSpan = (hours?: number, minutes?: number) => {
    return `0.${hours}:${minutes}:00`;
};

export const getDateWithoutTime = (date: Date) => {
    return new Date(date).toISOString().split('T')[0];
};

export const getHoursDuration = (duration?: string) => {
    if (!duration) {
        return 0;
    }

    return Number(duration?.split(':')[0]);
};

export const getMinutesDuration = (duration?: string) => {
    if (!duration) {
        return 0;
    }

    return Number(duration?.split(':')[1]);
};

export const toFriendlyDuration = (duration?: string, short: boolean = false) => {
    if (!duration) {
        return '';
    }

    const _duration = toDurationObject(duration);

    if (!!_duration.days || !!_duration.hours || !!_duration.minutes)
        var friendlyDuration = formatDuration(_duration, {
            format: ['days', 'hours', 'minutes'],
        });
    else friendlyDuration = formatDuration(_duration);

    if (!friendlyDuration) {
        friendlyDuration = '0';
    }

    if (short) {
        friendlyDuration = friendlyDuration
            .replace(' seconds', 's')
            .replace(' second', 's')
            .replace(' minutes', 'm')
            .replace(' minute', 'm')
            .replace(' hours', 'h')
            .replace(' hour', 'h')
            .replace(' days', 'd')
            .replace(' day', 'd');
    }

    return friendlyDuration;
};

export const toDuration = (duration?: string) => {
    if (!duration) {
        return '';
    }

    const _duration = toDurationObject(duration);

    const days = _duration.days;
    const hours = `${_duration.hours}`.padStart(2, '0');
    const minutes = `${_duration.minutes}`.padStart(2, '0');
    const seconds = `${_duration.seconds}`.padStart(2, '0');

    return `${!!days ? days + '.' : ''}${hours}:${minutes}:${seconds}`;
};

export const toDurationHHMMSS = (duration?: string) => {
    if (!duration) {
        return '';
    }

    const _duration = toDurationObject(duration);

    const hoursInDays = _duration.days ? 24 * _duration.days : 0;

    const totalHours = hoursInDays + (_duration.hours ?? 0);

    const hours = `${totalHours}`.padStart(2, '0');
    const minutes = `${_duration.minutes}`.padStart(2, '0');
    const seconds = `${_duration.seconds}`.padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

export const toDurationObject = (duration: string) => {
    if (!duration) {
        return { seconds: 0 };
    }

    let days;
    let time;

    const durationParts = duration.split('.');

    if (durationParts.length > 2) {
        days = Number(durationParts[0]);
        time = durationParts[1];
    } else if (durationParts.length > 0) {
        time = durationParts[0];
    } else {
        return { seconds: 0 };
    }

    const timeParts = time.split(':');

    return {
        days: days,
        hours: Number(timeParts[0]),
        minutes: Number(timeParts[1]),
        seconds: Number(timeParts[2]),
    };
};

export const sumDurations = (duration1: string, duration2: string) => {
    const duration1InSeconds = ConvertDurationToSeconds(duration1);
    const duration2InSeconds = ConvertDurationToSeconds(duration2);

    const durationInSeconds = duration1InSeconds + duration2InSeconds;

    const duration = ConvertSecondsToDuration(durationInSeconds);
    return duration;
};

export const subtractDurations = (duration1: string, duration2: string) => {
    const duration1InSeconds = ConvertDurationToSeconds(duration1);
    const duration2InSeconds = ConvertDurationToSeconds(duration2);

    const durationInSeconds = duration1InSeconds - duration2InSeconds;

    const duration = ConvertSecondsToDuration(durationInSeconds);
    return duration;
};

export const ConvertSecondsToDuration = (secondsNum: number) => {
    const days = (secondsNum / 86400) | 0;
    const hours = ((secondsNum % 86400) / 3600) | 0;
    const minutes = ((secondsNum % 3600) / 60) | 0;
    const seconds = secondsNum % 60;

    return `${!!days ? days + '.' : ''}${hours}:${minutes}:${seconds}${seconds.toString().includes(".") ? '' : '.0'}`;
};

export const ConvertDurationToSeconds = (duration: string) => {
    const durationObj = toDurationObject(duration);

    const durationInSeconds =
        (!!durationObj.days ? durationObj.days * 86400 : 0) +
        (!!durationObj.hours ? durationObj.hours * 3600 : 0) +
        (!!durationObj.minutes ? durationObj.minutes * 60 : 0) +
        (!!durationObj.seconds ? durationObj.seconds : 0);

    return durationInSeconds;
};

export const getInitialsFromFullName = (fullName?: string) => {
    if (!fullName) {
        return 'UU';
    }

    return fullName.split(' ').reduce((acc, curr) => `${acc}${curr.charAt(0).toUpperCase()}`, '');
};

export const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = match[1] ? '+1 ' : '';

        return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return null;
};

export const getFileNameFromAzureURI = (containerUri: string) => {
    return containerUri.substring(containerUri.lastIndexOf('/') + 1);
};

export const addFileNameToExistingAzureURI = (containerUri: string, existingUri: string, fileName: string) => {
    const uriWithoutFileName = existingUri.substring(0, existingUri.lastIndexOf('/'));
    const uuidName = uriWithoutFileName.substring(uriWithoutFileName.lastIndexOf('/') + 1);
    return generateAzureURIWithUuid(containerUri, uuidName, fileName);
};

export const generateAzureURIWithUuid = (containerUri: string, uuid: string, fileName: string) => {
    return containerUri! + `/${uuid}/${fileName}`;
};

export const deserializeHiddenColumns = (columns: string[]) => {
    const hiddenColumns = columns.reduce(
        (ret, column, index) => (ret += `"${column}":false${index === columns.length - 1 ? '}' : ','}`),
        '{'
    );

    return JSON.parse(hiddenColumns === '{' ? '{}' : hiddenColumns);
};

export const isNumber = (value: string | number | undefined) => {
    return value != null && value !== undefined && value !== '' && !isNaN(Number(value.toString()));
};

export const getCSVFilename = (title: string) => {
    return `${title}_${new Date().toJSON().slice(0, 10)}.csv`;
}