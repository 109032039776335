import React, { useCallback, useEffect, useState } from 'react';

import { formatPhoneNumber } from '../utilities/formatter';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import Loading from './Loading';
import { useNotifications } from '../hooks/useNotifications';
import useInput from '../hooks/useInput';

interface SelectPhoneNumberModalProps {
    showModal: boolean;
    toggler: () => void;
    onSelect: (phoneNumber: string) => void;
}

interface PhoneNumberCardProps {
    phoneNumber: string;
    name: string;
    isSelected: boolean;
}

const PhoneNumberCard = (props: PhoneNumberCardProps) => {
    const { phoneNumber, name, isSelected } = props;

    return (
        <>
            <div className="card card_hoverable card_list">
                <div className="image image_icon">
                    <span className="la la-phone la-4x"></span>
                </div>
                <div className="body">
                    <h5>{name}</h5>
                    <p>{formatPhoneNumber(phoneNumber)}</p>
                </div>
                <div className="actions">
                    <label className="custom-checkbox">
                        <input type="radio" value={phoneNumber} checked={isSelected} readOnly />
                        <span></span>
                    </label>
                </div>
            </div>
        </>
    );
};

const SelectPhoneNumberModal = (props: SelectPhoneNumberModalProps) => {
    const { showModal, toggler, onSelect } = props;

    const searchInput = useInput('');
    const [formattedSearch, setFormattedSearch] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');

    const { useGetAvailablePhoneNumbers } = useNotifications();
    const {
        data: phoneNumbers,
        isSuccess: phoneNumbersLoaded,
        isError: phoneNumbersError,
        isFetching: phoneNumbersLoading,
        refetch,
    } = useGetAvailablePhoneNumbers({
        enabled: showModal,
        search: formattedSearch || undefined,
    });

    const onSelectedPhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPhoneNumber(event.target.value);
    }, []);

    const handleOnSelect = () => {
        if (selectedPhoneNumber) {
            onSelect(selectedPhoneNumber);
        }
    };

    useEffect(() => {
        if (!showModal) {
            searchInput.reset();
        }
    }, [showModal, searchInput]);

    useEffect(() => {
        const newValue = searchInput.value ? `+1${searchInput.value}` : '';

        setFormattedSearch(newValue);
    }, [searchInput.value]);

    useEffect(() => {
        setSelectedPhoneNumber('');
    }, [phoneNumbersLoading, phoneNumbersLoaded]);

    return (
        <>
            <Modal isOpen={showModal} toggler={toggler} size="md" isStatic={true} isFullHeight={true}>
                <ModalHeader toggler={toggler}>Select a phone number</ModalHeader>
                <ModalBody>
                    <div className="flex flex-wrap gap-2 items-center mb-5">
                        <div className="flex flex-auto items-center">
                            <label className="form-control-addon-within rounded-full">
                                <input
                                    type="text"
                                    className="form-control border-none"
                                    placeholder="Starts with..."
                                    {...searchInput.bind}
                                />
                                <button
                                    className="btn btn-link text-gray-300 dark:text-gray-700 dark:hover:text-primary text-xl leading-none mr-4"
                                    onClick={() => refetch()}
                                >
                                    <span className="la la-search"></span>
                                </button>
                            </label>
                        </div>
                        <button className="btn btn_outlined btn_secondary uppercase" onClick={() => refetch()}>
                            <span className="la la-sync text-lg leading-none"></span>
                        </button>
                    </div>
                    <div className="px-1 pb-1">
                        <Loading isLoading={phoneNumbersLoading} />
                        {!phoneNumbersLoading &&
                            (phoneNumbersError || (phoneNumbersLoaded && !phoneNumbers?.length)) && (
                                <div className="flex items-center justify-center py-10">
                                    <div className="w-full md:w-1/2 xl:w-1/3">
                                        <div className="text-center">
                                            <h2 className="uppercase">No numbers found.</h2>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {!phoneNumbersLoading && phoneNumbersLoaded && (
                            <div className="flex flex-col gap-3" onChange={onSelectedPhone}>
                                {phoneNumbers?.map((number) => (
                                    <PhoneNumberCard
                                        key={number.phoneNumber}
                                        {...number}
                                        isSelected={number.phoneNumber === selectedPhoneNumber}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button className="btn btn_secondary uppercase" onClick={toggler}>
                            Cancel
                        </button>
                        <button
                            className="btn btn_primary ml-2 uppercase"
                            disabled={!selectedPhoneNumber}
                            onClick={handleOnSelect}
                        >
                            Select
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default SelectPhoneNumberModal;
