export const usStates = [
    {
        name: 'Alabama',
        abbreviation: 'AL',
    },
    {
        name: 'Alaska',
        abbreviation: 'AK',
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        name: 'California',
        abbreviation: 'CA',
    },
    {
        name: 'Colorado',
        abbreviation: 'CO',
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        name: 'Delaware',
        abbreviation: 'DE',
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM',
    },
    {
        name: 'Florida',
        abbreviation: 'FL',
    },
    {
        name: 'Georgia',
        abbreviation: 'GA',
    },
    {
        name: 'Guam',
        abbreviation: 'GU',
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        name: 'Idaho',
        abbreviation: 'ID',
    },
    {
        name: 'Illinois',
        abbreviation: 'IL',
    },
    {
        name: 'Indiana',
        abbreviation: 'IN',
    },
    {
        name: 'Iowa',
        abbreviation: 'IA',
    },
    {
        name: 'Kansas',
        abbreviation: 'KS',
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        name: 'Maine',
        abbreviation: 'ME',
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        name: 'Maryland',
        abbreviation: 'MD',
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        name: 'Missouri',
        abbreviation: 'MO',
    },
    {
        name: 'Montana',
        abbreviation: 'MT',
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        name: 'Nevada',
        abbreviation: 'NV',
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        name: 'New York',
        abbreviation: 'NY',
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        name: 'Ohio',
        abbreviation: 'OH',
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        name: 'Oregon',
        abbreviation: 'OR',
    },
    {
        name: 'Palau',
        abbreviation: 'PW',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        name: 'Texas',
        abbreviation: 'TX',
    },
    {
        name: 'Utah',
        abbreviation: 'UT',
    },
    {
        name: 'Vermont',
        abbreviation: 'VT',
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        name: 'Virginia',
        abbreviation: 'VA',
    },
    {
        name: 'Washington',
        abbreviation: 'WA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY',
    },
];

export const timeZones = [
    {
        id: 'Alaskan Standard Time',
        displayName: '(UTC-09:00) Alaska',
        ianaName: 'US/Alaska',
        country: 'US',
    },
    {
        id: 'Eastern Standard Time',
        displayName: '(UTC-05:00) Eastern Time',
        ianaName: 'EST5EDT',
        country: 'US',
    },
    {
        id: 'Hawaiian Standard Time',
        displayName: '(UTC-10:00) Hawaii',
        ianaName: 'Pacific/Honolulu',
        country: 'US',
    },

    {
        id: 'Mountain Standard Time',
        displayName: '(UTC-07:00) Mountain Time',
        ianaName: 'MST7MDT',
        country: 'US',
    },

    {
        id: 'Pacific Standard Time',
        displayName: '(UTC-08:00) Pacific Time',
        ianaName: 'PST8PDT',
        country: 'US',
    },

    {
        id: 'US Mountain Standard Time',
        displayName: '(UTC-07:00) Arizona',
        ianaName: 'America/Phoenix',
        country: 'US',
    },
];

export const recurringOptionsObject = [
    {
        name: 'Daily',
        value: '0',
    },
    {
        name: 'Biweekly',
        value: '1',
    },
    {
        name: 'Monthly',
        value: '2',
    },
    {
        name: 'Multiple Days',
        value: '3',
    },
];

export const entryTypeObject = [
    {
        name: 'Clock In',
        value: '0',
        jobsFeature: false,
        order: 1,
    },
    {
        name: 'Clock Out',
        value: '1',
        jobsFeature: false,
        order: 2,
    },
    {
        name: 'Time In',
        value: '2',
        jobsFeature: true,
        order: 5,
    },
    {
        name: 'Time Out',
        value: '3',
        jobsFeature: true,
        order: 6,
    },
    {
        name: 'Clock Idle',
        value: '4',
        jobsFeature: false,
        order: 3,
    },
    {
        name: 'Clock Period',
        value: '5',
        jobsFeature: false,
        order: 4,
    },
];

export const UserRoles = [
    {
        name: 'Admin',
        value: '1',
    },
    {
        name: 'Employee',
        value: '2',
    },
];

export const JobsSortingFields = [
    {
        name: 'Customer',
        value: 0,
    },
    {
        name: 'Employees',
        value: 1,
    },
    {
        name: 'Job Date',
        value: 2,
    },
    {
        name: 'Duration',
        value: 3,
    },
    {
        name: 'Status',
        value: 4,
    },
];

export enum userRolesEnum {
    SuperAdmin,
    Admin,
    Employee,
    Customer,
}

export const jobEntryTypeObject = [
    {
        name: 'Time In',
        value: '0',
    },
    {
        name: 'Time Out',
        value: '1',
    },
];

export enum entryTypeEnum {
    'Clock In',
    'Clock Out',
    'Time In',
    'Time Out',
    'Clock Idle',
    'Clock Period',
}

export const chartPieJobsData = ['Assigned Jobs', 'Floating Jobs'];

export const optionsCharts = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
};

export enum recurringOptionEnum {
    Daily,
    Biweekly,
    Monthly,
    'Multiple Days',
}

export enum timekeepingEntryTypeEnum {
    'Clock In',
    'Clock Out',
}

export enum jobTimekeepingEntryTypeEnum {
    'Time In',
    'Time Out',
}

export enum allowedJobActionEnum {
    Assign,
    Unassign,
    Edit,
    Delete,
}

export enum tenantFeatureEnum {
    Chat,
    Payments,
    RecurringJobs,
    Jobs,
}

export enum jobStatusEnum {
    Open,
    Assigned,
    Running,
    Completed,
}

export enum jobActionEnum {
    Clone,
    Edit,
    Info,
}

export enum jobAssignActionEnum {
    Assign,
    Unassign,
    Reassign,
}

export enum PointTypeEnum {
    AzureRoute,
    LocationStamp,
    ClockIn,
    ClockOut,
    TimeIn,
    TimeOut,
}
