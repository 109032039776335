import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
    FormInputAutocompleteControl,
    FormInputControl,
    FormNumberControl,
    FormSelectControl,
    FormTextareaControl,
    FormInputAutocompleteMultiSelectControl,
    FormInputCheckBoxControl,
    FormInputMultipleDateControl,
    FormInputDateControl,
} from './FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import { useJobs } from '../hooks/useJobs';
import {
    formatAddress,
    formatFullName,
    formatDateTime,
    getFileNameFromAzureURI,
    addFileNameToExistingAzureURI,
    generateAzureURIWithUuid,
    formatTimeSpan,
} from '../utilities/formatter';
import {
    allowedJobActionEnum,
    jobActionEnum,
    recurringOptionEnum,
    recurringOptionsObject,
} from '../utilities/staticData';
import { useTenants } from '../hooks/useTenants';
import { uploadFileToBlobStorage } from '../utilities/azure-storage.blob';
import { useAuth } from '../hooks/useAuth';
import TippyToolTip from './TippyToolTip';
import { Address } from '../utilities/interfaces';
import Loading from './Loading';

interface FormInput {
    description?: string;
    customerId?: string;
    customerName?: string;
    employees: string[];
    jobDate?: string;
    multipleJobDates?: string[];
    addressId?: string;
    address?: Address;
    windowOfArrivalStart?: string;
    windowOfArrivalEnd?: string;
    durationHours?: number;
    durationMinutes?: number;
    isFloating: boolean;
    recurringOptionsEnabled: boolean;
    recurrenceOption?: string;
    jobDateRangeStartDate?: string;
    jobDateRangeEndDate?: string;
    jobDates?: string[];
    files?: string[];
    isEdit?: boolean;
}

interface FileUpload {
    file?: File;
    fileUri: string;
}

interface JobModalProps {
    modalTitle: string;
    showModal: boolean;
    toggler: () => void;
    action: (obj: FormInput, recurrenceOption: string) => void;
    isSuccessRequest: boolean;
    isLoadingRequest: boolean;
    serverErrors: any;
    data?: FormInput;
    isLoaded?: boolean;
    customers: CreateJobCustomer[];
    employees: CreateJobEmployee[];
    isEditJob?: boolean;
    disableAssignEmployees?: boolean;
    disableEdition?: boolean;
}

interface CreateJobCustomer {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    addresses: Address[];
    value: string;
    text: string;
}

interface CreateJobEmployee {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    value: string;
    text: string;
}

const schema = yup
    .object({
        description: yup.string().optional(),
        customerId: yup.string().optional(),
        customerName: yup.string().required('Customer is required.'),
        employees: yup.array().when('isFloating', {
            is: false,
            then: yup
                .array()
                .required('At least one employee is required.')
                .min(1, 'At least one employee is required.'),
        }),
        jobDate: yup.string().when('isEdit', {
            is: true,
            then: yup
                .string()
                .when('recurrenceOption', {
                    is: '' || undefined,
                    then: yup.string().required('Job Date is required.'),
                })
                .when('recurringOptionsEnabled', {
                    is: false,
                    then: yup.string().required('Job Date is required.'),
                }),
        }),
        addressId: yup.string().required('Address is required.'),
        windowOfArrivalStart: yup.string().required('Window Of Arrival Start Time is required.'),
        windowOfArrivalEnd: yup
            .string()
            .required('Window Of Arrival End Time is required.')
            .test(
                'arrival-end-min',
                'Window Of Arrival End Time must be after Window Of Arrival Start Time.',
                (value, context) => {
                    return (context.parent.windowOfArrivalStart || '') <= (value || '');
                }
            ),
        durationHours: yup
            .number()
            .typeError('Duration Hours is required.')
            .integer('Duration Hours must be an integer number.')
            .moreThan(-1, 'Duration Hours must be from 00 to 23.')
            .lessThan(24, 'Duration Hours must be from 00 to 23.')
            .test('total-duration-minutes', 'Total duration must be greater than zero.', (value, context) => {
                return (context.parent.durationMinutes || 0) + (value || 0) > 0;
            }),
        durationMinutes: yup
            .number()
            .typeError('Duration Minutes is required.')
            .integer('Duration Minutes must be an integer number.')
            .moreThan(-1, 'Duration Hours must be from 00 to 59.')
            .lessThan(60, 'Duration Hours must be from 00 to 59.')
            .test('total-duration-hours', 'Total duration must be greater than zero.', (value, context) => {
                return (context.parent.durationHours || 0) + (value || 0) > 0;
            }),
        isFloating: yup.boolean().required(),
        recurringOptionsEnabled: yup.boolean().optional(),
        recurrenceOption: yup.string().when('recurringOptionsEnabled', {
            is: true,
            then: yup.string().required('Job Recurrence Option is required.'),
        }),
        jobDateRangeStartDate: yup.string().when('recurrenceOption', {
            is: (recurrenceOption: any) =>
                recurrenceOption === recurringOptionEnum.Daily.toString()
                    ? true
                    : recurrenceOption === recurringOptionEnum.Biweekly.toString()
                    ? true
                    : recurrenceOption === recurringOptionEnum.Monthly.toString()
                    ? true
                    : false,
            then: yup.string().required('Job Start Date is required.'),
        }),
        jobDateRangeEndDate: yup.string().when('recurrenceOption', {
            is: (recurrenceOption: any) =>
                recurrenceOption === recurringOptionEnum.Daily.toString()
                    ? true
                    : recurrenceOption === recurringOptionEnum.Biweekly.toString()
                    ? true
                    : recurrenceOption === recurringOptionEnum.Monthly.toString()
                    ? true
                    : false,
            then: yup.string().required('Job End Date is required.'),
        }),
        jobDates: yup.array().when('recurrenceOption', {
            is: (recurrenceOption: any) =>
                recurrenceOption === recurringOptionEnum['Multiple Days'].toString() ? true : false,
            then: yup.array().required('Job Multiple Days is Required').min(1, 'Job Multiple Days is Required'),
        }),
        multipleJobDates: yup.array().when('isEdit', {
            is: false || undefined,
            then: yup.array().when('recurringOptionsEnabled', {
                is: (recurringOptionsEnabled: any) => (recurringOptionsEnabled === true ? false : true),
                then: yup.array().required('Job Date(s) is Required').min(1, 'Job Date(s) is Required'),
            }),
        }),
        files: yup.array().optional(),
    })
    .required();

const JobModal = (props: JobModalProps) => {
    const {
        modalTitle,
        showModal,
        toggler,
        action,
        isSuccessRequest,
        isLoadingRequest,
        serverErrors,
        data: dataJob,
        isLoaded,
        customers,
        employees,
        isEditJob,
        disableAssignEmployees,
        disableEdition,
    } = props;

    const { tenant } = useAuth();

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        setValue,
        getValues,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const [addresses, setAddresses] = useState<{ value: string; text: string; address: Address }[]>();
    const [loadedEmployees, setLoadedEmployees] = useState<{ value: string; text: string }[]>([]);
    const [addressDisabled, setAddressDisabled] = useState(true);
    const [floatingJob, setFloatingJob] = useState(false);
    const [recurringJob, setRecurringJob] = useState(false);
    const [recurrenceOption, setRecurrenceOption] = useState('');
    const [uuid, setUuid] = useState('');
    const [filesSelected, setFilesSelected] = useState<File[]>([]);
    const [getSas, setGetSas] = useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);

    const [employeesFiltred, setEmployeesFiltred] = useState<any[]>(employees);

    const { useGetSasToken } = useTenants();
    const { data: dataToken } = useGetSasToken(getSas);

    const recurringOptions = recurringOptionsObject.map((option) => {
        return {
            value: option.value,
            text: option.name,
        };
    });

    const resetForm = useCallback(() => {
        setAddresses(undefined);
        resetClient();
        toggler();
        setValue('employees', []);
        setFloatingJob(false);
        setRecurringJob(false);
        setRecurrenceOption('');
        setFilesSelected([]);
        setGetSas(false);
        setIsUploadingFiles(false);
    }, [resetClient, toggler, setValue]);

    const onCustomerSelected = useCallback(
        (value?: string) => {
            let addresses =
                customers
                    .find((customer) => customer.id === value)
                    ?.addresses?.map((address) => ({
                        value: address.id || '',
                        text: formatAddress(
                            address.addressLine1,
                            address.addressLine2,
                            address.city,
                            address.state,
                            address.zipCode
                        ),
                        address: address,
                    })) || [];

            if (
                value === dataJob?.customerId &&
                isEditJob &&
                !!dataJob?.addressId &&
                !addresses?.some((a) => a.address.id === dataJob?.addressId)
            ) {
                const address = dataJob?.address!;
                addresses.push({
                    value: address.id || '',
                    text: formatAddress(
                        address.addressLine1,
                        address.addressLine2,
                        address.city,
                        address.state,
                        address.zipCode
                    ),
                    address: address,
                });
            }

            if (addresses && addresses?.length > 1) {
                setAddressDisabled(false);
            } else {
                setAddressDisabled(true);
            }

            if (!!value) {
                setValue('customerId', value);
            }

            setAddresses(addresses);
        },
        [customers, setValue, isEditJob, dataJob?.addressId, dataJob?.customerId, dataJob?.address]
    );

    const onSetFilesURLArray = (data: FormInput) => {
        let dataFiles: string[] = [];
        let filesURL: FileUpload[];

        if (!!data.files && data.files.length > 0) {
            filesURL = filesSelected.map((fileItem) => {
                return {
                    file: fileItem,
                    fileUri: addFileNameToExistingAzureURI(dataToken?.containerUri!, data.files![0], fileItem?.name),
                };
            });
        } else {
            filesURL = filesSelected.map((fileItem) => {
                return {
                    file: fileItem,
                    fileUri: generateAzureURIWithUuid(dataToken?.containerUri!, uuid, fileItem?.name),
                };
            });
        }

        dataFiles = filesURL
            .map((fileItem) => {
                return fileItem.fileUri;
            })
            .concat(data.files || []);

        return { filesURL, dataFiles };
    };

    const onSubmit = async (data: FormInput) => {
        if (!!filesSelected) {
            const { filesURL, dataFiles } = onSetFilesURLArray(data);
            data.files = dataFiles;
            if (!(await onFilesUpload(filesURL))) {
                return;
            }
        }

        data.address = addresses?.find((a) => a.address.id === data.addressId)?.address;

        action(data, recurrenceOption);
    };

    const onEmployeeSelected = (employees: { value: string; text: string }[]) => {
        setValue(
            'employees',
            employees.map((obj) => obj.value)
        );
    };

    const onSelectedReoccuringOption = (option?: string) => {
        setRecurrenceOption(option!);
    };

    const onJobDateSelected = (date?: string) => {
        setValue('jobDate', date);
    };

    const onDaySelected = (dates: string[]) => {
        setValue('multipleJobDates', dates);
    };

    const onDropFiles = (files: File[]) => {
        const notInSelectedFiles = files.filter((f) => !filesSelected.some((fs) => fs.name === f.name));
        const notInUploadedFiles = notInSelectedFiles.filter(
            (ns) => !dataJob?.files?.some((uf) => getFileNameFromAzureURI(uf) === ns.name)
        );
        setFilesSelected([...filesSelected, ...notInUploadedFiles]);

        setGetSas(true);

        if (!uuid) {
            setUuid(uuidv4());
        }
    };

    const onFileDeleted = (item: File) => {
        if (!!filesSelected) {
            const newFilesSelected = filesSelected.filter((element) => element !== item);
            setFilesSelected(newFilesSelected);
        }
    };

    const onFilesUpload = async (files: FileUpload[]) => {
        setIsUploadingFiles(true);
        var success = true;
        for (const fileItem of files) {
            if (fileItem.file) {
                if (!(await uploadFileToBlobStorage(fileItem.fileUri + dataToken?.sasToken!, fileItem.file))) {
                    success = false;
                }
            }
        }
        setIsUploadingFiles(false);
        return success;
    };

    const onReccurringJobChange = useCallback(
        (checked: boolean) => {
            setRecurringJob(checked);
            if (!checked) {
                setValue('recurrenceOption', undefined);
                setValue('jobDateRangeStartDate', undefined);
                setValue('jobDateRangeEndDate', undefined);
                setValue('jobDates', undefined);
                setRecurrenceOption('');
            }
        },
        [setRecurringJob, setValue, setRecurrenceOption]
    );

    const onFloatingJobChange = useCallback(
        (checked: boolean) => {
            setFloatingJob(checked);
            if (checked) {
                setValue('employees', []);
            }
        },
        [setValue]
    );

    useEffect(() => {
        if (isSuccessRequest) {
            resetForm();
        }
    }, [isSuccessRequest, resetForm]);

    useEffect(() => {
        if (addresses?.length === 1) {
            setValue('addressId', addresses[0].value);
        }
    }, [addresses, setValue]);

    useEffect(() => {
        if (isLoaded && customers.length > 0) {
            const customer = customers?.find((x) => x.id === dataJob?.customerId);

            if (dataJob?.customerId && !!customer) {
                setValue('customerName', formatFullName(customer?.firstName, customer?.lastName));
                onCustomerSelected(customer?.id);
                onFloatingJobChange(dataJob?.isFloating || false);
            }
            resetClient(dataJob);
        }
    }, [resetClient, dataJob, isLoaded, customers, setValue, onCustomerSelected, onFloatingJobChange]);

    useEffect(() => {
        if (isEditJob && isLoaded) {
            onReccurringJobChange(dataJob?.recurringOptionsEnabled || false);
            setRecurrenceOption(dataJob?.recurrenceOption!.toString()!);

            let activeEmployees = employees?.filter((element) => element.active === true);

            setEmployeesFiltred(activeEmployees);

            if (disableAssignEmployees) {
                setLoadedEmployees(
                    employees?.filter((element) =>
                        dataJob?.employees.some((employeePayload) => employeePayload === element.value)
                    )
                );
            } else {
                const activeEmployeesInJob = activeEmployees?.filter((element) =>
                    dataJob?.employees.some((employeePayload) => employeePayload === element.value)
                );

                setLoadedEmployees(activeEmployeesInJob);
                setValue(
                    'employees',
                    activeEmployeesInJob.map((obj) => obj.value)
                );
            }
        }
    }, [dataJob, isEditJob, isLoaded, onReccurringJobChange, employees, disableAssignEmployees, setValue]);

    useEffect(() => {
        if (!isEditJob || isEditJob === undefined) {
            let activeEmployees = employees?.filter((element) => element.active === true);
            setEmployeesFiltred(activeEmployees);
        }
    }, [isEditJob, employees]);

    return (
        <>
            <Modal
                isOpen={showModal}
                toggler={resetForm}
                size="lg"
                isStatic={true}
                isDisabled={isUploadingFiles || isLoadingRequest}
            >
                <ModalHeader toggler={resetForm} closeButtonDisabled={isUploadingFiles || isLoadingRequest}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={!isLoaded} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormTextareaControl
                                type="text"
                                label="Description"
                                register={register('description')}
                                clientErrors={clientErrors?.description}
                                serverErrors={serverErrors?.description}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputAutocompleteControl
                                disabled={disableEdition}
                                label="Customer"
                                register={register('customerName')}
                                options={customers}
                                onChange={onCustomerSelected}
                                clientErrors={clientErrors?.customerName}
                                serverErrors={serverErrors?.customerName}
                                initialValue={getValues('customerName')}
                            />
                        </div>
                        <div className="mb-5">
                            <FormSelectControl
                                disabled={addressDisabled}
                                hideEmpty={true}
                                label="Address"
                                register={register('addressId')}
                                options={addresses}
                                clientErrors={clientErrors?.addressId}
                                serverErrors={serverErrors?.addressId}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-5 sm:gap-5">
                        <div className="mb-5 col-span-1">
                            <FormInputCheckBoxControl
                                disabled={disableAssignEmployees}
                                label="Floating Job"
                                register={register('isFloating')}
                                clientErrors={clientErrors?.isFloating}
                                serverErrors={serverErrors?.isFloating}
                                onChange={onFloatingJobChange}
                                tippyChild={<TippyToolTip tooltipText="Floating job means anyone can work it" />}
                            />
                        </div>
                        {!floatingJob && (
                            <div className="mb-5 col-span-3">
                                <FormInputAutocompleteMultiSelectControl
                                    disabled={disableAssignEmployees}
                                    label="Employees"
                                    register={register('employees')}
                                    options={employeesFiltred}
                                    onChange={onEmployeeSelected}
                                    clientErrors={clientErrors?.employees as FieldError}
                                    serverErrors={serverErrors?.employees}
                                    initialValues={loadedEmployees}
                                />
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-5 sm:gap-5">
                        {/*TODO: Refactor recurrence*/}
                        {tenant.hasRecurringJobsFeature && (
                            <div className="mb-5">
                                <FormInputCheckBoxControl
                                    label="Reoccuring Job"
                                    register={register('recurringOptionsEnabled')}
                                    clientErrors={clientErrors?.recurringOptionsEnabled}
                                    serverErrors={serverErrors?.recurringOptionsEnabled}
                                    onChange={onReccurringJobChange}
                                    tippyChild={
                                        <TippyToolTip tooltipText="Recurring job means you can schedule a Job Daily, Weekly, etc." />
                                    }
                                />
                            </div>
                        )}
                        {recurringJob && (
                            <div className="mb-5">
                                <FormSelectControl
                                    label="Reoccuring Options"
                                    hideEmpty={true}
                                    register={register('recurrenceOption')}
                                    options={recurringOptions}
                                    clientErrors={clientErrors?.recurrenceOption}
                                    serverErrors={serverErrors?.recurrenceOption}
                                    onChange={onSelectedReoccuringOption}
                                />
                            </div>
                        )}
                        {!recurringJob && recurrenceOption === '' && isEditJob && (
                            <div className="mb-5">
                                <FormInputDateControl
                                    label="Job Date"
                                    disabled={disableEdition}
                                    onChange={onJobDateSelected}
                                    clientErrors={clientErrors?.jobDate}
                                    serverErrors={serverErrors?.jobDate}
                                    initialValue={getValues('jobDate')}
                                    includeDeselect={true}
                                />
                            </div>
                        )}
                        {!recurringJob && recurrenceOption === '' && !isEditJob && (
                            <div className="mb-5 col-span-2">
                                <FormInputMultipleDateControl
                                    label="Job Date(s)"
                                    onChange={onDaySelected}
                                    clientErrors={clientErrors?.multipleJobDates as FieldError}
                                    serverErrors={serverErrors?.multipleJobDates}
                                />
                            </div>
                        )}
                        {recurringJob &&
                            (recurrenceOption === recurringOptionEnum.Daily.toString() ||
                                recurrenceOption === recurringOptionEnum.Biweekly.toString() ||
                                recurrenceOption === recurringOptionEnum.Monthly.toString()) && (
                                <div className="mb-5">
                                    <FormInputControl
                                        type="date"
                                        label="Job Start Date"
                                        register={register('jobDateRangeStartDate')}
                                        clientErrors={clientErrors?.jobDateRangeStartDate}
                                        serverErrors={serverErrors?.jobDateRangeStartDate}
                                    />
                                </div>
                            )}
                        {recurringJob && recurrenceOption === recurringOptionEnum['Multiple Days'].toString() && (
                            <div className="mb-5 col-span-2"></div>
                        )}
                        {recurringJob &&
                            recurrenceOption !== '' &&
                            recurrenceOption !== recurringOptionEnum['Multiple Days'].toString() && (
                                <div className="mb-5 col-span-1">
                                    {(recurrenceOption === recurringOptionEnum.Daily.toString() ||
                                        recurrenceOption === recurringOptionEnum.Biweekly.toString() ||
                                        recurrenceOption === recurringOptionEnum.Monthly.toString()) && (
                                        <FormInputControl
                                            type="date"
                                            label="Job End Date"
                                            register={register('jobDateRangeEndDate')}
                                            clientErrors={clientErrors?.jobDateRangeEndDate}
                                            serverErrors={serverErrors?.jobDateRangeEndDate}
                                        />
                                    )}
                                </div>
                            )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="time"
                                label="Window of Arrival Start Time"
                                register={register('windowOfArrivalStart')}
                                clientErrors={clientErrors?.windowOfArrivalStart}
                                serverErrors={serverErrors?.windowOfArrivalStart}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="time"
                                label="Window of Arrival End Time"
                                register={register('windowOfArrivalEnd')}
                                clientErrors={clientErrors?.windowOfArrivalEnd}
                                serverErrors={serverErrors?.windowOfArrivalEnd}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormNumberControl
                                label="Duration Hours"
                                min="00"
                                max="23"
                                register={register('durationHours')}
                                clientErrors={clientErrors?.durationHours}
                                serverErrors={serverErrors?.duration}
                                options={[
                                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                                    23,
                                ]}
                            />
                        </div>
                        <div className="mb-5">
                            <FormNumberControl
                                label="Duration Minutes"
                                min="00"
                                max="59"
                                step="5"
                                register={register('durationMinutes')}
                                clientErrors={clientErrors?.durationMinutes}
                                serverErrors={serverErrors?.duration}
                                options={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                            />
                        </div>
                    </div>
                    {dataJob?.files && dataJob?.files.length > 0 && (
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <label className="label block mb-2">Uploaded Files</label>
                                <table className="table table-auto table_hoverable w-full overscroll-y-contain">
                                    <tbody>
                                        {dataJob?.files?.map((fileObject, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <a href={fileObject} target="_blank" rel="noreferrer">
                                                        {getFileNameFromAzureURI(fileObject)}
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-2">
                            <label className="label block mb-2">Attachment</label>
                            <Dropzone onDrop={onDropFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps({
                                            className: 'dropzone',
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <h3>Drop files here to upload</h3>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className="mb-2">
                            <table className="table table-auto table_hoverable w-full overscroll-y-contain">
                                <thead>
                                    <tr>
                                        <th className="text-left">Selected Files</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filesSelected
                                        ?.map((fileObject, index) => (
                                            <tr key={index}>
                                                <td> {fileObject.name}</td>
                                                {!!fileObject && (
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="ltr:ml-1 rtl:mr-1  la la-times"
                                                            onClick={() => onFileDeleted(fileObject)}
                                                        ></button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                        .reverse()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isUploadingFiles || isLoadingRequest}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isUploadingFiles || isLoadingRequest}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const CloneJobModal = (props: { jobId?: string }) => {
    const { jobId } = props;

    const { tenant } = useAuth();

    const [showModal, setShowModal] = useState(false);

    const { usePostJob, useGetJob, useGetCreateJob } = useJobs();
    const {
        isLoading,
        isSuccess,
        create,
        reset: resetServer,
        error: { errors: serverErrors },
    } = usePostJob();

    const { data: dataJob, isFetching } = useGetJob(jobId || '', showModal, jobActionEnum.Clone);
    const { data: getCreateJobResults, isFetching: isFetchingGetCreateJobs } = useGetCreateJob(showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const setWindowsOfArrivalTimes = (data: FormInput, recurrenceOption: string) => {
        let startDate: string;
        let endDate: string;

        if (recurrenceOption !== null && recurrenceOption === '') {
            startDate = data.multipleJobDates![0] || '';
            endDate = data.multipleJobDates![0] || '';
        } else if (
            recurrenceOption === recurringOptionEnum.Daily.toString() ||
            recurrenceOption === recurringOptionEnum.Biweekly.toString() ||
            recurrenceOption === recurringOptionEnum.Monthly.toString()
        ) {
            startDate = data.jobDateRangeStartDate!;
            endDate = data.jobDateRangeEndDate!;
        } else if (recurrenceOption === recurringOptionEnum['Multiple Days'].toString()) {
            startDate = data.jobDates![0];
            endDate = data.jobDates![data.jobDates!.length - 1];
        }
        return {
            windowOfArrivalStart: zonedTimeToUtc(
                formatDateTime(startDate!, data.windowOfArrivalStart),
                tenant.timeZoneId
            ),
            windowOfArrivalEnd: zonedTimeToUtc(formatDateTime(endDate!, data.windowOfArrivalEnd), tenant.timeZoneId),
        };
    };

    const onSubmitUpdate = (data: FormInput, recurrenceOption: string) => {
        const { windowOfArrivalStart, windowOfArrivalEnd } = setWindowsOfArrivalTimes(data, recurrenceOption);

        create({
            description: data.description,
            customerId: getCreateJobResults?.customers?.find((x) => x.id === data.customerId)?.value || '',
            employees: data.employees === undefined ? [] : data.employees,
            addressId: data.addressId,
            isFloating: data.isFloating,
            recurrenceOption: recurrenceOption === '' ? undefined : Number(recurrenceOption),
            windowOfArrivalStart: windowOfArrivalStart,
            windowOfArrivalEnd: windowOfArrivalEnd,
            duration: formatTimeSpan(data.durationHours, data.durationMinutes),
            jobDates:
                recurrenceOption === recurringOptionEnum['Multiple Days'].toString()
                    ? data.jobDates?.map((date) => {
                          return zonedTimeToUtc(formatDateTime(date), tenant.timeZoneId);
                      })
                    : undefined,
            multipleJobDates: data.multipleJobDates
                ? data.multipleJobDates?.map((date) => {
                      return zonedTimeToUtc(formatDateTime(date), tenant.timeZoneId);
                  })
                : undefined,
            jobDateRange:
                recurrenceOption !== '' && recurrenceOption !== recurringOptionEnum['Multiple Days'].toString()
                    ? {
                          startDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeStartDate!), tenant.timeZoneId),
                          endDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeEndDate!), tenant.timeZoneId),
                      }
                    : undefined,
            files: data.files || [],
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetServer();
            toast.success('Job Created Successfully');
        }
    }, [isSuccess, resetServer]);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Clone Job">
                <span className="la la-copy"></span>
            </button>
            <JobModal
                modalTitle="Clone Job"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitUpdate}
                toggler={toggler}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                isLoaded={!isFetching && !isFetchingGetCreateJobs}
                data={dataJob}
                customers={getCreateJobResults?.customers || []}
                employees={getCreateJobResults?.employees || []}
                isEditJob={false}
            />
        </>
    );
};

export const EditJobModal = (props: { jobId?: string }) => {
    const { jobId } = props;

    const { tenant } = useAuth();

    const [showModal, setShowModal] = useState(false);

    const { useUpdateJob, useGetJob, useGetCreateJob } = useJobs();
    const {
        isLoading,
        isSuccess,
        update,
        error: { errors: serverErrors },
    } = useUpdateJob();

    const { data: dataJob, isFetching } = useGetJob(jobId || '', showModal, jobActionEnum.Edit);
    const { data: getCreateJobResults, isFetching: isFetchingGetCreateJobs } = useGetCreateJob(showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const isActionAllowed = (actions: number[]) => {
        return dataJob?.allowedJobActions.some((a) => a in actions);
    };

    const setWindowsOfArrivalTimes = (data: FormInput, recurrenceOption: string) => {
        let startDate: string;
        let endDate: string;

        if (recurrenceOption !== null && recurrenceOption === '') {
            startDate = data.jobDate || '';
            endDate = data.jobDate || '';
        } else if (
            recurrenceOption === recurringOptionEnum.Daily.toString() ||
            recurrenceOption === recurringOptionEnum.Biweekly.toString() ||
            recurrenceOption === recurringOptionEnum.Monthly.toString()
        ) {
            startDate = data.jobDateRangeStartDate!;
            endDate = data.jobDateRangeEndDate!;
        } else if (recurrenceOption === recurringOptionEnum['Multiple Days'].toString()) {
            startDate = data.jobDates![0];
            endDate = data.jobDates![data.jobDates!.length - 1];
        }
        return {
            windowOfArrivalStart: zonedTimeToUtc(
                formatDateTime(startDate!, data.windowOfArrivalStart),
                tenant.timeZoneId
            ),
            windowOfArrivalEnd: zonedTimeToUtc(formatDateTime(endDate!, data.windowOfArrivalEnd), tenant.timeZoneId),
        };
    };

    const onSubmitUpdate = (data: FormInput, recurrenceOption: string) => {
        const { windowOfArrivalStart, windowOfArrivalEnd } = setWindowsOfArrivalTimes(data, recurrenceOption);

        update({
            jobId: jobId!,
            description: data.description,
            customerId: getCreateJobResults?.customers?.find((x) => x.id === data.customerId)?.value || '',
            employees: data.employees === undefined ? [] : data.employees,
            addressId: data.addressId,
            address: data.address,
            isFloating: data.isFloating,
            recurrenceOption: recurrenceOption === '' ? undefined : Number(recurrenceOption),
            windowOfArrivalStart: windowOfArrivalStart,
            windowOfArrivalEnd: windowOfArrivalEnd,
            duration: formatTimeSpan(data.durationHours, data.durationMinutes),
            jobDates:
                recurrenceOption === recurringOptionEnum['Multiple Days'].toString()
                    ? data.jobDates?.map((date) => {
                          return zonedTimeToUtc(formatDateTime(date), tenant.timeZoneId);
                      })
                    : undefined,
            jobDate:
                recurrenceOption === '' ? zonedTimeToUtc(formatDateTime(data.jobDate), tenant.timeZoneId) : undefined,
            jobDateRange:
                recurrenceOption !== '' && recurrenceOption !== recurringOptionEnum['Multiple Days'].toString()
                    ? {
                          startDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeStartDate!), tenant.timeZoneId),
                          endDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeEndDate!), tenant.timeZoneId),
                      }
                    : undefined,
            files: data.files || [],
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Job Updated Successfully');
        }
    }, [isSuccess]);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Update Job">
                <span className="la la-pen-fancy"></span>
            </button>
            <JobModal
                modalTitle="Update Job"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitUpdate}
                toggler={toggler}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                isLoaded={!isFetching && !isFetchingGetCreateJobs}
                data={dataJob}
                customers={getCreateJobResults?.customers || []}
                employees={getCreateJobResults?.employees || []}
                isEditJob={true}
                disableAssignEmployees={!isActionAllowed([allowedJobActionEnum.Assign, allowedJobActionEnum.Unassign])}
                disableEdition={!isActionAllowed([allowedJobActionEnum.Edit])}
            />
        </>
    );
};

export const AddJobModal = () => {
    const { tenant } = useAuth();

    const [showModal, setShowModal] = useState(false);

    const { usePostJob } = useJobs();
    const {
        isLoading,
        isSuccess,
        create,
        reset: resetServer,
        error: { errors: serverErrors },
    } = usePostJob();

    const { useGetCreateJob } = useJobs();
    const { data: getCreateJobResults, isFetching: isFetchingGetCreateJobs } = useGetCreateJob(showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const setWindowsOfArrivalTimes = (data: FormInput, recurrenceOption: string) => {
        let startDate: string;
        let endDate: string;

        if (recurrenceOption !== null && recurrenceOption === '') {
            let selecteDate = '';

            if (data.multipleJobDates) {
                selecteDate = data.multipleJobDates![0] || '';
            }
            startDate = selecteDate;
            endDate = selecteDate;
        } else if (
            recurrenceOption === recurringOptionEnum.Daily.toString() ||
            recurrenceOption === recurringOptionEnum.Biweekly.toString() ||
            recurrenceOption === recurringOptionEnum.Monthly.toString()
        ) {
            startDate = data.jobDateRangeStartDate!;
            endDate = data.jobDateRangeEndDate!;
        } else if (recurrenceOption === recurringOptionEnum['Multiple Days'].toString()) {
            startDate = data.jobDates![0];
            endDate = data.jobDates![data.jobDates!.length - 1];
        }
        return {
            windowOfArrivalStart: zonedTimeToUtc(
                formatDateTime(startDate!, data.windowOfArrivalStart),
                tenant.timeZoneId
            ),
            windowOfArrivalEnd: zonedTimeToUtc(formatDateTime(endDate!, data.windowOfArrivalEnd), tenant.timeZoneId),
        };
    };

    const onSubmitCreate = (data: FormInput, recurrenceOption: string) => {
        const { windowOfArrivalStart, windowOfArrivalEnd } = setWindowsOfArrivalTimes(data, recurrenceOption);

        create({
            description: data.description,
            customerId: getCreateJobResults?.customers?.find((x) => x.id === data.customerId)?.value || '',
            employees: data.employees === undefined ? [] : data.employees,
            addressId: data.addressId,
            isFloating: data.isFloating,
            recurrenceOption: recurrenceOption === '' ? undefined : Number(recurrenceOption),
            windowOfArrivalStart: windowOfArrivalStart,
            windowOfArrivalEnd: windowOfArrivalEnd,
            duration: formatTimeSpan(data.durationHours, data.durationMinutes),
            jobDates:
                recurrenceOption === recurringOptionEnum['Multiple Days'].toString()
                    ? data.jobDates?.map((date) => {
                          return zonedTimeToUtc(formatDateTime(date), tenant.timeZoneId);
                      })
                    : undefined,
            multipleJobDates: data.multipleJobDates
                ? data.multipleJobDates?.map((date) => {
                      return zonedTimeToUtc(formatDateTime(date), tenant.timeZoneId);
                  })
                : undefined,
            jobDateRange:
                recurrenceOption !== '' && recurrenceOption !== recurringOptionEnum['Multiple Days'].toString()
                    ? {
                          startDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeStartDate!), tenant.timeZoneId),
                          endDate: zonedTimeToUtc(formatDateTime(data.jobDateRangeEndDate!), tenant.timeZoneId),
                      }
                    : undefined,
            files: data.files || [],
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetServer();
            toast.success('Job Created Successfully');
        }
    }, [isSuccess, resetServer]);

    return (
        <>
            <button className="btn btn_primary uppercase" onClick={toggler}>
                Add New
            </button>
            <JobModal
                modalTitle="Add Job"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitCreate}
                toggler={toggler}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                isLoaded={!isFetchingGetCreateJobs}
                customers={getCreateJobResults?.customers || []}
                employees={getCreateJobResults?.employees || []}
            />
        </>
    );
};
