import React, { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import apiClient from '../utilities/apiClient';
import { storage } from '../utilities/storage';
import { useTenants } from './useTenants';
import { useUsers } from './useUsers';
import { tenantFeatureEnum } from '../utilities/staticData';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

interface AuthContextInterface {
    isLoading: boolean;
    isAuthenticated: boolean;
    login: (options: any) => Promise<void>;
    logout: () => void;
    hasTenant: boolean;
    tenant: {
        id: string;
        name: string;
        timeZoneId: string;
        hasChatFeature: boolean;
        hasJobsFeature: boolean;
        hasRecurringJobsFeature: boolean;
        hasPaymentsFeature: boolean;
        hasStripeAccountConnected: boolean;
        latitude: number;
        longitude: number;
        allowedUserCount?: number;
    };
    setTenant: (tenantId: string) => void;
    unsetTenant: () => void;
    user: {
        id: string;
        name: string;
        email: string;
        userProfilePicture?: string;
        tenantsNumber: number;
        isSuperAdmin: boolean;
    };
    hasToken: boolean;
    token: string;
    loggedOut: boolean;
}

const store = storage<string>('workalert.state.tenant');

export const useAuthProvider = (): AuthContextInterface => {
    const initStateTenant = store.get() || '';

    const [tenantId, setTenantId] = useState(initStateTenant);
    const [userProfilePictureBase64, setUserProfilePictureBase64] = useState('');

    const [token, setToken] = useState('');
    const [intcptId, setIntcptId] = useState<number | null>(null);

    const [getTenant, setGetTenant] = useState(false);
    const { useGetTenantSettings } = useTenants();
    const { data: tenantData } = useGetTenantSettings(getTenant);

    const [getUser, setGetUser] = useState(false);
    const { useGetUser } = useUsers();
    const { data: userData, isSuccess: isSuccessGetUser } = useGetUser(getUser);

    const [loggedOut, setLoggedOut] = useState(false);

    const { isLoading, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const imageToBase64 = (url?: string) => {
        if (!url) {
            return;
        }

        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.addEventListener('load', function () {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            context!.drawImage(image, 0, 0);
            try {
                const base64 = canvas.toDataURL('image/png');
                setUserProfilePictureBase64(base64);
            } catch (err) {
                console.error(err);
            }
        });
        image.src = url;
    };

    const clearDataAndLocalStorage = () => {
        store.remove();
        setTenantId('');
        setUserProfilePictureBase64('');
    };

    const logoutAndClear = () => {
        logout({ returnTo: window.location.origin });
        clearDataAndLocalStorage();
        setLoggedOut(true);
    };

    const unsetTenantAndClear = () => {
        clearDataAndLocalStorage();
        setGetTenant(false);
        setGetUser(false);
    };

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            clearDataAndLocalStorage();
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                setToken(await getAccessTokenSilently());
            }
        })();
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (token && intcptId === null) {
            setIntcptId(
                apiClient.interceptors.request.use((config: any) => {
                    if (config?.headers) {
                        config.headers['Authorization'] = `Bearer ${token}`;
                        config.headers['X-WA-TenantId'] = store.get() || '';
                    }
                    return config;
                })
            );
        }
    }, [token, intcptId, setIntcptId]);

    useEffect(() => {
        if (intcptId !== null && !!tenantId) {
            setGetTenant(true);
            setGetUser(true);
        }
    }, [intcptId, tenantId]);

    useEffect(() => {
        if (isSuccessGetUser) {
            imageToBase64(userData?.profilePicture);
        }
    }, [isSuccessGetUser, userData?.profilePicture, tenantData?.features]);

    return {
        isLoading: isLoading,
        isAuthenticated: isAuthenticated,
        login: (options) => {
            return loginWithRedirect(options);
        },
        logout: () => {
            logoutAndClear();
        },
        hasTenant: !!tenantId,
        tenant: {
            id: tenantId,
            name: tenantData?.name!,
            timeZoneId: tenantData?.timeZoneId!,
            hasChatFeature: tenantData?.features.some((f) => f === tenantFeatureEnum.Chat) || false,
            hasJobsFeature: tenantData?.features.some((f) => f === tenantFeatureEnum.Jobs) || false,
            hasRecurringJobsFeature: tenantData?.features.some((f) => f === tenantFeatureEnum.RecurringJobs) || false,
            hasPaymentsFeature: tenantData?.features.some((f) => f === tenantFeatureEnum.Payments) || false,
            latitude: tenantData?.address?.latitude!,
            longitude: tenantData?.address?.longitude!,
            hasStripeAccountConnected: (tenantData?.stripeAccountId && tenantData?.stripeAccountOnboarded) || false,
            allowedUserCount: tenantData?.allowedUsers
        },
        setTenant: (tenantId: string) => {
            store.set(tenantId);
            setTenantId(tenantId);
        },
        unsetTenant: () => {
            unsetTenantAndClear();
        },
        user: {
            id: userData?.id!,
            name: userData?.name!,
            email: userData?.email!,
            userProfilePicture: userProfilePictureBase64,
            tenantsNumber: userData?.tenantsNumber!,
            isSuperAdmin: userData?.isSuperAdmin || false,
        },
        hasToken: intcptId !== null,
        token,
        loggedOut: loggedOut,
    };
};

const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain!}
            clientId={clientId!}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>
    );
};

export const AuthProviderWithRoles = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuthProvider();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
