import { useQuery, useMutation, useQueryClient } from 'react-query';
import apiClient, { ErrorResponse } from '../utilities/apiClient';
import { sortByDate, toTimeZoneDateTime } from '../utilities/formatter';
import { Address } from '../utilities/interfaces';
import { jobTimekeepingEntryTypeEnum, timekeepingEntryTypeEnum, userRolesEnum } from '../utilities/staticData';
import { useAuth } from './useAuth';

interface UserTenantsResponse {
    records: UserTenant[];
}

interface UserLocationsResponse {
    records: Location[];
}

interface UserResponse {
    record: User;
}

interface UserTimeEntriesResponse {
    timekeepingEntries: TimeKeepingEntry[];
    jobTimekeepingEntries: JobTimeKeepingEntry[];
}

interface TimeKeepingEntry {
    entryType: number;
    timeStamp: Date;
    tenantId: string;
    userId: string;
}

interface JobTimeKeepingEntry {
    entryType: number;
    employeeId: string;
    jobId: string;
    title: string;
    timeStamp: Date;
}

interface Location {
    latitude: number;
    longitude: number;
    timeStamp?: Date;
    pointType: number;
    ignoreByLongDistance: boolean;
}

interface UserTenant {
    id: string;
    name?: string;
    role: number;
    active: boolean;
    timeZoneId: string;
    timeZoneDisplayName: string;
    address: string;
}

interface Customer {
    customerId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    addresses?: Address[];
    unsubscribeSms?: boolean;
}

interface Employee {
    employeeId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    address?: Address;
    profilePicture?: string;
    role?: number;
    active?: boolean;
    hourlyRate?: number;
    geofenceDisabled?: boolean;
    unsubscribeSms?: boolean;
}

interface User {
    id: string;
    name: string;
    email: string;
    profilePicture?: string;
    tenantsNumber: number;
    isSuperAdmin: boolean;
}

interface UpdateJobTimeEntry {
    jobId: string;
    employeeId: string;
    jobEntryId: string;
    entryDateTime: Date;
}

interface UpdateTimeEntry {
    employeeId: string;
    entryId: string;
    entryDateTime: Date;
}

interface CreateTimeEntry {
    employeeId: string;
    entryType: number;
    entryDateTime: Date;
}

interface CreateTimeIdle {
    employeeId: string;
    startDateTime: Date;
    endDateTime: Date;
}

interface CreateTimePeriod {
    employeeId: string;
    clockInDateTime: Date;
    clockOutDateTime: Date;
}

interface DeleteTimeEntry {
    employeeId: string;
    entryId: string;
    entryDateTime: Date;
}

interface CreateJobTimeEntry {
    jobId: string;
    employeeId: string;
    entryType: number;
    entryDateTime: Date;
}

interface DeleteJobTimeEntry {
    jobId: string,
    employeeId: string;
    entryId: string;
    entryDateTime: Date;
}

const useGetTenants = () => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(['tenants'], async () => {
        const response = await apiClient.get<UserTenantsResponse>(`user/tenants`);

        return response.data?.records
            .filter((t) => t.role === userRolesEnum.Admin || t.role === userRolesEnum.SuperAdmin)
            .sort((a, b) => a.name!.localeCompare(b.name!));
    });

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching
    };
};

const useGetTimeEntriesReport = (args: { userId: string; startDate: string; endDate: string }) => {
    const { userId, startDate, endDate } = args;

    const { tenant } = useAuth();

    const { isLoading, isSuccess, data, isError, error, refetch } = useQuery(
        ['employee-time', userId, startDate, endDate],
        async () => {
            const response = await apiClient.get<UserTimeEntriesResponse>(`/user/employees/${userId}/time`, {
                params: {
                    startDate,
                    endDate,
                },
            });

            var normalEntries = response.data.timekeepingEntries.map((entry) => {
                return {
                    entryType: timekeepingEntryTypeEnum[entry.entryType],
                    timeStamp: toTimeZoneDateTime(entry.timeStamp, tenant.timeZoneId, true),
                    jobTitle: null,
                };
            });

            var jobEntries = response.data.jobTimekeepingEntries.map((entry) => {
                return {
                    entryType: jobTimekeepingEntryTypeEnum[entry.entryType],
                    timeStamp: toTimeZoneDateTime(entry.timeStamp, tenant.timeZoneId, true),
                    jobTitle: entry.title,
                };
            });

            var totalEntries = [...normalEntries, ...jobEntries];

            return {
                entries: totalEntries.sort((a, b) => sortByDate(a.timeStamp, b.timeStamp)),
            };
        },
        {
            enabled: !!userId && !!startDate && !!endDate,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetEmployeeLocationsByDate = (args: { employeeId: string; startDate: string; endDate: string }) => {
    const { employeeId, startDate, endDate } = args;

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['employee-locations', employeeId, startDate, endDate],
        async () => {
            const response = await apiClient.get<UserLocationsResponse>(`/user/employees/${employeeId}/locations`, {
                params: {
                    startDate,
                    endDate,
                },
            });

            return response.data?.records;
        },
        {
            enabled: !!employeeId && !!startDate && !!endDate,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const usePostCustomer = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, Customer>(
        async (customer: Customer) => {
            return await apiClient.post<Customer>('user/customer', customer);
        }
    );

    const create = (customer: Customer) => {
        return mutate(customer, {
            onSuccess: () => {
                queryClient.invalidateQueries('customers');
                queryClient.invalidateQueries('createJob');
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const usePostEmployee = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, Employee>(
        async (employee: Employee) => {
            return await apiClient.post<Employee>('user/employee', employee);
        }
    );

    const create = (employee: Employee) => {
        return mutate(employee, {
            onSuccess: () => {
                queryClient.invalidateQueries('employees');
                queryClient.invalidateQueries('createJob');
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const useUpdateCustomer = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, Customer>(
        async (customer: Customer) => {
            return await apiClient.put<Customer>('user/customer', customer);
        }
    );

    const update = (customer: Customer) => {
        return mutate(customer, {
            onSuccess: () => {
                queryClient.invalidateQueries('customers');
                queryClient.invalidateQueries('createJob');
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useUpdateEmployee = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, Employee>(
        async (employee: Employee) => {
            return await apiClient.put<Employee>('user/employee', employee);
        }
    );

    const update = (employee: Employee) => {
        return mutate(employee, {
            onSuccess: () => {
                queryClient.invalidateQueries('employees');
                queryClient.invalidateQueries('createJob');
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useGetUser = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch } = useQuery(
        ['user'],
        async () => {
            const response = await apiClient.get<UserResponse>(`user`);
            return response.data?.record;
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
    };
};

const useUpdateJobTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        UpdateJobTimeEntry
    >(async (jobTimeEntry: UpdateJobTimeEntry) => {
        return await apiClient.put<UpdateJobTimeEntry>('user/jobtimeentry', jobTimeEntry);
    });

    const update = (jobTimeEntry: UpdateJobTimeEntry) => {
        return mutate(jobTimeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useDeleteJobTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, mutate } = useMutation<
        unknown,
        ErrorResponse,
        DeleteJobTimeEntry
    >(async (jobTimeEntry: DeleteJobTimeEntry) => {
        return await apiClient.delete<DeleteJobTimeEntry>('user/jobtimeentry', { data: jobTimeEntry });
    });

    const update = (jobTimeEntry: DeleteJobTimeEntry) => {
        return mutate(jobTimeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update
    };
};

const useUpdateTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        UpdateTimeEntry
    >(async (timeEntry: UpdateTimeEntry) => {
        return await apiClient.put<UpdateTimeEntry>('user/timeentry', timeEntry);
    });

    const update = (timeEntry: UpdateTimeEntry) => {
        return mutate(timeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const useDeleteTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        DeleteTimeEntry
    >(async (timeEntry: DeleteTimeEntry) => {
        return await apiClient.delete<DeleteTimeEntry>('user/timeentry', { data: timeEntry });
    });

    const update = (timeEntry: DeleteTimeEntry) => {
        return mutate(timeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const usePostTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        CreateTimeEntry
    >(async (timeEntry: CreateTimeEntry) => {
        return await apiClient.post<CreateTimeEntry>('user/timeentry', timeEntry);
    });

    const create = (timeEntry: CreateTimeEntry) => {
        return mutate(timeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const usePostTimeIdle = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        CreateTimeIdle
        >(async (timeIdle: CreateTimeIdle) => {
            return await apiClient.post<CreateTimeIdle>('user/timeidle', timeIdle);
    });

    const create = (timeIdle: CreateTimeIdle) => {
        return mutate(timeIdle, {
            onSuccess: () => {
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const usePostTimePeriod = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        CreateTimePeriod
        >(async (timePeriod: CreateTimePeriod) => {
            return await apiClient.post<CreateTimePeriod>('user/timeperiod', timePeriod);
    });

    const create = (timePeriod: CreateTimePeriod) => {
        return mutate(timePeriod, {
            onSuccess: () => {
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

const usePostJobTimeEntry = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<
        unknown,
        ErrorResponse,
        CreateJobTimeEntry
    >(async (jobTimeEntry: CreateJobTimeEntry) => {
        return await apiClient.post<CreateJobTimeEntry>('user/jobtimeentry', jobTimeEntry);
    });

    const create = (jobTimeEntry: CreateJobTimeEntry) => {
        return mutate(jobTimeEntry, {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                queryClient.invalidateQueries(['job']);
                queryClient.invalidateQueries(['tenant-time']);
            },
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        create,
        reset,
    };
};

export const useUsers = () => {
    return {
        useGetTenants,
        useGetEmployeeLocationsByDate,
        usePostCustomer,
        usePostEmployee,
        useUpdateEmployee,
        useUpdateCustomer,
        useGetTimeEntriesReport,
        useGetUser,
        useUpdateJobTimeEntry,
        useUpdateTimeEntry,
        useDeleteTimeEntry,
        useDeleteJobTimeEntry,
        usePostTimeEntry,
        usePostTimeIdle,
        usePostTimePeriod,
        usePostJobTimeEntry,
    };
};
