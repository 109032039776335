import React from 'react';
import { useEffect } from 'react';

const useOutsideClick = (callback: any) => {
    const ref = React.useRef();

    useEffect(() => {
        const handleClick = (event: any) => {
            let reactRef: any = ref;
            if (reactRef) {
                if (reactRef.current && !reactRef.current.contains(event.target)) {
                    callback();
                }
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, callback]);

    return ref;
};

export default useOutsideClick;
