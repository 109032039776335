import React, { useState } from 'react';
import FullCalendar, { DatesSetArg, EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { useTenants } from '../hooks/useTenants';
import Loading from './Loading';

const CalendarViewJobs = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const { useGetJobsDates } = useTenants();
    const { data, isFetching } = useGetJobsDates({
        startDate: startDate || '',
        endDate: endDate || '',
    });

    const handleDatesSet = (arg: DatesSetArg) => {
        setStartDate(arg.start.toISOString());
        setEndDate(arg.end.toISOString());
    };

    const onClick = (args: EventClickArg) => {
        //TODO Future Implementation, this is a way to obtain the selected calendar item
    };

    return (
        <>
            <div className="card p-5">
                <Loading isLoading={isFetching} boxStyle />
                <FullCalendar
                    timeZone={'UTC'}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    selectable={true}
                    expandRows={false}
                    eventClick={onClick}
                    events={data}
                    datesSet={handleDatesSet}
                />
            </div>
        </>
    );
};

export default CalendarViewJobs;
