import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const appInsightsConnectionString = process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING;

export var reactPlugin = new ReactPlugin();
export var appInsights = new ApplicationInsights({
    config: {
        connectionString: appInsightsConnectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    },
});
appInsights.loadAppInsights();
