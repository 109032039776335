import React from 'react';
import classNames from 'classnames';
import { boolean } from 'yup/lib/locale';

const Loading = (props: { text?: string; boxStyle?: boolean; overlapped?: boolean; isLoading: boolean; }) => {
    const { text, boxStyle, overlapped, isLoading } = props;

    const containerStyle = {
        'container flex items-center justify-center': true,
        'mb-2': boxStyle,
        'absolute left-1/2 transform -translate-x-1/2': overlapped,
        'py-10': !boxStyle,
    };

    const textStyle = {
        'text-center uppercase font-bold': true,
        'text-md border border-warning rounded-md bg bg-orange-50 py-1 px-10': boxStyle,
        'text-2xl': !boxStyle,
    };

    if (isLoading) {
        return (
            <>
                <div className={classNames(containerStyle)}>
                    <div className={classNames(textStyle)}>
                        {text || "Loading"}
                        <span className="animate-spin la la-circle-notch ml-1"></span>
                    </div>
                </div>
            </>
        );
    } else {
        return (<></>);
    }
};

export default Loading;
