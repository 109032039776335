import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
    FormInputAutocompleteControl,
    FormInputControl,
    FormInputDateControl,
    FormSelectControl,
} from '../components/FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import { useJobs } from '../hooks/useJobs';
import { useTenants } from '../hooks/useTenants';
import { useUsers } from '../hooks/useUsers';
import { entryTypeEnum, entryTypeObject, jobTimekeepingEntryTypeEnum } from '../utilities/staticData';
import { formatDateTime, toTimeZoneDateTime } from '../utilities/formatter';
import { useAuth } from '../hooks/useAuth';
import Loading from './Loading';

interface FormInput {
    employee?: string;
    date?: string;
    time?: string;
    entryType?: string;
    job?: string;
    idleStartTime?: string;
    idleEndTime?: string;
    clockIn?: string;
    clockOut?: string;
}

const schema = yup
    .object({
        employee: yup.string().required('Employee is required.'),
        date: yup.string().required('Date is required.'),
        entryType: yup.string().required('Entry type is required.'),
        time: yup.string().when('entryType', {
            is: (entryType: any) =>
                entryType === entryTypeEnum['Clock In'].toString() ||
                entryType === entryTypeEnum['Clock Out'].toString() ||
                entryType === entryTypeEnum['Time In'].toString() ||
                entryType === entryTypeEnum['Time Out'].toString(),
            then: yup.string().required('Time is required.'),
        }),
        job: yup.string().when('entryType', {
            is: (entryType: any) =>
                entryType === entryTypeEnum['Time In'].toString() ||
                entryType === entryTypeEnum['Time Out'].toString(),
            then: yup.string().required('Job is required.'),
        }),
        idleStartTime: yup.string().when('entryType', {
            is: entryTypeEnum['Clock Idle'].toString(),
            then: yup.string().required('Idle Start Time is required.'),
        }),
        idleEndTime: yup.string().when('entryType', {
            is: entryTypeEnum['Clock Idle'].toString(),
            then: yup.string()
                .required('Idle End Time is required.')
                .test(
                    'idle-end-min',
                    'Idle End Time must be after Idle Start Time.',
                    (value, context) => {
                        return (context.parent.idleStartTime || '') <= (value || '');
                    }
                ),
        }),
        clockIn: yup.string().when('entryType', {
            is: entryTypeEnum['Clock Period'].toString(),
            then: yup.string().required('Clock In is required.'),
        }),
        clockOut: yup.string().when('entryType', {
            is: entryTypeEnum['Clock Period'].toString(),
            then: yup.string()
                .required('Clock Out is required.')
                .test(
                    'clock-out-min',
                    'Clock Out must be after Clock In.',
                    (value, context) => {
                        return (context.parent.clockIn || '') <= (value || '');
                    }
                ),
        }),
    })
    .required();

const TimeEntryModal = () => {
    const { tenant } = useAuth();

    const [shouldGetJobs, setShouldGetJobs] = useState(false);
    const [jobs, setJobs] = useState<{ value: any; text: any }[]>();
    const [showModal, setShowModal] = useState(false);
    const [entryTypeSelected, setEntryTypeSelected] = useState('');
    const [employeeSelected, setEmployeeSelected] = useState('');
    const [dateSelected, setDateSelected] = useState('');
    const [jobSelected, setJobSelected] = useState('');

    const { usePostTimeEntry, usePostTimeIdle, usePostTimePeriod, usePostJobTimeEntry } = useUsers();

    const {
        isLoading: isLoadingPostTimeEntry,
        isSuccess: isSuccessPostTimeEntry,
        create: createPostTimeEntry,
        reset: resetServerPostTimeEntry,
        error: { errors: serverErrorsPostTimeEntry },
    } = usePostTimeEntry();

    const {
        isLoading: isLoadingPostTimeIdle,
        isSuccess: isSuccessPostTimeIdle,
        create: createPostTimeIdle,
        reset: resetServerPostTimeIdle,
        error: { errors: serverErrorsPostTimeIdle },
    } = usePostTimeIdle();

    const {
        isLoading: isLoadingPostTimePeriod,
        isSuccess: isSuccessPostTimePeriod,
        create: createPostTimePeriod,
        reset: resetServerPostTimePeriod,
        error: { errors: serverErrorsPostTimePeriod },
    } = usePostTimePeriod();

    const {
        isLoading: isLoadingPostJobTimeEntry,
        isSuccess: isSuccessPostJobTimeEntry,
        create: createPostJobTimeEntry,
        reset: resetServerPostJobTimeEntry,
        error: { errors: serverErrorsPostJobTimeEntry },
    } = usePostJobTimeEntry();

    const {
        register,
        setValue,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const { useGetJobsDates } = useTenants();
    const {
        data: dataGetJobs,
        isFetching: isFetchingGetJobs,
        refetch: refetchGetJobs,
    } = useGetJobsDates(
        {
            startDate: toTimeZoneDateTime(new Date(`${dateSelected},${new Date().toTimeString()}`), tenant.timeZoneId),
            endDate: toTimeZoneDateTime(new Date(`${dateSelected},${new Date().toTimeString()}`), tenant.timeZoneId),
            employeeId: employeeSelected,
        },
        shouldGetJobs,
    );

    const { useGetCreateJob } = useJobs();
    const { data: getCreateJobResults, isFetching: isFetchingGetCreateJob } = useGetCreateJob(showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const entryType = entryTypeObject
        .filter((e) => (tenant.hasJobsFeature ? true : !e.jobsFeature))
        .sort((a, b) => a.order - b.order)
        .map((option) => {
            return {
                value: option.value,
                text: option.name,
            };
        });

    const resetForm = useCallback(() => {
        resetServerPostTimeEntry();
        resetServerPostTimeIdle();
        resetServerPostTimePeriod();
        resetServerPostJobTimeEntry();
        resetClient();
        toggler();
        setEntryTypeSelected('');
        setEmployeeSelected('');
        setDateSelected('');
        setJobSelected('');
    }, [resetServerPostTimeEntry, resetServerPostTimeIdle, resetServerPostTimePeriod, resetServerPostJobTimeEntry, resetClient, toggler]);

    const onSave = (data: FormInput) => {


        if (data.entryType === entryTypeEnum['Clock In'].toString() ||
            data.entryType === entryTypeEnum['Clock Out'].toString()) {
            const dateTime = formatDateTime(data.date, data.time);
            const utcDate = zonedTimeToUtc(dateTime, tenant.timeZoneId);

            createPostTimeEntry({
                employeeId: employeeSelected,
                entryType: Number(data.entryType),
                entryDateTime: utcDate,
            });

            return;
        }

        if (data.entryType === entryTypeEnum['Clock Idle'].toString()) {
            const startDateTime = formatDateTime(data.date, data.idleStartTime);
            const startUtcDate = zonedTimeToUtc(startDateTime, tenant.timeZoneId);
            const endDateTime = formatDateTime(data.date, data.idleEndTime);
            const endUtcDate = zonedTimeToUtc(endDateTime, tenant.timeZoneId);

            createPostTimeIdle({
                employeeId: employeeSelected,
                startDateTime: startUtcDate,
                endDateTime: endUtcDate,
            });
        }

        if (data.entryType === entryTypeEnum['Clock Period'].toString()) {
            const clockInDateTime = formatDateTime(data.date, data.clockIn);
            const clockInUtcDate = zonedTimeToUtc(clockInDateTime, tenant.timeZoneId);
            const clockOutDateTime = formatDateTime(data.date, data.clockOut);
            const clockOutUtcDate = zonedTimeToUtc(clockOutDateTime, tenant.timeZoneId);

            createPostTimePeriod({
                employeeId: employeeSelected,
                clockInDateTime: clockInUtcDate,
                clockOutDateTime: clockOutUtcDate,
            });
        }

        if (data.entryType === entryTypeEnum['Time In'].toString() ||
            data.entryType === entryTypeEnum['Time Out'].toString()) {
            const dateTime = formatDateTime(data.date, data.time);
            const utcDate = zonedTimeToUtc(dateTime, tenant.timeZoneId);
            const jobEntryType =
                data.entryType === entryTypeEnum['Time In'].toString()
                    ? jobTimekeepingEntryTypeEnum['Time In']
                    : jobTimekeepingEntryTypeEnum['Time Out'];
            createPostJobTimeEntry({
                jobId: jobSelected,
                employeeId: employeeSelected,
                entryType: jobEntryType,
                entryDateTime: utcDate,
            });
        }
    };

    const onEntryTypeSelected = (option?: string) => {
        setEntryTypeSelected(option!);
    };

    const onEmployeeSelected = (option?: string) => {
        setEmployeeSelected(option!);
    };

    const onDateSelected = (option?: string) => {
        setValue('date', option!);
        setDateSelected(option!);
    };

    const onJobSelected = (option?: string) => {
        setJobSelected(option!);
    };

    useEffect(() => {
        if (
            (entryTypeSelected === entryTypeEnum['Time In'].toString() ||
                entryTypeSelected === entryTypeEnum['Time Out'].toString()) &&
            employeeSelected &&
            dateSelected
        ) {
            setShouldGetJobs(true);
        } else {
            setJobs([]);
            setShouldGetJobs(false);
        }
    }, [entryTypeSelected, employeeSelected, dateSelected]);

    useEffect(() => {
        if (shouldGetJobs) {
            refetchGetJobs();
            setShouldGetJobs(false);
        } else {
            setJobs([]);
        }
    }, [shouldGetJobs, refetchGetJobs]);

    useEffect(() => {
        if (!isFetchingGetJobs) {
            setJobs(
                dataGetJobs?.map((option) => {
                    return {
                        value: option.id,
                        text: option.title,
                    };
                })
            );
        }
    }, [isFetchingGetJobs, dataGetJobs]);

    useEffect(() => {
        if (isSuccessPostTimeEntry || isSuccessPostTimeIdle || isSuccessPostTimePeriod || isSuccessPostJobTimeEntry) {
            resetForm();
            toast.success(`${entryTypeEnum[Number(entryTypeSelected)]} Created Successfully`);
        }
    }, [isSuccessPostTimeEntry, isSuccessPostTimeIdle, isSuccessPostTimePeriod, isSuccessPostJobTimeEntry, resetForm, entryTypeSelected]);

    return (
        <>
            <button className="btn btn_primary uppercase" onClick={toggler}>
                Add New
            </button>
            <Modal isOpen={showModal} toggler={resetForm} size="md" isStatic={true}>
                <ModalHeader
                    toggler={resetForm}
                    closeButtonDisabled={isLoadingPostTimeEntry || isLoadingPostTimeIdle || isLoadingPostTimePeriod || isLoadingPostJobTimeEntry}
                >
                    Add Time Entry
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={isFetchingGetCreateJob} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormSelectControl
                                label="Entry Type"
                                hideEmpty={true}
                                register={register('entryType')}
                                options={entryType}
                                clientErrors={clientErrors?.entryType}
                                serverErrors={
                                    serverErrorsPostTimeEntry?.entryType ||
                                    serverErrorsPostTimeEntry?.EntryType ||
                                    serverErrorsPostJobTimeEntry?.entryType
                                }
                                onChange={onEntryTypeSelected}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputAutocompleteControl
                                label="Employee"
                                register={register('employee')}
                                options={getCreateJobResults?.employees.filter((employee) => employee.active)}
                                onChange={onEmployeeSelected}
                                clientErrors={clientErrors?.employee}
                                serverErrors={
                                    serverErrorsPostTimeEntry?.employeeId || serverErrorsPostJobTimeEntry?.employeeId
                                }
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputDateControl
                                label="Date"
                                onChange={onDateSelected}
                                includeDeselect={true}
                                clientErrors={clientErrors?.date}
                                serverErrors={serverErrorsPostTimeEntry?.date || serverErrorsPostJobTimeEntry?.date}
                            />
                        </div>
                        <div className="mb-5">
                            {(entryTypeSelected === entryTypeEnum['Clock In'].toString() ||
                                entryTypeSelected === entryTypeEnum['Clock Out'].toString() ||
                                entryTypeSelected === entryTypeEnum['Time In'].toString() ||
                                entryTypeSelected === entryTypeEnum['Time Out'].toString()) && (
                                    <>
                                        <FormInputControl
                                            type="time"
                                            step="1"
                                            label="Time"
                                            register={register('time')}
                                            clientErrors={clientErrors?.time}
                                            serverErrors={
                                                serverErrorsPostTimeEntry?.entryDateTime ||
                                                serverErrorsPostTimeEntry?.EntryDateTime ||
                                                serverErrorsPostJobTimeEntry?.entryDateTime
                                            }
                                        />
                                    </>
                                )}
                        </div>
                    </div>
                    {(entryTypeSelected === entryTypeEnum['Time In'].toString() ||
                        entryTypeSelected === entryTypeEnum['Time Out'].toString()) && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                    <Loading isLoading={isFetchingGetJobs} boxStyle />
                                    <FormInputAutocompleteControl
                                        label="Job"
                                        register={register('job')}
                                        onChange={onJobSelected}
                                        options={jobs}
                                        clientErrors={clientErrors?.job}
                                        serverErrors={
                                            serverErrorsPostTimeEntry?.jobId || serverErrorsPostJobTimeEntry?.jobId
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    {entryTypeSelected === entryTypeEnum['Clock Idle'].toString() && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <FormInputControl
                                    type="time"
                                    step="1"
                                    label="Idle Start Time"
                                    register={register('idleStartTime')}
                                    clientErrors={clientErrors?.idleStartTime}
                                    serverErrors={serverErrorsPostTimeIdle?.startDateTime}
                                />
                            </div>
                            <div className="mb-5">
                                <FormInputControl
                                    type="time"
                                    step="1"
                                    label="Idle End Time"
                                    register={register('idleEndTime')}
                                    clientErrors={clientErrors?.idleEndTime}
                                    serverErrors={serverErrorsPostTimeIdle?.endDateTime}
                                />
                            </div>
                        </div>
                    )}
                    {entryTypeSelected === entryTypeEnum['Clock Period'].toString() && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <FormInputControl
                                    type="time"
                                    step="1"
                                    label="Clock In"
                                    register={register('clockIn')}
                                    clientErrors={clientErrors?.clockIn}
                                    serverErrors={serverErrorsPostTimePeriod?.clockInDateTime}
                                />
                            </div>
                            <div className="mb-5">
                                <FormInputControl
                                    type="time"
                                    step="1"
                                    label="Clock Out"
                                    register={register('clockOut')}
                                    clientErrors={clientErrors?.clockOut}
                                    serverErrors={serverErrorsPostTimePeriod?.clockOutDateTime}
                                />
                            </div>
                        </div>
                    )}
                    {Array.isArray(serverErrorsPostTimeEntry?.GeneralErrors)
                        ? (serverErrorsPostTimeEntry?.GeneralErrors as string[])?.map((error, index) => (
                            <div key={index} className="grid grid-cols-1">
                                <small className="block invalid-feedback">
                                    {error}
                                </small>
                            </div>
                        ))
                        : null}
                    {Array.isArray(serverErrorsPostTimeIdle?.GeneralErrors)
                        ? (serverErrorsPostTimeIdle?.GeneralErrors as string[])?.map((error, index) => (
                            <div key={index} className="grid grid-cols-1">
                                <small className="block invalid-feedback">
                                    {error}
                                </small>
                            </div>
                        ))
                        : null}
                    {Array.isArray(serverErrorsPostTimePeriod?.GeneralErrors)
                        ? (serverErrorsPostTimePeriod?.GeneralErrors as string[])?.map((error, index) => (
                            <div key={index} className="grid grid-cols-1">
                                <small className="block invalid-feedback">
                                    {error}
                                </small>
                            </div>
                        ))
                        : null}
                    {Array.isArray(serverErrorsPostJobTimeEntry?.GeneralErrors)
                        ? (serverErrorsPostJobTimeEntry?.GeneralErrors as string[])?.map((error, index) => (
                            <div key={index} className="grid grid-cols-1">
                                <small className="block invalid-feedback">
                                    {error}
                                </small>
                            </div>
                        ))
                        : null}
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoadingPostTimeEntry || isLoadingPostTimeIdle || isLoadingPostTimePeriod || isLoadingPostJobTimeEntry}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSave)}
                            disabled={isLoadingPostTimeEntry || isLoadingPostTimeIdle || isLoadingPostTimePeriod || isLoadingPostJobTimeEntry}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default TimeEntryModal;
