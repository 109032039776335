import React from 'react';
import { useAuth } from '../hooks/useAuth';

const NotAllowedAccess = () => {
    const { logout } = useAuth();

    return (
        <>
            <div className="container flex items-center justify-center py-10">
                <div className="w-full md:w-1/2 xl:w-1/3">
                    <div className="text-center">
                        <h1>
                            <span className="las la-times-circle ml-1"></span>
                        </h1>
                        <h2 className="uppercase">Sorry, you are not allowed to access this page.</h2>
                        <button className="btn btn_primary uppercase mt-4" onClick={() => logout()}>
                            Go Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotAllowedAccess;
