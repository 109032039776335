import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useNotifications } from './useNotifications';
import { useSignalR } from './useSignalR';

interface UnreadSms {
    userId?: string;
    dateSent: Date;
}

interface SmsNotificationsContextInterface {
    hasUnreadSms: boolean;
    unreadSms?: UnreadSms[];
    removeUnread: (userId: string) => void;
}

const useSmsNotificationsProvider = (): SmsNotificationsContextInterface => {
    const [unreadSms, setUnreadSms] = useState<UnreadSms[]>([]);
    const [enabledGetUsers, setEnabledGetUsers] = useState(false);

    const { isConnected, subscribe, unsubscribe } = useSignalR();
    const {
        isConnected: isConnectedMarkRead,
        subscribe: subscribeMarkRead,
        unsubscribe: unsubscribeMarkRead,
    } = useSignalR();

    const { isAuthenticated, hasTenant, hasToken } = useAuth();

    const { useMarkSmsRead, useGetUsersLastUnreadSms } = useNotifications();
    const { update: updateMarkSmsRead } = useMarkSmsRead();
    const {
        data: dataUsersUnreadsms,
        isSuccess: isSuccessUsersUnreadsms,
        isFetching: isFetchingUsersUnreadsms,
    } = useGetUsersLastUnreadSms(enabledGetUsers);

    const removeUnread = (userId: string) => {
        const hasUnreadSms = unreadSms.some((x) => x.userId === userId);

        if (!hasUnreadSms) return;

        const _unreadSms = [...unreadSms.filter((x) => x.userId !== userId)];
        setUnreadSms(_unreadSms);

        updateMarkSmsRead({
            userId: userId,
        });
    };

    const onSmsReceived = useCallback(
        (userId: string, dateSent: Date) => {
            if (!userId) return;

            const newValues = [...unreadSms, { userId, dateSent }];
            setUnreadSms(newValues);
        },
        [unreadSms]
    );

    const onSmsMarkRead = useCallback(
        (userId: string) => {
            if (!userId) return;

            const newValues = [...unreadSms.filter((x) => x.userId !== userId)];
            setUnreadSms(newValues);
        },
        [unreadSms]
    );

    useEffect(() => {
        if (isConnected) {
            subscribe('smsReceived', onSmsReceived);
        }

        return () => {
            unsubscribe('smsReceived', onSmsReceived);
        };
    }, [isConnected, subscribe, unsubscribe, onSmsReceived]);

    useEffect(() => {
        if (isConnectedMarkRead) {
            subscribeMarkRead('smsMarkRead', onSmsMarkRead);
        }

        return () => {
            unsubscribeMarkRead('smsMarkRead', onSmsMarkRead);
        };
    }, [isConnectedMarkRead, subscribeMarkRead, unsubscribeMarkRead, onSmsMarkRead]);

    useEffect(() => {
        if (isAuthenticated && hasTenant && hasToken) {
            setEnabledGetUsers(true);
        } else {
            setEnabledGetUsers(false);
        }
    }, [isAuthenticated, hasTenant, hasToken, setEnabledGetUsers]);

    useEffect(() => {
        if (enabledGetUsers) {
            if (isSuccessUsersUnreadsms && !isFetchingUsersUnreadsms) {
                setUnreadSms(dataUsersUnreadsms || []);
            }
        }
    }, [enabledGetUsers, isSuccessUsersUnreadsms, isFetchingUsersUnreadsms, dataUsersUnreadsms]);

    return {
        hasUnreadSms: !!unreadSms?.length,
        unreadSms,
        removeUnread,
    };
};

const SmsNotificationsContext = createContext<SmsNotificationsContextInterface>({} as SmsNotificationsContextInterface);

export const SmsNotificationsProvider = ({ children }: { children: React.ReactNode }) => {
    const smsNotifications = useSmsNotificationsProvider();

    return <SmsNotificationsContext.Provider value={smsNotifications}>{children}</SmsNotificationsContext.Provider>;
};

export const useSmsNotifications = () => {
    return useContext(SmsNotificationsContext);
};
