import React from 'react';

const FeatureNotAllowed = (props: { flag?: boolean }) => {
    const { flag } = props;

    if (flag === undefined) {
        return <></>;
    }

    return (
        <>
            <div className="container flex items-center justify-center py-10">
                <div className="w-full md:w-1/2 xl:w-1/3">
                    <div className="text-center">
                        <h1>
                            <span className="las la-exclamation-triangle ml-1"></span>
                        </h1>
                        <h2 className="uppercase">Feature not allowed</h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeatureNotAllowed;
