import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useController } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { FuzzySearchResult } from '../hooks/useAddressSearch';
import { FormInputControl, FormInputCheckBoxControl, FormSelectControl } from './FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import { useUsers } from '../hooks/useUsers';
import { useTenants } from '../hooks/useTenants';
import {
    addFileNameToExistingAzureURI,
    formatAddressLine,
    generateAzureURIWithUuid,
    getFileNameFromAzureURI,
    isNumber,
} from '../utilities/formatter';
import { UserRoles, usStates } from '../utilities/staticData';
import InputAddressFuzySearch from './InputAddressFuzzySearch';
import { uploadFileToBlobStorage } from '../utilities/azure-storage.blob';
import { useAuth } from '../hooks/useAuth';
import TippyToolTip from './TippyToolTip';
import Loading from './Loading';

interface FormInput {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    showAddress: boolean;
    addressId?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    latitude?: number;
    longitude?: number;
    profilePictureUri?: string;
    role?: number;
    active?: boolean;
    hourlyRate?: string;
    geofenceDisabled?: boolean;
    unsubscribeSms?: boolean;
}

interface EmployeeModalProps {
    modalTitle: string;
    showModal: boolean;
    toggler: () => void;
    action: (obj: FormInput) => void;
    isSuccessRequest: boolean;
    isLoadingRequest: boolean;
    serverErrors: any;
    data?: FormInput;
    isLoaded?: boolean;
    resetServer: () => void;
}

const schema: yup.SchemaOf<FormInput> = yup.object({
    id: yup.string().optional(),
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    email: yup.string().required('Email is required').email('Email format is invalid.'),
    mobile: yup
        .string()
        .required('Mobile phone is required')
        .matches(/^\d{10}$/, 'Mobile phone format is invalid.'),
    showAddress: yup.boolean().required(),
    addressId: yup.string().optional(),
    addressLine1: yup.string().when('showAddress', {
        is: true,
        then: yup.string().required('Address Line1 is required.'),
    }),
    addressLine2: yup.string().optional(),
    city: yup.string().when('showAddress', {
        is: true,
        then: yup.string().required('City is required.'),
    }),
    state: yup.string().when('showAddress', {
        is: true,
        then: yup.string().required('State is required.'),
    }),
    zipCode: yup.string().when('showAddress', {
        is: true,
        then: yup
            .string()
            .required('Zip Code is required.')
            .matches(/^\d{5}(-\d{4})?$/, 'Zip code format is invalid.'),
    }),
    latitude: yup.number().optional(),
    longitude: yup.number().optional(),
    profilePictureUri: yup.string().optional(),
    role: yup
        .number()
        .required('Role is required.')
        .nullable(true)
        .transform((val) => (val === Number(val) ? val : null)),
    active: yup.boolean().optional(),
    hourlyRate: yup.string().test('is-decimal', 'Hourly Rate must be a decimal number', (value) => {
        return !isNaN(Number(value));
    }),
    geofenceDisabled: yup.boolean().optional(),
    unsubscribeSms: yup.boolean().optional(),
});

const EmployeeModal = (props: EmployeeModalProps) => {
    const { user } = useAuth();
    const {
        modalTitle,
        showModal,
        toggler,
        action,
        isSuccessRequest,
        isLoadingRequest,
        serverErrors,
        data,
        isLoaded,
        resetServer,
    } = props;

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        control,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const { field: addressLine1Field } = useController({
        name: 'addressLine1',
        control,
        defaultValue: '',
    });
    const { field: addressLine2Field } = useController({
        name: 'addressLine2',
        control,
        defaultValue: '',
    });
    const { field: cityField } = useController({
        name: 'city',
        control,
        defaultValue: '',
    });
    const { field: stateField } = useController({
        name: 'state',
        control,
        defaultValue: '',
    });
    const { field: zipCodeField } = useController({
        name: 'zipCode',
        control,
        defaultValue: '',
    });
    const { field: latitude } = useController({
        name: 'latitude',
        control,
        defaultValue: 0,
    });
    const { field: longitude } = useController({
        name: 'longitude',
        control,
        defaultValue: 0,
    });

    const [showAddress, setShowAddress] = useState(false);
    const [getSas, setGetSas] = useState(false);
    const [fileSelected, setFileSelected] = useState<File>();
    const [uuid, setUuid] = useState<string>('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isReadOnlyEditingUserIsLoggedUser, setIsReadOnlyEditingUserIsLoggedUser] = useState(false);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const { useGetSasToken } = useTenants();
    const { data: dataToken } = useGetSasToken(getSas);

    const states = usStates.map((state) => {
        return {
            value: state.abbreviation,
            text: state.name,
        };
    });

    const userRolesList = UserRoles.map((option) => {
        return {
            value: option.value,
            text: option.name,
        };
    });

    const resetForm = useCallback(() => {
        toggler();
        resetClient();
        resetServer();
        setShowAddress(false);
        setGetSas(false);
        setFileSelected(undefined);
        setIsUploadingFile(false);
    }, [resetClient, toggler, resetServer]);

    const onSubmit = async (data: FormInput) => {
        if (!!fileSelected) {
            if (!!data.profilePictureUri) {
                data.profilePictureUri = addFileNameToExistingAzureURI(
                    dataToken?.containerUri!,
                    data.profilePictureUri,
                    fileSelected.name
                );
            } else {
                data.profilePictureUri = generateAzureURIWithUuid(dataToken?.containerUri!, uuid, fileSelected?.name);
            }
            if (!(await onFileUpload(data.profilePictureUri + dataToken?.sasToken!))) {
                return;
            }
        }
        action(data);
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            setGetSas(true);
            setFileSelected(event?.target?.files[0]);
            setUuid(uuidv4());
        }
    };

    const onFileUpload = async (dataUri: string) => {
        setIsUploadingFile(true);
        const response = await uploadFileToBlobStorage(dataUri, fileSelected!);
        setIsUploadingFile(false);
        return response;
    };

    const onCheckBoxChange = (checked: boolean) => {
        setShowAddress(checked);
    };

    const onAddressSelected = (result: FuzzySearchResult) => {
        addressLine1Field.onChange(formatAddressLine(result.address.streetNumber, result.address.streetName));
        addressLine2Field.onChange('');
        cityField.onChange(result.address.municipality);
        stateField.onChange(result.address.countrySubdivision);
        zipCodeField.onChange(result.address.postalCode);
        latitude.onChange(result.position.lat);
        longitude.onChange(result.position.lon);
    };

    useEffect(() => {
        if (isSuccessRequest) {
            resetForm();
        }
    }, [isSuccessRequest, resetForm]);

    useEffect(() => {
        if (data) {
            setIsReadOnly(true);
            setIsReadOnlyEditingUserIsLoggedUser(user.id === data?.id);
            resetClient(data);
            setShowAddress(!!data?.showAddress);
        }
    }, [resetClient, data, isLoaded, user]);

    return (
        <>
            <Modal
                isOpen={showModal}
                toggler={resetForm}
                size="lg"
                isStatic={true}
                isDisabled={isUploadingFile || isLoadingRequest}
            >
                <ModalHeader toggler={resetForm} closeButtonDisabled={isUploadingFile || isLoadingRequest}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={!isLoaded} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5"></div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormSelectControl
                                label="Role"
                                hideEmpty={true}
                                register={register('role')}
                                options={userRolesList}
                                clientErrors={clientErrors?.role}
                                serverErrors={ serverErrors?.role || serverErrors?.Role }
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="First Name"
                                register={register('firstName')}
                                clientErrors={clientErrors?.firstName}
                                serverErrors={serverErrors?.firstName}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Last Name"
                                register={register('lastName')}
                                clientErrors={clientErrors?.lastName}
                                serverErrors={serverErrors?.lastName}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Email"
                                register={register('email')}
                                clientErrors={clientErrors?.email}
                                serverErrors={serverErrors?.email}
                                disabled={isReadOnly}
                            />
                            {Array.isArray(serverErrors?.GeneralErrors)
                                ? (serverErrors?.GeneralErrors as string[])?.map((error, index) => (
                                      <small key={index} className="block mt-1 invalid-feedback">
                                          {error}
                                      </small>
                                  ))
                                : null}
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Mobile Phone"
                                register={register('mobile')}
                                clientErrors={clientErrors?.mobile}
                                serverErrors={serverErrors?.mobile}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Hourly Rate"
                                register={register('hourlyRate')}
                                clientErrors={clientErrors?.hourlyRate}
                                serverErrors={serverErrors?.hourlyRate}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputCheckBoxControl
                                label="Address"
                                register={register('showAddress')}
                                clientErrors={clientErrors?.showAddress}
                                serverErrors={serverErrors?.showAddress}
                                onChange={onCheckBoxChange}
                                tippyChild={<TippyToolTip tooltipText="Address can be Optional" />}
                            />
                        </div>
                    </div>
                    {showAddress && (
                        <>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                                <div className="mb-5">
                                    <InputAddressFuzySearch
                                        label="Address Line 1"
                                        register={addressLine1Field}
                                        onChange={onAddressSelected}
                                        field={addressLine1Field}
                                        clientErrors={clientErrors?.addressLine1}
                                        serverErrors={serverErrors?.addressLine1}
                                    />
                                </div>
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="Address Line2"
                                        register={addressLine2Field}
                                        clientErrors={clientErrors?.addressLine2}
                                        serverErrors={serverErrors?.addressLine2}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="City"
                                        register={cityField}
                                        clientErrors={clientErrors?.city}
                                        serverErrors={serverErrors?.city}
                                    />
                                </div>
                                <div className="mb-5">
                                    <FormSelectControl
                                        label="State"
                                        register={stateField}
                                        options={states}
                                        clientErrors={clientErrors?.state}
                                        serverErrors={serverErrors?.state}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="Zip Code"
                                        register={zipCodeField}
                                        clientErrors={clientErrors?.zipCode}
                                        serverErrors={serverErrors?.zipCode}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {data?.profilePictureUri && (
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <label className="label block mb-2">Uploaded Image</label>
                                <table className="table table-auto table_hoverable w-full overscroll-y-contain">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a href={data?.profilePictureUri} target="_blank" rel="noreferrer">
                                                    {getFileNameFromAzureURI(data?.profilePictureUri!)}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <label className="label block mb-2">Employee Image</label>
                            <div className="input-group">
                                <label className="input-group-item input-group font-normal cursor-pointer">
                                    <div className="file-name input-addon input-addon-prepend input-group-item w-full overflow-x-hidden">
                                        {fileSelected! ? fileSelected!.name : 'No File Chosen'}
                                    </div>
                                    <input
                                        id="customFile"
                                        type="file"
                                        key={fileSelected?.name}
                                        className="hidden"
                                        accept="image/*"
                                        onChange={onFileChange}
                                    />
                                    <div className="input-group-item btn btn_primary uppercase">Choose File</div>
                                </label>
                                {fileSelected && (
                                    <button
                                        type="button"
                                        className="input-group-item btn btn-icon btn-icon_large btn_secondary uppercase"
                                        onClick={() => setFileSelected(undefined)}
                                    >
                                        <span className="las la-times"></span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {isLoaded && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <FormInputCheckBoxControl
                                    label="Active"
                                    register={register('active')}
                                    clientErrors={clientErrors?.active}
                                    serverErrors={serverErrors?.active || serverErrors?.Active}
                                    disabled={isReadOnlyEditingUserIsLoggedUser}
                                />
                            </div>
                        </div>
                    )}
                    {isLoaded && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <FormInputCheckBoxControl
                                    label="Disable Geofence"
                                    register={register('geofenceDisabled')}
                                    clientErrors={clientErrors?.geofenceDisabled}
                                    serverErrors={serverErrors?.geofenceDisabled}
                                    disabled={isReadOnlyEditingUserIsLoggedUser}
                                />
                            </div>
                        </div> 
                    )}
                    {isLoaded && data?.role === 1 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                            <div className="mb-5">
                                <FormInputCheckBoxControl
                                    label="Unsubscribe from sms"
                                    register={register('unsubscribeSms')}
                                    clientErrors={clientErrors?.unsubscribeSms}
                                    serverErrors={serverErrors?.unsubscribeSms}
                                    tippyChild={<TippyToolTip tooltipText="Unsubscribing will stop sending the &quot;Job is running over&quot; message." />}
                                />
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isUploadingFile || isLoadingRequest}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isUploadingFile || isLoadingRequest}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const EditEmployeeModal = (props: { employeeId?: string }) => {
    const { employeeId } = props;
    const [showModal, setShowModal] = useState(false);

    const { useUpdateEmployee } = useUsers();
    const {
        isLoading,
        isSuccess,
        update,
        error: { errors: serverErrors },
        reset: resetServer,
    } = useUpdateEmployee();

    const { useGetEmployee } = useTenants();
    const { data: employee, isFetching } = useGetEmployee(employeeId || '', showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const onSubmitUpdate = (data: FormInput) => {
        let hourlyRateNumber: number | undefined = undefined;
        if (isNumber(data.hourlyRate)) {
            hourlyRateNumber = Number(data.hourlyRate);
        }

        update({
            employeeId: employeeId!,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            address: data.showAddress
                ? {
                      id: data.addressId,
                      addressLine1: data.addressLine1,
                      addressLine2: data.addressLine2,
                      city: data.city,
                      state: data.state,
                      zipCode: data.zipCode,
                      latitude: data?.latitude,
                      longitude: data?.longitude,
                  }
                : undefined,
            profilePicture: data.profilePictureUri || '',
            role: data.role,
            active: data.active,
            hourlyRate: hourlyRateNumber,
            geofenceDisabled: data.geofenceDisabled,
            unsubscribeSms: data.unsubscribeSms
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Employee Updated Successfully');
        }
    }, [isSuccess]);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Update Employee">
                <span className="la la-pen-fancy"></span>
            </button>
            <EmployeeModal
                modalTitle="Update Employee"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitUpdate}
                toggler={toggler}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                data={employee}
                isLoaded={!isFetching}
                resetServer={resetServer}
            />
        </>
    );
};

export const AddEmployeeModal = () => {
    const [showModal, setShowModal] = useState(false);

    const { usePostEmployee } = useUsers();
    const {
        isLoading,
        isSuccess,
        create,
        reset: resetServer,
        error: { errors: serverErrors },
    } = usePostEmployee();

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const onSubmitCreate = (data: FormInput) => {
        let hourlyRateNumber: number | undefined = undefined;
        if (isNumber(data.hourlyRate)) {
            hourlyRateNumber = Number(data.hourlyRate);
        }

        create({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile,
            address: data.showAddress
                ? {
                      addressLine1: data.addressLine1,
                      addressLine2: data.addressLine2,
                      city: data.city,
                      state: data.state,
                      zipCode: data.zipCode,
                      latitude: data?.latitude,
                      longitude: data?.longitude,
                  }
                : undefined,
            profilePicture: data.profilePictureUri || '',
            role: data.role,
            hourlyRate: hourlyRateNumber,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetServer();
            toast.success('Employee Created Successfully');
        }
    }, [isSuccess, resetServer]);

    return (
        <>
            <button className="btn btn_primary uppercase" onClick={toggler}>
                Add New
            </button>
            <EmployeeModal
                modalTitle="Add Employee"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitCreate}
                toggler={toggler}
                isLoaded={true}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                resetServer={resetServer}
            />
        </>
    );
};
