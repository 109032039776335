import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

import AuthLayout from '../components/AuthLayout';
import { useTenants } from '../hooks/useTenants';
import { optionsCharts } from '../utilities/staticData';
import { JobOverTimeBadge, JobStatusBadge } from './Jobs';
import { useAuth } from '../hooks/useAuth';
import Loading from '../components/Loading';

ChartJS.register(CategoryScale, LinearScale, ArcElement, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const { tenant } = useAuth();

    const { useGetDashboardData } = useTenants();
    const { data, isSuccess, isFetching } = useGetDashboardData();

    return (
        <>
            <AuthLayout menuOption="Dashboard">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Dashboard</h1>
                    </div>
                </section>
                <Loading isLoading={isFetching} boxStyle />
                <div
                    className={`grid grid-cols-1 ${
                        tenant.hasJobsFeature ? 'md:grid-cols-3 lg:grid-cols-4' : 'md:grid-cols-2 lg:grid-cols-3'
                    } gap-5`}
                >
                    <div className="flex flex-col gap-y-5 md:col-span-2">
                        <div
                            className={`grid grid-cols-1 ${
                                tenant.hasJobsFeature ? 'md:grid-cols-3' : 'md:grid-cols-2'
                            } gap-5`}
                        >
                            <div className="card p-2 flex justify-center items-start text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                                <div className="flex flex-col items-center">
                                    <h3>Employees</h3>
                                    <div className="text-primary mt-5 text-3xl leading-none">
                                        {data?.employees.total}
                                    </div>
                                    <div className="mt-2 grid grid-cols-4 items-center">
                                        <span className="text-primary la la-user"></span>
                                        <h5 className="col-span-2">Logged In</h5>
                                        <div className="text-primary ml-2 mt-1">{data?.employees.loggedIn}</div>
                                        {tenant.hasJobsFeature && (
                                            <>
                                                <span className="text-success la la-briefcase"></span>
                                                <h5 className="col-span-2">On a Job</h5>
                                                <div className="text-primary ml-2 mt-1">{data?.employees.onAJob}</div>
                                                <span className="text-warning la la-warning"></span>
                                                <h5 className="col-span-2">Waiting</h5>
                                                <div className="text-primary ml-2 mt-1">{data?.employees.waiting}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {tenant.hasJobsFeature && (
                                <div className="card p-2 flex justify-center items-start text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                                    <div className="flex flex-col items-center">
                                        <h3>Jobs</h3>
                                        <div className="text-primary mt-5 text-3xl leading-none">
                                            {data?.jobs.total}
                                        </div>
                                        <div className="mt-2 grid grid-cols-3 items-center">
                                            <h5 className="col-span-2">Running Jobs</h5>
                                            <div className="text-primary ml-2 mt-1">{data?.jobs.running}</div>
                                            <h5 className="col-span-2">Done</h5>
                                            <div className="text-primary ml-2 mt-1">{data?.jobs.completed}</div>
                                            <h5 className="col-span-2">To do</h5>
                                            <div className="text-primary ml-2 mt-1">{(data?.jobs.assigned || 0) + (data?.jobs.floating || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="card p-2 flex justify-center items-start text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                                <div className="flex flex-col justify-start items-center">
                                    <h3>Customers</h3>
                                    <div className="text-primary mt-5 text-3xl leading-none">
                                        {data?.customers.total}
                                    </div>
                                    <div className="mt-2 grid  grid-cols-4 items-center">
                                        <span className="text-primary la la-user"></span>
                                        <h5 className="col-span-2">Logged In</h5>
                                        <div className="text-primary ml-2 mt-1">{data?.customers.loggedIn}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {tenant.hasJobsFeature && (
                            <div className="card p-2 flex flex-col justify-center items-center max-h-140 min-w-0 md:col-span-3 md:row-span-1">
                                <h3 className="text-center mb-3">Jobs in recent months</h3>
                                {isSuccess && <Line className="p-0" options={optionsCharts} data={data?.jobs.lineChartData!} />}
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-5">
                        <div className="flex flex-col gap-5">
                            {tenant.hasJobsFeature && (
                                <>
                                    <div className="card p-2 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                                        <div className="grid grid-cols-3">
                                            <div className="flex flex-col col-span-3">
                                                <h3 className="mb-3">Jobs to do</h3>
                                                {isSuccess && <Pie data={data?.jobs.pieChartData!} />}
                                            </div>
                                        </div>
                                   </div>
                                </>
                            )}
                            <div className="card p-2 flex justify-center items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                                <div className="grid grid-cols-3">
                                    <div className="flex flex-col col-span-3">
                                        <h3>Current month</h3>
                                        <div className="mt-5 grid grid-cols-3 items-center">
                                            <h5 className="col-span-2">New customers</h5>
                                            <div className="text-primary ml-2 mt-1">{data?.customers.currentMonthTotal}</div>
                                            {tenant.hasJobsFeature && (
                                                <>
                                                    <h5 className="col-span-2">New jobs</h5>
                                                    <div className="text-primary ml-2 mt-1">{data?.jobs.currentMonthTotal}</div>
                                                    <h5 className="col-span-2">Jobs completed</h5>
                                                    <div className="text-primary ml-2 mt-1">{data?.jobs.completedCurrentMonth}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {tenant.hasJobsFeature && (
                        <div className="grid grid-cols-1 md:col-span-3 lg:col-span-1 lg:grid-cols-1 gap-5">
                            <div className="flex flex-col gap-5">
                                <div className="card h-full max-h-full p-2 flex flex-col justify-start overscroll-y-auto">
                                    <div className="flex flex-col items-center">
                                        <h3>Today jobs</h3>
                                            {data?.jobs.todayJobs.length === 0 && (
                                                <div className="badge badge_outlined badge_danger uppercase mt-5">
                                                    No Jobs for Today
                                                </div>
                                            )}
                                    </div>
                                    {!!data?.jobs.todayJobs && data?.jobs.todayJobs.length > 0 && (
                                        <table className="table table_hoverable w-full mt-2">
                                            <thead>
                                                <tr>
                                                    <th className="text-left uppercase">Title</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="overscroll-y-contain">
                                                {data?.jobs.todayJobs!.slice(0, 15).map((job, index) => (
                                                    <tr key={index}>
                                                        <td>{job.title}</td>
                                                        <td className="text-center">
                                                            <div className="flex-col flex-wrap">
                                                                {!job.isOverTime && !job.isFloating &&
                                                                    <JobStatusBadge status={job.status} fit={true} />
                                                                }
                                                                {job.isOverTime && <JobOverTimeBadge />}
                                                                {job.isFloating && (
                                                                    <div className="badge badge_outlined badge_success uppercase">
                                                                        Floating
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </AuthLayout>
        </>
    );
};

export default Dashboard;
