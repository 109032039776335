import qs from 'qs';
import { DateObject, getAllDatesInRange } from 'react-multi-date-picker';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import apiClient, { ErrorResponse } from '../utilities/apiClient';
import {
    formatAddress,
    formatFullName,
    getDateWithoutTime,
    subtractDurations,
    sumDurations,
    toDateTime,
    toTimeZoneDate,
    toTimeZoneTime,
    ConvertDurationToSeconds,
    sortByDateDesc,
    toDuration,
    toDurationHHMMSS,
    ConvertSecondsToDuration,
    toTimeZoneDateTime,
    sortByDate
} from '../utilities/formatter';
import { Address } from '../utilities/interfaces';
import { mapRole } from '../utilities/mapRole';
import {
    chartPieJobsData,
    jobStatusEnum,
    jobTimekeepingEntryTypeEnum,
    timekeepingEntryTypeEnum,
} from '../utilities/staticData';
import { useAuth } from './useAuth';

interface TenantCustomersResponse {
    records: Customer[];
}

interface TenantJobsResponse {
    totalCount: number;
    records: Job[];
}

interface TenantEmployeesResponse {
    records: Employee[];
}

interface TenantSettingsResponse {
    record: GetTenant;
}

interface TenantCustomerResponse {
    record: Customer;
}

interface GetUserTenantEmployeeResponse {
    record: TenantEmployee;
}

interface TenantJobDatesResponse {
    records: JobDates[];
}

interface UsersLastLocationResponse {
    records: Location[];
}

interface DashboardDataResponse {
    employees: EmployeeDashboard;
    customers: CustomerDashboard;
    jobs: JobDashboard;
}

interface TimeEntriesResponse {
    clockTimeRecords: ClockTimeEntry[];
    jobTimeRecords: JobTimeEntry[];
    performanceRecords: EmpPerformance[];
}

interface ClockTimeEntry {
    userId: string;
    firstName: string;
    lastName: string;
    hourlyRate?: number;
    active: boolean;
    clockInId: string;
    clockInTime: Date;
    clockOutId: string;
    clockOutTime: Date;
    totalTime: string;
    autoCreated: boolean;
}

interface JobTimeEntry {
    userId: string;
    firstName: string;
    lastName: string;
    hourlyRate?: number;
    active: boolean;
    jobId: string;
    jobTitle: string;
    status: string;
    timeInId: string;
    timeInTime: Date;
    timeOutId: string;
    timeOutTime: Date;
    totalTime: string;
    autoCreated: boolean;
}

interface EmpPerformance {
    userId: string;
    lengthInMiles?: number;
    workDayDate: Date;
    mileagePrice?: number;
}

interface CustomerDashboard {
    currentMonthTotal: number;
    lastMonthTotal: number;
    loggedIn: number;
    total: number;
}

interface EmployeeDashboard {
    waiting: number;
    onAJob: number;
    loggedIn: number;
    total: number;
}

interface TodayJobsDashboard {
    id: string;
    isFloating: boolean;
    title: string;
    isOverTime: boolean;
    status: string;
}

interface JobsPerMonthDashboard {
    date: string;
    total: number;
}

interface JobDashboard {
    assigned: number;
    completed: number;
    completedCurrentMonth: number;
    currentMonthTotal: number;
    floating: number;
    jobsPerMonth: JobsPerMonthDashboard[];
    running: number;
    todayJobs: TodayJobsDashboard[];
    total: number;
    lineChartData: ChartData;
    pieChartData: ChartData;
}

interface Datasets {
    label: string;
    data: number[];
    borderColor?: string | string[];
    backgroundColor?: string | string[];
    borderWidth?: number;
}

interface ChartData {
    labels: string[];
    datasets: Datasets[];
}

interface SasToken {
    sasToken: string;
    containerUri: string;
}

interface PaymentsLoginLink {
    url?: string;
}

export interface Customer {
    id: string;
    firstName: string;
    lastName: string;
    active: boolean;
    activeText?: string;
    addresses: Address[];
    addressesText?: string;
    lastJobDate?: Date;
    mobile?: string;
    email?: string;
    includeToSearch: string;
    unsubscribeSms?: boolean;
}

export interface Job {
    id: string;
    title?: string;
    description: string;
    customerName: string;
    employees?: JobEmployee[];
    address: Address;
    jobDate: Date;
    status: string;
    isFloating: boolean;
    recurrenceOption?: number;
    jobDateRange: {
        startDate: Date;
        endDate: Date;
    };
    jobDates?: Date[];
    windowOfArrivalStart?: Date;
    windowOfArrivalEnd?: Date;
    allowedJobActions: number[];
    isOverTime?: boolean;
    runTime?: string;
    timeIn: Date;
    timeOut: Date;
    duration: string;
    timeZoneId: string;
    stripeInvoiceId?: string;
    durationText?: string;
    jobDateText?: string;
}

interface FilterJob {
    description?: string;
    customerName?: string;
    assignedEmployee?: string;
    jobDateFrom?: string;
    jobDateTo?: string;
    searchAll?: string;
    pageIndex?: number;
    pageSize?: number;
    sortingField?: number;
    isSortingDesc?: boolean;
}

interface JobEmployee {
    userId: string;
    firstName: string;
    lastName: string;
    timeIn: Date;
    timeOut: Date;
    active: boolean;
}

interface JobDates {
    id: string;
    title?: string;
    customerName?: string;
    address?: Address;
    status?: string;
    dates?: Date[];
    windowOfArrivalStart?: Date;
    windowOfArrivalEnd?: Date;
}

interface CalendarJobDates {
    id: string;
    title?: string;
    customerName?: string;
    address?: Address;
    date: string;
    status?: string;
    backgroundColor: string;
    borderColor: string;
}

interface EmployeeBase {
    id: string;
    firstName: string;
    lastName: string;
    email?: string;
    active: boolean;
    role: number;
    lastJobDate: Date;
    mobile?: string;
    profilePicture?: string;
    hourlyRate?: number;
    geofenceDisabled: boolean;
    unsubscribeSms: boolean;
}

interface TenantEmployee extends EmployeeBase {
    address?: Address;
}

export interface Employee extends EmployeeBase {
    addresses?: Address[];
    includeToSearch: string;
    activeText?: string;
    roleText?: string;
    addressesText?: string;
    hourlyRateText?: string;
}

interface Tenant {
    id?: string;
    allowedUsers?: number;
    name?: string;
    timeZoneId?: string;
    address?: Address;
    phoneNumber?: string;
    idleMinutes?: number;
    mileagePrice?: number;
}

interface GetTenant extends Tenant {
    features: number[];
    stripeAccountId?: string;
    stripeAccountOnboarded: boolean;
}

interface Location {
    employeeId: string;
    employeeFirstName?: string;
    employeeLastName?: string;
    employeePictureURL?: string;
    latitude: number;
    longitude: number;
    timeStamp?: Date;
}

interface SetupPayments {
    refreshUrl: string;
    returnUrl: string;
}

export interface TimecardInterface {
        employeeId: string;
        employeeName: string;
        employeeNameInverted: string;
        active: boolean;
        weekClocks: {
            date: string;
            duration: string;
            idle: string;
            entries: {
                groupIndex: number;
                entryId: string;
                entryType: string;
                entryTypeName: string;
                entryTime: Date;
                autoCreated?: boolean;
            }[];
        }[];
        weekJobs: {
            date: string;
            duration: string;
            idle: string;
            jobs: {
                jobId: string;
                jobTitle: string;
                jobDuration?: string;
                status?: string;
                entries: {
                    entryId: string;
                    entryTypeName: string;
                    entryType: string;
                    entryTime: Date;
                    autoCreated?: boolean;
                }[];
            }[];
        }[];
        weekMileage: {
            date: string;
            mileage?: number;
            mileageCost?: number;
        }[];
        weekClocksTotalDuration: string;
        weekJobsTotalDuration: string;
        weekIdleTotalDuration: string;
        weekMileageTotalMiles?: number;
        weekMileageCostTotal?: number;
        mileagePrice?: number;
}

const useGetCustomers = () => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(['customers'], async () => {
        const response = await apiClient.get<TenantCustomersResponse>(`tenant/customers`);

        response.data?.records.forEach((customer) => {
            customer.includeToSearch = '';

            customer.activeText = customer.active ? 'Active' : 'Inactive';

            customer.addressesText = customer.addresses
                .map((a) => {
                    return formatAddress(a.addressLine1, a.addressLine2, a.city, a.state, a.zipCode);
                })
                .join('\n');
        });

        return response.data?.records;
    });

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetCustomer = (customerId: string, shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['getEmployee', customerId],
        async () => {
            const response = await apiClient.get<TenantCustomerResponse>(`tenant/customers/${customerId}`);

            return {
                firstName: response.data.record?.firstName || '',
                lastName: response.data.record?.lastName || '',
                email: response.data.record?.email || '',
                mobile: response.data.record?.mobile || '',
                addresses: response.data.record?.addresses,
                unsubscribeSms: response.data.record?.unsubscribeSms,
            };
        },
        {
            enabled: !!customerId && shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetJobs = (jobOptions?: FilterJob, shouldGet: boolean = true, autoRefetch: boolean = true) => {
    const { tenant } = useAuth();

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['jobs', jobOptions],
        async () => {
            const response = await apiClient.get<TenantJobsResponse>(`tenant/jobs`, {
                params: {
                    description: jobOptions?.description,
                    customerName: jobOptions?.customerName,
                    assignedEmployee: jobOptions?.assignedEmployee,
                    jobDateFrom: jobOptions?.jobDateFrom,
                    jobDateTo: jobOptions?.jobDateTo,
                    searchAll: jobOptions?.searchAll,
                    pageIndex: jobOptions?.pageIndex,
                    pageSize: jobOptions?.pageSize,
                    sortingField: jobOptions?.sortingField,
                    isSortingDesc: jobOptions?.isSortingDesc,
                },
            });

            response.data?.records.forEach((job) => {
                job.timeZoneId = tenant.timeZoneId;
                job.jobDateText = toTimeZoneDate(job.jobDate, tenant.timeZoneId);

                let tempDuration = '';
                if (job.runTime) {
                    if (job.status !== jobStatusEnum[jobStatusEnum.Running]) {
                        tempDuration += `\nJob Duration: ${toDuration(job.runTime)}`;
                    }
                }
                if (job.timeIn) {
                    tempDuration += `\n(${toTimeZoneTime(job.timeIn, job.timeZoneId, true)} - `;
                    tempDuration += !job.timeOut ? 'Not Yet' : toTimeZoneTime(job.timeOut, job.timeZoneId, true);
                    tempDuration += ')';
                }
                job.durationText = `Planned: ${job.duration}${tempDuration}`;
            });

            var jobs = response.data?.records;

            const pageCount = jobOptions?.pageSize ? Math.ceil(response.data?.totalCount / jobOptions?.pageSize) : 1;
            const totalCount = response.data?.totalCount;

            return { jobs, pageCount, totalCount };
        },
        {
            enabled: shouldGet,
            refetchInterval: autoRefetch ? 60000 : false,
            refetchOnWindowFocus: autoRefetch ? 'always' : false,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetJobsDates = (args: { startDate: string; endDate: string; employeeId?: string; }, shouldGet: boolean = true) => {
    const { startDate, endDate, employeeId } = args;

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['jobsdates', startDate, endDate],
        async () => {
            const response = await apiClient.get<TenantJobDatesResponse>(`tenant/jobsdates`, {
                params: {
                    startDateTime: startDate,
                    endDateTime: endDate,
                    employeeId,
                },
            });

            let arrayCalendarEvents: CalendarJobDates[] = response.data?.records.flatMap((dataEvent) => {
                let statusColor: string = 'black';
                let arrayEventsDates: CalendarJobDates[] = [];
                switch (dataEvent.status) {
                    case jobStatusEnum[jobStatusEnum.Open]:
                        statusColor = '#FFC107';
                        break;
                    case jobStatusEnum[jobStatusEnum.Assigned]:
                        statusColor = '#28A745';
                        break;
                    case jobStatusEnum[jobStatusEnum.Running]:
                        statusColor = '#0284C7';
                        break;
                    case jobStatusEnum[jobStatusEnum.Completed]:
                        statusColor = '#4B5563';
                        break;
                }
                if (!!dataEvent.dates) {
                    arrayEventsDates = dataEvent.dates!.flatMap((object) => {
                        return {
                            id: dataEvent.id,
                            title: dataEvent.title,
                            customerName: dataEvent.customerName,
                            address: dataEvent.address,
                            date: getDateWithoutTime(object),
                            backgroundColor: statusColor,
                            borderColor: statusColor,
                        };
                    });
                }

                return arrayEventsDates;
            });

            return arrayCalendarEvents;
        },
        {
            enabled: !!startDate && !!endDate && shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetEmployees = () => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(['employees'], async () => {
        const response = await apiClient.get<TenantEmployeesResponse>(`tenant/users`);

        response.data?.records.forEach((employee) => {
            employee.includeToSearch = '';
            employee.activeText = employee.active ? 'Active' : 'Inactive';
            employee.roleText = mapRole(employee.role);
            employee.hourlyRateText = employee.hourlyRate?.toString() || ''; 
            employee.addressesText = employee.addresses
                ?.map((a) => {
                    return formatAddress(a.addressLine1, a.addressLine2, a.city, a.state, a.zipCode);
                })
                .join('\n');
        });

        const { activeEmployees, inactiveEmployees } = response.data?.records.reduce<any>(
            (acc, item) => {
                if (item.active) {
                    acc.activeEmployees.push(item);
                } else {
                    acc.inactiveEmployees.push(item);
                }
                return acc;
            },
            { activeEmployees: [], inactiveEmployees: [] }
        );

        return {activeEmployees, inactiveEmployees};      
    });

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetTenantSettings = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['tenantSettings'],
        async () => {
            const response = await apiClient.get<TenantSettingsResponse>(`user/tenant`);
            return response.data?.record;
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetSasToken = (getSas?: boolean) => {
    const { isLoading, isSuccess, isFetching, data, isError, error, refetch } = useQuery(
        ['sas-token'],
        async () => {
            const response = await apiClient.get<SasToken>(`tenant/sas-token`);

            return response.data!;
        },
        {
            enabled: getSas,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useUpdateTenantSettings = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, Tenant>(
        async (tenant: Tenant) => {
            return await apiClient.put<Tenant>('tenants', tenant);
        }
    );

    const update = (tenant: Tenant) => {
        return mutate(tenant, {
            onSuccess: () => queryClient.invalidateQueries(['tenantSettings']),
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        update,
        reset,
    };
};

const usePostSetupPayments = () => {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, data, isError, error, reset, mutate } = useMutation<unknown, ErrorResponse, SetupPayments>(
        async (setupPayments: SetupPayments) => {
            const response = await apiClient.post<SetupPayments>('tenant/payments/accounts', setupPayments);

            return response.data!;
        }
    );

    const setup = () => {
        const setupPayments: SetupPayments = {
            refreshUrl: window.location.href,
            returnUrl: window.location.href,
        };

        return mutate(setupPayments, {
            onSuccess: () => queryClient.invalidateQueries(['tenantSettings']),
        });
    };

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error: error || {},
        setup,
        reset,
    };
};

const useGetPaymentsLoginLink = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error } = useQuery(
        ['paymentsloginlink'],
        async () => {
            const response = await apiClient.get<PaymentsLoginLink>(`tenant/payments/accounts/loginlink`);

            return response.data!;
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
    };
};

const useGetEmployee = (employeeId: string, shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['getEmployee', employeeId],
        async () => {
            const response = await apiClient.get<GetUserTenantEmployeeResponse>(`tenant/users/${employeeId}`);

            return {
                firstName: response.data.record?.firstName || '',
                lastName: response.data.record?.lastName || '',
                email: response.data.record?.email || '',
                mobile: response.data.record?.mobile || '',
                addressId: response.data.record?.address?.id,
                addressLine1: response.data.record?.address?.addressLine1 || '',
                addressLine2: response.data.record?.address?.addressLine2 || '',
                showAddress: !!response.data.record?.address,
                state: response.data.record?.address?.state || '',
                city: response.data.record?.address?.city || '',
                zipCode: response.data.record?.address?.zipCode || '',
                latitude: response.data.record?.address?.latitude || 0,
                longitude: response.data.record?.address?.longitude || 0,
                profilePictureUri: response.data.record?.profilePicture || '',
                role: response.data.record?.role || 0,
                active: !!response.data.record?.active,
                id: response.data.record?.id || '',
                hourlyRate: response.data.record?.hourlyRate ? response.data.record?.hourlyRate.toString() : '',
                geofenceDisabled: response.data.record?.geofenceDisabled,
                unsubscribeSms: response.data.record?.unsubscribeSms || false,
            };
        },
        {
            enabled: !!employeeId && shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetDashboardData = () => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(['getDashboard'], async () => {
        const response = await apiClient.get<DashboardDataResponse>(`tenant/dashboard`);

        const jobsPerMonth = response.data.jobs?.jobsPerMonth.sort((a, b) => sortByDate(a.date, b.date));
        const labelsPerMonth = jobsPerMonth.map(j => {
            const date = new Date(j.date);
            const formattedDate = new Intl.DateTimeFormat('en-US', { year: '2-digit', month: 'short' }).format(date);
            return formattedDate.replace(" ", " '");
        });
        const dataPerMonth = jobsPerMonth.map(j => j.total);

        const datasetArrayLineChart: Datasets[] = [
            {
                label: 'Jobs',
                data: dataPerMonth,
                borderColor: 'rgb(2, 132, 199)',
                backgroundColor: 'rgba(2, 132, 199, 0.5)',
            },
        ];

        const datasetArrayPie: Datasets[] = [
            {
                label: 'Jobs',
                data: [response.data.jobs?.assigned, response.data.jobs?.floating],
                backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 99, 132)'],
                borderColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                borderWidth: 1,
            },
        ];

        if (response.data.jobs) {
            response.data.jobs.lineChartData = {
                labels: labelsPerMonth,
                datasets: datasetArrayLineChart,
            };

            response.data.jobs.pieChartData = {
                labels: chartPieJobsData,
                datasets: datasetArrayPie,
            };
        }

        return response.data;
    });

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetTimecards = (args: { startDate: DateObject; endDate: DateObject }, autoRefetch: boolean = true) => {
    const { tenant } = useAuth();
    const { startDate, endDate } = args;
    const startDateTime = toDateTime(startDate.format('YYYY-MM-DDT00:00:00'));
    const endDateTime = toDateTime(endDate.format('YYYY-MM-DDT23:59:59'));

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['tenant-time', startDateTime, endDateTime],
        async () => {
            const response = await apiClient.get<TimeEntriesResponse>(`/tenant/time`, {
                params: {
                    startDateTime,
                    endDateTime,
                    includePerformanceData: true,
                },
            });

            var records: TimecardInterface[] = [];

            const dates = getAllDatesInRange([startDate, endDate]) as Date[];

            response.data.clockTimeRecords.forEach((clockTimeRecord, index) => {
                var existingRecord = records.find((r) => r.employeeId === clockTimeRecord.userId);

                if (!existingRecord) {
                    existingRecord = {
                        employeeId: clockTimeRecord.userId,
                        employeeName: formatFullName(clockTimeRecord.firstName, clockTimeRecord.lastName),
                        employeeNameInverted: formatFullName(clockTimeRecord.lastName, clockTimeRecord.firstName),
                        active: clockTimeRecord.active,
                        weekClocks: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            idle: '00:00:00.0',
                            entries: [],
                        })),
                        weekJobs: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            idle: '00:00:00.0',
                            jobs: [],
                        })),
                        weekMileage: dates.map(() => ({
                            date: '',
                            mileage: 0,
                            mileageCost: 0,
                        })),
                        weekClocksTotalDuration: '00:00:00.0',
                        weekJobsTotalDuration: '00:00:00.0',
                        weekIdleTotalDuration: '00:00:00.0',
                        weekMileageTotalMiles: 0,
                        weekMileageCostTotal: 0,
                        mileagePrice: undefined,
                    };

                    records.push(existingRecord);
                }

                const clockInDateTimedZone = toTimeZoneDate(clockTimeRecord.clockInTime, tenant.timeZoneId);
                const clockInTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(clockInDateTimedZone).getDate()
                );
                if (clockInTimeDayIndex > -1) {
                    var totalDuration = sumDurations(
                        existingRecord.weekClocks[clockInTimeDayIndex].duration,
                        clockTimeRecord.totalTime
                    );

                    existingRecord.weekClocks[clockInTimeDayIndex].date = clockInDateTimedZone;
                    existingRecord.weekClocks[clockInTimeDayIndex].duration = totalDuration;

                    var weekTotalDuration = sumDurations(
                        existingRecord.weekClocksTotalDuration,
                        clockTimeRecord.totalTime
                    );
                    existingRecord.weekClocksTotalDuration = weekTotalDuration;

                    if (clockTimeRecord.clockInId) {
                        existingRecord.weekClocks[clockInTimeDayIndex].entries.push({
                            groupIndex: index,
                            entryId: clockTimeRecord.clockInId,
                            entryType: timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']],
                            entryTypeName: 'In',
                            entryTime: clockTimeRecord.clockInTime,
                        });
                    }
                }

                const clockOutDateTimedZone = toTimeZoneDate(clockTimeRecord.clockOutTime, tenant.timeZoneId);
                const clockOutTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(clockOutDateTimedZone).getDate()
                );
                if (clockOutTimeDayIndex > -1) {
                    if (clockTimeRecord.clockOutId) {
                        existingRecord.weekClocks[clockOutTimeDayIndex].entries.push({
                            groupIndex: index,
                            entryId: clockTimeRecord.clockOutId,
                            entryType: timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']],
                            entryTypeName: 'Out',
                            entryTime: clockTimeRecord.clockOutTime,
                            autoCreated: clockTimeRecord.autoCreated,
                        });
                    }
                }
            });

            response.data.jobTimeRecords.forEach((jobTimeRecord) => {
                var existingRecord = records.find((r) => r.employeeId === jobTimeRecord.userId);

                if (!existingRecord) {
                    existingRecord = {
                        employeeId: jobTimeRecord.userId,
                        employeeName: formatFullName(jobTimeRecord.firstName, jobTimeRecord.lastName),
                        employeeNameInverted: formatFullName(jobTimeRecord.lastName, jobTimeRecord.firstName),
                        active: jobTimeRecord.active,
                        weekClocks: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            idle: '00:00:00.0',
                            entries: [],
                        })),
                        weekJobs: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            idle: '00:00:00.0',
                            jobs: [],
                        })),
                        weekMileage: dates.map(() => ({
                            date: '',
                            mileage: 0,
                            mileageCost: 0,
                        })),
                        weekClocksTotalDuration: '00:00:00.0',
                        weekJobsTotalDuration: '00:00:00.0',
                        weekIdleTotalDuration: '00:00:00.0',
                        weekMileageTotalMiles: 0,
                        weekMileageCostTotal: 0,
                        mileagePrice: undefined,
                    };

                    records.push(existingRecord);
                }

                const timeInDateTimedZone = toTimeZoneDate(jobTimeRecord.timeInTime, tenant.timeZoneId);
                const timeInTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(timeInDateTimedZone).getDate()
                );
                if (timeInTimeDayIndex > -1) {
                    var totalDuration = sumDurations(
                        existingRecord.weekJobs[timeInTimeDayIndex].duration,
                        jobTimeRecord.totalTime
                    );

                    existingRecord.weekJobs[timeInTimeDayIndex].date = timeInDateTimedZone;
                    existingRecord.weekJobs[timeInTimeDayIndex].duration = totalDuration;

                    var weekTotalDuration = sumDurations(existingRecord.weekJobsTotalDuration, jobTimeRecord.totalTime);
                    existingRecord.weekJobsTotalDuration = weekTotalDuration;

                    var existingJob = existingRecord.weekJobs[timeInTimeDayIndex].jobs.find(
                        (j) => j.jobId === jobTimeRecord.jobId
                    );

                    if (!existingJob) {
                        existingJob = {
                            jobId: jobTimeRecord.jobId,
                            jobTitle: jobTimeRecord.jobTitle.replace(' hours', ''),
                            status: jobTimeRecord.status,
                            jobDuration: jobTimeRecord.totalTime,
                            entries: [],
                        };

                        existingRecord.weekJobs[timeInTimeDayIndex].jobs.push(existingJob);
                    }

                    existingJob.entries.push({
                        entryId: jobTimeRecord.timeInId,
                        entryTypeName: 'In',
                        entryType: jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']],
                        entryTime: jobTimeRecord.timeInTime,
                    });
                }

                const timeOutDateTimedZone = toTimeZoneDate(jobTimeRecord.timeOutTime, tenant.timeZoneId);
                const timeOutTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(timeOutDateTimedZone).getDate()
                );
                if (timeOutTimeDayIndex > -1) {
                    existingJob = existingRecord.weekJobs[timeOutTimeDayIndex].jobs.find(
                        (j) => j.jobId === jobTimeRecord.jobId
                    );

                    if (!existingJob) {
                        existingJob = {
                            jobId: jobTimeRecord.jobId,
                            jobTitle: jobTimeRecord.jobTitle,
                            status: jobTimeRecord.status,
                            jobDuration: jobTimeRecord.totalTime,
                            entries: [],
                        };

                        existingRecord.weekJobs[timeOutTimeDayIndex].jobs.push(existingJob);
                    }

                    existingJob.entries.push({
                        entryId: jobTimeRecord.timeOutId,
                        entryTypeName: 'Out',
                        entryType: jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']],
                        entryTime: jobTimeRecord.timeOutTime,
                        autoCreated: jobTimeRecord.autoCreated,
                    });
                }
            });

            response.data.performanceRecords.forEach((perfRecord) => {
                var existingRecord = records.find((r) => r.employeeId === perfRecord.userId);

                if (!existingRecord) {
                    return;
                }

                const workDateTimedZone = toTimeZoneDate(perfRecord.workDayDate, tenant.timeZoneId);
                const workTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(workDateTimedZone).getDate()
                );

                if (workTimeDayIndex > -1) {
                    let mileageCost = 0;
                    let lengthInMiles = perfRecord.lengthInMiles ?? 0;

                    if (perfRecord.mileagePrice) {
                        mileageCost = lengthInMiles * perfRecord.mileagePrice;
                    }

                    existingRecord.mileagePrice = perfRecord.mileagePrice;
                    existingRecord.weekMileageTotalMiles! += lengthInMiles;
                    existingRecord.weekMileageCostTotal! += mileageCost;

                    existingRecord.weekMileage[workTimeDayIndex].date = workDateTimedZone;
                    existingRecord.weekMileage[workTimeDayIndex].mileage = lengthInMiles;
                    existingRecord.weekMileage[workTimeDayIndex].mileageCost = mileageCost;
                }
            });

            var activeEmployees: TimecardInterface[] = [];
            var inactiveEmployees: TimecardInterface[] = [];

            records.forEach((employee) => {
                let weekIdleTotalDurationByEmployee = employee.weekIdleTotalDuration;

                employee.weekClocks
                    .filter((clock) => clock.date !== '')
                    .forEach((weekClock) => {
                        const employeeSelected = records.find((x) => x.employeeId === employee.employeeId);
                        var weekJob = employeeSelected?.weekJobs.find((x) => x.date === weekClock.date);

                        var jobDuration;
                        var jobOut:Date|undefined;

                        if (weekJob) {
                            jobOut = weekJob?.jobs.map((j) => j.entries.find((x) => x.entryTypeName === "Out"))
                                .sort((a, b) => sortByDateDesc(a?.entryTime, b?.entryTime))[0]?.entryTime;

                            jobDuration = weekJob.duration;
                        }
                        else {
                            jobDuration = '00:00:00.0';
                        }

                        var clockIn = weekClock.entries.find((x) => x.entryTypeName === "In");
                        var today = new Date(toTimeZoneDateTime(new Date(), tenant.timeZoneId));
                        var timeOut = today;
                        if (clockIn)
                        {
                            var localClockIn = new Date(toTimeZoneDateTime(clockIn.entryTime, tenant.timeZoneId));

                            if (jobOut) {
                                jobOut = new Date(toTimeZoneDateTime(jobOut,tenant.timeZoneId));
                                timeOut = localClockIn.toDateString() === today.toDateString() ? today : jobOut!;
                            }

                            var clockDuration = weekClock.duration;
                            if (clockDuration === "0:0:0.0") {
                                var time = timeOut.getTime() - localClockIn.getTime();
                                clockDuration = ConvertSecondsToDuration(time / 1000);
                            }

                            if (jobDuration !== "00:00:00.0") {
                                weekClock.idle = subtractDurations(clockDuration, jobDuration);
                            }
                            else {
                                weekClock.idle = clockDuration;
                            }

                            weekIdleTotalDurationByEmployee = toDurationHHMMSS(sumDurations(
                                weekIdleTotalDurationByEmployee,
                                weekClock.idle
                            ));
                        }
                    });
                employee.weekIdleTotalDuration = weekIdleTotalDurationByEmployee;
                if (employee.active) {
                    activeEmployees.push(employee);
                } else {
                    inactiveEmployees.push(employee);
                }
            });

            return {
                timecards: {
                    activeTimecards: activeEmployees.sort((a, b) => a.employeeName.localeCompare(b.employeeName)),
                    inactiveTimecards: inactiveEmployees.sort((a, b) => a.employeeName.localeCompare(b.employeeName)),
                }
            };
        },
        {
            enabled: !!startDate && !!endDate,
            refetchInterval: autoRefetch ? 60000 : false,
            refetchOnWindowFocus: autoRefetch ? 'always' : false,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

const useGetEmployeesLastLocation = (shouldGet: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['employees-lastlocations'],
        async () => {
            const response = await apiClient.get<UsersLastLocationResponse>(`/tenant/employees/lastlocation`);

            return response.data?.records;
        },
        {
            enabled: shouldGet,
        }
    );

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
        isFetching,
    };
};

const useGetUserPerformance = (args: { users: string[]; startDate: string; endDate: string }) => {
    const { tenant } = useAuth();
    const { users, startDate, endDate } = args;

    const startDateTime = toDateTime(`${startDate}T00:00:00`);
    const endDateTime = toDateTime(`${endDate}T23:59:59`);

    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['employee-performance', users, startDateTime, endDateTime],
        async () => {
            const response = await apiClient.get<TimeEntriesResponse>(`/tenant/time`, {
                params: {
                    users: users,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    includePerformanceData: true,
                },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'comma' });
                },
            });

            var records: {
                employeeId: string;
                employeeName: string;
                mileage?: number;
                totalMileageCost?: number;
                hourlyRate?: number;
                mileagePrice?: number;
                weekClocks: {
                    date: string;
                    duration: string;
                    workTime: string;
                    idleTime: string;
                }[];
                weekJobs: {
                    date: string;
                    duration: string;
                }[];
                weekPerformance: {
                    date: string;
                    lengthInMiles: number;
                    mileagePrice?: number;
                }[];
                weekClocksTotalDuration: string;
                weekJobsTotalDuration: string;
                weekIdleTotalDuration: string;
                workPercent: number;
                idlePercent: number;
                totalEstimated?: number;
                estimatedPayJob?: number;
                estimatedIdlePay?: number;
            }[];

            records = [];

            const dates = getAllDatesInRange([
                new DateObject(new Date(startDateTime!)),
                new DateObject(new Date(endDateTime!)),
            ]) as Date[];

            response.data.clockTimeRecords.forEach((clockTimeRecord, index) => {
                var existingRecord = records.find((r) => r.employeeId === clockTimeRecord.userId);
                if (!existingRecord) {
                    existingRecord = {
                        employeeId: clockTimeRecord.userId,
                        employeeName: formatFullName(clockTimeRecord.firstName, clockTimeRecord.lastName),
                        hourlyRate: clockTimeRecord.hourlyRate,
                        weekClocks: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            workTime: '00:00:00.0',
                            idleTime: '00:00:00.0',
                        })),
                        weekJobs: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                        })),
                        weekPerformance: dates.map(() => ({
                            date: '',
                            lengthInMiles: 0,
                        })),
                        weekClocksTotalDuration: '00:00:00.0',
                        weekJobsTotalDuration: '00:00:00.0',
                        weekIdleTotalDuration: '00:00:00.0',
                        workPercent: 0,
                        idlePercent: 0,
                    };
                    records.push(existingRecord);
                }

                const clockInDateTimedZone = toTimeZoneDate(clockTimeRecord.clockInTime, tenant.timeZoneId);
                const clockInTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(clockInDateTimedZone).getDate()
                );
                if (clockInTimeDayIndex > -1) {
                    var totalDuration = sumDurations(
                        existingRecord.weekClocks[clockInTimeDayIndex].duration,
                        clockTimeRecord.totalTime
                    );

                    existingRecord.weekClocks[clockInTimeDayIndex].date = clockInDateTimedZone;
                    existingRecord.weekClocks[clockInTimeDayIndex].duration = totalDuration;

                    var weekTotalDuration = sumDurations(
                        existingRecord.weekClocksTotalDuration,
                        clockTimeRecord.totalTime
                    );
                    existingRecord.weekClocksTotalDuration = weekTotalDuration;
                }
            });

            response.data.jobTimeRecords.forEach((jobTimeRecord) => {
                var existingRecord = records.find((r) => r.employeeId === jobTimeRecord.userId);

                if (!existingRecord) {
                    existingRecord = {
                        employeeId: jobTimeRecord.userId,
                        employeeName: formatFullName(jobTimeRecord.firstName, jobTimeRecord.lastName),
                        hourlyRate: jobTimeRecord.hourlyRate,
                        weekClocks: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                            idleTime: '00:00:00.0',
                            workTime: '00:00:00.0',
                        })),
                        weekJobs: dates.map(() => ({
                            date: '',
                            duration: '00:00:00.0',
                        })),
                        weekPerformance: dates.map(() => ({
                            date: '',
                            lengthInMiles: 0,
                        })),
                        weekClocksTotalDuration: '00:00:00.0',
                        weekJobsTotalDuration: '00:00:00.0',
                        weekIdleTotalDuration: '00:00:00.0',
                        workPercent: 0,
                        idlePercent: 0,
                    };
                    records.push(existingRecord);
                }

                const timeInDateTimedZone = toTimeZoneDate(jobTimeRecord.timeInTime, tenant.timeZoneId);
                const timeInTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(timeInDateTimedZone).getDate()
                );
                if (timeInTimeDayIndex > -1) {
                    var totalDuration = sumDurations(
                        existingRecord.weekJobs[timeInTimeDayIndex].duration,
                        jobTimeRecord.totalTime
                    );

                    existingRecord.weekJobs[timeInTimeDayIndex].date = timeInDateTimedZone;
                    existingRecord.weekJobs[timeInTimeDayIndex].duration = totalDuration;

                    var weekTotalDuration = sumDurations(existingRecord.weekJobsTotalDuration, jobTimeRecord.totalTime);
                    existingRecord.weekJobsTotalDuration = weekTotalDuration;
                }
            });

            response.data.performanceRecords.forEach((perfRecord) => {
                var existingRecord = records.find((r) => r.employeeId === perfRecord.userId);

                if (!existingRecord) {
                    return;
                }

                const workDateTimedZone = toTimeZoneDate(perfRecord.workDayDate, tenant.timeZoneId);
                const workTimeDayIndex = dates.findIndex(
                    (d) => new Date(d).getDate() === new Date(workDateTimedZone).getDate()
                );

                if (workTimeDayIndex > -1) {
                    existingRecord.weekPerformance[workTimeDayIndex].date = workDateTimedZone;
                    existingRecord.weekPerformance[workTimeDayIndex].lengthInMiles = perfRecord.lengthInMiles ?? 0;
                    existingRecord.weekPerformance[workTimeDayIndex].mileagePrice = perfRecord.mileagePrice;
                }
            });

            records.forEach((employee) => {
                const clockSecs = ConvertDurationToSeconds(employee.weekClocksTotalDuration);
                let estimatedComplete: number | undefined;

                if (tenant.hasJobsFeature) {
                    let weekIdleTotalDurationByEmployee = employee.weekIdleTotalDuration;

                    employee.weekClocks
                        .filter((clock) => clock.date !== '')
                        .forEach((weekClock) => {
                            const employeeSelected = records.find((x) => x.employeeId === employee.employeeId);
                            var weekJob = employeeSelected?.weekJobs.find((x) => x.date === weekClock.date);

                            if (weekJob) {
                                weekClock.idleTime = subtractDurations(weekClock.duration, weekJob.duration);
                                weekClock.workTime = weekJob.duration;
                                weekIdleTotalDurationByEmployee = sumDurations(
                                    weekIdleTotalDurationByEmployee,
                                    weekClock.idleTime
                                );
                            } else {
                                weekClock.idleTime = subtractDurations(weekClock.duration, '00:00:00.0');
                                weekClock.workTime = '00:00:00.0';
                                weekIdleTotalDurationByEmployee = sumDurations(
                                    weekIdleTotalDurationByEmployee,
                                    weekClock.idleTime
                                );
                            }
                        });
                    employee.weekIdleTotalDuration = weekIdleTotalDurationByEmployee;

                    const workSecs = ConvertDurationToSeconds(employee.weekJobsTotalDuration);
                    const idleSecs = ConvertDurationToSeconds(employee.weekIdleTotalDuration);

                    employee.workPercent = Math.round((workSecs * 100) / clockSecs);
                    employee.workPercent = isNaN(employee.workPercent) ? 0 : employee.workPercent;

                    employee.idlePercent = Math.round((idleSecs * 100) / clockSecs);
                    employee.idlePercent = isNaN(employee.idlePercent) ? 0 : employee.idlePercent;

                    let estimatedPayJob: number | undefined;
                    let estimatedIdlePay: number | undefined;

                    if (employee.hourlyRate) {
                        estimatedPayJob = (workSecs * employee.hourlyRate) / 3600;
                        estimatedIdlePay = (idleSecs * employee.hourlyRate) / 3600;

                        estimatedComplete = estimatedPayJob + estimatedIdlePay;
                    }

                    employee.estimatedPayJob = estimatedPayJob;
                    employee.estimatedIdlePay = estimatedIdlePay;
                } else {
                    if (employee.hourlyRate) {
                        estimatedComplete = (clockSecs * employee.hourlyRate) / 3600;
                    }
                }

                employee.totalEstimated = estimatedComplete;

                let mileageTotal = 0;
                let mileagePrice: number | undefined = undefined;
                employee.weekPerformance
                    .filter((p) => p.date !== '')
                    .forEach((p) => {
                        const employeeSelected = records.find((x) => x.employeeId === employee.employeeId);
                        var weekPerformance = employeeSelected?.weekPerformance.find((x) => x.date === p.date);

                        if (weekPerformance) {
                            mileagePrice = p.mileagePrice;
                            mileageTotal = mileageTotal + p.lengthInMiles;
                        }
                    });

                employee.mileagePrice = mileagePrice;
                employee.mileage = mileageTotal ?? 0;

                if (mileagePrice) {
                    employee.totalMileageCost = mileagePrice * mileageTotal;
                }

                employee.weekClocks = [];
                employee.weekJobs = [];
            });

            return {
                employeesData: records.sort((a, b) => a.employeeName.localeCompare(b.employeeName)),
            };
        },
        {
            enabled: !!startDate && !!endDate,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

export const useTenants = () => {
    return {
        useGetCustomers,
        useGetJobs,
        useGetJobsDates,
        useGetEmployees,
        useGetTenantSettings,
        useUpdateTenantSettings,
        useGetCustomer,
        useGetEmployee,
        useGetSasToken,
        useGetDashboardData,
        useGetTimecards,
        useGetEmployeesLastLocation,
        useGetUserPerformance,
        usePostSetupPayments,
        useGetPaymentsLoginLink,
    };
};