import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { formatFullName, toDateTime, toTimeZoneDateTime, toTimeZoneTime } from '../utilities/formatter';
import AzureMap, { iconType, Point } from '../components/AzureMap';
import AuthLayout from '../components/AuthLayout';
import useInput from '../hooks/useInput';
import { useJobs } from '../hooks/useJobs';
import { useUsers } from '../hooks/useUsers';
import { useSignalR } from '../hooks/useSignalR';
import { useAuth } from '../hooks/useAuth';
import { useTenants } from '../hooks/useTenants';
import { PointTypeEnum } from '../utilities/staticData';
import Tippy from '@tippyjs/react';

import { FormInputDateControl } from '../components/FormControls';
import Loading from '../components/Loading';

enum MapModeEnum {
    Realtime,
    Timeline,
}

const PinsLegend = () => {
    const { tenant } = useAuth();

    return (
        <>
            <div className="flex flex-col gap-2 p-3">
                <div className="flex flex-col items-center">
                    <div className="md:w-36">
                        <div className="badge badge_primary uppercase grid text-center">
                            <strong>Locations</strong>
                        </div>
                        <div className="badge badge_danger uppercase grid text-center mt-2">
                            <strong>Clocks</strong>
                        </div>
                        {tenant.hasJobsFeature && (
                            <div className="badge badge_warning uppercase grid text-center mt-2">
                                <strong>Jobs</strong>
                            </div>
                        )}
                        <div className="badge badge_secondary uppercase grid text-center mt-2">
                            <strong>Ignored</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Map = () => {
    const { isConnected, subscribe, unsubscribe } = useSignalR();

    const { tenant } = useAuth();

    const employeeInput = useInput('');
    const clockedInEmployeeInput = useInput('');
    const dateInput = useInput('');
    const showJobs = useInput(true);
    const showEmployees = useInput(true);
    const autoZoom = useInput(true);

    const [trackerOption, setTrackerOption] = useState(MapModeEnum.Realtime);

    const [shouldGetLastLocations, setShouldGetLastLocations] = useState(true);

    const [employeeSelected, setEmployeeSelected] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [showInactiveEmployees, setShowInactiveEmployees] = useState(false);

    const { useGetEmployeesLastLocation } = useTenants();
    const { data: employeesLastLocationData, isFetching: isFetchingEmployeesLastLocation } =
        useGetEmployeesLastLocation(shouldGetLastLocations);

    const { useGetCreateJob } = useJobs();
    const { data: tenantData, isSuccess: isSuccessTenant, isFetching: isFetchingGetCreateJob } = useGetCreateJob(trackerOption === MapModeEnum.Timeline);

    const { useGetEmployeeLocationsByDate } = useUsers();
    const { data: employeeLocationsData, isSuccess: isSuccessEmployeeLocations, isFetching: isFetchingEmployeeLocations } = useGetEmployeeLocationsByDate({
        employeeId: employeeSelected,
        startDate: startDate,
        endDate: endDate,
    });

    const currentDate = useMemo(() => new Date(), []);

    const { useGetJobsDates } = useTenants();
    const { data: dataGetJobs, isSuccess: isSuccessGetJobs, isFetching: isFetchingGetJobs } = useGetJobsDates(
        {
            startDate: toTimeZoneDateTime(currentDate, tenant.timeZoneId),
            endDate: toTimeZoneDateTime(currentDate, tenant.timeZoneId),
        },
        tenant.hasJobsFeature,
    );

    const [employees, setEmployees] = useState<{ value: string; text: string; active: boolean }[]>();
    const [clockedInEmployees, setClockedInEmployees] = useState<{ value: string; text: string }[]>([]);

    const [jobsPoints, setJobsPoints] = useState<Point[]>([]);
    const [realTimePoints, setRealTimePoints] = useState<Point[]>([]);
    const [employeePoints, setEmployeePoints] = useState<Point[]>([]);

    const disableAutoZoom = useCallback(() => {
        autoZoom.setValue(false);
    }, [autoZoom]);

    const onTrackerOptionSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = Number(e.target.value);

        setTrackerOption(selectedValue);

        if (selectedValue === MapModeEnum.Realtime) {
            employeeInput.reset();
            dateInput.reset();
            setShouldGetLastLocations(true);
        }

        if (selectedValue === MapModeEnum.Timeline) {
            clockedInEmployeeInput.reset();
            setShouldGetLastLocations(false);
        }
    };

    const onUpdateLocation = useCallback(
        (
            userId: string,
            userName: string,
            latitude: number,
            longitude: number,
            timestamp: string,
            profilePicture?: string
        ) => {
            const selectedValue = clockedInEmployees?.find(
                (employee) => employee.text === clockedInEmployeeInput.value
            )?.value;

            if (!selectedValue || selectedValue === userId) {
                const point = {
                    id: userId,
                    name: userName,
                    longitude: longitude,
                    latitude: latitude,
                    date: toTimeZoneTime(new Date(timestamp), tenant.timeZoneId, true),
                    icon: iconType.avatar,
                    pictureURL: profilePicture,
                };

                setRealTimePoints((m) => [...m.filter((a) => a.id !== point.id), point]);
            }

            if (!selectedValue) {
                const user = {
                    value: userId,
                    text: userName,
                };

                setClockedInEmployees((m) => [...m.filter((a) => a.value !== user.value), user]);
            }
        },
        [clockedInEmployees, clockedInEmployeeInput.value, tenant.timeZoneId]
    );

    const onDateChange = useCallback(
        (data: any) => {
            dateInput.setValue(data);
        },
        [dateInput]
    );

    useEffect(() => {
        if (isConnected && trackerOption === MapModeEnum.Realtime) {
            subscribe('updateLocation', onUpdateLocation);
        }

        return () => {
            unsubscribe('updateLocation', onUpdateLocation);
        };
    }, [isConnected, trackerOption, subscribe, unsubscribe, onUpdateLocation]);

    useEffect(() => {
        const selectedValue = employees?.find((employee) => employee.text === employeeInput.value)?.value;

        setEmployeeSelected(selectedValue || '');
    }, [employeeInput.value, employees]);

    useEffect(() => {
        const selectedValue = clockedInEmployees?.find(
            (employee) => employee.text === clockedInEmployeeInput.value
        )?.value;

        if (selectedValue) {
            setRealTimePoints((r) => r.filter((point) => point.id === selectedValue));
            setShouldGetLastLocations(false);
        }

        if (!selectedValue && trackerOption === MapModeEnum.Realtime) {
            setShouldGetLastLocations(true);
        }
    }, [clockedInEmployeeInput.value, clockedInEmployees, trackerOption]);

    useEffect(() => {
        const startDateValue = toDateTime(`${dateInput.value}T00:00:00`);
        const endDateValue = toDateTime(`${dateInput.value}T23:59:59`);

        setStartDate(startDateValue || '');
        setEndDate(endDateValue || '');
    }, [dateInput.value]);

    useEffect(() => {
        if (isSuccessTenant) {
            const employeesValues = tenantData?.employees
                .map((employee) => ({
                    id: employee.id,
                    name: formatFullName(employee.firstName, employee.lastName),
                    active: employee.active
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((employee) => ({
                    value: employee.id,
                    text: employee.name,
                    active: employee.active
                }));

            setEmployees(employeesValues || []);
        }
    }, [isSuccessTenant, tenantData]);

    useEffect(() => {
        if (isSuccessGetJobs) {
            const jobsValues = dataGetJobs?.flatMap((job) => ({
                id: job.id,
                name: job.customerName,
                latitude: job.address?.latitude,
                longitude: job.address?.longitude,
                icon: iconType.pinRed,
            }));

            setJobsPoints(jobsValues || []);
        }
    }, [isSuccessGetJobs, dataGetJobs]);

    useEffect(() => {
        if (isSuccessEmployeeLocations) {
            const employeeSelectedInfo = tenantData?.employees.find((e) => e.id === employeeSelected);

            const employeeLocationsFormatter = employeeLocationsData?.map((location, index) => ({
                id: employeeSelected,
                latitude: location.latitude,
                longitude: location.longitude,
                date:
                    (location.pointType === PointTypeEnum.ClockIn || location.pointType === PointTypeEnum.TimeIn
                        ? 'In: '
                        : '') +
                    (location.pointType === PointTypeEnum.ClockOut || location.pointType === PointTypeEnum.TimeOut
                        ? 'Out: '
                        : '') +
                    (location.timeStamp && toTimeZoneTime(new Date(location.timeStamp), tenant.timeZoneId, true)),
                icon:
                    index === 0
                        ? iconType.avatar
                        : location.pointType === PointTypeEnum.LocationStamp
                        ? iconType.markerBlue
                        : location.pointType === PointTypeEnum.ClockIn
                        ? iconType.markerRed
                        : location.pointType === PointTypeEnum.ClockOut
                        ? iconType.markerRed
                        : location.pointType === PointTypeEnum.TimeIn
                        ? iconType.markerYellow
                        : location.pointType === PointTypeEnum.TimeOut
                        ? iconType.markerYellow
                        : iconType.empty,
                pictureURL: employeeSelectedInfo?.profilePicture,
                name: !employeeSelectedInfo?.profilePicture
                    ? formatFullName(employeeSelectedInfo?.firstName, employeeSelectedInfo?.lastName)
                    : undefined,
                ignore: location.ignoreByLongDistance,
            }));
            setEmployeePoints(employeeLocationsFormatter || []);
        } else {
            setEmployeePoints([]);
        }
    }, [isSuccessEmployeeLocations, employeeLocationsData, employeeSelected, tenantData?.employees, tenant.timeZoneId]);

    useEffect(() => {
        if (!isFetchingEmployeesLastLocation) {
            const employeeLocationsFormatter = employeesLastLocationData?.map((location) => ({
                id: location.employeeId,
                latitude: location.latitude,
                longitude: location.longitude,
                date: location.timeStamp && toTimeZoneTime(new Date(location.timeStamp), tenant.timeZoneId, true),
                icon: iconType.avatar,
                pictureURL: location?.employeePictureURL,
                name: formatFullName(location.employeeFirstName, location.employeeLastName),
            }));
            setRealTimePoints(employeeLocationsFormatter || []);

            const employeesValues = employeesLastLocationData
                ?.map((e) => ({
                    id: e.employeeId,
                    name: formatFullName(e.employeeFirstName, e.employeeLastName),
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((employee) => ({
                    value: employee.id,
                    text: employee.name,
                }));

            setClockedInEmployees(employeesValues || []);
        }
    }, [isFetchingEmployeesLastLocation, employeesLastLocationData, tenant.timeZoneId]);

    useEffect(() => {
        if (showInactiveEmployees) {
            setEmployees(tenantData?.employees.filter((employee) => !employee.active));
        }
        else {
            setEmployees(tenantData?.employees.filter((employee) => employee.active));
        }
    }, [showInactiveEmployees, tenantData]);

    return (
        <>
            <AuthLayout menuOption="Map">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Map</h1>
                    </div>
                    <div className="flex flex-wrap w-full self-center gap-2 items-center mt-5 lg:mt-0">
                        <div className="flex flex-wrap gap-2 items-center ml-0 lg:ml-5">
                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="trackerOptions"
                                    value={MapModeEnum.Realtime}
                                    checked={trackerOption === MapModeEnum.Realtime}
                                    onChange={onTrackerOptionSelected}
                                />
                                <span></span>
                                <span>Real time</span>
                            </label>
                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="trackerOptions"
                                    value={MapModeEnum.Timeline}
                                    checked={trackerOption === MapModeEnum.Timeline}
                                    onChange={onTrackerOptionSelected}
                                />
                                <span></span>
                                <span>Timeline</span>
                            </label>
                        </div>
                        <div className="flex flex-wrap gap-2 items-center ml-auto">
                            <label className="switch">
                                <input type="checkbox"
                                    onChange={() => setShowInactiveEmployees(!showInactiveEmployees)}
                                />
                                <span></span>
                                <span>Show inactive employees</span>
                            </label>
                            {trackerOption === MapModeEnum.Realtime && (
                                <div className="flex gap-2">
                                    <label className="form-control-addon-within rounded-full">
                                        <input
                                            placeholder="Follow employee"
                                            className="form-control border-none disabled:opacity-50"
                                            list="map-clockedInEmployees"
                                            type="text"
                                            {...clockedInEmployeeInput.bind}
                                        />
                                        <datalist id="map-clockedInEmployees">
                                            {clockedInEmployees?.map((option, index) => (
                                                <option key={index} value={option.text} />
                                            ))}
                                        </datalist>
                                        {clockedInEmployeeInput.value && (
                                            <button
                                                type="button"
                                                className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                                                onClick={() => {
                                                    clockedInEmployeeInput.reset();
                                                }}
                                            ></button>
                                        )}
                                    </label>
                                    {!clockedInEmployeeInput.value && (
                                        <label className="switch w-48">
                                            <input type="checkbox" checked={autoZoom.value} {...autoZoom.bind} />
                                            <span></span>
                                            <span>Auto Zoom</span>
                                        </label>
                                    )}
                                </div>
                            )}
                            {trackerOption === MapModeEnum.Timeline && (
                                <div className="flex gap-2">
                                    <label className="form-control-addon-within rounded-full">
                                        <input
                                            placeholder="Select employee"
                                            className="form-control border-none disabled:opacity-50"
                                            list="map-employees"
                                            type="text"
                                            {...employeeInput.bind}
                                        />
                                        <datalist id="map-employees">
                                            {employees?.map((option, index) => (
                                                <option key={index} value={option.text}>
                                                    {option.active ? "" : "Inactive"}
                                                </option>
                                            ))}
                                        </datalist>
                                        {employeeInput.value && (
                                            <button
                                                type="button"
                                                className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                                                onClick={() => {
                                                    employeeInput.reset();
                                                }}
                                            ></button>
                                        )}
                                    </label>
                                    <FormInputDateControl
                                        label=""
                                        onChange={onDateChange}
                                        includeDeselect={true}
                                        isRoundedInput={true}
                                        placeholder="Select date"
                                    />
                                    <Tippy
                                        content={<PinsLegend />}
                                        offset={[0, 2]}
                                        theme="light-border"
                                        trigger="click"
                                        placement="left-start"
                                        animation="shift-toward-extreme"
                                    >
                                        <button
                                            className="btn btn-icon btn-icon_large btn_outlined btn_secondary w-16"
                                            title="Pins Legend"
                                        >
                                            <span className="la la-info"></span>
                                        </button>
                                    </Tippy>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-wrap gap-2 items-center ml-0 sm:ml-auto">
                            <div className="flex gap-2">
                                {tenant.hasJobsFeature && (
                                    <label className="switch">
                                        <input type="checkbox" checked={showJobs.value} {...showJobs.bind} />
                                        <span></span>
                                        <span>Jobs</span>
                                    </label>
                                )}
                                <label className="switch">
                                    <input type="checkbox" checked={showEmployees.value} {...showEmployees.bind} />
                                    <span></span>
                                    <span>Employees</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="card p-5">
                    <Loading
                        isLoading={isFetchingEmployeesLastLocation || isFetchingGetCreateJob || isFetchingEmployeeLocations || isFetchingGetJobs}
                        boxStyle />
                    <AzureMap
                        autoZoom={autoZoom.value}
                        disableAutoZoom={disableAutoZoom}
                        centerStart={[tenant.longitude, tenant.latitude]}
                        showJobs={showJobs.value}
                        jobsPoint={jobsPoints}
                        showRealTime={showEmployees.value && trackerOption === MapModeEnum.Realtime}
                        realTimePoints={realTimePoints}
                        showEmployee={showEmployees.value && trackerOption === MapModeEnum.Timeline}
                        employeePoints={employeePoints}
                        followEmployeeInRealTime={
                            !!clockedInEmployeeInput.value && trackerOption === MapModeEnum.Realtime
                        }
                    />
                </div>
            </AuthLayout>
        </>
    );
};

export default Map;
