import React, { useEffect, useState } from 'react';

import { mapRole } from '../utilities/mapRole';
import { useAuth } from '../hooks/useAuth';
import { useUsers } from '../hooks/useUsers';
import BasicLayout from '../components/BasicLayout';
import Loading from '../components/Loading';
import NotAllowedAccess from '../components/NotAllowedAccess';
import { userRolesEnum } from '../utilities/staticData';
import classNames from 'classnames';

const TenantSelection = () => {
    const { logout, loggedOut, setTenant } = useAuth();

    const { useGetTenants } = useUsers();
    const { isSuccess, isError, data, isFetching } = useGetTenants();

    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [roleToShow, setRoleToShow] = useState(userRolesEnum.Admin);

    const handleOnChangeSuperAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target.checked)
            setRoleToShow(userRolesEnum.SuperAdmin);
        else
            setRoleToShow(userRolesEnum.Admin);
    };

    useEffect(() => {
        if (data?.length === 1 && data[0].active && data[0].role === userRolesEnum.Admin && !loggedOut) {
            setTenant(data[0].id);
        }
    }, [data, setTenant, loggedOut]);

    useEffect(() => {
        if (!isFetching) {
            const superAdminTenants = data?.filter(t => t.role === userRolesEnum.SuperAdmin) || [];
            const _isSuperAdmin = superAdminTenants.length > 0;

            const adminTenants = data?.filter(t => t.role === userRolesEnum.Admin) || [];
            const _isAdmin = adminTenants.length > 0;

            setIsSuperAdmin(_isSuperAdmin);
            setIsAdmin(_isAdmin);

            if (_isSuperAdmin && !_isAdmin)
                setRoleToShow(userRolesEnum.SuperAdmin);
        }
    }, [isFetching, data]);

    return (
        <>
            <BasicLayout>
                <Loading isLoading={loggedOut} text="Logging Out" />
                {(isError || (isSuccess && data?.length === 0)) && !loggedOut && <NotAllowedAccess />}
                {isFetching && !loggedOut ? (
                    <Loading isLoading={true} />
                ) : (
                    !loggedOut && isSuccess && data && data?.length > 0 && (
                        <div className="container flex items-center justify-center py-10">
                            <div className="w-full lg:w-1/2">
                                <div className="flex">
                                    <h3>Select a company</h3>
                                    <div className="flex ml-auto">
                                    {isSuperAdmin && (
                                        <label className={classNames('switch', {
                                            'opacity-50 cursor-not-allowed': !isAdmin,
                                        })}>
                                            <input
                                                disabled={!isAdmin}
                                                type="checkbox"
                                                checked={roleToShow === userRolesEnum.SuperAdmin}
                                                onChange={handleOnChangeSuperAdmin}
                                            />
                                            <span></span>
                                            <span>Login as Super Admin</span>
                                        </label>
                                    )}
                                    <button className="flex items-center text-normal hover:text-primary ml-4" onClick={() => logout()}>
                                        <span className="la la-power-off text-2xl leading-none mr-1"></span>
                                        Logout
                                        </button>
                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-1 gap-5 mt-5">
                                    {data?.filter(t => t.role === roleToShow).map((tenant) => (
                                        <button
                                            key={tenant.id}
                                            className="card card_hoverable card_list"
                                            onClick={() => setTenant(tenant.id)}
                                            disabled={tenant.role !== userRolesEnum.SuperAdmin && !tenant.active}
                                        >
                                            <div className="image image_icon">
                                                <span className="la la-building la-4x"></span>
                                            </div>
                                            <div className="body">
                                                <div className="grid sm:grid-cols-4 gap-4">
                                                    <div className="text-left col-span-3">
                                                        <div className="flex">
                                                            <h5>{tenant.name}</h5>
                                                            {!tenant.active && (
                                                                <div className="badge badge_outlined badge_danger ml-1 mb-1">Inactive</div>
                                                            )}
                                                        </div>
                                                        <p>{mapRole(tenant.role)}</p>
                                                        <p>{tenant.address}</p>
                                                        <p>{tenant.timeZoneDisplayName}</p>
                                                    </div>
                                                    {/*TODO: Review what we should display here*/}
                                                    {/*<div className="text-center">*/}
                                                    {/*    <h5>*/}
                                                    {/*        Messages*/}
                                                    {/*        <span className="badge badge_outlined badge_primary ml-2">{0}</span>*/}
                                                    {/*    </h5>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <div className="actions">
                                                <span className="la la-angle-double-right text-4xl"></span>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                )}
            </BasicLayout>
        </>
    );
};

export default TenantSelection;
