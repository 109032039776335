import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapFeature,
    AzureMapHtmlMarker,
    AzureMapLayerProvider,
    AzureMapsContext,
    AzureMapsProvider,
    IAzureMapControls,
    IAzureMapOptions,
    IAzureMapsContextProps,
} from 'react-azure-maps';
import {
    data,
    AuthenticationType,
    SymbolLayerOptions,
    LineLayerOptions,
    ControlOptions,
    MapEvent,
} from 'azure-maps-control';
import { useMemo } from 'react';
import Avatar from './Avatar';
import classNames from 'classnames';

const AzureSearchSubscriptionKey = process.env.REACT_APP_AZURE_SEARCH_SUBSCRIPTIONKEY;

export interface Point {
    id: string;
    name?: string;
    latitude?: number;
    longitude?: number;
    date?: string;
    icon?: iconType;
    pictureURL?: string;
    ignore?: boolean;
}

interface Segment {
    key: string,
    layerOptions: LineLayerOptions,
    pins: Point[],
}

export enum iconType {
    avatar,
    pinRed,
    markerBlue,
    markerYellow,
    markerRed,
    empty,
}

interface MapProps {
    autoZoom?: boolean;
    disableAutoZoom: () => void;
    centerStart: number[];
    showJobs: boolean;
    jobsPoint: Point[];
    showRealTime: boolean;
    realTimePoints: Point[];
    showEmployee: boolean;
    employeePoints: Point[];
    followEmployeeInRealTime?: boolean;
}

interface LayerProps {
    id: string;
    display: boolean;
    pins: Point[];
    allowOverlap?: boolean;
    realtime?: boolean;
}

interface PinLayerProps extends LayerProps { }

interface LineLayerProps extends LayerProps { }

const controls: IAzureMapControls[] = [
    {
        controlName: 'StyleControl',
        controlOptions: { mapStyles: 'all' },
        options: { position: 'top-left' } as ControlOptions,
    },
    {
        controlName: 'ZoomControl',
        options: { position: 'bottom-right' } as ControlOptions,
    },
    {
        controlName: 'CompassControl',
        controlOptions: { rotationDegreesDelta: 10 },
        options: { position: 'bottom-right' } as ControlOptions,
    },
    {
        controlName: 'PitchControl',
        controlOptions: { pitchDegreesDelta: 5 },
        options: { position: 'bottom-right' } as ControlOptions,
    },
    {
        controlName: 'TrafficControl',
        controlOptions: { incidents: true },
        options: { position: 'bottom-left' } as ControlOptions,
    },
    {
        controlName: 'TrafficLegendControl',
        controlOptions: {},
        options: { position: 'bottom-left' } as ControlOptions,
    },
];

const MapPin = (point: Point, display: boolean, realtime?: boolean) => {
    const rendId = Math.random();

    const coord = point.latitude && point.longitude ? new data.Position(point.longitude, point.latitude) : undefined;

    const title =
        point.name && point.date
            ? `${point?.name}\n${point?.date}`
            : point.name
                ? `${point?.name}`
                : point.date
                    ? `${point?.date}`
                    : '';

    if (point.icon === iconType.empty) {
        return <></>;
    }

    if (point.icon === iconType.avatar) {
        return (
            <AzureMapHtmlMarker
                key={rendId}
                id={rendId.toString()}
                markerContent={
                    <div
                        className={classNames('flex flex-col place-items-center', { '-my-28': !realtime })}
                        style={{ visibility: display ? 'visible' : 'hidden' }}
                    >
                        <Avatar fullName={point.name!} imageURL={point.pictureURL} size={realtime ? 'md' : 'lg'} />
                        {realtime && (
                            <>
                                <div className="badge badge_secondary">{title}</div>
                                <h1 className="-my-2.5">
                                    <i className="las la-angle-double-down"></i>
                                </h1>
                            </>
                        )}
                    </div>
                }
                options={
                    {
                        position: coord,
                    } as any
                }
            />
        );
    } else {
        return (
            <AzureMapFeature
                // Key must be a new, unique Key & Id every time
                // or it won't re-render the pin on the new location
                key={rendId}
                id={rendId.toString()}
                type="Point"
                coordinate={coord}
                properties={{
                    title,
                    icon: iconType[point.icon!],
                    anchor: point.icon === iconType.markerYellow ? 'left' : point.icon === iconType.markerRed ? 'right' : 'center',
                    offset: point.icon === iconType.markerYellow ? [0, -.25] : point.icon === iconType.markerRed ? [0, -1.75] : [0, 1],
                }}
            />
        );
    }
};

const MapLine = (points: Point[]) => {
    const rendId = Math.random();

    const coordiantes = points
        .filter((point) => point.latitude && point.longitude)
        .map((point) => new data.Position(point.longitude!, point.latitude!));

    return (
        <AzureMapFeature
            // Key must be a new, unique Id every time
            // or it won't re-render the pin on the new location
            key={rendId}
            id={rendId.toString()}
            type="LineString"
            coordinates={coordiantes}
        />
    );
};

const PinLayer = (props: PinLayerProps) => {
    const { id, display, pins, allowOverlap, realtime } = props;
    const layerOptions: SymbolLayerOptions = useMemo(
        () => ({
            textOptions: {
                textField: ['get', 'title'],
                anchor: [
                    'case',
                    ['has', 'anchor'],
                    ['get', 'anchor'],

                    // default
                    'center',
                ],
                offset: [
                    'case',
                    ['has', 'offset'],
                    ['get', 'offset'],

                    //default
                    ["literal", [0, 1]]
                ],
                color: '#000000',
                size: 16,
                allowOverlap,
            },
            iconOptions: {
                image: [
                    'match',
                    ['get', 'icon'],

                    'pinRed', 'pin-red',
                    'pinBlue', 'pin-blue',
                    'pinDarkBlue', 'pin-darkblue',
                    'pinRoundBlue', 'pin-round-blue',
                    'pinRoundDarkblue', 'pin-round-darkblue',
                    'pinRoundRed', 'pin-round-red',
                    'markerDarkBlue', 'marker-darkblue',
                    'markerBlue', 'marker-blue',
                    'markerRed', 'marker-red',
                    'markerYellow', 'marker-yellow',
                    'markerBlack', 'marker-black',

                    // default
                    'marker-black',
                ],
                allowOverlap,
            },
            visible: display,
        }),
        [display, allowOverlap]
    );

    return (
        <>
            <AzureMapDataSourceProvider id={`${id} DataSourceProvider`} options={{ cluster: true, clusterRadius: 2 }}>
                <AzureMapLayerProvider id={`${id} LayerProvider`} options={layerOptions} type="SymbolLayer" />
                {pins.map((pin) => MapPin(pin, display, realtime))}
            </AzureMapDataSourceProvider>
        </>
    );
};

const LineLayer = (props: LineLayerProps) => {
    const { id, display, pins } = props;

    const [segments, setSegments] = useState<Segment[]>([]);

    const layerOptions: LineLayerOptions = useMemo(
        () => ({
            strokeWidth: 5,
            visible: display,
        }),
        [display]
    );

    const layerOptionsIgnore: LineLayerOptions = useMemo(
        () => ({
            strokeWidth: 5,
            visible: display,
            strokeColor: '#5b5b5b',
        }),
        [display]
    );

    useEffect(() => {
        if (pins) {
            var _pins = [...pins];

            const segmentsCount = _pins.filter(pin => pin.ignore).length;

            var _segments: Segment[] = [];

            for (var i = 0; i <= segmentsCount; i++) {

                const key = `${Math.random()}`;

                if (i === segmentsCount) {
                    _segments.push({
                        key,
                        layerOptions,
                        pins: [..._pins]
                    });
                }
                else {
                    const index = _pins.findIndex((point, index) => point.ignore && index > 0);

                    _segments.push({
                        key,
                        layerOptions,
                        pins: [..._pins.slice(0, index)]
                    });

                    _segments.push({
                        key: `${Math.random()}`,
                        layerOptions: layerOptionsIgnore,
                        pins: [..._pins.slice(index - 1, index + 1)]
                    });
                    _pins = _pins.slice(index);
                }
            }

            setSegments(_segments);
        }
    }, [pins, id, layerOptions, layerOptionsIgnore]);

    return (
        <>
            {segments.map((segment) => (
                <React.Fragment key={`${segment.key} Lines`}>
                    <AzureMapDataSourceProvider id={`${segment.key} ${id} DataSourceProvider`}>
                        <AzureMapLayerProvider id={`${segment.key} ${id} LayerProvider`} options={segment.layerOptions} type="LineLayer" />
                        {MapLine(segment.pins.filter((p) => p.icon === iconType.empty || p.ignore === true))}
                    </AzureMapDataSourceProvider>
                </React.Fragment>
            ))}
            {segments.map((segment) => (
                <React.Fragment key={`${segment.key} Points`}>
                    <PinLayer
                        id={`${segment.key} employeeLayer-points-locations`}
                        display={display}
                        pins={segment.pins.filter((p) => p.icon === iconType.avatar || p.icon === iconType.markerBlue)}
                    />
                    <PinLayer
                        id={`${segment.key} employeeLayer-points-times`}
                        display={display}
                        pins={segment.pins.filter((p) => p.icon === iconType.markerYellow)}
                        allowOverlap={true}
                    />
                    <PinLayer
                        id={`${segment.key} employeeLayer-points-clocks`}
                        display={display}
                        pins={segment.pins.filter((p) => p.icon === iconType.markerRed)}
                        allowOverlap={true}
                    />
                </React.Fragment>
            ))}
        </>
    );
};

const MapController = (props: MapProps) => {
    const { autoZoom, disableAutoZoom } = props;
    const { centerStart } = props;
    const { showJobs, jobsPoint } = props;
    const { showRealTime, realTimePoints, followEmployeeInRealTime } = props;
    const { showEmployee, employeePoints } = props;

    const [previousLocation, setPreviousLocation] = useState<Point>();

    const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(AzureMapsContext);

    const zoomEvent = useCallback(
        (e: MapEvent) => {
            if ((e.originalEvent?.type === 'wheel' || e.originalEvent?.type === 'touchend') && autoZoom) {
                disableAutoZoom();
            }
        },
        [autoZoom, disableAutoZoom]
    );

    const mapOptions: IAzureMapOptions = useMemo(
        () => ({
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: AzureSearchSubscriptionKey,
            },
            view: 'Auto',
        }),
        []
    );

    useEffect(() => {
        if (isMapReady && mapRef && showRealTime) {
            mapRef?.events.add('zoomend', zoomEvent);

            if (realTimePoints.length > 0) {
                if (followEmployeeInRealTime) {
                    const location = realTimePoints[realTimePoints.length - 1];

                    var brng = 0;
                    if (
                        previousLocation &&
                        previousLocation.longitude &&
                        previousLocation.latitude &&
                        location &&
                        location.longitude &&
                        location.latitude
                    ) {
                        const dLon = location.longitude - previousLocation?.longitude;
                        const y = Math.sin(dLon) * Math.cos(location.latitude);
                        const x =
                            Math.cos(previousLocation.latitude) * Math.sin(location.latitude) -
                            Math.sin(previousLocation.latitude) * Math.cos(location.latitude) * Math.cos(dLon);
                        brng = Math.atan2(y, x);
                        brng = brng * (180 / Math.PI);
                        brng = (brng + 360) % 360;
                    }

                    const zoom = mapRef.getCamera().zoom;
                    mapRef.setCamera({
                        center: [location.longitude, location.latitude],
                        zoom: !zoom || zoom < 1 ? 16 : zoom,
                        type: 'jump',
                        pitch: 55,
                        bearing: brng === 0 ? mapRef.getCamera().bearing : brng,
                    });

                    setPreviousLocation(location);
                } else {
                    if (autoZoom) {
                        var bbox = data.BoundingBox.fromPositions(
                            realTimePoints.map((point) => new data.Position(point.longitude || 0, point.latitude || 0))
                        );
                        mapRef.setCamera({
                            bounds: bbox,
                            padding: {
                                top: 100,
                                bottom: 10,
                                left: 120,
                                right: 120,
                            },
                        });
                        if ((mapRef.getCamera().zoom || 0) > 16) {
                            mapRef.setCamera({ zoom: 16, pitch: 0 });
                        }
                    }
                }
            } else {
                mapRef.setCamera({
                    center: centerStart.some((num) => !num) ? [-81.766687, 26.151798] : centerStart,
                    zoom: 10,
                    pitch: 0,
                });
            }
        }
    }, [
        isMapReady,
        mapRef,
        showRealTime,
        centerStart,
        realTimePoints,
        followEmployeeInRealTime,
        previousLocation,
        autoZoom,
        zoomEvent,
    ]);

    useEffect(() => {
        if (isMapReady && mapRef && employeePoints.length > 0) {
            var bbox = data.BoundingBox.fromPositions(
                employeePoints.map((point) => new data.Position(point.longitude || 0, point.latitude || 0))
            );
            mapRef.setCamera({ zoom: 10, pitch: 0 });
            mapRef.setCamera({
                bounds: bbox,
                padding: {
                    top: 100,
                    bottom: 50,
                    left: 50,
                    right: 50,
                },
            });
            if ((mapRef.getCamera().zoom || 0) > 16) {
                mapRef.setCamera({ zoom: 16, pitch: 0 });
            }
        }
    }, [isMapReady, mapRef, employeePoints]);

    return (
        <>
            <AzureMap options={mapOptions} controls={controls}>
                <PinLayer id="realtimeLayer-points" display={showRealTime} pins={realTimePoints} realtime={true} />
                <PinLayer id="jobsLayer-points" display={showJobs} pins={jobsPoint} />
                <LineLayer
                    id="employeeLayer-line"
                    display={showEmployee}
                    pins={employeePoints}
                />
            </AzureMap>
        </>
    );
};

const Map = (props: MapProps) => {
    const { autoZoom, disableAutoZoom } = props;
    const { centerStart } = props;
    const { showJobs, jobsPoint } = props;
    const { showRealTime, realTimePoints, followEmployeeInRealTime } = props;
    const { showEmployee, employeePoints } = props;

    return (
        <>
            <div className="h-65v">
                <AzureMapsProvider>
                    <MapController
                        autoZoom={autoZoom}
                        disableAutoZoom={disableAutoZoom}
                        centerStart={centerStart}
                        showJobs={showJobs}
                        jobsPoint={jobsPoint}
                        showRealTime={showRealTime}
                        realTimePoints={realTimePoints}
                        showEmployee={showEmployee}
                        employeePoints={employeePoints}
                        followEmployeeInRealTime={followEmployeeInRealTime}
                    />
                </AzureMapsProvider>
            </div>
        </>
    );
};

export default memo(Map);
