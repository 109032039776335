import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';

import AuthLayout from '../components/AuthLayout';
import { AddJobModal } from '../components/JobModal';
import ListViewJobs from '../components/ListViewJobs';
import CalendarViewJobs from '../components/CalendarViewJobs';
import { jobStatusEnum } from '../utilities/staticData';
import { useDebounce } from '../hooks/useDebounce';
import useInput from '../hooks/useInput';

export const JobStatusBadge = ({ status, fit }: { status?: string, fit?: boolean }) => {
    const badgeStyle =
        status === jobStatusEnum[jobStatusEnum.Open]
            ? 'badge_warning'
            : status === jobStatusEnum[jobStatusEnum.Assigned]
                ? 'badge_success'
                : status === jobStatusEnum[jobStatusEnum.Running]
                    ? 'badge_primary'
                    : status === jobStatusEnum[jobStatusEnum.Completed]
                        ? 'badge_secondary'
                        : '';
    return (
        <>
            <div className="flex flex-col items-center">
                <div className={classNames({ 'md:w-36': !fit })}>
                    <div className={`badge ${badgeStyle} uppercase grid text-center`}>
                        <strong>{status}</strong>
                    </div>
                </div>
            </div>
        </>
    );
};

export const JobOverTimeBadge = () => {
    return (
        <>
            <div className="flex flex-col items-center">
                <div className="badge badge_danger uppercase">
                    <span className="animate-ping h-2 w-2 rounded-full badge_warning mr-1"></span>
                    <b>Over Time</b>
                </div>
            </div>
        </>
    );
};

const CalendarLegend = () => {
    return (
        <>
            <div className="flex flex-col gap-2 p-3">
                <JobStatusBadge status={jobStatusEnum[jobStatusEnum.Open]} />
                <JobStatusBadge status={jobStatusEnum[jobStatusEnum.Assigned]} />
                <JobStatusBadge status={jobStatusEnum[jobStatusEnum.Running]} />
                <JobStatusBadge status={jobStatusEnum[jobStatusEnum.Completed]} />
            </div>
        </>
    );
};

const Jobs = () => {
    const searchInput = useInput('');
    const debouncedSearchValue = useDebounce(searchInput.value);

    const [jobsView, setJobsView] = useState<Boolean>(true);
    const [changedByUser, setChangedByUser] = useState(false);

    const listViewClasses = {
        'btn btn_outlined uppercase': true,
        btn_secondary: !jobsView,
        btn_primary: jobsView,
    };

    const calendarViewClasses = {
        'btn btn_outlined uppercase': true,
        btn_secondary: jobsView,
        btn_primary: !jobsView,
    };

    const calendarViewHandler = () => {
        cancelHandler();
        setJobsView(false);
    };

    const cancelHandler = () => {
        setChangedByUser(true);
        searchInput.reset();
    };

    const clearHandler = () => {
        setChangedByUser(false);
        searchInput.reset();
    };

    useEffect(() => {
        if (debouncedSearchValue) {
            setChangedByUser(true);
        }
    }, [debouncedSearchValue]);

    return (
        <>
            <AuthLayout menuOption="Jobs">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Jobs</h1>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center ml-auto mt-5 lg:mt-0">
                        <div className="flex gap-x-2">
                            {!jobsView && (
                                <Tippy
                                    content={<CalendarLegend />}
                                    offset={[0, 2]}
                                    theme="light-border"
                                    trigger="click"
                                    placement="left-start"
                                    animation="shift-toward-extreme"
                                >
                                    <button
                                        className="btn btn-icon btn-icon_large btn_outlined btn_secondary"
                                        title="Calendar Legend"
                                    >
                                        <span className="la la-info"></span>
                                    </button>
                                </Tippy>
                            )}
                            <button
                                className={classNames(listViewClasses)}
                                title="List View"
                                onClick={() => setJobsView(true)}
                            >
                                <span className="la la-list"></span>
                                List View
                            </button>
                            <button
                                className={classNames(calendarViewClasses)}
                                title="Calendar View"
                                onClick={calendarViewHandler}
                            >
                                <span className="la la-calendar"></span>
                                Calendar View
                            </button>
                        </div>
                        <div>
                            <label className="form-control-addon-within rounded-full h-10">
                                <input
                                    type="text"
                                    className="form-control border-none"
                                    placeholder="Search all columns..."
                                    {...searchInput.bind}
                                />
                                {searchInput.value ? (
                                    <button
                                        type="button"
                                        className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                                        onClick={cancelHandler}
                                    ></button>
                                ) : (
                                    <span className="text-gray-300 dark:text-gray-300 text-xl leading-none la la-search mr-4 mt-2"></span>
                                )
                                }
                            </label>
                        </div>
                        <div>
                            <AddJobModal />
                        </div>
                    </div>
                </section>
                {jobsView && <ListViewJobs onClearGlobalFilter={clearHandler} globalFilter={debouncedSearchValue || undefined} shouldSearchAll={changedByUser} />}
                {!jobsView && <CalendarViewJobs />}
            </AuthLayout>
        </>
    );
};

export default Jobs;
