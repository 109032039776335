import axios from 'axios';
import { toast } from 'react-toastify';

export const uploadFileToBlobStorage = async (uri: string, file: File) => {
    const toastId = toast.loading('Uploading file...');

    var isUploaded = false;

    try {
        const response = await axios.put(uri, file, {
            headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type,
            },
        });
        isUploaded = response.status === 201;
    } catch {}

    toast.dismiss(toastId);

    if (!isUploaded)
        toast.error(`Could not upload file '${file.name}'`, {
            autoClose: 6000,
            containerId: 'serverConnectionError',
        });

    return isUploaded;
};
