import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';
import format from 'date-fns/format';

import { useAuth } from '../hooks/useAuth';
import { useUsers } from '../hooks/useUsers';
import { formatDateTime, toTimeZoneDate, toTimeZoneTime } from '../utilities/formatter';
import { timekeepingEntryTypeEnum } from '../utilities/staticData';
import TippyToolTip from '../components/TippyToolTip';

interface TimeCardProps {
    employeeId: string;
    entryId: string;
    entryTime: Date;
    entryType?: string;
    isDeletableEntry?: boolean;
    autoCreated: boolean;
    onLoadingUpdateTimeEntry: (value: boolean) => void;
    onEditingEntry: (entryId?: string) => void;
    idEditingEntry?: string;
    narrowMode?: boolean;
    entryTypeName?: string;
    timeToValidate?: Date;
    timeToValidateBetweenClocks?: Date;
}

const TimeEntryEdit = (props: TimeCardProps) => {
    const {
        employeeId,
        entryId,
        entryTime,
        entryType,
        isDeletableEntry,
        autoCreated,
        onLoadingUpdateTimeEntry,
        onEditingEntry,
        idEditingEntry,
        narrowMode,
        entryTypeName,
        timeToValidate,
        timeToValidateBetweenClocks,
    } = props;

    const { tenant } = useAuth();

    const [value, setValue] = useState('00:00:00');
    const [timeValidationClientError, setTimeValidationClientError] = useState('');

    const { useUpdateTimeEntry, useDeleteTimeEntry } = useUsers();
    const {
        isLoading: isLoadingUpdateTimeEntry,
        isSuccess: isSuccessTimeEntryUpdate,
        update: updateTimeEntry,
        error: { errors: updateTimeEntryServerErrors },
        reset: resetTimeEntryServerErrors,
    } = useUpdateTimeEntry();

    const {
        isLoading: isLoadingDeleteTimeEntry,
        isSuccess: isSuccessTimeEntryDelete,
        update: deleteTimeEntry,
        error: { errors: deleteTimeEntryServerErrors }
    } = useDeleteTimeEntry();

    const onTimeEdit = () => {
        onEditingEntry(entryId);
        setValue(toTimeZoneTime(entryTime, tenant.timeZoneId));
    };

    const onTimeEditCancel = useCallback(() => {
        onEditingEntry(undefined);
        setValue('00:00:00');
        setTimeValidationClientError('');
        resetTimeEntryServerErrors();
    }, [onEditingEntry, resetTimeEntryServerErrors]);

    const onTimeEditSave = () => {
        const dateTimeZone = toTimeZoneDate(entryTime, tenant.timeZoneId);
        const dateOnly = format(new Date(dateTimeZone), 'yyyy-MM-dd');
        const dateTime = formatDateTime(dateOnly, value);
        const utcDate = zonedTimeToUtc(dateTime, tenant.timeZoneId);

        if (timeToValidate) {
            const utcDateToValidate = new Date(timeToValidate);
            if (
                entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']] &&
                utcDate >= utcDateToValidate
            ) {
                setTimeValidationClientError('Must be before Clock Out');
                return;
            }

            if (
                entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']] &&
                utcDate <= utcDateToValidate
            ) {
                setTimeValidationClientError('Must be after Clock In');
                return;
            }
        }
        if (timeToValidateBetweenClocks) {
            const utcDateToValidateInOtherClock = new Date(timeToValidateBetweenClocks);
            if (
                entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']] &&
                utcDate <= utcDateToValidateInOtherClock
            ) {
                setTimeValidationClientError('Must be after the previous Clock Out');
                return;
            }

            if (
                entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']] &&
                utcDate >= utcDateToValidateInOtherClock
            ) {
                setTimeValidationClientError('Must be before the next Clock In');
                return;
            }
        }

        updateTimeEntry({
            employeeId: employeeId,
            entryId: entryId,
            entryDateTime: utcDate,
        });
    };

    const onTimeDelete = () => {
        const utcDate = zonedTimeToUtc(entryTime, tenant.timeZoneId);

        deleteTimeEntry({
            employeeId: employeeId,
            entryId: entryId,
            entryDateTime: utcDate,
        });
    };

    const onTimeChange = (event: any) => {
        setValue(event.target.value);
        setTimeValidationClientError('');
        resetTimeEntryServerErrors();
    };

    useEffect(() => {
        if (isSuccessTimeEntryUpdate) {
            onTimeEditCancel();
            toast.success('Time Entry Updated Successfully');
        }
    }, [isSuccessTimeEntryUpdate, onTimeEditCancel]);

    useEffect(() => {
        onLoadingUpdateTimeEntry(isLoadingUpdateTimeEntry);
    }, [isLoadingUpdateTimeEntry, onLoadingUpdateTimeEntry]);

    useEffect(() => {
        if (isSuccessTimeEntryDelete) {
            onTimeEditCancel();
            toast.success('Time Entry Deleted Successfully');
        }
    }, [isSuccessTimeEntryDelete, onTimeEditCancel]);

    useEffect(() => {
        onLoadingUpdateTimeEntry(isLoadingDeleteTimeEntry);
    }, [isLoadingDeleteTimeEntry, onLoadingUpdateTimeEntry]);

    useEffect(() => {
        if (deleteTimeEntryServerErrors) {
            onTimeEditCancel();
            var errors = 'Time Entry Delete Failed';
            deleteTimeEntryServerErrors.GeneralErrors.forEach(function (error: any) {
                errors += '. ' + error;
            });
            toast.error(errors);
        }
    }, [deleteTimeEntryServerErrors, onTimeEditCancel]);

    if (narrowMode) {
        return (
            <>
                <div
                    className={`flex flex-wrap gap-0.5 items-center text-xs ${
                        idEditingEntry === entryId ? 'justify-center border-2 border-primary p-1' : 'justify-end'
                    }`}
                    style={{ minWidth: '115px' }}
                >
                    {autoCreated && <TippyToolTip tooltipText="Auto Clock Out" />}
                    {entryTypeName}
                    {entryTime && idEditingEntry !== entryId && toTimeZoneTime(entryTime, tenant.timeZoneId, true)}
                    {idEditingEntry === entryId && (
                        <input
                            type="time"
                            step="1"
                            className="form-control p-0"
                            onChange={onTimeChange}
                            value={value}
                        />
                    )}
                    {Array.isArray(updateTimeEntryServerErrors?.GeneralErrors)
                        ? (updateTimeEntryServerErrors?.GeneralErrors as string[])?.map((error, index) => (
                              <small key={index} className="block mt-1 invalid-feedback">
                                  {error}
                              </small>
                          ))
                        : null}
                    {timeValidationClientError && (
                        <small className="block mt-1 invalid-feedback">{timeValidationClientError}</small>
                    )}
                    {entryTime && (
                        <button
                            className="hover:text-primary"
                            title="Override Time"
                            onClick={() => onTimeEdit()}
                            disabled={!!idEditingEntry}
                            hidden={idEditingEntry === entryId}
                        >
                            <span className="la la-pen-fancy"></span>
                        </button>
                    )}
                    {idEditingEntry === entryId && (
                        <>
                            <div
                                className="w-full flex flex-row items-center justify-center mx-1">
                                {isDeletableEntry &&
                                    <div className="w-full flex">
                                        <button
                                            className="btn btn-icon icon-small btn_outlined btn_danger"
                                            title="Delete Time"
                                            onClick={() => onTimeDelete()}
                                            disabled={isLoadingDeleteTimeEntry || isLoadingUpdateTimeEntry}
                                        >
                                            <span className="la la-trash"></span>
                                        </button>
                                    </div>
                                }
                                <div className="flex">
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_secondary"
                                        title="Cancel"
                                        onClick={onTimeEditCancel}
                                        disabled={isLoadingDeleteTimeEntry || isLoadingUpdateTimeEntry}
                                    >
                                        <span className="la la-times"></span>
                                    </button>
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_primary ml-2"
                                        title="Save Time"
                                        onClick={() => onTimeEditSave()}
                                        disabled={isLoadingDeleteTimeEntry || isLoadingUpdateTimeEntry}
                                    >
                                        <span className="la la-save"></span>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="sm:grid sm:grid-cols-3 content-center">
                <div className="col-span-2 grid content-center">
                    {autoCreated && <TippyToolTip tooltipText="Auto Clock Out" />}
                    {entryTypeName}
                    {entryTime && idEditingEntry !== entryId && toTimeZoneTime(entryTime, tenant.timeZoneId, true)}
                    {idEditingEntry === entryId && (
                        <input type="time" step="1" className="form-control" onChange={onTimeChange} value={value} />
                    )}
                    {Array.isArray(updateTimeEntryServerErrors?.GeneralErrors)
                        ? (updateTimeEntryServerErrors?.GeneralErrors as string[])?.map((error, index) => (
                              <small key={index} className="block mt-1 invalid-feedback">
                                  {error}
                              </small>
                          ))
                        : null}
                    {timeValidationClientError && (
                        <small className="block mt-1 invalid-feedback">{timeValidationClientError}</small>
                    )}
                </div>
                <div className="grid content-center text-center">
                    {entryTime && !idEditingEntry && (
                        <button
                            className="btn btn-icon icon-small btn_outlined btn_secondary ml-2 mt-1 mr-1"
                            title="Override Time"
                            onClick={() => onTimeEdit()}
                        >
                            <span className="la la-pen-fancy"></span>
                        </button>
                    )}
                    {idEditingEntry === entryId && (
                        <div>
                            <button
                                className="btn btn-icon icon-small btn_outlined btn_secondary mx-2"
                                title="Cancel"
                                onClick={onTimeEditCancel}
                                disabled={isLoadingDeleteTimeEntry || isLoadingUpdateTimeEntry}
                            >
                                <span className="la la-times"></span>
                            </button>
                            <button
                                className="btn btn-icon icon-small btn_outlined btn_primary mt-1"
                                title="Save Time"
                                onClick={() => onTimeEditSave()}
                                disabled={isLoadingDeleteTimeEntry || isLoadingUpdateTimeEntry}
                            >
                                <span className="la la-save"></span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TimeEntryEdit;
