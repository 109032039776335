import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { FormMultiSelectEmployeesControl, FormTextareaControl } from '../components/FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import { useJobs } from '../hooks/useJobs';
import { useNotifications } from '../hooks/useNotifications';
import Loading from './Loading';

interface FormInput {
    message?: string;
    users: string[];
}

const schema = yup
    .object({
        message: yup.string().required('Message is required.'),
        users: yup.array().required('At least one is required.').min(1, 'At least one is required.'),
    })
    .required();

const MessageModal = (props: { tabSelected?: number, bgPhoneNumber?: string }) => {
    const { tabSelected, bgPhoneNumber } = props;

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        setValue,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const [showModal, setShowModal] = useState(false);
    const [loadedUsers, setLoadedUsers] = useState<{ value: string; label: string; img?: string; }[]>([]);

    const { useGetCreateJob } = useJobs();
    const { data, isFetching } = useGetCreateJob(showModal);

    const { useSendBulkSms } = useNotifications();
    const {
        isLoading: isLoadingSendingSms,
        isSuccess: isSuccessPostBulkSms,
        create: sendBulkSms,
        reset: resetServerPostBulkSms,
        error: { errors: serverErrorsPostBulkSms },
    } = useSendBulkSms(bgPhoneNumber);

    const getSelectedUsers = () => {
        const selectedUsers =
            tabSelected === 0 ? data?.employees?.filter((element) => element.active === true) : data?.customers;
        return selectedUsers;
    };

    const mappedOptions = getSelectedUsers()?.map((user) => ({
        value: user.value,
        label: user.text,
        img: user.profilePicture,
    }));

    const getNameGroupUsers = () => {
        return tabSelected === 0 ? 'Employees' : 'Customers';
    };

    const onUsersSelected = (users: { value: string; text: string }[]) => {
        setValue(
            'users',
            users.map((obj) => obj.value)
        );
    };

    const onSubmit = (data: FormInput) => {
        var records: {
            userId: string;
            userName: string;
            userMobile: string;
        }[];
        records = [];

        const usersBySelectedTab = getSelectedUsers();

        data.users.forEach((id) => {
            const user = usersBySelectedTab?.find((x) => x.id === id);
            records.push({
                userId: user?.id!,
                userName: user?.text!,
                userMobile: user?.mobile!
            });
        });

        sendBulkSms({
            to: records,
            message: data.message!,
        })
            .then(() => {
                resetForm();
                toast.success(`Multiple messages Sent Successfully`);
            })
            .catch((errors) => {
                var usersNamesInError: { value: string; label: string; img?: string; }[] = [];

                if (errors?.GeneralErrors?.length > 0) {
                    errors?.GeneralErrors?.forEach((error: any) => {
                        var splitted = error.split('|:|');
                        if (splitted <= 1) return;

                        const errorByUser = usersBySelectedTab?.find((x) => x.text === splitted[0]);
                        usersNamesInError.push({
                            value: errorByUser?.value!,
                            label: errorByUser?.text!,
                            img: errorByUser?.profilePicture,
                        });
                    });

                    var newItemsInErrorToMarkSelected = usersBySelectedTab
                        ?.filter((sel) => usersNamesInError?.some((item) => item.value === sel.value))
                        .map((finalResult) => {
                            return {
                                value: finalResult.value,
                                label: finalResult.text,
                                img: finalResult.profilePicture,
                            };
                        });

                    setValue(
                        'users',
                        newItemsInErrorToMarkSelected!.map((obj) => obj.value)
                    );
                    setLoadedUsers(newItemsInErrorToMarkSelected!);
                }

                var message = '';

                if (errors?.GeneralErrors?.length <= 0) {
                    message = 'An unknown error occurred if the issue persists contact your system administrator';
                    toast.error(message, { autoClose: 10000 });
                    return;
                }

                if (records.length === usersNamesInError.length) {
                    message = 'Failed to send all messages and the errors are displayed below.';
                    toast.error(message, { autoClose: 10000 });
                    return;
                }

                if (records.length > usersNamesInError.length) {
                    message =
                        'Some messages were sent successfully. However, some messages failed and the errors are displayed below.';
                    toast.warning(message, { autoClose: 10000 });
                    return;
                }
            });
    };

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        resetServerPostBulkSms();
        resetClient();
        toggler();
        setValue('users', []);
        setLoadedUsers([]);
        setValue('message', '');
    }, [resetServerPostBulkSms, resetClient, toggler, setValue]);

    useEffect(() => {
        if (isSuccessPostBulkSms) {
            resetForm();
            toast.success(`Multiple messages Sent Successfully`);
        }
    }, [isSuccessPostBulkSms, resetForm]);

    return (
        <>
            <button
                title="Bulk messages"
                onClick={toggler}
                className="btn btn-icon btn-icon_large btn_outlined btn_primary"
            >
                <span className="la la-users text-xl leading-none"> </span>
            </button>

            <Modal isOpen={showModal} toggler={resetForm} size="md" isCentered isFullHeight isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoadingSendingSms}>
                    Send SMS to multiple {getNameGroupUsers()}
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={isFetching} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormMultiSelectEmployeesControl
                                label={getNameGroupUsers()}
                                register={register('users')}
                                options={mappedOptions}
                                onChange={onUsersSelected}
                                clientErrors={clientErrors?.users as FieldError}
                                serverErrors={serverErrorsPostBulkSms?.users}
                                initialValues={loadedUsers}
                            />
                            {Array.isArray(serverErrorsPostBulkSms?.GeneralErrors)
                                ? (serverErrorsPostBulkSms?.GeneralErrors as string[])?.map((error, index) => (
                                      <small key={index} className="block mt-1 invalid-feedback">
                                          {error.replace('|:|', ':')}
                                      </small>
                                  ))
                                : null}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-1">
                            <FormTextareaControl
                                type="text"
                                label="Message"
                                register={register('message')}
                                clientErrors={clientErrors?.message}
                                serverErrors={serverErrorsPostBulkSms?.message}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoadingSendingSms}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoadingSendingSms}
                        >
                            <span className="la la-paper-plane text-xl leading-none mr-2"></span>
                            <span>Send</span>
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default MessageModal;
