import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';

import AuthLayout from '../components/AuthLayout';
import { useTenants, Customer } from '../hooks/useTenants';
import { toShortDate, deserializeHiddenColumns } from '../utilities/formatter';
import { AddCustomerModal, EditCustomerModal } from '../components/CustomerModal';
import { storage } from '../utilities/storage';
import { useAuth } from '../hooks/useAuth';
import {
    AddressesColumn,
    ColumnsPagination,
    ColumnsSearching,
    ColumnsSorting,
    ColumnsVisibility,
    ExportReactTableToCSV,
} from '../components/TableFeatures';
import Loading from '../components/Loading';

const columnHelper = createColumnHelper<Customer>();

const tableColumnsSchema = [
    columnHelper.accessor('firstName', {
        id: 'First Name',
        cell: (info) => <div className="font-bold">{info.getValue()}</div>,
        header: (header) => (
            <div className="text-left uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('lastName', {
        id: 'Last Name',
        cell: (info) => <div className="font-bold">{info.getValue()}</div>,
        header: (header) => (
            <div className="text-left uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('addressesText', {
        id: 'Addresses',
        cell: ({ row }) => <AddressesColumn addresses={row.original.addresses || []} />,
        header: (header) => (
            <div className="text-left uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('activeText', {
        id: 'Status',
        cell: (info) => (
            <div className="text-center">
                <div className="badge badge_outlined badge_secondary text-center uppercase">{info.getValue()}</div>
            </div>
        ),
        header: (header) => (
            <div className="text-center uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('lastJobDate', {
        id: 'Last Job',
        cell: (info) => <div className="text-center">{toShortDate(info.getValue())}</div>,
        header: (header) => (
            <div className="text-center uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('includeToSearch', {
        cell: (info) => info.getValue(),
    }),
];

const Customers = () => {
    const { user, tenant } = useAuth();

    const { useGetCustomers } = useTenants();
    const { data, isSuccess, isFetching } = useGetCustomers();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnVisibility, setColumnVisibility] = useState({});
    const fixedHiddenColumns = useMemo(
        () => ['includeToSearch', tenant.hasJobsFeature ? '' : 'Last Job'],
        [tenant.hasJobsFeature]
    );
    const initialHiddenColumns = useMemo(() => ['Last Job'], []);
    const storeTableVisibility = useMemo(
        () => storage<{}>(`workalert.state.customer_table_visibility.${user.id}`),
        [user.id]
    );

    const table = useReactTable({
        data: data || [],
        columns: tableColumnsSchema,
        state: {
            sorting,
            globalFilter,
            columnVisibility,
        },
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 20,
            },
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const searchHandler = (event: any) => {
        setGlobalFilter(event.target.value);
    };

    useEffect(() => {
        if (columnVisibility && Object.keys(columnVisibility).length !== 0) {
            const tableVisibility = storeTableVisibility.get() || {};
            if (!_.isEqual(columnVisibility, tableVisibility)) {
                storeTableVisibility.set(columnVisibility);
            }
        }
    }, [columnVisibility, storeTableVisibility]);

    useEffect(() => {
        if (isSuccess) {
            let tableVisibility = storeTableVisibility.get() || {};
            if (Object.keys(tableVisibility).length === 0) {
                tableVisibility = deserializeHiddenColumns(fixedHiddenColumns.concat(initialHiddenColumns));
            }
            setColumnVisibility(tableVisibility);
        }
    }, [isSuccess, storeTableVisibility, fixedHiddenColumns, initialHiddenColumns]);

    return (
        <>
            <AuthLayout menuOption="Customers">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Customers</h1>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center ml-auto mt-5 lg:mt-0">
                        <div>
                            <ColumnsSearching
                                handler={searchHandler}
                                globalFilter={globalFilter}
                                onClear={() => {
                                    setGlobalFilter('');
                                }}
                            />
                        </div>
                        <div>
                            <AddCustomerModal />
                        </div>
                    </div>
                </section>
                <div className="card p-5">
                    <div className="overflow-x-auto">
                        <Loading isLoading={isFetching} boxStyle />
                        <table className="table table-auto table_hoverable w-full">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <th key={header.id}>
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        className={`${
                                                            header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : ''
                                                        }`}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                        <th className="text-right">
                                            <ExportReactTableToCSV title={'Customers'} reacTable={table} />
                                            <ColumnsVisibility
                                                columns={table.getAllLeafColumns()}
                                                fixedHiddenColumns={fixedHiddenColumns}
                                            />
                                        </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {isSuccess &&
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.original.id}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                            <td className="whitespace-nowrap">
                                                <div className="inline-flex ml-auto">
                                                    <EditCustomerModal customerId={row.original.id} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card mt-5 lg:flex">
                    <ColumnsPagination table={table} onPageChanged={() => null} />
                </div>
            </AuthLayout>
        </>
    );
};

export default Customers;
