import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMenu } from '../hooks/useMenu';
import BasicLayout from './BasicLayout';
import Topbar from './Topbar';
import Menubar from './Menubar';
import Footer from './Footer';
import { useAuth } from '../hooks/useAuth';
import FeatureNotAllowed from './FeatureNotAllowed';

interface AuthLayoutProps {
    menuOption: string;
    children: React.ReactNode;
    sidebar?: React.ReactNode;
    className?: string;
    hideFooter?: boolean;
}

const AuthLayout = (props: AuthLayoutProps) => {
    const { menuOption, children, sidebar, className, hideFooter } = props;

    const { tenant } = useAuth();
    const isChatAllowed = tenant.hasChatFeature;

    const { setMenuBarOption } = useMenu();

    useEffect(() => {
        setMenuBarOption(menuOption);
    }, [menuOption, setMenuBarOption]);

    const mainClasses = {
        'workspace_with-sidebar': !!sidebar,
        'overflow-hidden': !sidebar,
    };

    if ((menuOption === 'Messages' && !tenant.hasChatFeature) || (menuOption === 'Jobs' && !tenant.hasJobsFeature)) {
        return (
            <BasicLayout>
                <FeatureNotAllowed flag={isChatAllowed} />
            </BasicLayout>
        );
    }

    return (
        <>
            <ToastContainer enableMultiContainer containerId={'serverConnectionError'} autoClose={3000} pauseOnHover={true} limit={1} />
            <ToastContainer enableMultiContainer autoClose={3000} pauseOnHover={true} />
            <BasicLayout>
                <Topbar />
                <Menubar />
                <main className={classNames('workspace', mainClasses, className)}>
                    {children}
                    {!hideFooter && <Footer />}
                </main>
                {sidebar}
            </BasicLayout>
        </>
    );
};

export default AuthLayout;
