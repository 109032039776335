import axios from 'axios';

export interface ErrorResponse {
    statusCode?: number;
    message?: string;
    errors?: any;
}

const apiClient = axios.create();

const baseURL = process.env.REACT_APP_API_BASE_URL;

apiClient.defaults.baseURL = baseURL;

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response && error?.response.status === 400) {
            return Promise.reject(error?.response?.data);
        }

        return Promise.reject(error);
    }
);

export default apiClient;
