import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import PrivateRoute from './PrivateRoute';
import BasicLayout from './BasicLayout';
import Loading from './Loading';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Timecard from '../pages/Timecard';
import Jobs from '../pages/Jobs';
import Customers from '../pages/Customers';
import Employees from '../pages/Employees';
import Map from '../pages/Map';
import Messages from '../pages/Messages';
import Settings from '../pages/Settings';
import EmployeePerformance from '../pages/EmployeePerformance';

const Routing = () => {
    const { isLoading } = useAuth();

    const [isChangingRoute, setIsChangingRoute] = useState(false);
    const [previousLocation, setPreviousLocation] = useState({});
    const location = useLocation();

    useEffect(() => {
        setPreviousLocation(location);
        setIsChangingRoute(true);
    }, [location]);

    useEffect(() => {
        setIsChangingRoute(false);
    }, [previousLocation]);

    if (isLoading || isChangingRoute) {
        return (
            <BasicLayout>
                <Loading isLoading={true} />
            </BasicLayout>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/timecard" element={<Timecard />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/employeePerformance" element={<EmployeePerformance />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/settings" element={<Settings />} />
                </Route>
                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    );
};

export default Routing;
