import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FormInputAutocompleteMultiSelectControl } from '../components/FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import { useJobs } from '../hooks/useJobs';
import { formatFullName } from '../utilities/formatter';
import { jobAssignActionEnum } from '../utilities/staticData';
import Loading from './Loading';

interface FormInput {
    employees: string[];
}

const AssignJobModal = (props: {
    children: React.ReactNode;
    jobs: { id: string; title?: string; employees?: string[] }[];
    multiple?: boolean;
    disabled?: boolean;
}) => {
    const { children, jobs, multiple, disabled } = props;

    const [assignAction, setAssignAction] = useState(jobAssignActionEnum.Reassign);

    const { useAssignJobs } = useJobs();
    const {
        isLoading,
        isSuccess,
        assign,
        reset: resetServer,
        error: { errors: serverErrors },
    } = useAssignJobs(assignAction);

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        setValue,
    } = useForm<FormInput>();

    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { useGetCreateJob } = useJobs();
    const { data: getCreateJobResults, isSuccess: isSuccessGetCreateJob, isFetching: isFetchingGetCreateJobs } = useGetCreateJob(showModal);

    const [employees, setEmployees] = useState<{ value: string; text: string; active: boolean }[]>();
    const [loadedEmployees, setLoadedEmployees] = useState<{ value: string; text: string }[]>([]);
    const [shouldLoadEmployees, setShouldLoadEmployees] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        resetServer();
        resetClient();
        toggler();
        setShouldLoadEmployees((value) => false);
        setLoadedEmployees((value) => []);
        setValue('employees', []);
        setIsEditing((value) => false);
    }, [resetServer, resetClient, toggler, setValue]);

    const onReassign = (data: FormInput) => {
        setAssignAction(jobAssignActionEnum.Reassign);
        save(data);
    };

    const onAssign = (data: FormInput) => {
        setAssignAction(jobAssignActionEnum.Assign);
        save(data);
    };

    const onUnassign = (data: FormInput) => {
        setAssignAction(jobAssignActionEnum.Unassign);
        save(data);
    };

    const save = (data: FormInput) => {
        assign({
            jobs: jobs.map((job) => job.id),
            employees: data.employees,
        });
    };

    const onEmployeeSelected = (employees: { value: string; text: string }[]) => {
        setValue(
            'employees',
            employees.map((obj) => obj.value)
        );
        setIsEditing(true);
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            if (multiple) toast.success(`Successfully ${jobAssignActionEnum[assignAction]}ed Jobs`);
            else toast.success('Job Assigned Successfully');
        }
    }, [isSuccess, resetForm, multiple, assignAction]);

    useEffect(() => {
        if (isSuccessGetCreateJob && showModal) {
            setEmployees(
                getCreateJobResults?.employees
                    .filter((el) => el.active === true)
                    .sort((a, b) =>
                        formatFullName(a.firstName, a.lastName).localeCompare(formatFullName(b.firstName, b.lastName))
                    )
                    .map((employee) => ({
                        value: employee.id,
                        text: formatFullName(employee.firstName, employee.lastName),
                        active: employee.active
                    }))
            );
            setShouldLoadEmployees(true);
        }
    }, [isSuccessGetCreateJob, getCreateJobResults, showModal]);

    useEffect(() => {
        if (!multiple && jobs[0].employees && showModal && shouldLoadEmployees && loadedEmployees.length === 0) {
            const selectedEmployees: { value: string; text: string }[] = employees?.filter((element) =>
                jobs[0]?.employees?.some((employeePayload) => employeePayload === element.value)
            )!;

            if (selectedEmployees!) {
                setLoadedEmployees(selectedEmployees!);
                setValue(
                    'employees',
                    selectedEmployees!.map((obj) => obj.value)
                );
            }
        }
    }, [employees, jobs, multiple, setValue, shouldLoadEmployees, showModal, loadedEmployees.length]);

    return (
        <>
            <button
                className={`btn btn-icon ${multiple ? 'btn_primary uppercase' : 'btn_outlined btn_secondary ml-2'}`}
                disabled={disabled}
                onClick={toggler}
                title={multiple ? 'Assign Jobs' : 'Assign Job'}
            >
                {children}
            </button>
            <Modal isOpen={showModal} toggler={resetForm} size="lg" isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoading}>
                    {multiple ? 'Assign Jobs' : 'Assign Job'}
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={isFetchingGetCreateJobs} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-5 sm:gap-5">
                        {multiple && (
                            <div className="mb-5">
                                <label className="label block mb-2">Jobs</label>
                                <textarea
                                    className="form-control"
                                    value={jobs?.map((job, index) => (index ? ' ' : '') + '[' + job.title + ']')}
                                    readOnly={true}
                                />
                            </div>
                        )}
                        {!multiple && (
                            <div className="mb-5">
                                <label className="label block mb-2">Job</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={jobs?.map((job, index) => (index ? ' ' : '') + '[' + job.title + ']')}
                                    readOnly={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-5 sm:gap-5">
                        <div className="mb-5 col-span-3">
                            <FormInputAutocompleteMultiSelectControl
                                label="Employees"
                                register={register('employees')}
                                options={employees}
                                onChange={onEmployeeSelected}
                                clientErrors={clientErrors?.employees as FieldError}
                                serverErrors={serverErrors?.employees}
                                initialValues={loadedEmployees}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {multiple && (
                        <button
                            type="submit"
                            className="btn btn_info mr-2 uppercase"
                            onClick={handleSubmit(onUnassign)}
                            disabled={isLoading || !isEditing}
                        >
                            Un-Assign
                        </button>
                    )}
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        {multiple && (
                            <button
                                type="submit"
                                className="btn btn_primary ml-2 uppercase"
                                onClick={handleSubmit(onAssign)}
                                disabled={isLoading || !isEditing}
                            >
                                Assign
                            </button>
                        )}
                        {!multiple && (
                            <button
                                type="submit"
                                className="btn btn_primary ml-2 uppercase"
                                onClick={handleSubmit(onReassign)}
                                disabled={isLoading || !isEditing}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AssignJobModal;
