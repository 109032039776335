export const mapRole = (roleNumber: number) => {
    switch (roleNumber) {
        case 0:
            return 'SuperAdmin';
        case 1:
            return 'Admin';
        case 2:
            return 'Employee';
        case 3:
            return 'Customer';
        default:
            return 'unknown';
    }
};
