import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import { useJobs } from '../hooks/useJobs';

const DeleteJobModal = (props: { job: { id: string; title?: string }; disabled?: boolean }) => {
    const { job, disabled } = props;

    const { useDeleteJob } = useJobs();
    const { isLoading, isSuccess, deleteJob, reset: resetServer, isError } = useDeleteJob();

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        resetServer();
        toggler();
    }, [resetServer, toggler]);

    const onDelete = () => {
        deleteJob({
            jobId: job.id,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Job Deleted Successfully');
        }
    }, [isSuccess, resetForm]);

    useEffect(() => {
        if (isError) {
            toast.error('Job could not be deleted');
        }
    }, [isError]);

    return (
        <>
            <button
                className={`btn btn-icon btn_outlined ${disabled ? 'btn_secondary' : 'btn_danger'} ml-2`}
                disabled={disabled}
                onClick={toggler}
                title="Delete Job"
            >
                <span className="las la-trash"></span>
            </button>
            <Modal isOpen={showModal} toggler={resetForm} isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoading}>
                    Delete Job
                </ModalHeader>
                <ModalBody>
                    <div>
                        <h3>Are you sure you want to delete this job?</h3>
                    </div>
                    <div className="m-5">
                        <input type="text" className="form-control" value={job.title} readOnly={true} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={onDelete}
                            disabled={isLoading}
                        >
                            Delete
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DeleteJobModal;
