import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import './scss/app.scss';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './components/AppInsights';

import Routing from './components/Routing';
import { AuthProvider, AuthProviderWithRoles } from './hooks/useAuth';
import { SignalRProvider } from './hooks/useSignalR';
import { SmsNotificationsProvider } from './hooks/useSmsNotifications';
import { MenuProvider } from './hooks/useMenu';
import { toast } from 'react-toastify';

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                onSuccess: () => {
                    toast.dismiss('toastCouldNotConnect');
                    toast.dismiss('toastTryingConnect');
                    toast.dismiss('toastConnectionFailed');
                    toast.dismiss('toastForbidden');
                    toast.clearWaitingQueue({ containerId: 'serverConnectionError' });
                },
                onError: (error: any) => {
                    if (error?.request?.status === 403) {
                        toast.error('Sorry, you are not allowed to access this.', {
                            toastId: 'toastForbidden',
                            containerId: 'serverConnectionError',
                        });
                    }
                },
                retry: (failureCount: number, error: any) => {
                    const { message } = error;

                    if (!(message.includes('timeout') || message.includes('Network Error'))) {
                        return false;
                    }

                    if (failureCount === 0) {
                        toast.error('Could not connect to the server.', {
                            toastId: 'toastCouldNotConnect',
                            containerId: 'serverConnectionError',
                        });
                        toast.loading(`Trying to connect to the server ...`, {
                            toastId: 'toastTryingConnect',
                            containerId: 'serverConnectionError',
                        });
                        return true;
                    }

                    if (failureCount <= 2) return true;

                    toast.dismiss('toastTryingConnect');
                    toast.clearWaitingQueue({ containerId: 'serverConnectionError' });
                    toast.error(`Connection failed: ${message}. Contact your administrator.`, {
                        autoClose: 5000,
                        toastId: 'toastConnectionFailed',
                        containerId: 'serverConnectionError',
                    });

                    return false;
                },
                retryDelay: 5000,
            },
        },
    });

    return (
        <>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <AuthProviderWithRoles>
                            <SignalRProvider>
                                <SmsNotificationsProvider>
                                    <MenuProvider>
                                        <Routing />
                                    </MenuProvider>
                                </SmsNotificationsProvider>
                            </SignalRProvider>
                        </AuthProviderWithRoles>
                    </AuthProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </>
    );
};

export default withAITracking(reactPlugin, App);
