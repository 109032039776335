import React, { useState } from 'react';

const useInput = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        reset: () => {
            setValue(initialValue);
        },
        setValue: (newValue: any) => {
            setValue(newValue);
        },
        bind: {
            value,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (typeof initialValue == 'boolean') {
                    setValue((v: boolean) => !v);

                    return;
                }

                setValue(event.target.value);
            },
        },
    };
};

export default useInput;
