import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useController, FieldError } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormInputControl, FormSelectControl } from './FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import { FuzzySearchResult } from '../hooks/useAddressSearch';
import { usStates } from '../utilities/staticData';
import { formatAddressFromObject, formatAddressLine } from '../utilities/formatter';
import InputAddressFuzySearch from './InputAddressFuzzySearch';
import { Address } from '../utilities/interfaces';
import { guid } from '@fullcalendar/react';
import { toast } from 'react-toastify';

interface AddressModalProps {
    modalTitle: string;
    showModal: boolean;
    toggler: () => void;
    address?: Address;
    onChange: (value?: any) => void;
    okButtonName: string;
}

interface AddressListProps {
    serverErrors?: string[];
    clientErrors?: FieldError;
    initialAddresses?: Address[];
    onChange: (value?: any) => void;
}

const schema: yup.SchemaOf<Address> = yup.object({
    id: yup.string().optional(),
    addressLine1: yup.string().required('Address Line1 is required.'),
    addressLine2: yup.string().optional(),
    city: yup.string().required('City is required.'),
    state: yup.string().required('State is required.'),
    zipCode: yup
        .string()
        .required('Zip Code is required.')
        .matches(/^\d{5}(-\d{4})?$/, 'Zip code format is invalid.'),
    latitude: yup.number().optional(),
    longitude: yup.number().optional(),
    isBillingAddress: yup.bool().optional(),
});

const AddressModal = (props: AddressModalProps) => {
    const { modalTitle, showModal, toggler, address, onChange, okButtonName } = props;

    const {
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        control,
    } = useForm<Address>({ resolver: yupResolver(schema) });
    const { field: addressLine1Field } = useController({
        name: 'addressLine1',
        control,
        defaultValue: '',
    });
    const { field: addressLine2Field } = useController({
        name: 'addressLine2',
        control,
        defaultValue: '',
    });
    const { field: cityField } = useController({
        name: 'city',
        control,
        defaultValue: '',
    });
    const { field: stateField } = useController({
        name: 'state',
        control,
        defaultValue: '',
    });
    const { field: zipCodeField } = useController({
        name: 'zipCode',
        control,
        defaultValue: '',
    });
    const { field: latitude } = useController({
        name: 'latitude',
        control,
        defaultValue: 0,
    });
    const { field: longitude } = useController({
        name: 'longitude',
        control,
        defaultValue: 0,
    });

    const states = usStates.map((state) => {
        return {
            value: state.abbreviation,
            text: state.name,
        };
    });

    const resetForm = useCallback(() => {
        resetClient();
        toggler();
    }, [resetClient, toggler]);

    const onSubmit = (data: Address) => {
        onChange(data);
        resetForm();
    };

    const onAddressSelected = (result: FuzzySearchResult) => {
        addressLine1Field.onChange(formatAddressLine(result.address.streetNumber, result.address.streetName));
        addressLine2Field.onChange('');
        cityField.onChange(result.address.municipality);
        stateField.onChange(result.address.countrySubdivision);
        zipCodeField.onChange(result.address.postalCode);
        latitude.onChange(result.position.lat);
        longitude.onChange(result.position.lon);
    };

    useEffect(() => {
        resetClient(address);
    }, [resetClient, address]);

    return (
        <>
            <Modal isOpen={showModal} toggler={resetForm} size="md" isStatic={true}>
                <ModalHeader toggler={resetForm}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <InputAddressFuzySearch
                                label="Address Line 1"
                                register={addressLine1Field}
                                onChange={onAddressSelected}
                                field={addressLine1Field}
                                clientErrors={clientErrors?.addressLine1}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Address Line2"
                                register={addressLine2Field}
                                clientErrors={clientErrors?.addressLine2}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="City"
                                register={cityField}
                                clientErrors={clientErrors?.city}
                            />
                        </div>
                        <div className="mb-5">
                            <FormSelectControl
                                label="State"
                                register={stateField}
                                options={states}
                                clientErrors={clientErrors?.state}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Zip Code"
                                register={zipCodeField}
                                clientErrors={clientErrors?.zipCode}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button type="button" className="btn btn_secondary uppercase" onClick={resetForm}>
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {okButtonName}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const EditAddressModal = (props: { address?: Address; onChange: (value?: any) => void }) => {
    const { address, onChange } = props;

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Update Address">
                <span className="la la-pen-fancy"></span>
            </button>
            <AddressModal
                modalTitle="Update Address"
                showModal={showModal}
                toggler={toggler}
                address={address}
                onChange={onChange}
                okButtonName="Ok"
            />
        </>
    );
};

export const AddAddressModal = (props: { onChange: (value?: any) => void }) => {
    const { onChange } = props;

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    return (
        <>
            <button className="btn btn_primary uppercase h-6" onClick={toggler}>
                Add New
            </button>
            <AddressModal
                modalTitle="Add Address"
                showModal={showModal}
                toggler={toggler}
                onChange={onChange}
                okButtonName="Ok"
            />
        </>
    );
};

export const AddressList = (props: AddressListProps) => {
    const { serverErrors, clientErrors, initialAddresses, onChange } = props;

    const [addresses, setAddresses] = useState<Address[]>([]);

    const isDuplicated = (address: Address) => {
        const newAddress = formatAddressFromObject(address).trim();

        const exists = addresses.some((a) => formatAddressFromObject(a).trim() === newAddress);

        if (exists) {
            toast.error('Address already exists on customer');
        }

        return exists;
    };

    const applyChanges = (_addresses: Address[]) => {
        setAddresses(_addresses);
        onChange(_addresses);
    };

    const onAddressAdded = (result: Address) => {
        if (isDuplicated(result)) {
            return;
        }

        result.id = guid();

        const _addresses = [...(addresses || []), result];

        applyChanges(_addresses);
    };

    const onAddressUpdated = (result: Address) => {
        if (isDuplicated(result)) {
            return;
        }

        var _addresses = [...addresses];

        let updated = _addresses.find((a) => a.id === result.id);

        if (updated !== undefined) {
            updated.addressLine1 = result.addressLine1;
            updated.addressLine2 = result.addressLine2;
            updated.city = result.city;
            updated.latitude = result.latitude;
            updated.longitude = result.longitude;
            updated.state = result.state;
            updated.zipCode = result.zipCode;
        }

        applyChanges(_addresses);
    };

    const onAddressDeleted = (id: string) => {
        const _addresses = [...addresses.filter((b) => b.id !== id)];

        applyChanges(_addresses);
    };

    useEffect(() => {
        if (initialAddresses) {
            setAddresses([...initialAddresses!]);
        }
    }, [initialAddresses, setAddresses]);

    return (
        <>
            <div className="sm:flex justify-between items-center">
                <h4>ADDRESSES</h4>
                <AddAddressModal onChange={onAddressAdded} />
            </div>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
            {addresses?.map((entry, index) => (
                <div
                    key={index}
                    className={`sm:flex justify-between items-center mt-2 p-1 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                >
                    <div className="whitespace-normal break-words">{formatAddressFromObject(entry)}</div>
                    <div>
                        <EditAddressModal address={entry} onChange={onAddressUpdated} />
                        <button
                            className="btn btn-icon btn_outlined btn_secondary ml-1"
                            onClick={() => onAddressDeleted(entry.id || '')}
                            title="Delete Address"
                        >
                            <span className="las la-trash"></span>
                        </button>
                    </div>
                </div>
            ))}
        </>
    );
};
