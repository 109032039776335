import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="mt-auto">
                <div className="footer">
                    <span className="uppercase">&copy; {new Date().getFullYear()} Work Alert</span>
                </div>
            </footer>
        </>
    );
};

export default Footer;
