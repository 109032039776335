import React, { useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../components/Modal';
import { useAuth } from '../hooks/useAuth';
import { useJobs } from '../hooks/useJobs';
import { JobOverTimeBadge, JobStatusBadge } from '../pages/Jobs';
import {
    formatFullName,
    getFileNameFromAzureURI,
    sortByDate,
    sortByDateDesc,
    toMoney,
    toTimeZoneDate,
    toTimeZoneTime,
} from '../utilities/formatter';
import { jobActionEnum, jobStatusEnum, jobTimekeepingEntryTypeEnum } from '../utilities/staticData';
import JobTimeEntryEdit from './JobTimeEntryEdit';
import JobTimeEntryModal from './JobTimeEntryModal';
import TippyToolTip from './TippyToolTip';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { CancelInvoiceModal, InvoiceModal, SendInvoiceModal } from './InvoiceModal';
import Loading from './Loading';

interface TimeCardProps {
    jobId: string;
    jobDate?: string;
    employeesTimeCard?: any[];
    onLoadingUpdateJobTimeEntry: (value: boolean) => void;
    onEditingEntry: (entryId?: string) => void;
}

const EmployeeNotes = (props: { dataJob: any }) => {
    const { dataJob } = props;
    const noteToRow = (note: { employeeId: any; note?: string; files?: string[] }, timeEntries: any[]) => {
        const entry = timeEntries.find((x) => x.employeeId === note.employeeId);
        if (entry === undefined) {
            return null;
        }
        return {
            firstName: entry.firstName,
            lastName: entry.lastName,
            note: note.note,
            files: note.files,
        };
    };

    const isImage = (path: string) => {
        const imageExtensions = ['.gif', '.png', '.bmp', '.jpg', '.jpeg', '.webp'];
        const extension = path.substr(path.lastIndexOf('.'));
        return imageExtensions.includes(extension);
    };

    const rows = dataJob?.notes
        .map((x: { employeeId: any; note?: string; files?: string[] }) => noteToRow(x, dataJob?.employeesTimeCard))
        .filter((x: any | null) => x !== null);

    return (
        <>
            <div className="sm:flex justify-between">
                <h3>Employee Notes</h3>
            </div>
            <div className="mt-3">
                <div className="sm:grid sm:grid-cols-5">
                    <h4>EMPLOYEE</h4>
                    <h4 className="col-span-2">NOTES</h4>
                    <h4 className="col-span-2">IMAGES</h4>
                </div>
                {rows &&
                    rows.length > 0 &&
                    rows?.map(
                        (
                            entry: {
                                firstName: string;
                                lastName: string;
                                note: string | null | undefined;
                                files: string[];
                            },
                            index: number
                        ) => (
                            <div
                                key={index}
                                className={`sm:grid sm:grid-cols-5 mt-1 p-1 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                            >
                                <div className="grid content-center">
                                    {formatFullName(entry.firstName, entry.lastName)}
                                </div>
                                <div className="grid content-center col-span-2">
                                    <pre
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            paddingRight: '5px',
                                        }}
                                    >
                                        {entry.note}
                                    </pre>
                                </div>
                                <div className="grid content-center col-span-2">
                                    <div className="flex justify-start flex-wrap gap-1">
                                        {entry.files &&
                                            entry.files.length > 0 &&
                                            entry.files?.map((path: string, index: number) =>
                                                isImage(path) ? (
                                                    <a key={index} href={path} target="_blank" rel="noreferrer">
                                                        <img src={path} alt={path} width="32" height="32" />
                                                    </a>
                                                ) : (
                                                    <a key={index} href={path} target="_blank" rel="noreferrer">
                                                        {path}
                                                    </a>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
            </div>
        </>
    );
};

const CustomerCard = (props: { dataJob: any }) => {
    const { dataJob } = props;

    return (
        <>
            <h3>Customer</h3>
            <div className="mt-3">
                <div className="mb-2 whitespace-normal break-words">
                    <strong>{dataJob?.customerName}</strong>
                </div>
                <div className="whitespace-normal break-words">{dataJob?.address.addressLine1}</div>
                {dataJob?.address.addressLine2 && <div className="whitespace-normal break-words">{dataJob?.address.addressLine2}</div>}
                <div>{dataJob?.address.city}</div>
                <div>{dataJob?.address.state}</div>
                <div>{dataJob?.address.zipCode}</div>
            </div>
        </>
    );
};

const DescriptionCard = (props: { dataJob: any }) => {
    const { dataJob } = props;

    return (
        <>
            <h3>Description</h3>
            <div className="mt-3">
                <div className="whitespace-normal break-words">{dataJob?.description}</div>
                {dataJob?.files && dataJob?.files.length > 0 && (
                    <div className="mt-2">
                        <strong>Files</strong>
                        <div className="whitespace-normal break-words mt-1">
                            {dataJob?.files?.map((fileObject: any, index: any) => (
                                <a key={index} href={fileObject} target="_blank" rel="noreferrer">
                                    <div className="badge badge_outlined badge_secondary ml-1 mb-1">
                                        <i className="las la-file mr-1"></i>
                                        {getFileNameFromAzureURI(fileObject)}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                )}
                {dataJob?.jobLink && (
                    <div className="flex mt-2 gap-2">
                        <a href={dataJob?.jobLink} target="_blank" rel="noreferrer">
                            <span className="las la-link mr-1"></span>
                            View on Marketing Site
                        </a>
                        <button
                            className="hover:text-primary"
                            title="Copy link to clipboard"
                            onClick={() => {
                                navigator.clipboard.writeText(dataJob?.jobLink);
                                toast.info("Copied!");
                            }}>
                            <span className="las la-copy"></span>
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

const DetailsCard = (props: { dataJob: any }) => {
    const { dataJob } = props;

    return (
        <>
            <h3>Details</h3>
            <div className="mt-3">
                <div className="sm:flex justify-between bg-gray-100">
                    <div>Job Date</div>
                    <div>
                        <strong>{dataJob?.jobDate}</strong>
                    </div>
                </div>
                <div className="sm:flex justify-between">
                    <div>Window of Arrival</div>
                    <div className="text-right whitespace-normal break-words">
                        <strong>
                            {dataJob?.windowOfArrivalStart}
                            {'\u00a0-\u00a0'}
                            {dataJob?.windowOfArrivalEnd}
                        </strong>
                    </div>
                </div>
                <div className="sm:flex justify-between bg-gray-100">
                    <div>Job Duration (planned)</div>
                    <div>
                        <strong>{dataJob?.duration}</strong>
                    </div>
                </div>
                <div className="sm:flex justify-between">
                    <div>Time In - Time Out</div>
                    <div className="text-right whitespace-normal break-words">
                        <strong>
                            {dataJob?.timeIn && (
                                <div>
                                    {dataJob?.timeIn}
                                    {'\u00a0-\u00a0'}
                                    {dataJob?.timeOut === '' ? 'Not Yet' : dataJob?.timeOut}
                                </div>
                            )}
                        </strong>
                    </div>
                </div>
                <div className="sm:flex justify-between bg-gray-100">
                    <div>Job Duration</div>
                    <div>
                        <strong>{dataJob?.runTime}</strong>
                    </div>
                </div>
            </div>
        </>
    );
};

const TimeCard = (props: TimeCardProps) => {
    const { jobId, jobDate, employeesTimeCard, onLoadingUpdateJobTimeEntry, onEditingEntry } = props;

    const { tenant } = useAuth();

    const [idEditingEntry, setIdEditingEntry] = useState<string | undefined>();

    const _onEditingEntry = useCallback(
        (entryId?: string) => {
            setIdEditingEntry(entryId);
            onEditingEntry(entryId);
        },
        [onEditingEntry]
    );

    const getTimeToValidate = (entries: any[], currentEntry: any, validateBetweenTimeGroups: boolean) => {
        var entryToValidateInSameGroup = null;
        var entryInOtherClockGroup = null;

        const timeEntries = entries
            ?.filter((x) => x.employeeId === currentEntry.employeeId)
            .sort((a, b) => sortByDate(a.timeStamp, b.timeStamp));

        if (currentEntry.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']]) {
            entryInOtherClockGroup = timeEntries!
                .sort((a, b) => sortByDateDesc(a.timeStamp, b.timeStamp))
                .find(
                    (c) =>
                        c.jobTimekeepingEntryId !== currentEntry.jobTimekeepingEntryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                        new Date(c.timeStamp) < new Date(currentEntry.timeStamp)
                );

            entryToValidateInSameGroup = timeEntries!
                .sort((a, b) => sortByDate(a.timeStamp, b.timeStamp))
                .find(
                    (c) =>
                        c.jobTimekeepingEntryId !== currentEntry.jobTimekeepingEntryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                        new Date(c.timeStamp) > new Date(currentEntry.timeStamp)
                );
        }

        if (currentEntry.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']]) {
            entryToValidateInSameGroup = timeEntries!
                .sort((a, b) => sortByDateDesc(a.timeStamp, b.timeStamp))
                .find(
                    (c) =>
                        c.jobTimekeepingEntryId !== currentEntry.jobTimekeepingEntryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                        new Date(c.timeStamp) < new Date(currentEntry.timeStamp)
                );

            entryInOtherClockGroup = timeEntries!
                .sort((a, b) => sortByDate(a.timeStamp, b.timeStamp))
                .find(
                    (c) =>
                        c.jobTimekeepingEntryId !== currentEntry.jobTimekeepingEntryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                        new Date(c.timeStamp) > new Date(currentEntry.timeStamp)
                );
        }

        if (validateBetweenTimeGroups === true) {
            return entryInOtherClockGroup?.timeStamp;
        }

        if (validateBetweenTimeGroups === false) {
            return entryToValidateInSameGroup?.timeStamp;
        }
    };

    return (
        <>
            <div className="sm:flex justify-between">
                <h3>Timecards</h3>
                {employeesTimeCard && employeesTimeCard?.length > 0 && (
                    <JobTimeEntryModal
                        jobId={jobId}
                        jobDate={format(new Date(jobDate || 0), 'yyyy-MM-dd')}
                        jobEmployees={employeesTimeCard}
                    />
                )}
            </div>
            <div className="mt-3">
                <div className="sm:grid sm:grid-cols-5">
                    <h4>EMPLOYEE</h4>
                    <h4>EVENT</h4>
                    <h4>DATE</h4>
                    <h4 className="col-span-2">TIME</h4>
                </div>
                {employeesTimeCard
                    ?.sort(function (a, b) {
                        return (
                            sortByDate(a.timeStamp, b.timeStamp) ||
                            formatFullName(a.firstName, a.lastName).localeCompare(
                                formatFullName(b.firstName, b.lastName)
                            )
                        );
                    })
                    .map((entry, index, arr) => (
                        <div
                            key={index}
                            className={`sm:grid sm:grid-cols-5 mt-1 p-1 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                        >
                            <div className="grid content-center whitespace-normal break-words">{formatFullName(entry.firstName, entry.lastName)}</div>
                            <div className="flex items-center gap-1 whitespace-normal break-words">
                                {entry.entryType}
                                {entry.autoCreated && <TippyToolTip tooltipText="Auto Time Out" />}
                            </div>
                            <div className="grid content-center">
                                {entry.timeStamp && toTimeZoneDate(entry.timeStamp, tenant.timeZoneId)}
                            </div>
                            <div className="col-span-2 grid content-center">
                                <JobTimeEntryEdit
                                    jobId={jobId}
                                    employeeId={entry.employeeId}
                                    entryId={entry.jobTimekeepingEntryId}
                                    entryTime={entry.timeStamp}
                                    isDeletableEntry={index === arr.length - 1}
                                    autoCreated={entry.autoCreated || false}
                                    timeToValidate={getTimeToValidate(employeesTimeCard, entry, false)}
                                    timeToValidateBetweenTimes={getTimeToValidate(employeesTimeCard, entry, true)}
                                    entryType={entry.entryType}
                                    onLoadingUpdateJobTimeEntry={onLoadingUpdateJobTimeEntry}
                                    onEditingEntry={_onEditingEntry}
                                    idEditingEntry={idEditingEntry}
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};

const InvoiceCard = (props: { dataJob: any }) => {
    const { dataJob } = props;
    const { tenant } = useAuth();

    const { useGetInvoicesLink } = useJobs();

    const {
        data: dataInvoicesLink,
        isError: isErrorInvoicesLink,
        error: errorInvoicesLink,
        isFetching: isFetchingInvoicesLink,
    } = useGetInvoicesLink(dataJob?.id);

    useEffect(() => {
        if (isErrorInvoicesLink) {
            const errors = (errorInvoicesLink?.errors.GeneralErrors as string[])?.map((error) => { return error }).join("; ");

            toast.error(`An error occured: ${errors}`, {
                autoClose: 4000,
            });
        }
    }, [isErrorInvoicesLink, errorInvoicesLink]);

    return (
        <>
            <div className="sm:flex justify-between">
                <h3>Stripe Invoice</h3>
                {tenant.hasStripeAccountConnected && !dataJob?.stripeInvoiceId && (
                    <InvoiceModal jobId={dataJob?.id} />
                )}
            </div>
            {!tenant.hasStripeAccountConnected && (
                <div className="text-center">
                    <span className="las la-unlink text-4xl text-danger"></span>
                    <p className="my-2">Your account is not connected, please go to:</p>
                    <Link to="/settings" className="flex items-center text-normal hover:text-primary">
                        <span className="la la-user-circle text-2xl leading-none ml-auto"></span>
                        <span className="title ml-1 mr-auto">Settings</span>
                    </Link>
                </div>
            )}
            <Loading isLoading={isFetchingInvoicesLink} boxStyle />
            {dataInvoicesLink && dataInvoicesLink.length > 0 && !isFetchingInvoicesLink && (
                <div className="mt-3">
                    <div className="sm:grid sm:grid-cols-6">
                        <h4 className="col-span-2">CREATED</h4>
                        <h4>TOTAL</h4>
                        <h4 className="col-span-2">STATUS</h4>
                    </div>
                    {dataInvoicesLink.map((invoice, index) => (
                        <div
                            key={index}
                            className={`sm:grid sm:grid-cols-6 mt-1 p-1 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                        >
                            <div className="grid content-center col-span-2">
                                {invoice.created && toTimeZoneDate(invoice.created, tenant.timeZoneId)}
                                <br />
                                {invoice.created && toTimeZoneTime(invoice.created, tenant.timeZoneId, true)}
                            </div>
                            <div className="grid content-center">{invoice.total && toMoney(invoice.total)}</div>
                            <div className="grid content-center col-span-2">
                                <p
                                    className={`uppercase ${invoice.status?.toLowerCase() === 'void' ? 'text-danger' : ''}`}
                                >
                                    {invoice.status}
                                </p>
                                {invoice.dueDate && invoice.status?.toLowerCase() === 'open' && (
                                    `Due Date: ${toTimeZoneDate(invoice.dueDate, tenant.timeZoneId)}`
                                )}
                            </div>
                            <div className="grid content-center text-center">
                                <div className="flex">
                                    {invoice?.url && (
                                        <a
                                            className="btn btn-icon icon-small btn_outlined btn_secondary mr-1"
                                            href={invoice?.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="View Invoice"
                                        >
                                            <span className="las la-eye"></span>
                                        </a>
                                    )}
                                    {invoice?.status?.toLowerCase() === 'open' && (
                                        <SendInvoiceModal stripeInvoiceId={dataJob?.stripeInvoiceId} />
                                    )}
                                    {dataJob?.stripeInvoiceId === invoice?.id && (
                                        <CancelInvoiceModal jobId={dataJob?.id} />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

const JobInfoModal = (props: { jobId?: string }) => {
    const { jobId } = props;
    const { tenant } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [isLoadingUpdateJobTimeEntry, setIsLoadingUpdateJobTimeEntry] = useState(false);
    const [isEditingEntry, setIsEditingEntry] = useState(false);

    const { useGetJob } = useJobs();
    const { data: dataJob, isFetching } = useGetJob(jobId || '', showModal, jobActionEnum.Info);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        toggler();
    }, [toggler]);

    const onLoadingUpdateJobTimeEntry = useCallback((value: boolean) => {
        setIsLoadingUpdateJobTimeEntry(value);
    }, []);

    const onEditingEntry = useCallback((entryId?: string) => {
        setIsEditingEntry(!!entryId);
    }, []);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Job Info">
                <span className="las la-info"></span>
            </button>
            <Modal
                isOpen={showModal}
                toggler={resetForm}
                isStatic={true}
                size="full"
                isDisabled={isLoadingUpdateJobTimeEntry}
            >
                <ModalHeader toggler={resetForm} closeButtonDisabled={!!isEditingEntry || isLoadingUpdateJobTimeEntry}>
                    <div className="flex items-center gap-2">
                        <div>{dataJob?.title}</div>
                        <div>
                            <JobStatusBadge status={dataJob?.status} fit={true} />
                        </div>
                        {dataJob?.isOverTime && (
                            <div>
                                <JobOverTimeBadge />
                            </div>
                        )}
                    </div>
                </ModalHeader>
                <ModalBody bgColor="bg-gray-100">
                    <Loading isLoading={isFetching} boxStyle />
                    <div className="grid p-5 gap-5">
                        <div className="grid lg:grid-cols-8 gap-5">
                            <div className="card col-span-2 p-3">
                                <CustomerCard dataJob={dataJob} />
                            </div>
                            <div className="card col-span-3 p-3">
                                <DescriptionCard dataJob={dataJob} />
                            </div>
                            <div className="card col-span-3 p-3">
                                <DetailsCard dataJob={dataJob} />
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 gap-5">
                            <div className="card p-3">
                                <TimeCard
                                    employeesTimeCard={dataJob?.employeesTimeCard}
                                    jobId={jobId!}
                                    jobDate={dataJob?.jobDate}
                                    onEditingEntry={onEditingEntry}
                                    onLoadingUpdateJobTimeEntry={onLoadingUpdateJobTimeEntry}
                                />
                            </div>
                            <div className="card p-3">
                                <EmployeeNotes dataJob={dataJob} />
                            </div>
                        </div>
                        {tenant.hasPaymentsFeature && dataJob?.status === jobStatusEnum[jobStatusEnum.Completed] && (
                            <div className="grid lg:grid-cols-2 gap-5">
                                <div className="card p-3">
                                    <InvoiceCard dataJob={dataJob} />
                                </div>
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={!!isEditingEntry || isLoadingUpdateJobTimeEntry}
                        >
                            Close
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default JobInfoModal;
