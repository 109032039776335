import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';
import format from 'date-fns/format';
import { jobTimekeepingEntryTypeEnum } from '../utilities/staticData';

import { useAuth } from '../hooks/useAuth';
import { useUsers } from '../hooks/useUsers';
import { formatDateTime, toTimeZoneDate, toTimeZoneTime } from '../utilities/formatter';
import TippyToolTip from './TippyToolTip';

interface JobTimeCardProps {
    jobId: string;
    employeeId: string;
    entryId: string;
    entryTime: Date;
    entryTypeName?: string;
    isDeletableEntry?: boolean;
    status?: string;
    onLoadingUpdateJobTimeEntry: (value: boolean) => void;
    onEditingEntry: (entryId?: string) => void;
    idEditingEntry?: string;
    narrowMode?: boolean;
    entryType?: string;
    autoCreated: boolean;
    timeToValidate?: Date;
    timeToValidateBetweenTimes?: Date;
}

const JobTimeEntryEdit = (props: JobTimeCardProps) => {
    const {
        jobId,
        employeeId,
        entryId,
        entryTime,
        entryTypeName,
        isDeletableEntry,
        onLoadingUpdateJobTimeEntry,
        onEditingEntry,
        idEditingEntry,
        narrowMode,
        entryType,
        autoCreated,
        timeToValidate,
        timeToValidateBetweenTimes,
    } = props;
    const { tenant } = useAuth();

    const [timeValidationError, setTimeValidationError] = useState('');
    const [value, setValue] = useState('00:00:00');

    const { useUpdateJobTimeEntry, useDeleteJobTimeEntry } = useUsers();
    const {
        isLoading: isLoadingUpdateJobTimeEntry,
        isSuccess: isSuccessJobTimeEntryUpdate,
        update: updateJobTimeEntry,
        error: { errors: updateJobTimeEntryServerErrors },
        reset: resetJobTimeEntryServerErrors,
    } = useUpdateJobTimeEntry();

    const {
        isLoading: isLoadingDeleteJobTimeEntry,
        isSuccess: isSuccessJobTimeEntryDelete,
        update: deleteJobTimeEntry,
        error: { errors: deleteJobTimeEntryServerErrors },
    } = useDeleteJobTimeEntry();

    const onTimeEdit = () => {
        onEditingEntry(entryId);
        setValue(toTimeZoneTime(entryTime, tenant.timeZoneId));
    };

    const onTimeEditCancel = useCallback(() => {
        onEditingEntry(undefined);
        setValue('00:00:00');
        setTimeValidationError('');
        resetJobTimeEntryServerErrors();
    }, [onEditingEntry, resetJobTimeEntryServerErrors]);

    const onTimeEditSave = () => {
        const dateTimeZone = toTimeZoneDate(entryTime, tenant.timeZoneId);
        const dateOnly = format(new Date(dateTimeZone), 'yyyy-MM-dd');
        const dateTime = formatDateTime(dateOnly, value);
        const utcDate = zonedTimeToUtc(dateTime, tenant.timeZoneId);

        if (timeToValidate) {
            const utcDateToValidate = new Date(timeToValidate);
            if (
                entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                utcDate >= utcDateToValidate
            ) {
                setTimeValidationError('Must be before Time Out');
                return;
            }

            if (
                entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                utcDate <= utcDateToValidate
            ) {
                setTimeValidationError('Must be after Time In');
                return;
            }
        }

        if (timeToValidateBetweenTimes) {
            const utcDateToValidateInOtherTime = new Date(timeToValidateBetweenTimes);
            if (
                entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                utcDate <= utcDateToValidateInOtherTime
            ) {
                setTimeValidationError('Must be after the previous Time Out');
                return;
            }

            if (
                entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                utcDate >= utcDateToValidateInOtherTime
            ) {
                setTimeValidationError('Must be before the next Time In');
                return;
            }
        }

        updateJobTimeEntry({
            jobId: jobId,
            employeeId: employeeId,
            jobEntryId: entryId,
            entryDateTime: utcDate,
        });
    };

    const onJobTimeDelete = () => {
        const utcDate = zonedTimeToUtc(entryTime, tenant.timeZoneId);

        deleteJobTimeEntry({
            jobId: jobId,
            employeeId: employeeId,
            entryId: entryId,
            entryDateTime: utcDate,
        });
    };

    const onTimeChange = (event: any) => {
        setValue(event.target.value);
        setTimeValidationError('');
        resetJobTimeEntryServerErrors();
    };

    useEffect(() => {
        if (isSuccessJobTimeEntryUpdate) {
            onTimeEditCancel();
            toast.success('Job Time Entry Updated Successfully');
        }
    }, [isSuccessJobTimeEntryUpdate, onTimeEditCancel]);

    useEffect(() => {
        onLoadingUpdateJobTimeEntry(isLoadingUpdateJobTimeEntry);
    }, [isLoadingUpdateJobTimeEntry, onLoadingUpdateJobTimeEntry]);

    useEffect(() => {
        if (isSuccessJobTimeEntryDelete) {
            onTimeEditCancel();
            toast.success('Job Time Entry Deleted Successfully');
        }
    }, [isSuccessJobTimeEntryDelete, onTimeEditCancel]);

    useEffect(() => {
        onLoadingUpdateJobTimeEntry(isLoadingDeleteJobTimeEntry);
    }, [isLoadingDeleteJobTimeEntry, onLoadingUpdateJobTimeEntry]);

    useEffect(() => {
            if (deleteJobTimeEntryServerErrors) {
                onTimeEditCancel();
                var errors = 'Job Time Entry Delete Failed \n';
                errors += deleteJobTimeEntryServerErrors.GeneralErrors?.join("\n ");
                toast.error(errors);
            }
    }, [deleteJobTimeEntryServerErrors, onTimeEditCancel]);

    if (narrowMode) {
        return (
            <>
                <div
                    className={`flex flex-wrap gap-0.5 items-center text-xs ${
                        idEditingEntry === entryId ? 'justify-center border-2 border-primary p-1' : 'justify-end'
                    }`}
                    style={{ minWidth: '115px' }}
                >
                    {autoCreated && <TippyToolTip tooltipText="Auto Time Out" />}
                    {entryTypeName}
                    {entryTime && idEditingEntry !== entryId && toTimeZoneTime(entryTime, tenant.timeZoneId, true)}
                    {idEditingEntry === entryId && (
                        <input
                            type="time"
                            step="1"
                            className="form-control p-0"
                            onChange={onTimeChange}
                            value={value}
                        />
                    )}
                    {Array.isArray(updateJobTimeEntryServerErrors?.GeneralErrors)
                        ? (updateJobTimeEntryServerErrors?.GeneralErrors as string[])?.map((error, index) => (
                              <small key={index} className="block mt-1 invalid-feedback">
                                  {error}
                              </small>
                          ))
                        : null}
                    {timeValidationError && (
                        <small className="block mt-1 invalid-feedback">{timeValidationError}</small>
                    )}
                    {entryTime && (
                        <button
                            className="hover:text-primary"
                            title="Override Time"
                            onClick={() => onTimeEdit()}
                            disabled={!!idEditingEntry}
                            hidden={idEditingEntry === entryId}
                        >
                            <span className="la la-pen-fancy"></span>
                        </button>
                    )}
                    {idEditingEntry === entryId && (
                        <>
                            <div
                                className="w-full flex flex-row items-center justify-center mx-1">
                                {isDeletableEntry &&
                                    <div className="w-full flex">
                                        <button
                                            className="btn btn-icon icon-small btn_outlined btn_danger"
                                            title="Delete Time"
                                            onClick={() => onJobTimeDelete()}
                                            disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                        >
                                            <span className="la la-trash"></span>
                                        </button>
                                    </div>
                                }
                                <div className="flex">
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_secondary mx-1"
                                        title="Cancel"
                                        onClick={onTimeEditCancel}
                                        disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                    >
                                        <span className="la la-times"></span>
                                    </button>
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_primary"
                                        title="Save Time"
                                        onClick={() => onTimeEditSave()}
                                        disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                    >
                                        <span className="la la-save"></span>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="sm:grid sm:grid-cols-4 content-center">
                <div className="col-span-2 grid content-center">
                    {entryTypeName}
                    {entryTime && idEditingEntry !== entryId && toTimeZoneTime(entryTime, tenant.timeZoneId, true)}
                    {idEditingEntry === entryId && (
                        <input type="time" step="1" className="form-control" onChange={onTimeChange} value={value} />
                    )}
                    {Array.isArray(updateJobTimeEntryServerErrors?.GeneralErrors)
                        ? (updateJobTimeEntryServerErrors?.GeneralErrors as string[])?.map((error, index) => (
                              <small key={index} className="block mt-1 invalid-feedback">
                                  {error}
                              </small>
                          ))
                        : null}
                    {timeValidationError && (
                        <small className="block mt-1 invalid-feedback">{timeValidationError}</small>
                    )}
                </div>
                <div className="grid content-center text-center col-span-2 mx-1">
                    {entryTime && !idEditingEntry && (
                        <button
                            className="btn btn-icon icon-small btn_outlined btn_secondary ml-2 mt-1 mr-1"
                            title="Override Time"
                            onClick={() => onTimeEdit()}
                        >
                            <span className="la la-pen-fancy"></span>
                        </button>
                    )}
                    {idEditingEntry === entryId && (
                        <>
                            <div
                                className="w-full flex flex-row items-center justify-center">
                                <div className="w-full flex">
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_secondary mx-1"
                                        title="Cancel"
                                        onClick={onTimeEditCancel}
                                        disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                    >
                                        <span className="la la-times"></span>
                                    </button>
                                    <button
                                        className="btn btn-icon icon-small btn_outlined btn_primary"
                                        title="Save Time"
                                        onClick={() => onTimeEditSave()}
                                        disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                    >
                                        <span className="la la-save"></span>
                                    </button>
                                </div>
                                {isDeletableEntry &&
                                    <div className="flex ml-2">
                                        <button
                                            className="btn btn-icon icon-small btn_outlined btn_danger"
                                            title="Delete Time"
                                            onClick={() => onJobTimeDelete()}
                                            disabled={isLoadingDeleteJobTimeEntry || isLoadingUpdateJobTimeEntry}
                                        >
                                            <span className="la la-trash"></span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default JobTimeEntryEdit;
