import React from 'react';
import Tippy from '@tippyjs/react';

import { useMenu } from '../hooks/useMenu';
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import logo from '../images/logo.png';

const UserMenu = () => {
    const { logout, user, unsetTenant } = useAuth();

    return (
        <>
            <div className="custom-dropdown-menu">
                <div className="p-5">
                    <h5 className="uppercase">{user.name}</h5>
                    <label>{user.email}</label>
                </div>
                <hr />
                <div className="p-5">
                    <Link to="/settings" className="flex items-center text-normal hover:text-primary">
                        <span className="la la-user-circle text-2xl leading-none mr-2"></span>
                        <span className="title">Settings</span>
                    </Link>
                </div>
                <hr />
                <div className="p-5">
                    {(user.tenantsNumber > 1 || user.isSuperAdmin) && (
                        <button
                            className="flex items-center text-normal hover:text-primary mb-3"
                            onClick={() => unsetTenant()}
                        >
                            <span className="la la-sign-in-alt text-2xl leading-none mr-2"></span>
                            Switch Tenant
                        </button>
                    )}
                    <button className="flex items-center text-normal hover:text-primary" onClick={() => logout()}>
                        <span className="la la-power-off text-2xl leading-none mr-2"></span>
                        Logout
                    </button>
                </div>
            </div>
        </>
    );
};

const Topbar = () => {
    const { user, tenant } = useAuth();
    const { toggleMenuBar } = useMenu();

    return (
        <>
            <header className="top-bar">
                <button type="button" className="menu-toggler la la-bars" onClick={toggleMenuBar}></button>
                <img className="object-scale-down w-56" src={logo} alt="" />
                <div className="flex items-center ml-auto">
                    <h5 className="brand">{tenant.name}</h5>
                    <Tippy
                        interactive={true}
                        arrow={true}
                        theme="light-border"
                        placement="bottom-end"
                        trigger="click"
                        content={<UserMenu />}
                    >
                        <button className="flex items-center ml-4">
                            <Avatar fullName={user.name || ''} imageURL={user.userProfilePicture} />
                        </button>
                    </Tippy>
                </div>
            </header>
        </>
    );
};

export default Topbar;
