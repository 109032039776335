import React, { useState, useEffect } from 'react';
import { FieldError } from 'react-hook-form';
import Tippy from '@tippyjs/react';
import { useAddressSearch } from '../hooks/useAddressSearch';
import classNames from 'classnames';
import Loading from './Loading';

interface InputAddressFuzySearchProps {
    label: string;
    register: {
        onChange: (event: any) => void;
        value?: any;
        ref: (instance: any) => void;
        name: string;
    };
    onChange: (value?: any) => void;
    field: {
        onChange: (event: any) => void;
        onBlur: () => void;
        value?: any;
        ref: (instance: any) => void;
        name: string;
    };
    clientErrors?: FieldError;
    serverErrors?: string[];
}

const InputAddressFuzySearch = (props: InputAddressFuzySearchProps) => {
    const { label, register, onChange, field, clientErrors, serverErrors } = props;
    const [search, setSearch] = useState(false);

    const { useFuzzySearch } = useAddressSearch();
    const { data: fuzzyResults, isSuccess: isSuccessFuzzySearch, isFetching: isFetchingFuzzySearch } = useFuzzySearch(register.value || '', search);
    const [showOptions, setShowOptions] = useState(false);

    const { onBlur: addressLine1FieldOnBlur } = field;

    const startSearch = () => {
        setSearch(true);
    };

    const stopSearch = () => {
        addressLine1FieldOnBlur();
        setSearch(false);
        setTimeout(() => setShowOptions(false), 200);
    };

    useEffect(() => {
        if (isSuccessFuzzySearch && search) {
            setShowOptions(!!fuzzyResults?.length);
        }
    }, [isSuccessFuzzySearch, fuzzyResults, search]);

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <div className="search-select">
                <Tippy
                    offset={[0, 8]}
                    interactive={true}
                    arrow={false}
                    theme="light-border"
                    placement="bottom-start"
                    maxWidth="none"
                    animation="shift-toward-extreme"
                    visible={showOptions}
                    content={
                        <div className="search-select-menu">
                            <Loading isLoading={isFetchingFuzzySearch} boxStyle />
                            {fuzzyResults?.map((result, index) => (
                                <div key={index} className="item" onClick={() => onChange(result)}>
                                    {result.address.freeformAddress}
                                </div>
                            ))}
                        </div>
                    }
                >
                    <input
                        type="text"
                        className={classNames('form-control', {
                            'is-invalid': !!clientErrors || !!serverErrors,
                        })}
                        {...register}
                        onBlur={stopSearch}
                        onFocus={startSearch}
                    />
                </Tippy>
            </div>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error: string, index: number) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export default InputAddressFuzySearch;
