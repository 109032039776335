import React from 'react';
import classNames from 'classnames';
import { useMenu } from '../hooks/useMenu';

const Sidebar = ({ children }: { children?: React.ReactNode }) => {
    const { isSidebarOpened, toggleSidebar } = useMenu();

    const sidebarClasses = {
        open: isSidebarOpened,
    };

    return (
        <>
            <aside className={classNames('sidebar', sidebarClasses)}>
                <button className="sidebar-toggler la la-ellipsis-v" onClick={toggleSidebar}></button>
                {children}
            </aside>
        </>
    );
};

export default Sidebar;
