import classNames from 'classnames';
import React from 'react';
import { getInitialsFromFullName } from '../utilities/formatter';

interface AvatarProps {
    fullName: string;
    imageURL?: string;
    showDangerStatus?: boolean;
    size?: string;
}

const Avatar = (props: AvatarProps) => {
    const { fullName, imageURL, showDangerStatus, size } = props;

    const avatarClass = {
        'avatar': true,
        'w-8 h-8': size === 'sm',
        'w-12 h-12': !size || size === 'md',
        'w-16 h-16': size === 'lg',
    };

    const initialsClass = {
        'text-sm': size === 'sm',
        'text-md': !size || size === 'md',
        'text-2xl': size === 'lg',
    };

    return (
        <>
            <div className={classNames(avatarClass)}>
                {showDangerStatus && <div className="status bg-danger"></div>}
                {!imageURL && <span className={classNames(initialsClass)}>{getInitialsFromFullName(fullName)}</span>}
                {imageURL && <img src={imageURL} alt={fullName} style={{ objectFit: 'fill' }} loading="lazy"></img>}
            </div>
        </>
    );
};

export default Avatar;
