// A simple library to manage storage

const getStore = (type: string) => {
    switch (type) {
        case 'localStorage':
            return localStorage;
        case 'sessionStorage':
            return sessionStorage;
        default:
            return localStorage;
    }
};

export const storage = <T extends unknown>(key: string, storageType: string = 'localStorage') => {
    const store = getStore(storageType);

    const get = (): T | undefined => {
        const value = store.getItem(key) || '';

        if (!value) {
            return;
        }

        try {
            const deserialized: T = JSON.parse(value);

            return deserialized;
        } catch {
            return;
        }
    };

    const set = (data: T) => {
        const serialized = JSON.stringify(data);

        store.setItem(key, serialized);
    };

    const remove = () => {
        store.removeItem(key);
    };

    return {
        get,
        set,
        remove,
    };
};
