import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { zonedTimeToUtc } from 'date-fns-tz';

import { FormInputAutocompleteControl, FormInputControl, FormSelectControl } from '../components/FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import { useUsers } from '../hooks/useUsers';
import { jobEntryTypeObject, jobTimekeepingEntryTypeEnum } from '../utilities/staticData';
import { formatDateTime, formatFullName } from '../utilities/formatter';
import { useAuth } from '../hooks/useAuth';

interface FormInput {
    employee?: string;
    time?: string;
    entryType?: string;
}

const schema = yup
    .object({
        employee: yup.string().required('Employee is required.'),
        time: yup.string().required('Time is required.'),
        entryType: yup.string().required('Entry type is required.'),
    })
    .required();

const JobTimeEntryModal = (props: { jobId: string; jobDate: string; jobEmployees: any[] }) => {
    const { jobId, jobDate, jobEmployees } = props;

    const { tenant } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [entryTypeSelected, setEntryTypeSelected] = useState('');
    const [employeeSelected, setEmployeeSelected] = useState('');

    const { usePostJobTimeEntry } = useUsers();
    const {
        isLoading: isLoadingPostJobTimeEntry,
        isSuccess: isSuccessPostJobTimeEntry,
        create: createPostJobTimeEntry,
        reset: resetServerPostJobTimeEntry,
        error: { errors: serverErrorsPostJobTimeEntry },
    } = usePostJobTimeEntry();

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
    } = useForm<FormInput>({ resolver: yupResolver(schema) });

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const entryType = jobEntryTypeObject.map((option) => {
        return {
            value: option.value,
            text: option.name,
        };
    });

    const employees = jobEmployees
        .map((option) => {
            return {
                value: option.employeeId,
                text: formatFullName(option.firstName, option.lastName),
            };
        })
        .sort((a, b) => a.value.localeCompare(b.value))
        .filter((item, index, objects) => {
            if (index === 0) {
                return item;
            } else if (item.value !== objects[index - 1].value) {
                return item;
            } else {
                return false;
            }
        });

    const resetForm = useCallback(() => {
        resetServerPostJobTimeEntry();
        resetClient();
        toggler();
        setEntryTypeSelected('');
        setEmployeeSelected('');
    }, [resetServerPostJobTimeEntry, resetClient, toggler]);

    const onSave = (data: FormInput) => {
        const dateTime = formatDateTime(jobDate, data.time);
        const utcDate = zonedTimeToUtc(dateTime, tenant.timeZoneId);

        createPostJobTimeEntry({
            jobId: jobId,
            employeeId: employeeSelected,
            entryType: Number(data.entryType),
            entryDateTime: utcDate,
        });
    };

    const onEntryTypeSelected = (option?: string) => {
        setEntryTypeSelected(option!);
    };

    const onEmployeeSelected = (option?: string) => {
        setEmployeeSelected(option!);
    };

    useEffect(() => {
        if (isSuccessPostJobTimeEntry) {
            resetForm();
            toast.success(`${jobTimekeepingEntryTypeEnum[Number(entryTypeSelected)]} Created Successfully`);
        }
    }, [isSuccessPostJobTimeEntry, resetForm, entryTypeSelected]);
    return (
        <>
            <button className="btn btn_primary uppercase h-8" onClick={toggler}>
                Add New
            </button>
            <Modal isOpen={showModal} toggler={resetForm} size="lg" isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoadingPostJobTimeEntry}>
                    Add Job Time Entry
                </ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormSelectControl
                                label="Entry Type"
                                hideEmpty={true}
                                register={register('entryType')}
                                options={entryType}
                                clientErrors={clientErrors?.entryType}
                                serverErrors={serverErrorsPostJobTimeEntry?.entryType}
                                onChange={onEntryTypeSelected}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputAutocompleteControl
                                label="Employee"
                                register={register('employee')}
                                options={employees}
                                onChange={onEmployeeSelected}
                                clientErrors={clientErrors?.employee}
                                serverErrors={serverErrorsPostJobTimeEntry?.employeeId}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <label className="label block mb-2">Date</label>
                            <input
                                className="form-control opacity-75 cursor-not-allowed"
                                type="date"
                                value={jobDate}
                                readOnly
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="time"
                                step="1"
                                label="Time"
                                register={register('time')}
                                clientErrors={clientErrors?.time}
                                serverErrors={serverErrorsPostJobTimeEntry?.time}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoadingPostJobTimeEntry}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSave)}
                            disabled={isLoadingPostJobTimeEntry}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default JobTimeEntryModal;
