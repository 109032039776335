import { useCallback, useEffect, useState } from 'react';
import { useForm, FieldError, useController } from 'react-hook-form';
import { toast } from 'react-toastify';
import { guid } from '@fullcalendar/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import { ItemInvoice } from '../utilities/interfaces';
import { FormInputControl, FormNumberControl } from './FormControls';
import { useJobs } from '../hooks/useJobs';

interface FormInput {
    daysUntilDue: number;
    items?: ItemInvoice[];
}

const invoiceSchema: yup.SchemaOf<FormInput> = yup.object({
    daysUntilDue: yup
        .number()
        .typeError('Days Until Due is required.')
        .integer('Days Until Due must be an integer number.')
        .min(0, 'Days Until Due must be zero or greater.')
        .required('Days Until Due is required.'),
    items: yup.array().required('At least one item is required.').min(1, 'At least one item is required.'),
});

interface ItemModalProps {
    modalTitle: string;
    showModal: boolean;
    toggler: () => void;
    item?: ItemInvoice;
    onChange: (value?: any) => void;
    okButtonName: string;
}

interface ItemsListProps {
    serverErrors?: string[];
    clientErrors?: FieldError;
    initialItems?: ItemInvoice[];
    onChange: (value?: any) => void;
}

const itemSchema: yup.SchemaOf<ItemInvoice> = yup.object({
    id: yup
        .string()
        .optional(),
    description: yup
        .string()
        .required('Description is required.'),
    quantity: yup
        .number()
        .typeError('Qty is required.')
        .integer('Qty must be an integer number.')
        .moreThan(0, 'Qty must be greater than 0.')
        .required('Qty is required.'),
    unitAmountDecimal: yup
        .string()
        .matches(/^(\d+(\.\d{1,2})?)?$/, 'Please enter a valid number with up to 2 decimals.')
        .required('Price is required.'),
});

const ItemModal = (props: ItemModalProps) => {
    const { modalTitle, showModal, toggler, item, onChange, okButtonName } = props;

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        control,
    } = useForm<ItemInvoice>({ resolver: yupResolver(itemSchema) });

    const { field: description } = useController({
        name: 'description',
        control,
        defaultValue: '',
    });

    const { field: unitAmountDecimal } = useController({
        name: 'unitAmountDecimal',
        control,
        defaultValue: '0.00',
    });

    const resetForm = useCallback(() => {
        resetClient();
        toggler();
    }, [resetClient, toggler]);

    const onSubmit = (data: ItemInvoice) => {
        onChange(data);
        resetForm();
    };

    useEffect(() => {
        resetClient(item);
    }, [resetClient, item]);

    return (
        <>
            <Modal isOpen={showModal} toggler={resetForm} size="sm" isStatic={true} isCentered={true}>
                <ModalHeader toggler={resetForm}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-1">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Description"
                                register={description}
                                clientErrors={clientErrors?.description}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormNumberControl
                                label="Qty"
                                min="0"
                                register={register('quantity')}
                                clientErrors={clientErrors?.quantity}
                                options={[
                                    1, 2, 3, 4, 5, 6, 7, 8
                                ]}
                            />
                        </div>
                        <div className="mb-5">
                            <FormNumberControl
                                label="Price"
                                min="0.00"
                                register={unitAmountDecimal}
                                clientErrors={clientErrors?.unitAmountDecimal}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button type="button" className="btn btn_secondary uppercase" onClick={resetForm}>
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {okButtonName}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

const EditItemModal = (props: { item?: ItemInvoice; onChange: (value?: any) => void }) => {
    const { item, onChange } = props;

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Update Item">
                <span className="la la-pen-fancy"></span>
            </button>
            <ItemModal
                modalTitle="Edit Item"
                showModal={showModal}
                toggler={toggler}
                item={item}
                onChange={onChange}
                okButtonName="Ok"
            />
        </>
    );
};

const AddItemModal = (props: { onChange: (value?: any) => void }) => {
    const { onChange } = props;

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    return (
        <>
            <button className="btn btn_primary uppercase h-6" onClick={toggler}>
                Add New
            </button>
            <ItemModal
                modalTitle="Add Item"
                showModal={showModal}
                toggler={toggler}
                onChange={onChange}
                okButtonName="Ok"
            />
        </>
    );
};

const ItemsList = (props: ItemsListProps) => {
    const { serverErrors, clientErrors, onChange } = props;

    const [items, setItems] = useState<ItemInvoice[]>([]);

    const isDuplicated = (item: ItemInvoice) => {
        const exists = items.some((a) =>
            a.description.trim() === item.description.trim()
            && a.id !== item.id
        );

        if (exists) {
            toast.error('Item already exists');
        }

        return exists;
    };

    const applyChanges = (_items: ItemInvoice[]) => {
        setItems(_items);
        onChange(_items);
    };

    const onItemAdded = (result: ItemInvoice) => {
        if (isDuplicated(result)) {
            return;
        }

        result.id = guid();

        const _items = [...(items || []), result];

        applyChanges(_items);
    };

    const onItemUpdated = (result: ItemInvoice) => {
        if (isDuplicated(result)) {
            return;
        }

        var _items = [...items];

        let updated = _items.find((a) => a.id === result.id);

        if (updated !== undefined) {
            updated.description = result.description;
            updated.quantity = result.quantity;
            updated.unitAmountDecimal = result.unitAmountDecimal;
        }

        applyChanges(_items);
    };

    const onItemDeleted = (id: string) => {
        const _items = [...items.filter((b) => b.id !== id)];

        applyChanges(_items);
    };

    return (
        <>
            <div className="sm:flex justify-between items-center">
                <h4>ITEMS</h4>
                <AddItemModal onChange={onItemAdded} />
            </div>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
            {items?.map((entry, index) => (
                <div
                    key={index}
                    className={`sm:flex justify-between items-center mt-2 p-1 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                >
                    <div className="whitespace-normal break-words mr-2">{`${entry.description}`}<strong>{` x ${entry.quantity}`}</strong></div>
                    <div className="ml-auto"><strong>{`$${entry.unitAmountDecimal}`}</strong></div>
                    <div>
                        <EditItemModal item={entry} onChange={onItemUpdated} />
                        <button
                            className="btn btn-icon btn_outlined btn_secondary ml-1"
                            onClick={() => onItemDeleted(entry.id || '')}
                            title="Delete Item"
                        >
                            <span className="las la-trash"></span>
                        </button>
                    </div>
                </div>
            ))}
        </>
    );
};

export const InvoiceModal = (props: { jobId: string }) => {
    const { jobId } = props;
    const [showModal, setShowModal] = useState(false);

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        control,
    } = useForm<FormInput>({ resolver: yupResolver(invoiceSchema) });

    const { field: itemsField } = useController({
        name: 'items',
        control,
        defaultValue: [],
    });

    const { usePostInvoiceJob } = useJobs();
    const {
        isLoading,
        isSuccess,
        createInvoice,
        reset: resetServer,
        error: { errors: serverErrors },
    } = usePostInvoiceJob();

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        resetClient();
        resetServer();
        toggler();
    }, [resetClient, toggler, resetServer]);

    const onSubmit = (data: FormInput) => {
        data.items?.forEach((item) => {
            item.id = undefined;
        });

        createInvoice({
            jobId: jobId,
            daysUntilDue: data.daysUntilDue,
            items: data.items || [],
        });
    };

    const onItemChanged = (result: ItemInvoice[]) => {
        itemsField.onChange(result);
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
        }
    }, [isSuccess, resetForm]);

    useEffect(() => {
        if (isSuccess) {
            resetServer();
            toast.success('Invoice Created Successfully');
        }
    }, [isSuccess, resetServer]);

    return (
        <>
            <button
                type="button"
                className="btn btn_primary uppercase h-8"
                onClick={toggler}
            >
                <span className="las la-file-invoice text-xl leading-none mr-2"></span>
                Create Invoice
            </button>
            <Modal isOpen={showModal} toggler={resetForm} size="md" isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoading}>
                    Create Invoice
                </ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-1">
                        <div className="mb-5">
                            <p className="whitespace-normal break-words">The invoice will be created in the name of the customer and will be sent to their email,
                                the Job address will be included.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1">
                        <div className="mb-5">
                            <ItemsList
                                clientErrors={clientErrors?.items as unknown as FieldError}
                                serverErrors={serverErrors?.items}
                                onChange={onItemChanged}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3">
                        <div>
                            <FormNumberControl
                                label="Days Until Due"
                                min="0"
                                register={register('daysUntilDue')}
                                clientErrors={clientErrors?.daysUntilDue}
                                serverErrors={serverErrors?.daysUntilDue}
                                options={[
                                    0, 1, 7, 14, 30, 45, 60, 90
                                ]}
                            />
                        </div>
                    </div>
                    {Array.isArray(serverErrors?.GeneralErrors)
                        ? (serverErrors?.GeneralErrors as string[])?.map((error, index) => (
                            <div className="grid grid-cols-1">
                                <div className="mt-5">
                                    <small key={index} className="block mt-1 invalid-feedback">
                                        {error}
                                    </small>
                                </div>
                            </div>
                        ))
                        : null}
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const SendInvoiceModal = (props: { stripeInvoiceId: string }) => {
    const { stripeInvoiceId } = props;

    const { usePostSendInvoice } = useJobs();

    const {
        isLoading,
        isSuccess,
        sendInvoice,
        reset,
        isError,
        error,
    } = usePostSendInvoice();

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        reset();
        toggler();
    }, [reset, toggler]);

    const onSend = () => {
        sendInvoice({
            stripeInvoiceId: stripeInvoiceId,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Invoice Sent Successfully');
        }
    }, [isSuccess, resetForm]);

    useEffect(() => {
        if (isError) {
            const errors = (error?.errors.GeneralErrors as string[])?.map((error) => { return error }).join("; ");

            toast.error(`Invoice could not be sent. ${errors}`, {
                autoClose: 4000,
            });
        }
    }, [isError, error]);

    return (
        <>
            <button
                type="button"
                className="btn btn-icon btn_outlined btn_secondary mr-1"
                title="Send Invoice"
                onClick={toggler}
            >
                <span className="las la-paper-plane"></span>
            </button>
            <Modal isOpen={showModal} toggler={resetForm} isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoading}>
                    Send Invoice
                </ModalHeader>
                <ModalBody>
                    <div>
                        <h3>Are you sure you want to send invoice?</h3>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={onSend}
                            disabled={isLoading}
                        >
                            Send
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const CancelInvoiceModal = (props: { jobId: string }) => {
    const { jobId } = props;

    const { useCancelInvoice } = useJobs();

    const {
        isLoading,
        isSuccess,
        cancelInvoice,
        reset,
        isError,
        error,
    } = useCancelInvoice();

    const [showModal, setShowModal] = useState(false);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const resetForm = useCallback(() => {
        reset();
        toggler();
    }, [reset, toggler]);

    const onCancel = () => {
        cancelInvoice({
            jobId: jobId,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Invoice Cancelled Successfully');
        }
    }, [isSuccess, resetForm]);

    useEffect(() => {
        if (isError) {
            const errors = (error?.errors.GeneralErrors as string[])?.map((error) => { return error }).join("; ");

            toast.error(`Invoice could not be cancelled. ${errors}`, {
                autoClose: 4000,
            });
        }
    }, [isError, error]);

    return (
        <>
            <button
                type="button"
                className="btn btn-icon btn_outlined btn_danger"
                title="Cancel Invoice"
                onClick={toggler}
            >
                <span className="las la-ban"></span>
            </button>
            <Modal isOpen={showModal} toggler={resetForm} isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoading}>
                    Cancel Invoice
                </ModalHeader>
                <ModalBody>
                    <div>
                        <h3>Are you sure you want to cancel the invoice?</h3>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoading}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={onCancel}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};
