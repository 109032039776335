import Tippy from '@tippyjs/react';
import { CSVLink } from 'react-csv';
import { Table } from '@tanstack/react-table';

import { formatAddress, getCSVFilename } from '../utilities/formatter';
import { Address } from '../utilities/interfaces';

export const AddressesColumn = ({ addresses }: { addresses: Address[] }) => {
    return (
        <>
            {addresses?.map((address) => (
                <div key={address.id}>
                    {formatAddress(
                        address.addressLine1,
                        address.addressLine2,
                        address.city,
                        address.state,
                        address.zipCode
                    )}
                </div>
            ))}
        </>
    );
};

export const ColumnsSorting = ({ header }: { header: any }) => {
    return (
        <>
            {header.column.id}
            {header.column.getIsSorted() === 'asc' && <span className="las la-sort-up"></span>}
            {header.column.getIsSorted() === 'desc' && <span className="las la-sort-down"></span>}
        </>
    );
};

export const ColumnsVisibility = (props: { columns: any; fixedHiddenColumns: string[] }) => {
    const { columns, fixedHiddenColumns } = props;

    return (
        <>
            <Tippy
                content={
                    <div className="flex flex-col gap-2 p-3">
                        {columns
                            .filter((column: any) => fixedHiddenColumns.every((h) => h !== column.id))
                            .map((column: any) => {
                                return (
                                    <div key={column.id}>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={column.getIsVisible()}
                                                onChange={column.getToggleVisibilityHandler()}
                                            />
                                            <span></span>
                                            <span>{column.id}</span>
                                        </label>
                                    </div>
                                );
                            })}
                    </div>
                }
                offset={[0, 2]}
                theme="light-border"
                trigger="click"
                placement="left-start"
                animation="shift-toward-extreme"
                interactive={true}
            >
                <button className="text-2xl" title="Columns Visibility">
                    <span className="las la-ellipsis-v"></span>
                </button>
            </Tippy>
        </>
    );
};

export const ColumnsSearching = (props: {
    handler: (event: any) => void;
    globalFilter: string;
    onClear: () => void;
}) => {
    const { handler, globalFilter, onClear } = props;

    return (
        <>
            <label className="form-control-addon-within rounded-full h-10">
                <input
                    className="form-control border-none"
                    placeholder="Search all columns..."
                    onChange={handler}
                    value={globalFilter}
                />
                {!globalFilter && (
                    <span className="text-gray-300 dark:text-gray-300 text-xl leading-none la la-search mr-4 mt-2"></span>
                )}
                {globalFilter && (
                    <button
                        type="button"
                        className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                        onClick={onClear}
                    ></button>
                )}
            </label>
        </>
    );
};

export const ColumnsPagination = (props: {
    table: any;
    onPageChanged: (value: number) => void;
    rowsSelected?: number;
    totalCount?: number;
}) => {
    const { table, rowsSelected, onPageChanged, totalCount } = props;

    return (
        <>
            {!!rowsSelected && (
                <div className="flex flex-wrap items-center gap-2 p-4 font-bold">{rowsSelected} rows selected</div>
            )}
            <div className="flex flex-wrap items-center divide-x ml-auto">
                <div className="flex flex-wrap gap-2 p-4">
                    <button
                        className="btn btn_primary"
                        onClick={() => {
                            table.setPageIndex(0);
                            onPageChanged(0);
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'First'}
                    </button>
                    <button
                        className="btn btn_primary"
                        onClick={() => {
                            table.previousPage();
                            onPageChanged(table.getState().pagination.pageIndex - 1);
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        <span className="flex items-center gap-1">
                            <select
                                value={table.getState().pagination.pageIndex + 1}
                                className="btn btn_outlined btn_secondary text-center pl-1 pr-0"
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    table.setPageIndex(page);
                                    onPageChanged(page);
                                }}
                            >
                                {Array.from({ length: table.getPageCount() }, (_, i) => i + 1).map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                        <strong>of {table.getPageCount()}</strong>
                    </span>
                    <button
                        className="btn btn_primary"
                        onClick={() => {
                            table.nextPage();
                            onPageChanged(table.getState().pagination.pageIndex + 1);
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button
                        className="btn btn_primary"
                        onClick={() => {
                            table.setPageIndex(table.getPageCount() - 1);
                            onPageChanged(table.getPageCount() - 1);
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        {'Last'}
                    </button>
                </div>
                <div className="flex flex-wrap items-center gap-2 px-4 h-16">
                    <span>
                        Displaying {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
                        {table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                            table.getRowModel().rows.length}{' '}
                        of {totalCount ?? table.getFilteredRowModel().rows.length} rows
                    </span>
                </div>
                <div className="flex flex-wrap items-center gap-2 px-4 h-16">
                    <span>Show</span>
                    <select
                        defaultValue={table.initialState.pagination.pageSize}
                        className="btn btn_outlined btn_secondary text-center pl-1 pr-0"
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                            onPageChanged(0);
                        }}
                    >
                        {[5, 10, 20, 50, 75, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    <span>items</span>
                </div>
            </div>
        </>
    );
};

export const ExportReactTableToCSV = (props: { title: string; reacTable: Table<any> }) => {
    const { title, reacTable } = props;

    const getDataToExportCSV = () => {
        let dataComplete: any[][] = [];

        const headersArray = reacTable
            .getHeaderGroups()
            .map((headerGroup) => headerGroup.headers.map((header) => (header.isPlaceholder ? '' : header.id)));

        headersArray.forEach((x) => {
            dataComplete.push(x);
        });

        const dataArray = reacTable.getFilteredRowModel().rows.map((row) =>
            row.getVisibleCells().map((cell) => {
                const value = cell.getValue();

                if (typeof value === 'string') {
                    return value;
                } else if (typeof value === 'number') {
                    return value.toString();
                } else if (typeof value == 'boolean') {
                    return value ? 'Yes' : 'No';
                } else if (Array.isArray(value)) {
                    return '';
                } else {
                    return '';
                }
            })
        );
        dataArray.forEach((x) => {
            dataComplete.push(x);
        });

        return dataComplete;
    };

    return (
        <>
            {reacTable.getFilteredRowModel().rows && reacTable.getFilteredRowModel().rows.length > 0 && (
                <CSVLink
                    data={getDataToExportCSV()}
                    separator=","
                    filename={getCSVFilename(title)}
                    className=""
                    title="Export to CSV"
                    target="_blank"
                >
                    <span className="las la-download text-2xl  "></span>
                </CSVLink>
            )}
        </>
    );
};

export const ExportSimpleTableToCSV = (props: { title: string; data: any[] }) => {
    const { title, data } = props;

    return (
        <>
            {data && data.length > 0 && (
                <CSVLink
                    data={data}
                    separator=","
                    filename={getCSVFilename(title)}
                    className=""
                    title="Export to CSV"
                    target="_blank"
                >
                    <span className="las la-download text-2xl  "></span>
                </CSVLink>
            )}
        </>
    );
};

export const ExportSimpleTableCustomHeaderToCSV = (props: { title: string; headers: any[]; data: any[] }) => {
    const { title, headers, data } = props;

    return (
        <>
            {data && data.length > 0 && (
                <CSVLink
                    headers={headers}
                    data={data}
                    separator=","
                    filename={getCSVFilename(title)}
                    className=""
                    title="Export to CSV"
                    target="_blank"
                >
                    <span className="las la-download text-2xl  "></span>
                </CSVLink>
            )}
        </>
    );
};
