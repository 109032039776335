import { useQuery } from 'react-query';
import apiClient from '../utilities/apiClient';

export interface FuzzySearchResult {
    type: string;
    address: {
        streetNumber: string;
        streetName: string;
        municipality: string;
        countrySubdivision: string;
        postalCode: string;
        freeformAddress: string;
    };
    position: {
        lat: number;
        lon: number;
    };
}

interface FuzzySearchResponse {
    results: FuzzySearchResult[];
}

const useFuzzySearch = (address: string, shouldSearch: boolean) => {
    const { isLoading, isSuccess, data, isError, error, refetch, isFetching } = useQuery(
        ['fuzzySearch', address],
        async () => {
            const response = await apiClient.get<FuzzySearchResponse>(`tenants/addresses`, {
                params: {
                    address: address
                },
            });
            return response.data?.results.filter((result) => {
                return !!result?.address && !!result?.address.streetNumber;
            });
        },
        {
            enabled: !!address && shouldSearch,
        }
    );

    return {
        isLoading,
        isSuccess,
        isFetching,
        data,
        isError,
        error,
        refetch,
    };
};

export const useAddressSearch = () => {
    return {
        useFuzzySearch,
    };
};
