import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import AuthLayout from '../components/AuthLayout';
import { FormInputCheckBoxControl, FormInputControl, FormSelectControl } from '../components/FormControls';
import InputAddressFuzySearch from '../components/InputAddressFuzzySearch';
import Loading from '../components/Loading';
import SelectPhoneNumberModal from '../components/SelectPhoneNumberModal';
import TippyToolTip from '../components/TippyToolTip';
import { FuzzySearchResult } from '../hooks/useAddressSearch';
import { useAuth } from '../hooks/useAuth';
import { useTenants } from '../hooks/useTenants';
import { formatAddressLine, formatPhoneNumber, isNumber } from '../utilities/formatter';
import { Coordinate } from '../utilities/interfaces';
import { timeZones, usStates } from '../utilities/staticData';


interface FormInput {
    name?: string;
    timezone?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    idleMinutes?: number;
    autoClockOut?: boolean;
    mileagePrice?: string;
}

const schema: yup.SchemaOf<FormInput> = yup.object({
    name: yup.string().required('Name is required.'),
    timezone: yup.string().required('TimeZone is required.'),
    addressLine1: yup.string().required('Address Line1 is required.'),
    addressLine2: yup.string().optional(),
    city: yup.string().required('City is required.'),
    state: yup.string().required('State is required.'),
    zipCode: yup
        .string()
        .required('Zip Code is required.')
        .matches(/^\d{5}(-\d{4})?$/, 'Zip code format is invalid.'),
    autoClockOut: yup.boolean().required(),
    idleMinutes: yup.mixed().when('autoClockOut', {
        is: true,
        then: yup
            .number()
            .typeError('Idle Time is required.')
            .integer('Idle Time must be an integer number.')
            .moreThan(0, 'Idle Time must be greater than 0.'),
    }),
    mileagePrice: yup.string().test('is-decimal', 'Mileage Price must be a decimal number', (value) => {
        return !isNaN(Number(value));
    }),
});

const Settings = () => {
    const { tenant } = useAuth();

    const [autoClockOut, setAutoClockOut] = useState(false);
    const [position, setPosition] = useState<Coordinate>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showSelectPhoneNumberModal, setShowSelectPhoneNumberModal] = useState(false);
    const [getPaymentsLoginLink, setGetPaymentsLoginLink] = useState(false);

    const { useGetTenantSettings, useUpdateTenantSettings, usePostSetupPayments, useGetPaymentsLoginLink } = useTenants();
    const { data, isSuccess: isSuccessGetTenant, isFetching: isFetchingGetTenant } = useGetTenantSettings(true);

    const {
        isSuccess,
        isError,
        isLoading,
        update,
        error: { errors: serverErrors },
    } = useUpdateTenantSettings();

    const {
        data: dataSetupPayments,
        isSuccess: isSuccessSetupPayments,
        isError: isErrorSetupPayments,
        isLoading: isLoadingSetupPayments,
        setup: setupPayments,
        error: { errors: serverErrorsSetupPayments },
    } = usePostSetupPayments();

    const {
        data: dataPaymentsLoginLink,
        isLoading: isLoadingPaymentsLoginLink,
        isSuccess: isSuccessPaymentsLoginLink,
        isError: isErrorPaymentsLoginLink,
        error: errorPaymentsLoginLink,
    } = useGetPaymentsLoginLink(getPaymentsLoginLink);

    const {
        register,
        handleSubmit,
        formState: { errors: clientErrors },
        control,
        setValue,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });
    const { field: addressLine1Field } = useController({
        name: 'addressLine1',
        control,
        defaultValue: '',
    });
    const { field: addressLine2Field } = useController({
        name: 'addressLine2',
        control,
        defaultValue: '',
    });
    const { field: cityField } = useController({
        name: 'city',
        control,
        defaultValue: '',
    });
    const { field: stateField } = useController({
        name: 'state',
        control,
        defaultValue: '',
    });
    const { field: zipCodeField } = useController({
        name: 'zipCode',
        control,
        defaultValue: '',
    });
    const { field: timeZoneField } = useController({
        name: 'timezone',
        control,
        defaultValue: '',
    });

    const states = usStates.map((state) => {
        return {
            value: state.abbreviation,
            text: state.name,
        };
    });

    const timeZoneOptions = timeZones
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((timeZone) => {
            return {
                value: timeZone.ianaName,
                text: timeZone.displayName,
            };
        });

    const onSubmit = (formData: FormInput) => {
        let mileagePriceNumber: number | undefined = undefined;
        if (isNumber(formData.mileagePrice)) {
            mileagePriceNumber = Number(formData.mileagePrice);
        }

        update({
            name: formData.name,
            timeZoneId: formData.timezone,
            address: {
                addressLine1: formData.addressLine1!,
                addressLine2: formData.addressLine2!,
                city: formData.city!,
                state: formData.state!,
                zipCode: formData.zipCode!,
                latitude: position?.latitude!,
                longitude: position?.longitude!,
            },
            phoneNumber: phoneNumber || data?.phoneNumber,
            idleMinutes: formData.idleMinutes,
            mileagePrice: mileagePriceNumber,
        });
    };

    const onAddressSelected = (result: FuzzySearchResult) => {
        addressLine1Field.onChange(formatAddressLine(result.address.streetNumber, result.address.streetName));
        addressLine2Field.onChange('');
        cityField.onChange(result.address.municipality);
        stateField.onChange(result.address.countrySubdivision);
        zipCodeField.onChange(result.address.postalCode);
        setPosition({
            latitude: result.position.lat!,
            longitude: result.position.lon!,
        });
    };

    const onTimeZoneSelected = (timeZone?: string) => {
        timeZoneField.onChange(timeZone);
    };

    const onPhoneNumberSelected = (phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
        setShowSelectPhoneNumberModal(false);
    };

    const onCheckBoxChange = (checked: boolean) => {
        setAutoClockOut(checked);
        if (!checked) {
            setValue('idleMinutes', undefined);
        } else {
            setValue('idleMinutes', 120);
        }
    };

    useEffect(() => {
        if (isSuccessGetTenant) {
            setValue('name', data?.name || '');
            setValue('addressLine1', data?.address?.addressLine1 || '');
            setValue('addressLine2', data?.address?.addressLine2 || '');
            setValue('city', data?.address?.city || '');
            setValue('state', data?.address?.state || '');
            setValue('zipCode', data?.address?.zipCode || '');
            setValue('timezone', data?.timeZoneId || '');
            setPosition({
                latitude: data?.address?.latitude!,
                longitude: data?.address?.longitude!,
            });
            setPhoneNumber(data?.phoneNumber || '');
            setValue('idleMinutes', data?.idleMinutes);
            setValue('autoClockOut', !!data?.idleMinutes);
            setAutoClockOut(!!data?.idleMinutes);
            setValue('mileagePrice', data?.mileagePrice ? data?.mileagePrice.toString() : '');
        }
    }, [data, isSuccessGetTenant, setValue]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Tenant Updated Successfully');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && serverErrors?.GeneralErrors?.length) {
            toast.error('An error occured: ' + serverErrors?.GeneralErrors[0], {
                autoClose: 4000,
            });
        }
    }, [isError, serverErrors]);

    useEffect(() => {
        if (isSuccessSetupPayments) {
            window.location.replace(dataSetupPayments as string);
        }
    }, [isSuccessSetupPayments, dataSetupPayments]);

    useEffect(() => {
        if (isErrorSetupPayments && serverErrorsSetupPayments?.GeneralErrors?.length) {
            toast.error('An error occured: ' + serverErrorsSetupPayments?.GeneralErrors[0], {
                autoClose: 4000,
            });
        }
    }, [isErrorSetupPayments, serverErrorsSetupPayments]);

    useEffect(() => {
        if (getPaymentsLoginLink && isSuccessPaymentsLoginLink) {
            window.open(dataPaymentsLoginLink?.url, "_blank");
            setGetPaymentsLoginLink(false);
        }
    }, [isSuccessPaymentsLoginLink, getPaymentsLoginLink, dataPaymentsLoginLink]);

    useEffect(() => {
        if (isErrorPaymentsLoginLink && errorPaymentsLoginLink) {
            toast.error('An error occured: ' + errorPaymentsLoginLink, {
                autoClose: 4000,
            });
        }
    }, [isErrorPaymentsLoginLink, errorPaymentsLoginLink]);

    return (
        <>
            <AuthLayout menuOption="Settings">
                <section className="breadcrumb lg:flex items-start">
                    <div>
                        <h1>Settings</h1>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center ml-auto mt-5 lg:mt-0">
                        <div>
                            <button
                                type="submit"
                                className="btn btn_primary uppercase"
                                disabled={!isSuccessGetTenant || isLoading || isLoadingSetupPayments}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {(isLoading || isLoadingSetupPayments) && <span className="la la-circle-notch text-xl leading-none mr-2"></span>}
                                {(!isLoading && !isLoadingSetupPayments) && <span className="la la-save text-xl leading-none mr-2"></span>}
                                Save
                            </button>
                        </div>
                    </div>
                </section>
                <Loading isLoading={isFetchingGetTenant} boxStyle />
                <div className="grid gap-5">
                    <div className="grid lg:grid-cols-2 gap-5">
                        <div className="card p-5">
                            <h3>General</h3>
                            <div className="flex flex-col xl:col-span-2 mt-3">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5">
                                    <div className="mb-5">
                                        <FormInputControl
                                            type="text"
                                            label="Name"
                                            register={register('name')}
                                            clientErrors={clientErrors?.name}
                                            serverErrors={serverErrors?.name}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <label className="label block mb-2">Phone Number</label>
                                        <div className="input-group">
                                            <span className="form-control input-group-item bg-gray-200">
                                                {formatPhoneNumber(phoneNumber) || '\u200B'}
                                            </span>
                                            {isSuccessGetTenant && !data?.phoneNumber && (
                                                <>
                                                    <button
                                                        className="btn btn_primary uppercase input-group-item"
                                                        onClick={() => setShowSelectPhoneNumberModal(true)}
                                                    >
                                                        {!phoneNumber ? 'Add' : 'Change'}
                                                    </button>
                                                    <SelectPhoneNumberModal
                                                        showModal={showSelectPhoneNumberModal}
                                                        toggler={() => setShowSelectPhoneNumberModal((v) => !v)}
                                                        onSelect={onPhoneNumberSelected}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5">
                                    <div className="mb-5">
                                        <FormSelectControl
                                            label="Time Zone"
                                            register={timeZoneField}
                                            options={timeZoneOptions}
                                            onChange={onTimeZoneSelected}
                                            clientErrors={clientErrors?.timezone}
                                            serverErrors={serverErrors?.timezone}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5">
                                    <div className="mb-5">
                                        <FormInputControl
                                            type="text"
                                            label="Mileage Price"
                                            register={register('mileagePrice')}
                                            clientErrors={clientErrors?.mileagePrice}
                                            serverErrors={serverErrors?.mileagePrice}
                                            tippyChild={
                                                <TippyToolTip tooltipText="Dollar amount per mile to calculate the mileage cost" />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-5">
                            <h3>Address</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5 mt-3">
                                <div className="mb-5">
                                    <InputAddressFuzySearch
                                        label="Address Line 1"
                                        register={addressLine1Field}
                                        onChange={onAddressSelected}
                                        field={addressLine1Field}
                                        clientErrors={clientErrors?.addressLine1}
                                        serverErrors={serverErrors?.addressLine1}
                                    />
                                </div>
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="Address Line2"
                                        register={addressLine2Field}
                                        clientErrors={clientErrors?.addressLine2}
                                        serverErrors={serverErrors?.addressLine2}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5">
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="City"
                                        register={cityField}
                                        clientErrors={clientErrors?.city}
                                        serverErrors={serverErrors?.city}
                                    />
                                </div>
                                <div className="mb-5">
                                    <FormSelectControl
                                        label="State"
                                        register={stateField}
                                        options={states}
                                        clientErrors={clientErrors?.state}
                                        serverErrors={serverErrors?.state}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5">
                                <div className="mb-5">
                                    <FormInputControl
                                        type="text"
                                        label="Zip Code"
                                        register={zipCodeField}
                                        clientErrors={clientErrors?.zipCode}
                                        serverErrors={serverErrors?.zipCode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-5">
                        {tenant.hasJobsFeature && (
                            <div className="card p-5">
                                <h3>Jobs</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 sm:gap-5 mt-3">
                                    <div className="mb-5">
                                        <FormInputCheckBoxControl
                                            label="Auto Clock Out"
                                            register={register('autoClockOut')}
                                            onChange={onCheckBoxChange}
                                        />
                                    </div>
                                    {autoClockOut && (
                                        <div className="mb-5">
                                            <FormInputControl
                                                type="text"
                                                label="Idle Time"
                                                register={register('idleMinutes')}
                                                clientErrors={clientErrors?.idleMinutes}
                                                serverErrors={serverErrors?.idleMinutes}
                                                tippyChild={
                                                    <TippyToolTip tooltipText="Idle time in minutes to auto clock out employees" />
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {tenant.hasPaymentsFeature && (
                            <div className="card p-5">
                                <h3>Payments</h3>
                                <div className="text-center">
                                    {!data?.stripeAccountId && (
                                        <>
                                            <span className="las la-unlink text-4xl text-danger"></span>
                                            <h3>Connect your account to Stripe</h3>
                                            <p className="my-4">We use Stripe to make sure you get paid on time and keep your personal and bank details secure.</p>
                                        </>
                                    )}
                                    {data?.stripeAccountId && !data?.stripeAccountOnboarded && (
                                        <>
                                            <span className="las la-link text-4xl text-warning"></span>
                                            <h3>Stripe payment setup is in process</h3>
                                            <p className="my-4">Your account is connected but the Stripe onboarding is not complete.</p>
                                        </>
                                    )}
                                    {(!data?.stripeAccountId || (data?.stripeAccountId && !data?.stripeAccountOnboarded)) && (
                                        <button
                                            type="button"
                                            className="btn btn_primary uppercase"
                                            disabled={!isSuccessGetTenant || isLoading || isLoadingSetupPayments}
                                            onClick={() => setupPayments()}
                                        >
                                            {(isLoading || isLoadingSetupPayments) && <span className="la la-circle-notch text-xl leading-none mr-2"></span>}
                                            {(!isLoading && !isLoadingSetupPayments) && <span className="las la-credit-card text-xl leading-none mr-2"></span>}
                                            Set up payments
                                        </button>
                                    )}
                                    {tenant.hasStripeAccountConnected && (
                                        <>
                                            <span className="las la-link text-4xl text-success"></span>
                                            <h3>Your account is connected to Stripe</h3>
                                            <button
                                                type="button"
                                                className="mt-2 text-normal hover:text-primary"
                                                disabled={isLoadingPaymentsLoginLink}
                                                onClick={() => setGetPaymentsLoginLink(true)}
                                            >
                                                <span className="align-middle las la-eye text-xl leading-none mr-1"></span>
                                                View Stripe account
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Settings;
