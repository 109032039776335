import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { validate as isValidUUID } from 'uuid';

import { FormInputCheckBoxControl, FormInputControl } from './FormControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';
import { useUsers } from '../hooks/useUsers';
import { useTenants } from '../hooks/useTenants';
import { AddressList } from './Addresses';
import { Address } from '../utilities/interfaces';
import TippyToolTip from './TippyToolTip';
import { useAuth } from '../hooks/useAuth';
import Loading from './Loading';

interface CustomerModalProps {
    modalTitle: string;
    showModal: boolean;
    toggler: () => void;
    action: (obj: FormInput) => void;
    isSuccessRequest: boolean;
    isLoadingRequest: boolean;
    serverErrors: any;
    data?: FormInput;
    isLoaded?: boolean;
    resetServer: () => void;
}

interface FormInput {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    addresses?: Address[];
    unsubscribeSms?: boolean;
}

const schema: yup.SchemaOf<FormInput> = yup.object({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    email: yup.string().required('Email is required').email('Email format is invalid.'),
    mobile: yup
        .string()
        .required('Mobile phone is required')
        .matches(/^\d{10}$/, 'Mobile phone format is invalid.'),
    addresses: yup.array().required('At least one address is required.').min(1, 'At least one address is required.'),
    unsubscribeSms: yup.boolean().optional(),
});

const CustomerModal = (props: CustomerModalProps) => {
    const {
        modalTitle,
        showModal,
        toggler,
        action,
        isSuccessRequest,
        isLoadingRequest,
        serverErrors,
        data,
        isLoaded,
        resetServer,
    } = props;

    const { tenant } = useAuth();
    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
        control,
    } = useForm<FormInput>({ resolver: yupResolver(schema) });
    const { field: addressesField } = useController({
        name: 'addresses',
        control,
        defaultValue: [],
    });

    const [enableEmailInput, setEnableEmailInput] = useState(false);

    const resetForm = useCallback(() => {
        resetClient();
        resetServer();
        toggler();
    }, [resetClient, toggler, resetServer]);

    const onSubmit = (data: FormInput) => {
        action(data);
    };

    const onAddressChanged = (result: Address[]) => {
        addressesField.onChange(result);
    };

    useEffect(() => {
        if (isSuccessRequest) {
            resetForm();
        }
    }, [isSuccessRequest, resetForm]);

    useEffect(() => {
        if (data) {
            setEnableEmailInput(true);
            resetClient(data);
        }
    }, [resetClient, data, isLoaded]);

    return (
        <>
            <Modal isOpen={showModal} toggler={resetForm} size="lg" isStatic={true}>
                <ModalHeader toggler={resetForm} closeButtonDisabled={isLoadingRequest}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    <Loading isLoading={!isLoaded} boxStyle />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="First Name"
                                register={register('firstName')}
                                clientErrors={clientErrors?.firstName}
                                serverErrors={serverErrors?.firstName}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Last Name"
                                register={register('lastName')}
                                clientErrors={clientErrors?.lastName}
                                serverErrors={serverErrors?.lastName}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Email"
                                register={register('email')}
                                clientErrors={clientErrors?.email}
                                serverErrors={serverErrors?.email}
                                disabled={enableEmailInput}
                            />
                            {Array.isArray(serverErrors?.GeneralErrors)
                                ? (serverErrors?.GeneralErrors as string[])?.map((error, index) => (
                                      <small key={index} className="block mt-1 invalid-feedback">
                                          {error}
                                      </small>
                                  ))
                                : null}
                        </div>
                        <div className="mb-5">
                            <FormInputControl
                                type="text"
                                label="Mobile Phone"
                                register={register('mobile')}
                                clientErrors={clientErrors?.mobile}
                                serverErrors={serverErrors?.mobile}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 sm:gap-5">
                        <div>
                            <AddressList
                                initialAddresses={addressesField.value}
                                clientErrors={clientErrors?.addresses as unknown as FieldError}
                                serverErrors={serverErrors?.addresses}
                                onChange={onAddressChanged}
                            />
                        </div>
                        {isLoaded && (
                            <div className="mb-5">
                                <FormInputCheckBoxControl
                                    label="Unsubscribe from sms"
                                    register={register('unsubscribeSms')}
                                    clientErrors={clientErrors?.unsubscribeSms}
                                    serverErrors={serverErrors?.unsubscribeSms}
                                    tippyChild={<TippyToolTip tooltipText={`Unsubscribing will stop sending the " ${tenant.name} is on the way" message.`} />}
                                />
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex ml-auto">
                        <button
                            type="button"
                            className="btn btn_secondary uppercase"
                            onClick={resetForm}
                            disabled={isLoadingRequest}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn_primary ml-2 uppercase"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoadingRequest}
                        >
                            Save
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const EditCustomerModal = (props: { customerId?: string }) => {
    const { customerId } = props;

    const [showModal, setShowModal] = useState(false);

    const { useUpdateCustomer } = useUsers();
    const {
        isLoading,
        isSuccess,
        update,
        reset: resetServer,
        error: { errors: serverErrors },
    } = useUpdateCustomer();

    const { useGetCustomer } = useTenants();
    const { data: customer, isFetching } = useGetCustomer(customerId || '', showModal);

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const onSubmitUpdate = (data: FormInput) => {
        data.addresses
            ?.filter((address) => !isValidUUID(address.id || ''))
            .forEach((address) => {
                address.id = undefined;
            });

        update({
            customerId: customerId!,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            addresses: data.addresses,
            unsubscribeSms: data.unsubscribeSms
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Customer Updated Successfully');
        }
    }, [isSuccess]);

    return (
        <>
            <button className="btn btn-icon btn_outlined btn_secondary ml-2" onClick={toggler} title="Update Customer">
                <span className="la la-pen-fancy"></span>
            </button>
            <CustomerModal
                modalTitle="Update Customer"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitUpdate}
                toggler={toggler}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                data={customer}
                isLoaded={!isFetching}
                resetServer={resetServer}
            />
        </>
    );
};

export const AddCustomerModal = () => {
    const [showModal, setShowModal] = useState(false);

    const { usePostCustomer } = useUsers();
    const {
        isLoading,
        isSuccess,
        create,
        reset: resetServer,
        error: { errors: serverErrors },
    } = usePostCustomer();

    const toggler = useCallback(() => {
        setShowModal((value) => !value);
    }, []);

    const onSubmitCreate = (data: FormInput) => {
        data.addresses?.forEach((address) => {
            address.id = undefined;
        });

        create({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobile: data.mobile,
            addresses: data.addresses,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetServer();
            toast.success('Customer Created Successfully');
        }
    }, [isSuccess, resetServer]);

    return (
        <>
            <button className="btn btn_primary uppercase" onClick={toggler}>
                Add New
            </button>
            <CustomerModal
                modalTitle="Add Customer"
                showModal={showModal}
                isSuccessRequest={isSuccess}
                action={onSubmitCreate}
                toggler={toggler}
                isLoaded={true}
                isLoadingRequest={isLoading}
                serverErrors={serverErrors}
                resetServer={resetServer}
            />
        </>
    );
};
