import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useMenu } from '../hooks/useMenu';
import { useAuth } from '../hooks/useAuth';
import { useSmsNotifications } from '../hooks/useSmsNotifications';

const Menubar = () => {
    const { tenant } = useAuth();
    const { isMenuBarOpened, selectedMenuBarOption } = useMenu();

    const { hasUnreadSms } = useSmsNotifications();

    const [menuSelected, setMenuSelected] = useState(0);

    const [menu] = useState([
        {
            name: 'Dashboard',
            page: '/',
            icon: 'laptop',
        },
        {
            name: 'Messages',
            page: '/messages',
            icon: 'sms',
        },
        {
            name: 'Jobs',
            page: '/jobs',
            icon: 'cube',
        },
        {
            name: 'Map',
            page: '/map',
            icon: 'map',
        },
        {
            name: 'Customers',
            page: '/customers',
            icon: 'building',
        },
        {
            name: 'Employees',
            page: '/employees',
            icon: 'users',
        },
        {
            name: 'Timecard',
            page: '/timecard',
            icon: 'user-clock',
        },
        {
            name: 'Employee Performance',
            page: '/employeePerformance',
            icon: 'tachometer-alt',
        },
    ]);

    const menuBarClasses = {
        'menu-hidden': !isMenuBarOpened,
    };

    useEffect(() => {
        const index = menu.findIndex((item) => item.name === selectedMenuBarOption);
        setMenuSelected(index);
    }, [selectedMenuBarOption, menu]);

    return (
        <>
            <aside className={classNames('menu-bar menu-sticky menu_branded', menuBarClasses)}>
                <div className="menu-items">
                    {menu.map(
                        (menuItem, index) =>
                            (menuItem.name !== 'Messages' || tenant.hasChatFeature) &&
                            (menuItem.name !== 'Jobs' || tenant.hasJobsFeature) && (
                                <Link
                                    key={index}
                                    to={menuItem.page}
                                    className={classNames('link', {
                                        active: index === menuSelected,
                                    })}
                                >
                                    {menuItem.name !== 'Messages' && (
                                        <span className={classNames('icon la', `la-${menuItem.icon}`)}></span>
                                    )}
                                    {menuItem.name === 'Messages' && (
                                        <div
                                            className={classNames(
                                                'relative flex items-center h-full px-2 icon la',
                                                `la-${menuItem.icon}`
                                            )}
                                        >
                                            {hasUnreadSms && (
                                                <span className="absolute top-0 right-0 rounded-full badge_danger -mt-1 -mr-1 px-2 text-xs">
                                                    {'\u00a0'}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    <span className="title">{menuItem.name}</span>
                                </Link>
                            )
                    )}
                </div>
            </aside>
        </>
    );
};

export default Menubar;
