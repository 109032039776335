import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker, { DateObject, getAllDatesInRange } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import AuthLayout from '../components/AuthLayout';
import Avatar from '../components/Avatar';
import JobTimeEntryEdit from '../components/JobTimeEntryEdit';
import Loading from '../components/Loading';
import { ExportSimpleTableCustomHeaderToCSV } from '../components/TableFeatures';
import TimeEntryEdit from '../components/TimeEntryEdit';
import TimeEntryModal from '../components/TimeEntryModal';
import TippyToolTip from '../components/TippyToolTip';
import { useAuth } from '../hooks/useAuth';
import useInput from '../hooks/useInput';
import { useJobs } from '../hooks/useJobs';
import { TimecardInterface, useTenants } from '../hooks/useTenants';
import { formatFullName, sortByDate, sortByDateDesc, toDuration, toDurationHHMMSS, toMoney, toTimeZoneTime } from '../utilities/formatter';
import { jobStatusEnum, jobTimekeepingEntryTypeEnum, timekeepingEntryTypeEnum } from '../utilities/staticData';

const Timecard = () => {
    const { tenant } = useAuth();

    const { useGetCreateJob } = useJobs();
    const { data: tenantData, isSuccess: isSuccessTenant, isFetching: isFetchingGetCreateJob } = useGetCreateJob(true);

    const employeeInput = useInput('');
    const [employees, setEmployees] = useState<{ value: string; text: string; active: boolean }[]>();
    const [employeeSelected, setEmployeeSelected] = useState('');

    const [dates, setDates] = useState<DateObject[]>([
        new DateObject().toFirstOfWeek(),
        new DateObject().toLastOfWeek(),
    ]);
    const [allDates, setAllDates] = useState(getAllDatesInRange(dates));
    const [filteredTimecardData, setFilteredTimecardData] = useState<TimecardInterface[]>([]);
    const [showTimeEntries, setShowTimeEntries] = useState(false);
    const [showJobs, setShowJobs] = useState(false);
    const [showEmployeePerformance, setShowEmployeePerformance] = useState(false);
    const [showInactiveEmployees, setShowInactiveEmployees] = useState(false);
    const [idEditingEntry, setIdEditingEntry] = useState<string | undefined>();
    const [mileagePricetext, setMileagePricetext] = useState<string>('');
    const [sortByFirstNameAsc, setSortByFirstNameAsc] = useState<boolean | undefined>(true);
    const [sortByLastNameAsc, setSortByLastNameAsc] = useState<boolean | undefined>();

    const { useGetTimecards, useGetTenantSettings } = useTenants();
    const { data, isSuccess, isFetching } = useGetTimecards({
        startDate: dates[0],
        endDate: dates[1],
    });

    const { data: dataGetTenant, isSuccess: isSuccessGetTenant, isFetching: isFetchingGetTenant } = useGetTenantSettings(true);

    const onChangeWeek = (dateObjects: DateObject[]) => {
        setDates(dateObjects);
        setAllDates(getAllDatesInRange(dateObjects));
    };

    const onCurrentWeek = () => {
        const currentWeek = [new DateObject().toFirstOfWeek(), new DateObject().toLastOfWeek()];
        onChangeWeek(currentWeek);
    };

    const onSubstractWeek = () => {
        const lastWeek = [dates[0].subtract(7, 'd'), dates[1].subtract(7, 'd')];
        onChangeWeek(lastWeek);
    };

    const onAddWeek = () => {
        const nextWeek = [dates[0].add(7, 'd'), dates[1].add(7, 'd')];
        onChangeWeek(nextWeek);
    };

    const onSortByFirstName = () => {
        setSortByFirstNameAsc(!sortByFirstNameAsc);
        setSortByLastNameAsc(undefined);
    };

    const onSortByLastName = () => {
        setSortByLastNameAsc(!sortByLastNameAsc);
        setSortByFirstNameAsc(undefined);
    };

    const getURLProfilePicture = (employeeId: string) => {
        return tenantData?.employees.find((e) => e.value === employeeId)?.profilePicture;
    };

    const onEditingEntry = useCallback((entryId?: string) => {
        setIdEditingEntry(entryId);
    }, []);

    const getClockToValidate = (dayClocksEntries: any[], currentEntry: any, validateBetweenClockGroups: boolean) => {
        var entryToValidateInSameGroup = null;
        var entryInOtherClockGroup = null;

        if (currentEntry.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']]) {
            entryInOtherClockGroup = dayClocksEntries!
                .sort((a, b) => sortByDateDesc(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentEntry.entryId &&
                        c.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']] &&
                        new Date(c.entryTime) < new Date(currentEntry.entryTime)
                );

            entryToValidateInSameGroup = dayClocksEntries!
                .sort((a, b) => sortByDate(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentEntry.entryId &&
                        c.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']] &&
                        new Date(c.entryTime) > new Date(currentEntry.entryTime)
                );
        }

        if (currentEntry.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock Out']]) {
            entryToValidateInSameGroup = dayClocksEntries!
                .sort((a, b) => sortByDateDesc(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentEntry.entryId &&
                        c.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']] &&
                        new Date(c.entryTime) < new Date(currentEntry.entryTime)
                );

            entryInOtherClockGroup = dayClocksEntries!
                .sort((a, b) => sortByDate(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentEntry.entryId &&
                        c.entryType === timekeepingEntryTypeEnum[timekeepingEntryTypeEnum['Clock In']] &&
                        new Date(c.entryTime) > new Date(currentEntry.entryTime)
                );
        }

        if (validateBetweenClockGroups === true) {
            return entryInOtherClockGroup?.entryTime;
        }

        if (validateBetweenClockGroups === false) {
            return entryToValidateInSameGroup?.entryTime;
        }
    };

    const getTimeToValidate = (jobEntries: any[], currentTimeEntry: any, validateBetweenTimeGroups: boolean) => {
        var entryToValidateInSameGroup = null;
        var entryInOtherClockGroup = null;

        if (currentTimeEntry.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']]) {
            entryInOtherClockGroup = jobEntries!
                .sort((a, b) => sortByDateDesc(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentTimeEntry.entryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                        new Date(c.entryTime) < new Date(currentTimeEntry.entryTime)
                );

            entryToValidateInSameGroup = jobEntries!
                .sort((a, b) => sortByDate(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentTimeEntry.entryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']] &&
                        new Date(c.entryTime) > new Date(currentTimeEntry.entryTime)
                );
        }

        if (currentTimeEntry.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time Out']]) {
            entryToValidateInSameGroup = jobEntries!
                .sort((a, b) => sortByDateDesc(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentTimeEntry.entryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                        new Date(c.entryTime) < new Date(currentTimeEntry.entryTime)
                );

            entryInOtherClockGroup = jobEntries!
                .sort((a, b) => sortByDate(a.entryTime, b.entryTime))
                .find(
                    (c) =>
                        c.entryId !== currentTimeEntry.entryId &&
                        c.entryType === jobTimekeepingEntryTypeEnum[jobTimekeepingEntryTypeEnum['Time In']] &&
                        new Date(c.entryTime) > new Date(currentTimeEntry.entryTime)
                );
        }

        if (validateBetweenTimeGroups === true) {
            return entryInOtherClockGroup?.entryTime;
        }

        if (validateBetweenTimeGroups === false) {
            return entryToValidateInSameGroup?.entryTime;
        }
    };

    const getHeadersToExportCSV = () => {
        let headers = [
            { key: 'employeeName', label: 'Employee' },
            { key: 'type', label: 'Type' },
        ];

        if (allDates) {
            (allDates as DateObject[]).forEach((entry, index) => {
                headers.push(...[{
                    key: `day${index}`,
                    label: `${entry.format('ddd')}\n${entry.format('MMM D')}`
                }]);

            });
        }

        headers.push(...[{ key: 'total', label: 'Total' }]);

        return headers;
    };

    const getDataToExportCSV = () => {
        const dataToExport: any = [];

        filteredTimecardData
            .filter((t) => (!employeeSelected ? true : t.employeeId === employeeSelected))
            .sort((a, b) =>
                sortByLastNameAsc !== undefined
                    ? (sortByLastNameAsc
                        ? a.employeeNameInverted.localeCompare(b.employeeNameInverted)
                        : b.employeeNameInverted.localeCompare(a.employeeNameInverted)
                    )
                    : (sortByFirstNameAsc
                        ? a.employeeName.localeCompare(b.employeeName)
                        : b.employeeName.localeCompare(a.employeeName)
                    )
            )
            .forEach((timecard) => {

                //CLOCKS
                const rowClocks = new Map([
                    ['employeeName', timecard.employeeName],
                    ['type', 'Clocks Duration']
                ]);
                timecard.weekClocks.forEach((day, index) => {
                    rowClocks.set(
                        `day${index}`,
                        toDuration(day.duration));
                });
                rowClocks.set(
                    'total',
                    toDurationHHMMSS(timecard.weekClocksTotalDuration));

                dataToExport.push(Object.fromEntries(rowClocks));

                //IDLE
                if (showEmployeePerformance && tenant.hasJobsFeature) {
                    var rowIdle = new Map([
                        ['employeeName', timecard.employeeName],
                        ['type', 'Idle Duration']
                    ]);
                    timecard.weekClocks.forEach((day, index) => {
                        rowIdle.set(
                            `day${index}`,
                            toDuration(day.idle));
                    });
                    rowIdle.set(
                        'total',
                        toDuration(timecard.weekIdleTotalDuration));

                    dataToExport.push(Object.fromEntries(rowIdle));
                }

                //CLOCK ENTRIES
                if (showTimeEntries) {
                    var rowClockEntries = new Map([
                        ['employeeName', timecard.employeeName],
                        ['type', 'Clock Entries']
                    ]);
                    timecard.weekClocks.forEach((day, index) => {
                        rowClockEntries.set(
                            `day${index}`,
                            day.entries.map(entry => {
                                return `${entry.entryTypeName}: ${toTimeZoneTime(entry.entryTime, tenant.timeZoneId, true)} ${entry.autoCreated ? '(Auto)' : ''}`;
                            }).join('\n'));
                    });

                    dataToExport.push(Object.fromEntries(rowClockEntries));
                }

                //JOBS
                if (tenant.hasJobsFeature) {
                    var rowJobs = new Map([
                        ['employeeName', timecard.employeeName],
                        ['type', 'Jobs Duration']
                    ]);
                    timecard.weekJobs.forEach((day, index) => {
                        rowJobs.set(
                            `day${index}`,
                            toDurationHHMMSS(day.duration));
                    });
                    rowJobs.set(
                        'total',
                        toDurationHHMMSS(timecard.weekJobsTotalDuration));

                    dataToExport.push(Object.fromEntries(rowJobs));

                    //JOB ENTRIES
                    if (showJobs) {
                        var rowJobEntries = new Map([
                            ['employeeName', timecard.employeeName],
                            ['type', 'Jobs Entries']
                        ]);
                        timecard.weekJobs.forEach((day, index) => {
                            rowJobEntries.set(
                                `day${index}`,
                                day.jobs.map(job => {
                                    return 'Job: ' + job.jobTitle + '\n' +
                                        'Duration: ' + toDuration(job.jobDuration) + '\n' +
                                        job.entries.map(entry => {
                                            return `${entry.entryTypeName}: ${toTimeZoneTime(entry.entryTime, tenant.timeZoneId, true)} ${entry.autoCreated ? '(Auto)' : ''}`;
                                        }).join('\n')
                                }).join('\n---------------\n'));
                        });

                        dataToExport.push(Object.fromEntries(rowJobEntries));
                    }
                }

                //MILEAGE
                if (showEmployeePerformance) {
                    var rowMileage = new Map([
                        ['employeeName', timecard.employeeName],
                        ['type', 'Mileage']
                    ]);
                    timecard.weekMileage.forEach((day, index) => {
                        rowMileage.set(
                            `day${index}`,
                            day.mileage
                                ? `${day.mileage.toFixed(2)}`
                                : '0.00');
                    });
                    rowMileage.set('total',
                        timecard.weekMileageTotalMiles
                            ? `${timecard.weekMileageTotalMiles.toFixed(2)}`
                            : '0.00');

                    dataToExport.push(Object.fromEntries(rowMileage));
                }

                //COST
                if (showEmployeePerformance) {
                    var rowCost = new Map([
                        ['employeeName', timecard.employeeName],
                        ['type', `Cost\n(Mile Price: ${mileagePricetext})`]
                    ]);
                    timecard.weekMileage.forEach((day, index) => {
                        rowCost.set(`day${index}`,
                            (timecard.mileagePrice || timecard.mileagePrice === 0
                                ? toMoney(day.mileageCost)
                                : notAssignedFmt) || '');
                    });
                    rowCost.set('total', (timecard.mileagePrice ||
                        timecard.mileagePrice === 0
                        ? toMoney(timecard.weekMileageCostTotal)
                        : notAssignedFmt) || '');

                    dataToExport.push(Object.fromEntries(rowCost));
                }
            });

        return (dataToExport);
    };

    useEffect(() => {
        const selectedValue = employees?.find((employee) => employee.text === employeeInput.value)?.value;

        setEmployeeSelected(selectedValue || '');
    }, [employeeInput.value, employees]);

    useEffect(() => {
        if (showInactiveEmployees) {
            setFilteredTimecardData(data?.timecards?.inactiveTimecards || []);
            setEmployees(tenantData?.employees.filter((employee) => !employee.active));
        }
        else {
            setFilteredTimecardData(data?.timecards?.activeTimecards || []);
            setEmployees(tenantData?.employees.filter((employee) => employee.active));
        }
    }, [showInactiveEmployees, data, tenantData]);

    useEffect(() => {
        if (isSuccessTenant) {
            const employeesValues = tenantData?.employees
                .map((employee) => ({
                    id: employee.id,
                    name: formatFullName(employee.firstName, employee.lastName),
                    active: employee.active
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((employee) => ({
                    value: employee.id,
                    text: employee.name,
                    active: employee.active
                }));

            setEmployees(employeesValues || []);
        }
    }, [isSuccessTenant, tenantData]);

    useEffect(() => {
        if (isSuccessGetTenant) {
            const mileagePrice = dataGetTenant?.mileagePrice;
            const milPricetext = mileagePrice ? toMoney(mileagePrice) : 'Not assigned';
            setMileagePricetext(milPricetext || '');
        }
    }, [dataGetTenant, isSuccessGetTenant, setMileagePricetext]);

    const durationWidth = {
        minWidth: '95px',
    };
    const notAssignedFmt = '-.--';

    return (
        <>
            <AuthLayout menuOption="Timecard">
                <section className="breadcrumb lg:flex items-start">
                    <div className="mr-4">
                        <h1>Timecard</h1>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center ml-auto mt-5 lg:mt-0">
                        <div className="flex gap-x-2">
                            {tenant.hasJobsFeature && (
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        onChange={() => setShowEmployeePerformance(!showEmployeePerformance)}
                                    />
                                    <span></span>
                                    <span>Show Performance</span>
                                </label>
                            )}
                            <label className="switch">
                                <input type="checkbox" onChange={() => setShowTimeEntries(!showTimeEntries)} />
                                <span></span>
                                <span>Show Time Entries</span>
                            </label>
                            {tenant.hasJobsFeature && (
                                <label className="switch">
                                    <input type="checkbox" onChange={() => setShowJobs(!showJobs)} />
                                    <span></span>
                                    <span>Show Jobs Details</span>
                                </label>
                            )}
                            <label className="switch">
                                <input type="checkbox" onChange={() => setShowInactiveEmployees(!showInactiveEmployees)} defaultChecked={false} />
                                <span></span>
                                <span>Show inactive employees</span>
                            </label>
                            <div>
                                <form className="flex flex-auto">
                                    <label className="form-control-addon-within rounded-full">
                                        <input
                                            placeholder="Filter by employee"
                                            className="form-control border-none"
                                            list="map-employees"
                                            type="text"
                                            {...employeeInput.bind}
                                        />
                                        <datalist id="map-employees">
                                            {employees?.map((option, index) => (
                                                <option key={index} value={option.text}>
                                                    {option.active ? "" : "Inactive"}
                                                </option>
                                            ))}
                                        </datalist>
                                        {employeeSelected && (
                                            <button
                                                type="button"
                                                className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                                                onClick={() => {
                                                    setEmployeeSelected('');
                                                    employeeInput.reset();
                                                }}
                                            ></button>
                                        )}
                                    </label>
                                </form>
                            </div>
                            <div className="btn-group h-[38px]">
                                <button className="btn btn_outlined btn_secondary uppercase" onClick={onSubstractWeek}>
                                    {'<'}
                                </button>
                                <button className="btn btn_outlined btn_secondary uppercase">
                                    <DatePicker
                                        range
                                        weekPicker
                                        value={dates}
                                        render={<Icon />}
                                        onChange={onChangeWeek}
                                    />
                                </button>
                                <button className="btn btn_outlined btn_secondary uppercase" onClick={onAddWeek}>
                                    {'>'}
                                </button>
                                <button className="btn btn_outlined btn_secondary uppercase" onClick={onCurrentWeek}>
                                    Current Week
                                </button>
                            </div>
                            <div>
                                <TimeEntryModal />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="card py-5 px-4">
                    <div className="overflow-x-auto">
                        <Loading isLoading={isFetchingGetCreateJob || isFetching || isFetchingGetTenant} boxStyle />
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th className="text-left uppercase">
                                        <p>EMPLOYEE</p>
                                        <div className="flex text-gray-600 text-xs">
                                            <button
                                                className="flex items-center"
                                                onClick={() => onSortByFirstName()}>
                                                First name
                                                <span className={
                                                    classNames("las text-primary text-sm",
                                                        sortByFirstNameAsc === undefined ? "" : sortByFirstNameAsc ? "la-sort-up" : "la-sort-down")
                                                }></span>
                                            </button>
                                            <p className=" text-sm text-primary">&nbsp;|&nbsp;</p>
                                            <button
                                                className="flex items-center"
                                                onClick={() => onSortByLastName()}>
                                                Last name
                                                <span className={
                                                    classNames("las text-primary text-sm",
                                                        sortByLastNameAsc === undefined ? "" : sortByLastNameAsc ? "la-sort-up" : "la-sort-down")
                                                }></span>
                                            </button>
                                        </div>
                                    </th>
                                    <th className="text-right uppercase">Type</th>
                                    {allDates &&
                                        (allDates as DateObject[]).map((entry, index) => (
                                            <th key={index} className="text-right uppercase">
                                                {entry.format('ddd')}
                                                <p className="text-gray-600">{entry.format('MMM D')}</p>
                                            </th>
                                        ))}
                                    <th className="text-right uppercase">
                                        <strong>TOTAL</strong>
                                        <ExportSimpleTableCustomHeaderToCSV
                                            title={'Timecard'}
                                            headers={getHeadersToExportCSV()}
                                            data={getDataToExportCSV()}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isSuccess &&
                                    filteredTimecardData
                                        .filter((t) => (!employeeSelected ? true : t.employeeId === employeeSelected))
                                        .sort((a, b) =>
                                            sortByLastNameAsc !== undefined
                                                ? (sortByLastNameAsc
                                                    ? a.employeeNameInverted.localeCompare(b.employeeNameInverted)
                                                    : b.employeeNameInverted.localeCompare(a.employeeNameInverted)
                                                )
                                                : (sortByFirstNameAsc
                                                    ? a.employeeName.localeCompare(b.employeeName)
                                                    : b.employeeName.localeCompare(a.employeeName)
                                                )
                                        )
                                        .map((timecard, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="align-top bg-gray-100">
                                                    <td className="text-left">
                                                        <div className="flex items-top">
                                                            <Avatar
                                                                fullName={timecard.employeeName}
                                                                imageURL={getURLProfilePicture(timecard.employeeId)} size="lg"
                                                            />
                                                            <div className="ml-1">
                                                                <strong>{timecard.employeeName}</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-right uppercase">
                                                        <strong>Clocks</strong>
                                                        {showEmployeePerformance && tenant.hasJobsFeature && (
                                                            <>
                                                                <br />
                                                                <span>Idle</span>
                                                            </>
                                                        )}
                                                    </td>
                                                    {timecard.weekClocks.map((dayClocks, index) => (
                                                        <td
                                                            key={index}
                                                            className="text-right"
                                                            style={{
                                                                paddingLeft: '0.25rem',
                                                                paddingRight: '0.125rem',
                                                            }}
                                                        >
                                                            <div style={durationWidth}>
                                                                <i className="las la-stopwatch"></i>
                                                                <strong>{toDuration(dayClocks.duration)}</strong>
                                                                {showEmployeePerformance && tenant.hasJobsFeature && (
                                                                    <>
                                                                        <br />
                                                                        <i className="las la-pause-circle"></i>
                                                                        <span>{toDuration(dayClocks.idle)}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                            {showTimeEntries &&
                                                                dayClocks.entries.map((clock, index, arr) => (
                                                                    <TimeEntryEdit
                                                                        key={index}
                                                                        employeeId={timecard.employeeId}
                                                                        entryId={clock.entryId}
                                                                        entryTime={clock.entryTime}
                                                                        isDeletableEntry={index === arr.length - 1}
                                                                        autoCreated={clock.autoCreated || false}
                                                                        timeToValidate={getClockToValidate(
                                                                            dayClocks.entries,
                                                                            clock,
                                                                            false
                                                                        )}
                                                                        timeToValidateBetweenClocks={getClockToValidate(
                                                                            dayClocks.entries,
                                                                            clock,
                                                                            true
                                                                        )}
                                                                        entryType={clock.entryType}
                                                                        entryTypeName={`${clock.entryTypeName}: `}
                                                                        onLoadingUpdateTimeEntry={() => false}
                                                                        onEditingEntry={onEditingEntry}
                                                                        idEditingEntry={idEditingEntry}
                                                                        narrowMode={true}
                                                                    />
                                                                ))}
                                                        </td>
                                                    ))}
                                                    <td className="text-right align-top">
                                                        <div style={durationWidth}>
                                                            <i className="las la-stopwatch"></i>
                                                            <strong>
                                                                {toDurationHHMMSS(timecard.weekClocksTotalDuration)}
                                                            </strong>
                                                            {showEmployeePerformance && tenant.hasJobsFeature && (
                                                                <>
                                                                    <br />
                                                                    <i className="las la-pause-circle"></i>
                                                                    <span>
                                                                        {toDuration(timecard.weekIdleTotalDuration)}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                {tenant.hasJobsFeature && (
                                                    <tr className="align-top">
                                                        <td></td>
                                                        <td className="text-right uppercase">
                                                            <strong>Jobs</strong>
                                                        </td>
                                                        {timecard.weekJobs.map((dayJobs, index) => (
                                                            <td
                                                                key={index}
                                                                className="text-right"
                                                                style={{
                                                                    paddingLeft: '0.25rem',
                                                                    paddingRight: '0.125rem',
                                                                }}
                                                            >
                                                                <i className="las la-business-time"></i>{' '}
                                                                <strong>{toDurationHHMMSS(dayJobs.duration)}</strong>
                                                                {showJobs &&
                                                                    dayJobs.jobs.map((job, index) => (
                                                                        <div key={index} className="text-xs">
                                                                            <hr />
                                                                            <i className="las la-cube"></i>
                                                                            <em>{job.jobTitle}</em>
                                                                            <p>
                                                                                <i className="las la-stopwatch"></i>
                                                                                {toDuration(job.jobDuration)}
                                                                            </p>
                                                                            {showTimeEntries &&
                                                                                job.entries.map((time, index, arr) => (
                                                                                    <JobTimeEntryEdit
                                                                                        key={index}
                                                                                        jobId={job.jobId}
                                                                                        employeeId={timecard.employeeId}
                                                                                        isDeletableEntry={index === arr.length - 1 && job.status !== jobStatusEnum.Completed.toString()}
                                                                                        entryId={time.entryId}
                                                                                        entryTime={time.entryTime}
                                                                                        autoCreated={
                                                                                            time.autoCreated || false
                                                                                        }
                                                                                        timeToValidate={getTimeToValidate(
                                                                                            job.entries,
                                                                                            time,
                                                                                            false
                                                                                        )}
                                                                                        timeToValidateBetweenTimes={getTimeToValidate(
                                                                                            job.entries,
                                                                                            time,
                                                                                            true
                                                                                        )}
                                                                                        entryType={time.entryType}
                                                                                        entryTypeName={`${time.entryTypeName}: `}
                                                                                        onLoadingUpdateJobTimeEntry={() =>
                                                                                            false
                                                                                        }
                                                                                        onEditingEntry={onEditingEntry}
                                                                                        idEditingEntry={idEditingEntry}
                                                                                        narrowMode={true}
                                                                                    />
                                                                                ))}
                                                                        </div>
                                                                    ))}
                                                            </td>
                                                        ))}
                                                        <td className="text-right">
                                                            <div style={durationWidth}>
                                                                <i className="las la-business-time"></i>{' '}
                                                                <strong>
                                                                    {toDurationHHMMSS(timecard.weekJobsTotalDuration)}
                                                                </strong>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                                {showEmployeePerformance && (
                                                    <tr className="align-top">
                                                        <td></td>
                                                        <td className="text-right uppercase">
                                                            <strong>Mileage</strong>
                                                            <br />
                                                            <span className="text-xs">Cost</span>{' '}
                                                            <TippyToolTip
                                                                tooltipText={`Mile Price: ${mileagePricetext}`}
                                                            />
                                                        </td>
                                                        {timecard.weekMileage.map((dayMileage, index) => (
                                                            <td
                                                                key={index}
                                                                className="text-right"
                                                                style={{
                                                                    paddingLeft: '0.25rem',
                                                                    paddingRight: '0.125rem',
                                                                }}
                                                            >
                                                                <i className="las la-car-side"></i>{' '}
                                                                <strong>
                                                                    {dayMileage.mileage
                                                                        ? `${dayMileage.mileage.toFixed(2)}`
                                                                        : '0.00'}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {timecard.mileagePrice ||
                                                                        timecard.mileagePrice === 0
                                                                        ? toMoney(dayMileage.mileageCost)
                                                                        : notAssignedFmt}
                                                                </span>
                                                            </td>
                                                        ))}
                                                        <td className="text-right">
                                                            <div style={durationWidth}>
                                                                <i className="las la-car-side"></i>{' '}
                                                                <strong>
                                                                    {timecard.weekMileageTotalMiles
                                                                        ? `${timecard.weekMileageTotalMiles.toFixed(2)}`
                                                                        : '0.00'}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {timecard.mileagePrice ||
                                                                        timecard.mileagePrice === 0
                                                                        ? toMoney(timecard.weekMileageCostTotal)
                                                                        : notAssignedFmt}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Timecard;
