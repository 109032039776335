import React, { createContext, useContext, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';

import { useAuth } from './useAuth';

interface SignalRContextInterface {
    isConnected: boolean;
    subscribe: (methodName: string, newMethod: (...args: any[]) => void) => void;
    unsubscribe: (methodName: string, newMethod: (...args: any[]) => void) => void;
}

const signalrURL = process.env.REACT_APP_SIGNALR_URL;

const createConnection = (tenant: string, token: string) => {
    return new signalR.HubConnectionBuilder()
        .withUrl(signalrURL!, {
            withCredentials: true,
            // We'll probably want a better way to track client type
            // but this gets us going for now.
            headers: {
                'X-WA-TenantId': tenant,
                'X-WA-ClientType': 'portal',
            },
            transport: signalR.HttpTransportType.LongPolling,
            accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();
};

const useSignalRProvider = (): SignalRContextInterface => {
    const { tenant, token } = useAuth();

    const [connection, setConnection] = useState<signalR.HubConnection>();
    const [isConnected, setIsConnected] = useState(false);

    const subscribe = (methodName: string, newMethod: (...args: any[]) => void) => {
        connection?.on(methodName, newMethod);
    };

    const unsubscribe = (methodName: string, newMethod: (...args: any[]) => void) => {
        connection?.off(methodName, newMethod);
    };

    useEffect(() => {
        if (tenant.id && token) {
            setConnection(createConnection(tenant.id, token));
        }
    }, [tenant.id, token]);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    setIsConnected(true);
                })
                .catch((error) =>
                    toast.error(`SignalR Connection failed: ${error}. If the error persist, contact your administrator.`, {
                        containerId: 'serverConnectionError',
                    })
                );
        }
    }, [connection]);

    useEffect(() => {
        if (!tenant.id && connection) {
            connection.stop();
        }
    }, [tenant.id, connection]);

    return {
        isConnected,
        subscribe,
        unsubscribe,
    };
};

const SignalRContext = createContext<SignalRContextInterface>({} as SignalRContextInterface);

export const SignalRProvider = ({ children }: { children: React.ReactNode }) => {
    const signalR = useSignalRProvider();

    return <SignalRContext.Provider value={signalR}>{children}</SignalRContext.Provider>;
};

export const useSignalR = () => {
    return useContext(SignalRContext);
};
