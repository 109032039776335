import Tippy from '@tippyjs/react';

const TippyToolTip = ({ tooltipText }: { tooltipText: string }) => {
    return (
        <Tippy
            content={<p className="py-1 px-3">{tooltipText}</p>}
            offset={[0, 2]}
            theme="light-border"
            placement="top-start"
            maxWidth="none"
            animation="shift-toward-extreme"
        >
            <span className="la la-info-circle"> </span>
        </Tippy>
    );
};

export default TippyToolTip;
