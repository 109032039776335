import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    PaginationState
} from '@tanstack/react-table';

import { Job, useTenants } from '../hooks/useTenants';
import { FormInputControl } from '../components/FormControls';
import AssignJobModal from '../components/AssignJobModal';
import { allowedJobActionEnum, JobsSortingFields, jobStatusEnum, recurringOptionEnum } from '../utilities/staticData';
import {
    formatFullName,
    sortByDate,
    sumDurations,
    toDuration,
    toShortDate,
    toTimeZoneTime,
    deserializeHiddenColumns,
} from '../utilities/formatter';
import { CloneJobModal, EditJobModal } from './JobModal';
import { useAuth } from '../hooks/useAuth';
import JobInfoModal from './JobInfoModal';
import { JobOverTimeBadge, JobStatusBadge } from '../pages/Jobs';
import { storage } from '../utilities/storage';
import {
    ColumnsPagination,
    ColumnsSorting,
    ColumnsVisibility,
    ExportReactTableToCSV,
} from '../components/TableFeatures';
import DeleteJobModal from './DeleteJobModal';
import Loading from './Loading';

interface FormInput {
    description?: string;
    customerName?: string;
    assignedEmployee?: string;
    jobDateFrom?: string;
    jobDateTo?: string;
    searchAll?: string;
    pageIndex?: number;
    pageSize?: number;
    sortingField?: number;
    isSortingDesc?: boolean;
}

const columnHelper = createColumnHelper<Job>();

const tableColumnsSchema = [
    columnHelper.accessor('customerName', {
        id: 'Customer',
        cell: ({ getValue }) => <div className="">{getValue()}</div>,
        header: (header) => (
            <div className="text-left uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('employees', {
        id: 'Employees',
        cell: ({ row }) => <EmployeesColumn employees={row.original.employees} />,
        header: (header) => (
            <div className="text-left uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
        sortDescFirst: false,
    }),
    columnHelper.accessor('jobDateText', {
        id: 'Job Date',
        cell: ({ row, getValue }) => (
            <div className="text-center">
                <div className="flex-col flex-wrap">
                    {row.original.recurrenceOption !== null && (
                        <div className="mt-1 ml-1 badge badge_outlined badge_info uppercase">
                            {recurringOptionEnum[row.original.recurrenceOption!]}
                        </div>
                    )}
                </div>
                {row.original.recurrenceOption === null && <>{getValue()}</>}
                {row.original.recurrenceOption !== null &&
                    row.original.recurrenceOption !== recurringOptionEnum['Multiple Days'] && (
                        <>
                            {toShortDate(row.original.jobDateRange.startDate)} -{' '}
                            {toShortDate(row.original.jobDateRange.endDate)}
                        </>
                    )}
                {row.original.recurrenceOption === recurringOptionEnum['Multiple Days'] && (
                    <MultipleDaysColumn dates={row.original?.jobDates || []} />
                )}
            </div>
        ),
        header: (header) => (
            <div className="text-center uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('durationText', {
        id: 'Duration',
        cell: ({ row }) => (
            <div className="text-center">
                Planned: {toDuration(row.original.duration)}
                {row.original.runTime && (
                    <div>
                        <strong>
                            <span className="las la-stopwatch"></span>
                            {row.original.status !== jobStatusEnum[jobStatusEnum.Running] &&
                                toDuration(row.original.runTime)}
                            {row.original.status === jobStatusEnum[jobStatusEnum.Running] && (
                                <RunningDuration initialDuration={row.original.runTime} />
                            )}
                        </strong>
                    </div>
                )}
                {row.original.timeIn && (
                    <div>
                        {toTimeZoneTime(row.original.timeIn, row.original.timeZoneId, true)}
                        {'\u00a0-\u00a0'}
                        {!row.original.timeOut
                            ? 'Not Yet'
                            : toTimeZoneTime(row.original.timeOut, row.original.timeZoneId, true)}
                    </div>
                )}
            </div>
        ),
        header: (header) => (
            <div className="text-center uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.accessor('status', {
        id: 'Status',
        cell: ({ getValue }) => (
            <div className="content-center">
                <JobStatusBadge status={getValue()} />
            </div>
        ),
        header: (header) => (
            <div className="text-center uppercase">
                <ColumnsSorting header={header} />
            </div>
        ),
    }),
    columnHelper.display({
        id: 'Tags',
        cell: ({ row }) => (
            <div className="text-center">
                <div className="flex-col flex-wrap">
                    {row.original.isOverTime && <JobOverTimeBadge />}
                    {row.original.isFloating && (
                        <div className="badge badge_outlined badge_success uppercase">Floating</div>
                    )}
                    {row.original.stripeInvoiceId && (
                        <div className="badge badge_info uppercase">Invoiced</div>
                    )}
                </div>
            </div>
        ),
    }),
];

const EmployeesColumn = ({
    employees,
}: {
    employees?: {
        userId: string;
        firstName: string;
        lastName: string;
        timeIn: Date;
        timeOut: Date;
        active: boolean;
    }[];
}) => {
    const { tenant } = useAuth();
    return (
        <>
            {employees
                ?.sort(function (a, b) {
                    return (
                        sortByDate(a.timeIn, b.timeIn) ||
                        formatFullName(a.firstName, a.lastName).localeCompare(formatFullName(b.firstName, b.lastName))
                    );
                })
                .map((employee, index) => (
                    <div key={index}>
                        {formatFullName(employee.firstName, employee.lastName)}
                        {!employee.active && (
                            <div className="badge badge_outlined badge_danger ml-1 mb-1">Inactive</div>
                        )}
                        {employee.timeIn && (
                            <div className="badge badge_outlined badge_primary ml-1 mb-1">
                                <i className="las la-clock mr-1"></i>
                                {toTimeZoneTime(employee.timeIn, tenant.timeZoneId, true)}
                                {employee.timeOut && (
                                    <>
                                        {'\u00a0-\u00a0'}
                                        {toTimeZoneTime(employee.timeOut, tenant.timeZoneId, true)}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ))}
        </>
    );
};

const MultipleDaysColumn = ({ dates }: { dates: Date[] }) => {
    return (
        <>
            {dates.map!((date, index) => (
                <div key={index}>{toShortDate(date)}</div>
            ))}
        </>
    );
};

const RunningDuration = ({ initialDuration }: { initialDuration: string }) => {
    const [runningDuration, setRunningDuration] = useState(initialDuration);

    useEffect(() => {
        setRunningDuration(initialDuration);
    }, [initialDuration]);

    useEffect(() => {
        const timer = setInterval(() => {
            setRunningDuration(sumDurations(runningDuration, '00:00:01.0'));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [runningDuration]);

    return <>{toDuration(runningDuration)}</>;
};

const ListViewJobs = (props: { globalFilter?: string; onClearGlobalFilter: () => void; shouldSearchAll: boolean; }) => {
    const { globalFilter, onClearGlobalFilter, shouldSearchAll } = props;

    const { user } = useAuth();

    const {
        register,
        handleSubmit,
        reset: resetClient,
        formState: { errors: clientErrors },
    } = useForm<FormInput>();

    const [toggleDropdown, setToggleDropdown] = useState<Boolean>(false);
    const [filterJob, setFilterJob] = useState<FormInput>({
        pageIndex: 0,
        pageSize: 10
    });

    const [{ pageIndex, pageSize }, setPagination] =
        useState<PaginationState>({
            pageIndex: 0,
            pageSize: 10,
        });

    const { useGetJobs } = useTenants();
    const { data, isSuccess, isFetching } = useGetJobs(filterJob);
    const [rowSelection, setRowSelection] = useState({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]);
    const fixedHiddenColumns = useMemo(() => [], []);
    const initialHiddenColumns = useMemo(() => [], []);
    const storeTableVisibility = useMemo(
        () => storage<{}>(`workalert.state.job_table_visibility.${user.id}`),
        [user.id]
    );

    const defaultData = useMemo(() => [], []);

    const table = useReactTable({
        data: data?.jobs || defaultData,
        columns: tableColumnsSchema,
        pageCount: data?.pageCount,
        manualPagination: true,
        manualSorting: true,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            sorting,
            columnVisibility,
            pagination
        },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const onSubmitFilter = (data: FormInput) => {
        onClearGlobalFilter();
        setFilterJob({
            description: data.description !== '' ? data.description : undefined,
            jobDateFrom: data.jobDateFrom !== '' ? data.jobDateFrom : undefined,
            jobDateTo: data.jobDateTo !== '' ? data.jobDateTo : undefined,
            customerName: data.customerName !== '' ? data.customerName : undefined,
            assignedEmployee: data.assignedEmployee !== '' ? data.assignedEmployee : undefined,
            pageIndex: 0,
            pageSize: pageSize,
            sortingField: JobsSortingFields.find(f => f.name === sorting[0]?.id)?.value,
            isSortingDesc: sorting[0]?.desc
        });
        setPagination({ pageIndex: 0, pageSize: pageSize });
    };

    const onResetForm = () => {
        resetClient({
            description: '',
            customerName: '',
            assignedEmployee: '',
            jobDateFrom: '',
            jobDateTo: '',
            searchAll: ''
        });
        onSubmitFilter({});
    };

    useEffect(() => {
        setFilterJob((prevFilterJob) => ({
            ...prevFilterJob,
            pageIndex: pageIndex,
            pageSize: pageSize
        }));
    }, [pageIndex, pageSize]);

    useEffect(() => {
        setFilterJob((prevFilterJob) => ({
            ...prevFilterJob,
            pageIndex: 0,
            sortingField: JobsSortingFields.find(f => f.name === sorting[0]?.id)?.value,
            isSortingDesc: sorting[0]?.desc
        }));

        setPagination((prevPagination) => ({
            ...prevPagination,
            pageIndex: 0
        }));
    }, [sorting]);

    useEffect(() => {
        setRowSelection({});
    }, [data, isSuccess]);

    useEffect(() => {
        if (shouldSearchAll) {
            setFilterJob((prevFilterJob) => ({
                searchAll: globalFilter,
                pageIndex: 0,
                pageSize: prevFilterJob.pageSize,
                sortingField: prevFilterJob.sortingField,
                isSortingDesc: prevFilterJob.isSortingDesc,
            }));

            setPagination((prevPagination) => ({
                ...prevPagination,
                pageIndex: 0
            }));

            resetClient({
                description: '',
                customerName: '',
                assignedEmployee: '',
                jobDateFrom: '',
                jobDateTo: '',
                searchAll: ''
            });
        }
    }, [shouldSearchAll, globalFilter, resetClient]);

    useEffect(() => {
        if (columnVisibility && Object.keys(columnVisibility).length !== 0) {
            const tableVisibility = storeTableVisibility.get() || {};
            if (!_.isEqual(columnVisibility, tableVisibility)) {
                storeTableVisibility.set(columnVisibility);
            }
        }
    }, [columnVisibility, storeTableVisibility]);

    useEffect(() => {
        if (isSuccess) {
            let tableVisibility = storeTableVisibility.get() || {};
            if (Object.keys(tableVisibility).length === 0) {
                tableVisibility = deserializeHiddenColumns(fixedHiddenColumns.concat(initialHiddenColumns));
            }
            setColumnVisibility(tableVisibility);
        }
    }, [isSuccess, storeTableVisibility, fixedHiddenColumns, initialHiddenColumns]);

    useEffect(() => {
        if (!isFetching) {
            table.setPageIndex(pageIndex);
        }
    }, [isFetching, pageIndex, table]);

    return (
        <>
            <div className="card p-5 mb-5">
                <div className="accordion">
                    <h5
                        className={classNames('header', {
                            active: toggleDropdown,
                        })}
                        onClick={() => setToggleDropdown(!toggleDropdown)}
                    >
                        Search by
                        <span className="collapse-indicator la la-arrow-circle-down"></span>
                    </h5>
                    <div
                        className={classNames('flex flex-col collapse', {
                            open: toggleDropdown,
                        })}
                    >
                        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-5 mt-5">
                            <div>
                                <FormInputControl
                                    type="text"
                                    label="Description"
                                    register={register('description')}
                                    clientErrors={clientErrors?.description}
                                />
                            </div>
                            <div>
                                <FormInputControl
                                    type="date"
                                    label="Job Date From"
                                    register={register('jobDateFrom')}
                                    clientErrors={clientErrors?.jobDateFrom}
                                />
                            </div>
                            <div>
                                <FormInputControl
                                    type="date"
                                    label="Job Date To"
                                    register={register('jobDateTo')}
                                    clientErrors={clientErrors?.jobDateTo}
                                />
                            </div>
                            <div>
                                <FormInputControl
                                    type="text"
                                    label="Customer"
                                    register={register('customerName')}
                                    clientErrors={clientErrors?.customerName}
                                />
                            </div>
                            <div>
                                <FormInputControl
                                    type="text"
                                    label="Employee"
                                    register={register('assignedEmployee')}
                                    clientErrors={clientErrors?.assignedEmployee}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 mt-5 ml-auto">
                            <button type="submit" className="btn btn_secondary uppercase" onClick={onResetForm}>
                                Clear
                            </button>
                            <button
                                type="submit"
                                className="btn btn_primary uppercase"
                                onClick={handleSubmit(onSubmitFilter)}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    <Loading isLoading={isFetching} boxStyle overlapped />
                    <table className="table table-auto table_hoverable w-full">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    <th>
                                        <AssignJobModal
                                            disabled={table.getFilteredSelectedRowModel().rows.length <= 0}
                                            jobs={table.getFilteredSelectedRowModel().rows.map((row) => ({
                                                id: row.original.id,
                                                title: row.original.title,
                                                employees: row.original.employees?.map((employee) => employee.userId),
                                            }))}
                                            multiple={true}
                                        >
                                            <span className="las la-user-check"></span>
                                        </AssignJobModal>
                                    </th>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    className={`${header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                                        }`}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                    <th className="text-right">
                                        <ExportReactTableToCSV title={'Jobs'} reacTable={table} />
                                        <ColumnsVisibility
                                            columns={table.getAllLeafColumns()}
                                            fixedHiddenColumns={fixedHiddenColumns}
                                        />
                                    </th>
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {isSuccess &&
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.original.id}>
                                        <td>
                                            <label className="custom-table-checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={row.getIsSelected()}
                                                    onChange={row.getToggleSelectedHandler()}
                                                    disabled={
                                                        !row.original.allowedJobActions?.some(
                                                            (a) =>
                                                                a in
                                                                [
                                                                    allowedJobActionEnum.Assign,
                                                                    allowedJobActionEnum.Unassign,
                                                                ]
                                                        )
                                                    }
                                                />
                                                <span></span>
                                            </label>
                                        </td>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                        <td className="whitespace-nowrap">
                                            <div className="inline-flex ml-auto">
                                                <EditJobModal jobId={row.original.id} />
                                                <JobInfoModal jobId={row.original.id} />
                                                <CloneJobModal jobId={row.original.id} />
                                                <AssignJobModal
                                                    disabled={
                                                        !row.original.allowedJobActions?.some(
                                                            (a) =>
                                                                a in
                                                                [
                                                                    allowedJobActionEnum.Assign,
                                                                    allowedJobActionEnum.Unassign,
                                                                ]
                                                        )
                                                    }
                                                    jobs={[
                                                        {
                                                            id: row.original.id,
                                                            title: row.original.title,
                                                            employees: row.original.employees?.map(
                                                                (employee) => employee.userId
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <span className="las la-user-check"></span>
                                                </AssignJobModal>
                                                <DeleteJobModal
                                                    disabled={
                                                        !row.original.allowedJobActions?.some(
                                                            (a) => a === allowedJobActionEnum.Delete
                                                        )
                                                    }
                                                    job={{
                                                        id: row.original.id,
                                                        title: row.original.title,
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card mt-5 lg:flex">
                <ColumnsPagination
                    table={table}
                    rowsSelected={Object.keys(rowSelection).length}
                    onPageChanged={() => null}
                    totalCount={data?.totalCount}
                />
            </div>
        </>
    );
};

export default ListViewJobs;
