import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import TenantSelection from '../pages/TenantSelection';
import BasicLayout from './BasicLayout';
import Loading from './Loading';

const PrivateRoute = () => {
    const { isAuthenticated, hasToken, hasTenant, isLoading } = useAuth();
    const location = useLocation();

    if (isLoading) {
        return (
            <BasicLayout>
                <Loading isLoading={true} />
            </BasicLayout>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!hasToken) {
        return (
            <BasicLayout>
                <Loading isLoading={true} />
            </BasicLayout>
        );
    }

    if (!hasTenant) {
        return <TenantSelection />;
    }

    return <Outlet />;
};

export default PrivateRoute;
