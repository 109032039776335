import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import Select, { components } from 'react-select';

import useOutsideClick from '../hooks/useOutsideClick';
import Avatar from './Avatar';

interface FormControlProps {
    label: string;
    register: {
        onChange: (event: any) => void;
        value?: any;
        ref: (instance: any) => void;
        name: string;
    };
    serverErrors?: string[];
}

interface FormInputControlProps extends FormControlProps {
    type: string;
    clientErrors?: FieldError;
    disabled?: boolean;
    onChange?: (value?: string) => void;
    step?: string;
    tippyChild?: React.ReactNode;
}

interface FormSelectControlProps extends FormControlProps {
    options?: {
        value: string;
        text: string;
    }[];
    onChange?: (value?: string) => void;
    hideEmpty?: boolean;
    disabled?: boolean;
    clientErrors?: FieldError;
    initialValue?: string;
}

interface FormInputAutocompleteMultiSelectControlProps extends FormControlProps {
    options?: {
        value: string;
        text: string;
        active: boolean;
    }[];
    onChange?: (value: { value: string; text: string }[]) => void;
    hideEmpty?: boolean;
    disabled?: boolean;
    clientErrors?: FieldError;
    initialValues?: {
        value: string;
        text: string;
    }[];
    placementSelectedAtBottom?: boolean;
    tippyChild?: React.ReactNode;
}

interface FormMultiSelectEmployeesControlProps extends FormControlProps {
    options?: {
        value: string;
        label: string;
        img?: string;
    }[];
    onChange?: (value: { value: string; text: string }[]) => void;
    hideEmpty?: boolean;
    disabled?: boolean;
    clientErrors?: FieldError;
    initialValues?: {
        value: string;
        label: string;
    }[];
    tippyChild?: React.ReactNode;
}

interface SelectedOptionsBadgesProps {
    selectedoptions?: {
        value: string;
        text: string;
    }[];
    onClick: (value: { value: string; text: string }) => void;
    disabled?: boolean;
}

interface FormNumberControlProps extends FormControlProps {
    min?: string;
    max?: string;
    step?: string;
    options?: number[];
    clientErrors?: FieldError;
}

interface FormInputCheckBoxProps extends FormControlProps {
    onChange?: (value?: any) => void;
    clientErrors?: FieldError;
    tippyChild?: React.ReactNode;
    disabled?: boolean;
}

interface FormInputDateControlProps {
    label: string;
    serverErrors?: string[];
    clientErrors?: FieldError;
    disabled?: boolean;
    onChange?: (value?: string) => void;
    minValue?: string;
    maxValue?: string;
    initialValue?: string;
    includeDeselect?: boolean;
    isRoundedInput?: boolean;
    placeholder?: string;
}

interface CustomInputDateControlProps {
    openCalendar?: any;
    handleValueChange?: any;
    value?: string;
    className?: string;
    disabled?: boolean;
    isRoundedInput?: boolean;
    placeholder?: string;
}

interface FormInputMultipleDateControlProps {
    label: string;
    serverErrors?: string[];
    clientErrors?: FieldError;
    onChange?: (value: any[]) => void;
    hideEmpty?: boolean;
    disabled?: boolean;
    initialValues?: any[];
    closePickerOnChange?: boolean;
}

export const FormInputControl = (props: FormInputControlProps) => {
    const { label, type, step, register, onChange, clientErrors, serverErrors, tippyChild, disabled } = props;

    const { onChange: registerOnChange } = register;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        registerOnChange(event);

        if (onChange) {
            onChange(event?.target.value);
        }
    };

    const handleOnFocus = (event: any) => {
        if (type === 'date') {
            handleOnFocusDatePicker(event);
        }
    };

    return (
        <>
            <label className="label block mb-2">
                {label}
                {tippyChild}
            </label>
            <input
                step={step}
                type={type}
                className={classNames(
                    'form-control',
                    { 'is-invalid': !!clientErrors || !!serverErrors },
                    { 'opacity-75 cursor-not-allowed': disabled }
                )}
                {...register}
                disabled={disabled}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onClick={handleOnFocus}
            />
            {clientErrors?.message && <small className="block mt-1 invalid-feedback whitespace-normal break-words">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback whitespace-normal break-words">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormTextareaControl = (props: FormInputControlProps) => {
    const { label, register, clientErrors, serverErrors } = props;

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <textarea
                className={classNames('form-control', {
                    'is-invalid': !!clientErrors || !!serverErrors,
                })}
                {...register}
            />
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormSelectControl = (props: FormSelectControlProps) => {
    const { label, register, options, onChange, hideEmpty, disabled, clientErrors, serverErrors } = props;

    const { onChange: registerOnChange, ...registerBind } = register;

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        registerOnChange(event);

        if (onChange) {
            onChange(event?.target.value);
        }
    };

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <div className="custom-select">
                <select
                    disabled={disabled}
                    className={classNames(
                        'form-control',
                        { 'is-invalid': !!clientErrors || !!serverErrors },
                        { 'opacity-75 cursor-not-allowed': disabled }
                    )}
                    onChange={handleOnChange}
                    {...registerBind}
                >
                    <option value="" hidden={hideEmpty}></option>
                    {options?.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
                <div className="custom-select-icon la la-caret-down" />
            </div>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormNumberControl = (props: FormNumberControlProps) => {
    const { label, register, options, min, max, step, clientErrors, serverErrors } = props;

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <input
                list={register.name}
                type="number"
                min={min}
                max={max}
                step={step}
                autoComplete="off"
                className={classNames('form-control', {
                    'is-invalid': !!clientErrors || !!serverErrors,
                })}
                {...register}
            />
            <datalist id={register.name}>
                {options?.map((value, index) => (
                    <option key={index} value={value} />
                ))}
            </datalist>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback whitespace-normal break-words">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback whitespace-normal break-words">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormInputAutocompleteControl = (props: FormSelectControlProps) => {
    const { label, register, initialValue, options, onChange, disabled, clientErrors, serverErrors } = props;

    const [value, setValue] = useState('');

    const { onChange: registerOnChange, ...registerBind } = register;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event?.target.value);

        registerOnChange(event);
        if (onChange) {
            const selected = options?.find((x) => x.text === event?.target.value);
            onChange(selected?.value);
        }
    };

    useEffect(() => {
        setValue(initialValue || '');
    }, [initialValue]);

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <label
                className={classNames(
                    'form-control-addon-within',
                    { 'is-invalid': !!clientErrors || !!serverErrors },
                    { 'opacity-75': disabled }
                )}
            >
                <input
                    disabled={disabled}
                    list={register.name}
                    type="text"
                    className={classNames('form-control', 'border-none', { 'cursor-not-allowed': disabled })}
                    onChange={handleOnChange}
                    value={value}
                    {...registerBind}
                />
                <datalist id={register.name}>
                    {options?.map((option, index) => (
                        <option key={index} value={option.text} />
                    ))}
                </datalist>
                {!disabled && value && (
                    <button
                        type="button"
                        className="text-gray-300 dark:text-gray-700 text-xl leading-none la la-times mr-4"
                        onClick={() => {
                            setValue('');
                            if (onChange) {
                                onChange('');
                            }
                        }}
                    ></button>
                )}
            </label>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

const SelectedOptionsBadges = (props: SelectedOptionsBadgesProps) => {
    const { selectedoptions, disabled, onClick: action } = props;

    const onClickBadget = async (data: { value: string; text: string }) => {
        action(data);
    };

    return (
        <span className="flex-row flew-wrap h-auto items-center ltr:pl-4 rtl:pr-4">
            {selectedoptions?.map((result, index) => (
                <span
                    key={index}
                    className={`badge ${disabled ? 'badge_secondary cursor-not-allowed' : 'badge_primary'} mr-2 mt-2`}
                >
                    {result.text}
                    <button
                        disabled={disabled}
                        type="button"
                        className={`ltr:ml-1 rtl:mr-1 ${disabled ? '' : 'la la-times'}`}
                        onClick={() => onClickBadget(result)}
                        tabIndex={-1}
                    ></button>
                </span>
            ))}
        </span>
    );
};

export const FormInputAutocompleteMultiSelectControl = (props: FormInputAutocompleteMultiSelectControlProps) => {
    const {
        label,
        options,
        onChange,
        clientErrors,
        serverErrors,
        initialValues,
        disabled,
        placementSelectedAtBottom,
        tippyChild,
    } = props;

    const [selectedOptions, setSelectedOptions] = useState<{ value: string; text: string }[]>([]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selected = options?.find((x) => x.text === event?.target.value);
        if (selected !== undefined) {
            if (!selectedOptions.includes(selected)) {
                var newSelectedOptions = [...selectedOptions, selected];
                setSelectedOptions(newSelectedOptions);
                if (onChange) {
                    onChange(newSelectedOptions);
                }
            }
            event.target.value = '';
        }
    };

    const onItemDelete = (item: { value: string; text: string }) => {
        const newSelectedOptions = selectedOptions.filter((element) => element.value !== item.value);
        setSelectedOptions(newSelectedOptions);
        if (onChange) {
            onChange(newSelectedOptions);
        }
    };

    useEffect(() => {
        if (initialValues) {
            setSelectedOptions((selectedOptions) => [...initialValues!]);
        }
    }, [initialValues, setSelectedOptions]);

    return (
        <>
            <label className="label block mb-2">
                {label} {tippyChild}
            </label>

            {!placementSelectedAtBottom && (
                <SelectedOptionsBadges selectedoptions={selectedOptions} disabled={disabled} onClick={onItemDelete} />
            )}

            <label
                className="form-control-addon-within flex-row-reverse border-0  mt-2"
                data-toggle="search-select"
                aria-expanded="true"
            >
                <input
                    disabled={disabled}
                    list="multi"
                    type="text"
                    className={classNames(
                        'form-control',
                        { 'is-invalid': !!clientErrors || !!serverErrors },
                        'min-w-180',
                        {
                            'opacity-75 cursor-not-allowed': disabled,
                        }
                    )}
                    onChange={handleOnChange}
                />
                <datalist id="multi">
                    {options
                        ?.filter(
                            (optionObject) => !selectedOptions?.some((object) => object.value === optionObject.value)
                        )
                        .map((option, index) => (
                            <option key={index} value={option.text}>
                                {option.active ? "" : "Inactive"}
                            </option>
                        ))}
                </datalist>
            </label>

            {placementSelectedAtBottom && (
                <SelectedOptionsBadges selectedoptions={selectedOptions} disabled={disabled} onClick={onItemDelete} />
            )}

            {clientErrors && (
                <small className="block mt-1 invalid-feedback">{(clientErrors as unknown as FieldError).message}</small>
            )}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormMultiSelectEmployeesControl = (props: FormMultiSelectEmployeesControlProps) => {
    const {
        label,
        options,
        onChange,
        clientErrors,
        serverErrors,
        initialValues,
        disabled,
        tippyChild,
    } = props;

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            border: !!clientErrors || !!serverErrors ? '1px solid #DC3545' : state.isFocused ? '1px solid #0284C7' : '1px solid #D1D5DB',
            boxShadow: 'none',
            opacity: state.isDisabled ? 0.75 : 1,
            '&:hover': {
                border: state.isFocused ? '1px solid #0284C7' : '',
            },
        }),
        multiValue: (base: any) => ({
            ...base,
            backgroundColor: '#0284C7',
            color: 'white',
            borderRadius: '14px',
            height: '20px',
        }),
        multiValueLabel: (base: any) => ({
            ...base,
            color: 'white',
            padding: '0px',
        }),
        multiValueRemove: (base: any) => ({
            ...base,
            color: 'white',
            ':hover': {
            },
        }),
    };

    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const customOption: React.FC<any> = (props) => {
        return (
            <components.Option {...props}>
                <div className="flex items-center">
                    {props.data.value !== 'selectAll' && (
                        <Avatar fullName={props.data.label} imageURL={props.data.img} size="sm" />
                    )}
                    <div className="ml-2">
                        <p className="text-md font-bold">{props.data.label}</p>
                    </div>
                </div>
            </components.Option>
        );
    };

    const handleSelectChange = (selectedOptions: any) => {
        const isSelectAllOptionSelected = selectedOptions.some(
            (option: any) => option.value === 'selectAll'
        );

        var newSelectedOptions = isSelectAllOptionSelected ? options : selectedOptions;

        if (isSelectAllOptionSelected || selectedOptions.length === options?.length)
            setIsMenuOpen(false);

        setSelectedOptions(newSelectedOptions);

        if (onChange) {
            onChange(newSelectedOptions);
        }
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    const handleOpenMenu = () => {
        setIsMenuOpen(true);
    };

    useEffect(() => {
        if (initialValues) {
            setSelectedOptions(() => [...initialValues!]);
        }
    }, [initialValues, setSelectedOptions]);

    return (
        <>
            <label className="label block mb-2">
                {label} {tippyChild}
            </label>
            <Select
                isDisabled={disabled}
                closeMenuOnSelect={false}
                components={{ Option: customOption }}
                isMulti
                options={[{ value: 'selectAll', label: 'Select All' }, ...(options || [])]}
                value={selectedOptions}
                onChange={handleSelectChange}
                placeholder=""
                styles={customStyles}
                menuPortalTarget={document.parentElement}
                menuPosition={'fixed'}
                menuIsOpen={isMenuOpen}
                onMenuClose={handleCloseMenu}
                onMenuOpen={handleOpenMenu}
            />
            {clientErrors && (
                <small className="block mt-1 invalid-feedback">{(clientErrors as unknown as FieldError).message}</small>
            )}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export const FormInputCheckBoxControl = (props: FormInputCheckBoxProps) => {
    const { label, onChange, register, clientErrors, serverErrors, tippyChild, disabled } = props;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event?.target.checked);
        }
    };

    return (
        <>
            <label className="label block mb-2">
                {label}
                {tippyChild}
            </label>
            <label
                className={classNames('switch', {
                    'opacity-50 cursor-not-allowed': disabled,
                })}
            >
                <input
                    disabled={disabled}
                    type="checkbox"
                    className={classNames('form-control', {
                        'is-invalid': !!clientErrors || !!serverErrors,
                    })}
                    {...register}
                    onChange={handleOnChange}
                />
                <span></span>
            </label>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

export const handleOnFocusDatePicker = (event: any) => {
    if (event) {
        if ('showPicker' in HTMLInputElement.prototype) {
            if ('showPicker' in event.target) {
                try {
                    event.target.showPicker();
                } catch { }
            }
        }
    }
};

export const FormInputDateControl = (props: FormInputDateControlProps) => {
    const {
        label,
        onChange,
        clientErrors,
        serverErrors,
        disabled,
        minValue,
        maxValue,
        initialValue,
        includeDeselect,
        isRoundedInput,
        placeholder,
    } = props;

    const [dateInPicker, setDateInPicker] = useState<DateObject>();
    let ref = React.useRef();

    const handleOnChange = (dateObject: DateObject) => {
        setDateInPicker(dateObject);

        handleClickOutside();
    };

    const handleClickOutside = useCallback(() => {
        if (ref) {
            let reference: any = ref;
            reference.current.closeCalendar();
        }
    }, []);
    ref = useOutsideClick(handleClickOutside);

    useEffect(() => {
        if (initialValue) {
            const dateInitial = new DateObject(initialValue!);
            setDateInPicker(dateInitial);
        } else {
            setDateInPicker(undefined);
        }
    }, [initialValue]);

    useEffect(() => {
        if (onChange) {
            onChange(dateInPicker ? dateInPicker!.format('YYYY-MM-DD') : undefined);
        }
    }, [dateInPicker, onChange]);

    return (
        <>
            {label && <label className="label block mb-2">{label}</label>}
            <DatePicker
                className=""
                render={
                    <CustomInputDate
                        placeholder={placeholder}
                        isRoundedInput={isRoundedInput}
                        className={classNames({
                            'is-invalid': !!clientErrors || !!serverErrors,
                        })}
                        disabled={disabled}
                    />
                }
                ref={ref}
                arrow={true}
                format="MM/DD/YYYY"
                hideOnScroll
                sort
                value={dateInPicker}
                onChange={handleOnChange}
                minDate={minValue}
                maxDate={maxValue}
            >
                {includeDeselect && (
                    <button
                        style={{ margin: '10px' }}
                        onClick={() => {
                            setDateInPicker(undefined);
                            handleClickOutside();
                        }}
                    >
                        <u>Clear</u>
                    </button>
                )}
                <button style={{ margin: '10px' }} onClick={handleClickOutside} onFocus={handleClickOutside}>
                    <u>Close</u>
                </button>
            </DatePicker>
            <div tabIndex={0} onFocus={handleClickOutside}></div>

            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};

const CustomInputDate = (props: CustomInputDateControlProps) => {
    const { openCalendar, value, handleValueChange, className, disabled, isRoundedInput, placeholder } = props;

    return (
        <>
            <label
                style={{ minWidth: '150px' }}
                className={classNames('form-control-addon-within min-w-150', className, {
                    'opacity-75 cursor-not-allowed': disabled,
                    'rounded-full': isRoundedInput,
                })}
            >
                <input
                    style={{ borderColor: 'transparent' }}
                    className={classNames('form-control', {
                        'opacity-75 cursor-not-allowed': disabled,
                    })}
                    placeholder={placeholder}
                    onFocus={openCalendar}
                    value={value || ''}
                    disabled={disabled}
                    onChange={handleValueChange}
                    onKeyDown={(e) => {
                        if (e.keyCode !== 9 || e.which !== 9) {
                            e.preventDefault();
                        }
                    }}
                />
                <button
                    type="button"
                    tabIndex={-1}
                    className={classNames(
                        'btn btn-link text-gray-900 dark:text-gray-900 dark:hover:text-primary text-xl leading-none  mr-2  pointer-events-none la la-calendar',
                        {
                            'opacity-75 cursor-not-allowed': disabled,
                        }
                    )}
                ></button>
            </label>
        </>
    );
};

export const FormInputMultipleDateControl = (props: FormInputMultipleDateControlProps) => {
    const { label, onChange, clientErrors, serverErrors, initialValues, closePickerOnChange } = props;

    const [datesInPicker, setDatesInPicker] = useState<DateObject[]>([]);

    const [selectedDates, setSelectedDates] = useState<string[]>([]);

    const handleOnChange = (dateObjects: DateObject[]) => {
        setDatesInPicker(dateObjects);

        if (closePickerOnChange === true) {
            if (ref) {
                let reference: any = ref;
                reference.current.closeCalendar();
            }
        }
    };

    const onItemDelete = (item: string) => {
        const dateToDelete = new DateObject(item!);

        var found = datesInPicker.find(
            (element) => element?.format('YYYY-MM-DD') === dateToDelete?.format('YYYY-MM-DD')
        );
        var newSelectedDates = datesInPicker.filter((element) => element !== found);

        setDatesInPicker(newSelectedDates);
    };

    const handleClickOutside = () => {
        if (ref) {
            let reference: any = ref;
            reference.current.closeCalendar();
        }
    };
    const ref = useOutsideClick(handleClickOutside);

    useEffect(() => {
        if (initialValues?.length! > 0) {
            setSelectedDates((selectedDates) => [...initialValues!].sort((a, b) => a.localeCompare(b)));
        }
    }, [initialValues, setSelectedDates]);

    useEffect(() => {
        setSelectedDates([]);

        const newSelected: string[] = [];

        datesInPicker.forEach((selected) => {
            newSelected.push(selected?.format('YYYY-MM-DD'));
        });
        setSelectedDates(newSelected);

        if (onChange) {
            onChange(newSelected);
        }
    }, [datesInPicker, onChange]);

    return (
        <>
            <label className="label block mb-2">{label}</label>
            <DatePicker
                className=""
                render={
                    <InputIcon
                        className={classNames('form-control', {
                            'is-invalid': !!clientErrors || !!serverErrors,
                        })}
                    />
                }
                ref={ref}
                arrow={true}
                sort
                multiple
                hideOnScroll
                value={datesInPicker}
                onChange={handleOnChange}
            >
                <button style={{ margin: '10px' }} onClick={handleClickOutside} onFocus={handleClickOutside}>
                    <u>Close</u>
                </button>
            </DatePicker>
            <div tabIndex={0} onFocus={handleClickOutside}></div>

            <span className="flex-row flew-wrap h-auto items-center ltr:pl-4 rtl:pr-4">
                {selectedDates?.map((result, index) => (
                    <span key={index} className="badge badge_primary mb-1 mr-2 mt-2 ">
                        {result}
                        <button
                            tabIndex={-1}
                            type="button"
                            className="ltr:ml-1 rtl:mr-1 la la-times"
                            onClick={() => onItemDelete(result)}
                        ></button>
                    </span>
                ))}
            </span>
            {clientErrors?.message && <small className="block mt-1 invalid-feedback">{clientErrors?.message}</small>}
            {serverErrors?.map((error, index) => (
                <small key={index} className="block mt-1 invalid-feedback">
                    {error}
                </small>
            ))}
        </>
    );
};
